import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = { key: 0 }
const _hoisted_2 = { key: 1 }
const _hoisted_3 = { key: 2 }
const _hoisted_4 = { key: 3 }
const _hoisted_5 = { key: 4 }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_MyProfile = _resolveComponent("MyProfile")!
  const _component_HelpCenter = _resolveComponent("HelpCenter")!
  const _component_Orders = _resolveComponent("Orders")!
  const _component_Settings = _resolveComponent("Settings")!
  const _component_Notifications = _resolveComponent("Notifications")!

  return (_openBlock(), _createElementBlock("div", null, [
    (_ctx.menu_choice === 'Profile')
      ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
          _createVNode(_component_MyProfile, { id: "profile" })
        ]))
      : _createCommentVNode("", true),
    (_ctx.menu_choice === 'HelpCenter')
      ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
          _createVNode(_component_HelpCenter, { id: "help" })
        ]))
      : _createCommentVNode("", true),
    (
        _ctx.menu_choice === 'Orders' ||
        _ctx.menu_choice === 'Ongoing' ||
        _ctx.menu_choice === 'History' ||
        _ctx.menu_choice === 'Drafts'
      )
      ? (_openBlock(), _createElementBlock("div", _hoisted_3, [
          _createVNode(_component_Orders, { id: "orders" })
        ]))
      : _createCommentVNode("", true),
    (_ctx.menu_choice === 'Settings')
      ? (_openBlock(), _createElementBlock("div", _hoisted_4, [
          _createVNode(_component_Settings, { id: "settings" })
        ]))
      : _createCommentVNode("", true),
    (_ctx.menu_choice === 'Notifications')
      ? (_openBlock(), _createElementBlock("div", _hoisted_5, [
          _createVNode(_component_Notifications, { id: "notifications" })
        ]))
      : _createCommentVNode("", true)
  ]))
}