import { createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createBlock as _createBlock, createCommentVNode as _createCommentVNode, vModelText as _vModelText, withDirectives as _withDirectives, withModifiers as _withModifiers, createVNode as _createVNode, createStaticVNode as _createStaticVNode } from "vue"
import _imports_0 from '../assets/images/icons/logonew.png'
import _imports_1 from '../assets/images/icons/appstore.png'
import _imports_2 from '../assets/images/icons/playstore.png'


const _hoisted_1 = { class: "bg-white" }
const _hoisted_2 = { class: "mx-0 w-full max-w-screen-xl py-6 px-4 lg:p-4 lg:py-8" }
const _hoisted_3 = { class: "md:flex md:justify-between" }
const _hoisted_4 = { class: "mb-6 md:mb-0" }
const _hoisted_5 = { class: "my-6 gap-4 flex flex-col" }
const _hoisted_6 = {
  key: 0,
  class: "flex flex-col gap-2"
}
const _hoisted_7 = {
  key: 1,
  class: "flex flex-col gap-2"
}
const _hoisted_8 = {
  key: 2,
  class: "flex flex-col gap-2"
}
const _hoisted_9 = { id: "newsletter-container" }
const _hoisted_10 = { id: "emailbutton" }
const _hoisted_11 = { class: "grid grid-cols-1 gap-8 w-full lg:w-[60%] md:w-[70%] sm:gap-6 sm:grid-cols-3" }
const _hoisted_12 = { class: "text-gray-500 font-medium" }
const _hoisted_13 = { class: "mb-4" }
const _hoisted_14 = { class: "mb-4" }
const _hoisted_15 = { class: "mb-4" }
const _hoisted_16 = { class: "mb-4" }
const _hoisted_17 = { class: "mb-4" }
const _hoisted_18 = { class: "text-gray-500 font-medium" }
const _hoisted_19 = { class: "mb-4" }
const _hoisted_20 = { class: "mb-4" }
const _hoisted_21 = { class: "mb-4" }
const _hoisted_22 = { class: "text-gray-500 font-medium" }
const _hoisted_23 = { class: "mb-4" }
const _hoisted_24 = { class: "flex mt-4 sm:mt-0" }
const _hoisted_25 = { key: 0 }
const _hoisted_26 = {
  key: 1,
  class: "flex gap-2 text-primary h-4 w-4 [&>*]:disabled:cursor-progress"
}
const _hoisted_27 = ["href"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_router_link = _resolveComponent("router-link")!
  const _component_mdicon = _resolveComponent("mdicon")!

  return (_openBlock(), _createElementBlock("footer", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, [
        _createElementVNode("div", _hoisted_4, [
          _createElementVNode("div", null, [
            _cache[6] || (_cache[6] = _createElementVNode("img", {
              src: _imports_0,
              alt: "Fondstore logo",
              class: "w-52"
            }, null, -1)),
            _createElementVNode("div", _hoisted_5, [
              _cache[5] || (_cache[5] = _createElementVNode("h2", { class: "text-sm font-bold text-gray-900 uppercase" }, " ConTact ", -1)),
              (_ctx.helpCenterStore.helpCenterData)
                ? (_openBlock(), _createElementBlock("div", _hoisted_6, [
                    _cache[2] || (_cache[2] = _createElementVNode("b", null, "Email: ", -1)),
                    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.helpCenterStore.helpCenterData, (data) => {
                      return (_openBlock(), _createBlock(_component_router_link, { to: "" }, {
                        default: _withCtx(() => [
                          _createTextVNode(_toDisplayString(data.email), 1)
                        ]),
                        _: 2
                      }, 1024))
                    }), 256))
                  ]))
                : _createCommentVNode("", true),
              (_ctx.helpCenterStore.helpCenterData)
                ? (_openBlock(), _createElementBlock("div", _hoisted_7, [
                    _cache[3] || (_cache[3] = _createElementVNode("b", null, "Phone: ", -1)),
                    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.helpCenterStore.helpCenterData, (data) => {
                      return (_openBlock(), _createBlock(_component_router_link, { to: "" }, {
                        default: _withCtx(() => [
                          _createTextVNode(_toDisplayString(data.phone_number), 1)
                        ]),
                        _: 2
                      }, 1024))
                    }), 256))
                  ]))
                : _createCommentVNode("", true),
              (_ctx.helpCenterStore.helpCenterData)
                ? (_openBlock(), _createElementBlock("div", _hoisted_8, [
                    _cache[4] || (_cache[4] = _createElementVNode("b", null, "Address: ", -1)),
                    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.helpCenterStore.helpCenterData, (data) => {
                      return (_openBlock(), _createBlock(_component_router_link, { to: "" }, {
                        default: _withCtx(() => [
                          _createTextVNode(_toDisplayString(data.address), 1)
                        ]),
                        _: 2
                      }, 1024))
                    }), 256))
                  ]))
                : _createCommentVNode("", true)
            ])
          ]),
          _createElementVNode("div", _hoisted_9, [
            _cache[7] || (_cache[7] = _createElementVNode("p", null, "Subscribe to our newsletter", -1)),
            _createElementVNode("form", {
              onSubmit: _cache[1] || (_cache[1] = _withModifiers(
//@ts-ignore
(...args) => (_ctx.subScribe && _ctx.subScribe(...args)), ["prevent"]))
            }, [
              _withDirectives(_createElementVNode("input", {
                "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.email) = $event)),
                type: "email",
                name: "Email",
                id: "emailfield",
                placeholder: "Enter your email address"
              }, null, 512), [
                [_vModelText, _ctx.email]
              ]),
              _createElementVNode("button", _hoisted_10, _toDisplayString(_ctx.subBtn), 1)
            ], 32)
          ])
        ]),
        _createElementVNode("div", _hoisted_11, [
          _createElementVNode("div", null, [
            _createElementVNode("ul", _hoisted_12, [
              _createElementVNode("li", _hoisted_13, [
                _createVNode(_component_router_link, {
                  to: "/categories",
                  class: "router-link"
                }, {
                  default: _withCtx(() => _cache[8] || (_cache[8] = [
                    _createTextVNode("Categories")
                  ])),
                  _: 1
                })
              ]),
              _createElementVNode("li", _hoisted_14, [
                _createVNode(_component_router_link, {
                  to: "/trending",
                  class: "router-link"
                }, {
                  default: _withCtx(() => _cache[9] || (_cache[9] = [
                    _createTextVNode("Trending")
                  ])),
                  _: 1
                })
              ]),
              _createElementVNode("li", _hoisted_15, [
                _createVNode(_component_router_link, {
                  to: "/blog",
                  class: "router-link"
                }, {
                  default: _withCtx(() => _cache[10] || (_cache[10] = [
                    _createTextVNode("Blog")
                  ])),
                  _: 1
                })
              ]),
              _createElementVNode("li", _hoisted_16, [
                _createVNode(_component_router_link, {
                  to: "/latest-arrival",
                  class: "router-link"
                }, {
                  default: _withCtx(() => _cache[11] || (_cache[11] = [
                    _createTextVNode("Latest Arriival")
                  ])),
                  _: 1
                })
              ]),
              _createElementVNode("li", _hoisted_17, [
                _createVNode(_component_router_link, {
                  to: {
                    path: '/product',
                    query: { mixed: 'popular-items' },
                  },
                  class: "router-link"
                }, {
                  default: _withCtx(() => _cache[12] || (_cache[12] = [
                    _createTextVNode("Popular")
                  ])),
                  _: 1
                })
              ])
            ])
          ]),
          _createElementVNode("div", null, [
            _cache[16] || (_cache[16] = _createElementVNode("h2", { class: "mb-6 text-sm font-semibold text-gray-900 uppercase" }, " Company ", -1)),
            _createElementVNode("ul", _hoisted_18, [
              _createElementVNode("li", _hoisted_19, [
                _createVNode(_component_router_link, { to: "/policy/privacy-policy" }, {
                  default: _withCtx(() => _cache[13] || (_cache[13] = [
                    _createTextVNode("Privacy Policy")
                  ])),
                  _: 1
                })
              ]),
              _createElementVNode("li", _hoisted_20, [
                _createVNode(_component_router_link, { to: "/policy/terms-and-conditions" }, {
                  default: _withCtx(() => _cache[14] || (_cache[14] = [
                    _createTextVNode("Terms and Conditions")
                  ])),
                  _: 1
                })
              ]),
              _createElementVNode("li", _hoisted_21, [
                _createVNode(_component_router_link, { to: "/policy/return-and-exchange-policy" }, {
                  default: _withCtx(() => _cache[15] || (_cache[15] = [
                    _createTextVNode("Return and Exchange Policy")
                  ])),
                  _: 1
                })
              ])
            ])
          ]),
          _createElementVNode("div", null, [
            _cache[19] || (_cache[19] = _createElementVNode("h2", { class: "mb-6 text-sm font-semibold text-gray-900 uppercase" }, " Product ", -1)),
            _createElementVNode("ul", _hoisted_22, [
              _createElementVNode("li", _hoisted_23, [
                _createVNode(_component_router_link, {
                  to: "/help-center",
                  class: "router-link"
                }, {
                  default: _withCtx(() => _cache[17] || (_cache[17] = [
                    _createTextVNode("Help Center")
                  ])),
                  _: 1
                })
              ])
            ]),
            _createElementVNode("div", _hoisted_24, [
              (_ctx.loadingLinks)
                ? (_openBlock(), _createElementBlock("div", _hoisted_25, _cache[18] || (_cache[18] = [
                    _createElementVNode("div", { class: "h-10 w-full bg-gray-300 animate-pulse" }, null, -1)
                  ])))
                : (_openBlock(), _createElementBlock("div", _hoisted_26, [
                    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.helpCenterStore.getSocials, (item) => {
                      return (_openBlock(), _createElementBlock("a", {
                        href: item.url
                      }, [
                        _createVNode(_component_mdicon, {
                          name: item.icon
                        }, null, 8, ["name"])
                      ], 8, _hoisted_27))
                    }), 256))
                  ]))
            ])
          ])
        ])
      ]),
      _cache[20] || (_cache[20] = _createStaticVNode("<hr class=\"mt-6 border-gray-200 sm:mx-auto lg:mt-8\" data-v-ecaa3b12><div class=\"sm:flex sm:items-center sm:justify-between\" data-v-ecaa3b12><section class=\"phone my-0\" data-v-ecaa3b12><p data-v-ecaa3b12>Download our App</p><div data-v-ecaa3b12><span id=\"butt\" data-v-ecaa3b12><img src=\"" + _imports_1 + "\" alt=\"\" data-v-ecaa3b12><p data-v-ecaa3b12>Coming soon</p></span><span id=\"butt\" data-v-ecaa3b12><img src=\"" + _imports_2 + "\" alt=\"\" data-v-ecaa3b12><p data-v-ecaa3b12>Coming soon</p></span></div></section></div>", 2))
    ])
  ]))
}