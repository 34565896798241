import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, resolveComponent as _resolveComponent, createVNode as _createVNode, createTextVNode as _createTextVNode, renderList as _renderList, Fragment as _Fragment, createBlock as _createBlock, normalizeStyle as _normalizeStyle, createStaticVNode as _createStaticVNode } from "vue"

const _hoisted_1 = { class: "main-container" }
const _hoisted_2 = {
  key: 2,
  class: "main-container-text"
}
const _hoisted_3 = {
  key: 0,
  class: "total-outer-container"
}
const _hoisted_4 = {
  key: 0,
  class: "main-container-expanded-content"
}
const _hoisted_5 = { class: "top-text" }
const _hoisted_6 = { class: "main-container-unexpanded" }
const _hoisted_7 = { class: "arrow-container" }
const _hoisted_8 = { class: "main-container-unexpanded" }
const _hoisted_9 = { class: "arrow-container" }
const _hoisted_10 = { class: "!p-0 !m-0 !bg-none relative" }
const _hoisted_11 = ["href", "disabled"]
const _hoisted_12 = {
  key: 0,
  class: "absolute text-black"
}
const _hoisted_13 = {
  key: 0,
  class: "absolute inset-0"
}
const _hoisted_14 = {
  key: 1,
  class: "faqsection"
}
const _hoisted_15 = {
  key: 2,
  class: "help-section"
}
const _hoisted_16 = {
  key: 0,
  class: "helpsection"
}
const _hoisted_17 = {
  key: 1,
  class: "searchsection"
}
const _hoisted_18 = {
  key: 2,
  class: "searchsection"
}
const _hoisted_19 = {
  key: 3,
  class: "searchsection"
}
const _hoisted_20 = {
  key: 4,
  class: "searchsection"
}
const _hoisted_21 = {
  key: 5,
  class: "searchsection"
}
const _hoisted_22 = {
  key: 6,
  class: "searchsection"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Spinner = _resolveComponent("Spinner")!
  const _component_HelpCentreFAQ = _resolveComponent("HelpCentreFAQ")!
  const _component_Search = _resolveComponent("Search")!
  const _component_Notifications = _resolveComponent("Notifications")!
  const _component_Favorite = _resolveComponent("Favorite")!
  const _component_Cart = _resolveComponent("Cart")!
  const _component_Profile = _resolveComponent("Profile")!
  const _component_Network = _resolveComponent("Network")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (_ctx.expandedFaq === 'in')
      ? (_openBlock(), _createElementBlock("div", {
          key: 0,
          onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.setExpandedFaqFalse && _ctx.setExpandedFaqFalse(...args))),
          class: "main-container-text"
        }, _cache[10] || (_cache[10] = [
          _createElementVNode("h1", { style: {"display":"flex","gap":"16px","align-items":"center","cursor":"pointer"} }, [
            _createElementVNode("svg", {
              width: "24",
              height: "24",
              viewBox: "0 0 24 24",
              fill: "none",
              xmlns: "http://www.w3.org/2000/svg"
            }, [
              _createElementVNode("path", {
                d: "M5.25 11.25H20.25C20.4489 11.25 20.6397 11.329 20.7803 11.4697C20.921 11.6103 21 11.8011 21 12C21 12.1989 20.921 12.3897 20.7803 12.5303C20.6397 12.671 20.4489 12.75 20.25 12.75H5.25C5.05109 12.75 4.86032 12.671 4.71967 12.5303C4.57902 12.3897 4.5 12.1989 4.5 12C4.5 11.8011 4.57902 11.6103 4.71967 11.4697C4.86032 11.329 5.05109 11.25 5.25 11.25Z",
                fill: "#0F172A",
                "fill-opacity": "0.7"
              }),
              _createElementVNode("path", {
                d: "M5.56086 12.0009L11.7814 18.2199C11.9222 18.3608 12.0013 18.5518 12.0013 18.7509C12.0013 18.9501 11.9222 19.1411 11.7814 19.2819C11.6405 19.4228 11.4495 19.5019 11.2504 19.5019C11.0512 19.5019 10.8602 19.4228 10.7194 19.2819L3.96936 12.5319C3.89952 12.4623 3.84411 12.3795 3.8063 12.2884C3.76849 12.1973 3.74902 12.0996 3.74902 12.0009C3.74902 11.9023 3.76849 11.8046 3.8063 11.7135C3.84411 11.6224 3.89952 11.5396 3.96936 11.4699L10.7194 4.71995C10.8602 4.57912 11.0512 4.5 11.2504 4.5C11.4495 4.5 11.6405 4.57912 11.7814 4.71995C11.9222 4.86078 12.0013 5.05178 12.0013 5.25095C12.0013 5.45011 11.9222 5.64112 11.7814 5.78195L5.56086 12.0009Z",
                fill: "#0F172A",
                "fill-opacity": "0.7"
              })
            ]),
            _createElementVNode("span", null, "Frequently asked questions")
          ], -1)
        ])))
      : _createCommentVNode("", true),
    (_ctx.expandedFaq === 'help')
      ? (_openBlock(), _createElementBlock("div", {
          key: 1,
          onClick: _cache[1] || (_cache[1] = 
//@ts-ignore
(...args) => (_ctx.setHelpFalse && _ctx.setHelpFalse(...args))),
          class: "main-container-text"
        }, _cache[11] || (_cache[11] = [
          _createElementVNode("h1", { style: {"display":"flex","gap":"16px","align-items":"center","cursor":"pointer"} }, [
            _createElementVNode("svg", {
              width: "24",
              height: "24",
              viewBox: "0 0 24 24",
              fill: "none",
              xmlns: "http://www.w3.org/2000/svg"
            }, [
              _createElementVNode("path", {
                d: "M5.25 11.25H20.25C20.4489 11.25 20.6397 11.329 20.7803 11.4697C20.921 11.6103 21 11.8011 21 12C21 12.1989 20.921 12.3897 20.7803 12.5303C20.6397 12.671 20.4489 12.75 20.25 12.75H5.25C5.05109 12.75 4.86032 12.671 4.71967 12.5303C4.57902 12.3897 4.5 12.1989 4.5 12C4.5 11.8011 4.57902 11.6103 4.71967 11.4697C4.86032 11.329 5.05109 11.25 5.25 11.25Z",
                fill: "#0F172A",
                "fill-opacity": "0.7"
              }),
              _createElementVNode("path", {
                d: "M5.56086 12.0009L11.7814 18.2199C11.9222 18.3608 12.0013 18.5518 12.0013 18.7509C12.0013 18.9501 11.9222 19.1411 11.7814 19.2819C11.6405 19.4228 11.4495 19.5019 11.2504 19.5019C11.0512 19.5019 10.8602 19.4228 10.7194 19.2819L3.96936 12.5319C3.89952 12.4623 3.84411 12.3795 3.8063 12.2884C3.76849 12.1973 3.74902 12.0996 3.74902 12.0009C3.74902 11.9023 3.76849 11.8046 3.8063 11.7135C3.84411 11.6224 3.89952 11.5396 3.96936 11.4699L10.7194 4.71995C10.8602 4.57912 11.0512 4.5 11.2504 4.5C11.4495 4.5 11.6405 4.57912 11.7814 4.71995C11.9222 4.86078 12.0013 5.05178 12.0013 5.25095C12.0013 5.45011 11.9222 5.64112 11.7814 5.78195L5.56086 12.0009Z",
                fill: "#0F172A",
                "fill-opacity": "0.7"
              })
            ]),
            _createElementVNode("span", null, "Help")
          ], -1),
          _createElementVNode("p", null, "Select the category you need help with", -1)
        ])))
      : _createCommentVNode("", true),
    (_ctx.expandedFaq === 'out')
      ? (_openBlock(), _createElementBlock("div", _hoisted_2, _cache[12] || (_cache[12] = [
          _createElementVNode("h1", { style: {"cursor":"default"} }, "Tell us how we can help", -1),
          _createElementVNode("p", null, "Our Crew of superheroes are standing by for service & support", -1)
        ])))
      : _createCommentVNode("", true),
    _createElementVNode("div", {
      class: "helpcenter-container",
      style: _normalizeStyle(_ctx.expandedFaq === 'in' || 'help' ? 'border-radius: 24px;' : 'border: 1px solid var(--black-30, rgba(15, 23, 42, 0.30));')
    }, [
      (_ctx.expandedFaq === 'out')
        ? (_openBlock(), _createElementBlock("div", _hoisted_3, [
            (_ctx.expanded == 'in')
              ? (_openBlock(), _createElementBlock("div", _hoisted_4, [
                  _createElementVNode("div", _hoisted_5, [
                    _createElementVNode("h4", null, _toDisplayString(_ctx.text_expanded_one), 1),
                    _cache[13] || (_cache[13] = _createElementVNode("svg", {
                      class: "help-icon",
                      width: "24",
                      height: "24",
                      viewBox: "0 0 24 24",
                      fill: "none",
                      xmlns: "http://www.w3.org/2000/svg"
                    }, [
                      _createElementVNode("path", {
                        d: "M17 9H7V7H17V9Z",
                        fill: "#0F172A",
                        "fill-opacity": "0.5"
                      }),
                      _createElementVNode("path", {
                        d: "M7 13H17V11H7V13Z",
                        fill: "#0F172A",
                        "fill-opacity": "0.5"
                      }),
                      _createElementVNode("path", {
                        "fill-rule": "evenodd",
                        "clip-rule": "evenodd",
                        d: "M2 18V2H22V18H16V22H14C11.7909 22 10 20.2091 10 18H2ZM12 16V18C12 19.1046 12.8954 20 14 20V16H20V4H4V16H12Z",
                        fill: "#0F172A",
                        "fill-opacity": "0.5"
                      })
                    ], -1))
                  ]),
                  _cache[15] || (_cache[15] = _createElementVNode("div", { class: "breaker" }, null, -1)),
                  _createElementVNode("div", {
                    onClick: _cache[2] || (_cache[2] = 
//@ts-ignore
(...args) => (_ctx.toggleHelp && _ctx.toggleHelp(...args))),
                    class: "following-text"
                  }, [
                    _createElementVNode("h4", null, _toDisplayString(_ctx.text_expanded_two), 1),
                    _cache[14] || (_cache[14] = _createElementVNode("svg", {
                      class: "help-icon",
                      width: "24",
                      height: "24",
                      viewBox: "0 0 24 24",
                      fill: "none",
                      xmlns: "http://www.w3.org/2000/svg"
                    }, [
                      _createElementVNode("circle", {
                        cx: "12",
                        cy: "12",
                        r: "11",
                        stroke: "#0F172A",
                        "stroke-opacity": "0.5",
                        "stroke-width": "2"
                      }),
                      _createElementVNode("path", {
                        d: "M9.432 16.672L9.352 14.128H9.944C10.3707 14.128 10.7813 14.1013 11.176 14.048C11.5707 13.9947 11.9173 13.8933 12.216 13.744C12.5147 13.584 12.7493 13.36 12.92 13.072C13.1013 12.7733 13.192 12.3893 13.192 11.92C13.192 11.504 13.1013 11.152 12.92 10.864C12.7493 10.5653 12.5093 10.3413 12.2 10.192C11.8907 10.032 11.5333 9.952 11.128 9.952C10.712 9.952 10.3493 10.032 10.04 10.192C9.73067 10.3413 9.49067 10.56 9.32 10.848C9.14933 11.1253 9.064 11.4613 9.064 11.856H7.544C7.53333 11.216 7.67733 10.6507 7.976 10.16C8.27467 9.66933 8.696 9.29067 9.24 9.024C9.784 8.74667 10.4133 8.608 11.128 8.608C11.832 8.608 12.456 8.74133 13 9.008C13.544 9.27467 13.9653 9.65333 14.264 10.144C14.5733 10.6347 14.728 11.2267 14.728 11.92C14.728 12.688 14.5627 13.3173 14.232 13.808C13.9013 14.288 13.448 14.6453 12.872 14.88C12.296 15.104 11.6293 15.216 10.872 15.216L10.824 16.672H9.432ZM10.12 20.08C9.82133 20.08 9.57067 19.984 9.368 19.792C9.16533 19.5893 9.064 19.3493 9.064 19.072C9.064 18.784 9.16533 18.544 9.368 18.352C9.57067 18.1493 9.82133 18.048 10.12 18.048C10.4293 18.048 10.68 18.1493 10.872 18.352C11.064 18.544 11.16 18.784 11.16 19.072C11.16 19.3493 11.064 19.5893 10.872 19.792C10.68 19.984 10.4293 20.08 10.12 20.08Z",
                        fill: "#0F172A",
                        "fill-opacity": "0.5"
                      })
                    ], -1))
                  ])
                ]))
              : _createCommentVNode("", true),
            _createElementVNode("div", _hoisted_6, [
              _cache[17] || (_cache[17] = _createStaticVNode("<div class=\"text-container\" data-v-2fc90e7e><div class=\"main-text\" data-v-2fc90e7e><h3 class=\"topic-text\" data-v-2fc90e7e>FAQs</h3></div><div class=\"subtext\" data-v-2fc90e7e><p class=\"sub-text\" data-v-2fc90e7e>Find intelligent answers instantly</p></div></div>", 1)),
              _createElementVNode("div", _hoisted_7, [
                (_openBlock(), _createElementBlock("svg", {
                  onClick: _cache[3] || (_cache[3] = 
//@ts-ignore
(...args) => (_ctx.toggleExpandedFaq && _ctx.toggleExpandedFaq(...args))),
                  style: {"transform":"rotate(-90deg)"},
                  width: "30",
                  height: "30",
                  viewBox: "0 0 30 30",
                  fill: "none",
                  xmlns: "http://www.w3.org/2000/svg"
                }, _cache[16] || (_cache[16] = [
                  _createElementVNode("path", {
                    d: "M9.2147 13.0715L11.1432 11.143L15.0001 15L18.857 11.1431L20.7855 13.0715L15.0001 18.8569L9.2147 13.0715Z",
                    fill: "#0F172A"
                  }, null, -1),
                  _createElementVNode("path", {
                    "fill-rule": "evenodd",
                    "clip-rule": "evenodd",
                    d: "M0 15C0 6.71573 6.71573 0 15 0C23.2843 0 30 6.71573 30 15C30 23.2843 23.2843 30 15 30C6.71573 30 0 23.2843 0 15ZM15 27.2727C8.22196 27.2727 2.72727 21.778 2.72727 15C2.72727 8.22196 8.22196 2.72727 15 2.72727C21.778 2.72727 27.2727 8.22196 27.2727 15C27.2727 21.778 21.778 27.2727 15 27.2727Z",
                    fill: "#0F172A",
                    "fill-opacity": "0.5"
                  }, null, -1)
                ])))
              ])
            ]),
            _createElementVNode("div", _hoisted_8, [
              _cache[19] || (_cache[19] = _createStaticVNode("<div class=\"text-container\" data-v-2fc90e7e><div class=\"main-text\" data-v-2fc90e7e><h3 class=\"topic-text\" data-v-2fc90e7e>Email</h3></div><div class=\"subtext\" data-v-2fc90e7e><p class=\"sub-text\" data-v-2fc90e7e>Get your solution</p></div></div>", 1)),
              _createElementVNode("div", _hoisted_9, [
                _createElementVNode("button", _hoisted_10, [
                  _createElementVNode("a", {
                    href: `mailto:${_ctx.helpCenterStore.helpCenterData?.[0]?.email}`,
                    class: "bg-primary px-6 p-3 text-white rounded-3xl relative flex items-center justify-center",
                    disabled: _ctx.helpCenterStore.fetching
                  }, [
                    _cache[18] || (_cache[18] = _createTextVNode(" Send a mail ")),
                    (_ctx.helpCenterStore.fetching || !_ctx.helpCenterStore.helpCenterData?.[0]?.email)
                      ? (_openBlock(), _createElementBlock("span", _hoisted_12, [
                          _createVNode(_component_Spinner, { class: "w-7" })
                        ]))
                      : _createCommentVNode("", true)
                  ], 8, _hoisted_11),
                  (_ctx.helpCenterStore.fetching)
                    ? (_openBlock(), _createElementBlock("span", _hoisted_13))
                    : _createCommentVNode("", true)
                ])
              ])
            ])
          ]))
        : _createCommentVNode("", true),
      (_ctx.expandedFaq === 'in')
        ? (_openBlock(), _createElementBlock("div", _hoisted_14, [
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.FAQs, (line) => {
              return (_openBlock(), _createBlock(_component_HelpCentreFAQ, {
                key: line.question,
                main_text: line.question,
                text_expanded: line.answer
              }, null, 8, ["main_text", "text_expanded"]))
            }), 128))
          ]))
        : _createCommentVNode("", true),
      (_ctx.expandedFaq === 'help')
        ? (_openBlock(), _createElementBlock("div", _hoisted_15, [
            (_ctx.expandedSearch === 'closed')
              ? (_openBlock(), _createElementBlock("div", _hoisted_16, [
                  _createElementVNode("div", {
                    onClick: _cache[4] || (_cache[4] = 
//@ts-ignore
(...args) => (_ctx.setToSearch && _ctx.setToSearch(...args))),
                    class: "element"
                  }, _cache[20] || (_cache[20] = [
                    _createElementVNode("div", { class: "card" }, [
                      _createElementVNode("svg", {
                        width: "71",
                        height: "71",
                        viewBox: "0 0 71 71",
                        fill: "none",
                        xmlns: "http://www.w3.org/2000/svg"
                      }, [
                        _createElementVNode("path", {
                          "fill-rule": "evenodd",
                          "clip-rule": "evenodd",
                          d: "M53.5349 48.3067C62.586 36.6824 61.7684 19.8647 51.0823 9.17855C39.5109 -2.39285 20.7499 -2.39285 9.17855 9.17855C-2.39285 20.7499 -2.39285 39.5109 9.17855 51.0823C19.8647 61.7684 36.6824 62.586 48.3067 53.5349C48.3569 53.5915 48.4091 53.647 48.4633 53.7013L64.1772 69.4152C65.6236 70.8616 67.9688 70.8616 69.4152 69.4152C70.8616 67.9688 70.8616 65.6236 69.4152 64.1772L53.7013 48.4633C53.647 48.4091 53.5915 48.3569 53.5349 48.3067ZM45.8443 14.4165C54.5229 23.0951 54.5229 37.1658 45.8443 45.8443C37.1658 54.5229 23.0951 54.5229 14.4165 45.8443C5.73797 37.1658 5.73797 23.0951 14.4165 14.4165C23.0951 5.73797 37.1658 5.73797 45.8443 14.4165Z",
                          fill: "#0F172A",
                          "fill-opacity": "0.7"
                        })
                      ])
                    ], -1),
                    _createElementVNode("p", { class: "element-text" }, "Search", -1)
                  ])),
                  _createElementVNode("div", {
                    onClick: _cache[5] || (_cache[5] = 
//@ts-ignore
(...args) => (_ctx.setToNotify && _ctx.setToNotify(...args))),
                    class: "element"
                  }, _cache[21] || (_cache[21] = [
                    _createElementVNode("div", { class: "card" }, [
                      _createElementVNode("svg", {
                        width: "53",
                        height: "71",
                        viewBox: "0 0 53 71",
                        fill: "none",
                        xmlns: "http://www.w3.org/2000/svg"
                      }, [
                        _createElementVNode("path", {
                          "fill-rule": "evenodd",
                          "clip-rule": "evenodd",
                          d: "M33 6.86364V7.78598C42.3973 10.5241 49.25 19.0468 49.25 29.1364V51.4091H52.5V57.7727H0.5V51.4091H3.75V29.1364C3.75 19.0468 10.6027 10.5241 20 7.78598V6.86364C20 3.3491 22.9101 0.5 26.5 0.5C30.0899 0.5 33 3.3491 33 6.86364ZM10.25 51.4091H42.75V29.1364C42.75 20.35 35.4746 13.2273 26.5 13.2273C17.5254 13.2273 10.25 20.35 10.25 29.1364V51.4091ZM33 64.1364V60.9545H20V64.1364C20 67.6509 22.9101 70.5 26.5 70.5C30.0899 70.5 33 67.6509 33 64.1364Z",
                          fill: "#0F172A",
                          "fill-opacity": "0.7"
                        })
                      ])
                    ], -1),
                    _createElementVNode("p", { class: "element-text" }, "Notification", -1)
                  ])),
                  _createElementVNode("div", {
                    onClick: _cache[6] || (_cache[6] = 
//@ts-ignore
(...args) => (_ctx.setToFavor && _ctx.setToFavor(...args))),
                    class: "element"
                  }, _cache[22] || (_cache[22] = [
                    _createElementVNode("div", { class: "card" }, [
                      _createElementVNode("svg", {
                        width: "71",
                        height: "71",
                        viewBox: "0 0 71 71",
                        fill: "none",
                        xmlns: "http://www.w3.org/2000/svg"
                      }, [
                        _createElementVNode("path", {
                          "fill-rule": "evenodd",
                          "clip-rule": "evenodd",
                          d: "M35.5357 16.7517L32.3653 13.5813C26.1006 7.31665 15.9436 7.31665 9.67896 13.5813C3.4143 19.846 3.4143 30.003 9.67896 36.2677L35.4589 62.0476L35.4641 62.0423L35.541 62.1191L61.3209 36.3392C67.5856 30.0746 67.5856 19.9175 61.3209 13.6529C55.0562 7.38821 44.8992 7.38821 38.6345 13.6529L35.5357 16.7517ZM35.4641 53.7928L49.8615 39.3954L54.093 35.309L54.0973 35.3133L57.1961 32.2144C61.1827 28.2278 61.1827 21.7643 57.1961 17.7777C53.2095 13.7911 46.7459 13.7911 42.7593 17.7777L35.5388 24.9982L35.5177 24.977L35.5146 24.9802L28.2405 17.7061C24.2539 13.7195 17.7904 13.7195 13.8038 17.7061C9.81715 21.6927 9.81715 28.1563 13.8038 32.1429L21.2104 39.5495L21.2157 39.5443L35.4641 53.7928Z",
                          fill: "#0F172A",
                          "fill-opacity": "0.7"
                        })
                      ])
                    ], -1),
                    _createElementVNode("p", { class: "element-text" }, "Favorite", -1)
                  ])),
                  _createElementVNode("div", {
                    onClick: _cache[7] || (_cache[7] = 
//@ts-ignore
(...args) => (_ctx.setToCart && _ctx.setToCart(...args))),
                    class: "element"
                  }, _cache[23] || (_cache[23] = [
                    _createElementVNode("div", { class: "card" }, [
                      _createElementVNode("svg", {
                        width: "71",
                        height: "71",
                        viewBox: "0 0 71 71",
                        fill: "none",
                        xmlns: "http://www.w3.org/2000/svg"
                      }, [
                        _createElementVNode("path", {
                          "fill-rule": "evenodd",
                          "clip-rule": "evenodd",
                          d: "M11 7.5H60C61.933 7.5 63.5 9.067 63.5 11V60C63.5 61.933 61.933 63.5 60 63.5H11C9.067 63.5 7.5 61.933 7.5 60V11C7.5 9.067 9.067 7.5 11 7.5ZM0.5 11C0.5 5.20101 5.20101 0.5 11 0.5L60 0.5C65.799 0.5 70.5 5.20101 70.5 11V60C70.5 65.799 65.799 70.5 60 70.5H11C5.20101 70.5 0.5 65.799 0.5 60L0.5 11ZM35.5 35.5C25.835 35.5 18 26.098 18 14.5H25C25 23.4812 30.8418 28.5 35.5 28.5C40.1582 28.5 46 23.4812 46 14.5H53C53 26.098 45.165 35.5 35.5 35.5Z",
                          fill: "#0F172A",
                          "fill-opacity": "0.7"
                        })
                      ])
                    ], -1),
                    _createElementVNode("p", { class: "element-text" }, "Cart", -1)
                  ])),
                  _createElementVNode("div", {
                    onClick: _cache[8] || (_cache[8] = 
//@ts-ignore
(...args) => (_ctx.setToProfile && _ctx.setToProfile(...args))),
                    class: "element"
                  }, _cache[24] || (_cache[24] = [
                    _createStaticVNode("<div class=\"card\" data-v-2fc90e7e><svg width=\"71\" height=\"71\" viewBox=\"0 0 71 71\" fill=\"none\" xmlns=\"http://www.w3.org/2000/svg\" data-v-2fc90e7e><path fill-rule=\"evenodd\" clip-rule=\"evenodd\" d=\"M48.2273 25.9545C48.2273 32.9836 42.5291 38.6818 35.5 38.6818C28.4709 38.6818 22.7727 32.9836 22.7727 25.9545C22.7727 18.9255 28.4709 13.2273 35.5 13.2273C42.5291 13.2273 48.2273 18.9255 48.2273 25.9545ZM41.8636 25.9545C41.8636 29.4691 39.0145 32.3182 35.5 32.3182C31.9855 32.3182 29.1364 29.4691 29.1364 25.9545C29.1364 22.44 31.9855 19.5909 35.5 19.5909C39.0145 19.5909 41.8636 22.44 41.8636 25.9545Z\" fill=\"#0F172A\" fill-opacity=\"0.7\" data-v-2fc90e7e></path><path fill-rule=\"evenodd\" clip-rule=\"evenodd\" d=\"M35.5 0.5C16.17 0.5 0.5 16.17 0.5 35.5C0.5 54.83 16.17 70.5 35.5 70.5C54.83 70.5 70.5 54.83 70.5 35.5C70.5 16.17 54.83 0.5 35.5 0.5ZM6.86364 35.5C6.86364 42.1507 9.13083 48.2718 12.9345 53.1327C18.1669 46.2828 26.4199 41.8636 35.7052 41.8636C44.8699 41.8636 53.0291 46.1689 58.2707 52.8673C61.9506 48.0499 64.1364 42.0302 64.1364 35.5C64.1364 19.6846 51.3154 6.86364 35.5 6.86364C19.6846 6.86364 6.86364 19.6846 6.86364 35.5ZM35.5 64.1364C28.6584 64.1364 22.3771 61.7371 17.4517 57.734C21.4794 51.9857 28.1534 48.2273 35.7052 48.2273C43.1632 48.2273 49.7651 51.893 53.8074 57.5209C48.8441 61.6518 42.4622 64.1364 35.5 64.1364Z\" fill=\"#0F172A\" fill-opacity=\"0.7\" data-v-2fc90e7e></path></svg></div><p class=\"element-text\" data-v-2fc90e7e>Profile</p>", 2)
                  ])),
                  _createElementVNode("div", {
                    onClick: _cache[9] || (_cache[9] = 
//@ts-ignore
(...args) => (_ctx.setToNetwork && _ctx.setToNetwork(...args))),
                    class: "element"
                  }, _cache[25] || (_cache[25] = [
                    _createElementVNode("div", { class: "card" }, [
                      _createElementVNode("svg", {
                        width: "71",
                        height: "51",
                        viewBox: "0 0 71 51",
                        fill: "none",
                        xmlns: "http://www.w3.org/2000/svg"
                      }, [
                        _createElementVNode("path", {
                          "fill-rule": "evenodd",
                          "clip-rule": "evenodd",
                          d: "M44.2273 50.9885C44.4841 50.9962 44.7418 51 45.0005 51C59.0835 51 70.5 39.5833 70.5 25.5C70.5 11.4167 59.0835 0 45.0005 0C36.9478 0 29.7669 3.7328 25.0937 9.5625H21.2184C9.77593 9.5625 0.5 18.8386 0.5 30.2812C0.5 41.7239 9.77593 51 21.2184 51H43.5305C43.7637 51 43.996 50.9961 44.2273 50.9885ZM50.4533 43.7356C57.9444 41.2851 64.1251 34.0473 64.1251 25.5C64.1251 14.9376 55.5627 6.375 45.0005 6.375C34.4382 6.375 25.8758 14.9376 25.8758 25.5H19.5009C19.5009 22.1184 20.1592 18.8905 21.3545 15.9375H21.2184C13.2967 15.9375 6.87488 22.3594 6.87488 30.2812C6.87488 38.2031 13.2967 44.625 21.2184 44.625H43.5305C45.2827 44.625 47.9441 44.3108 50.4533 43.7356Z",
                          fill: "#0F172A",
                          "fill-opacity": "0.7"
                        })
                      ])
                    ], -1),
                    _createElementVNode("p", { class: "element-text" }, "Network", -1)
                  ]))
                ]))
              : _createCommentVNode("", true),
            (_ctx.expandedSearch === 'search')
              ? (_openBlock(), _createElementBlock("div", _hoisted_17, [
                  _createVNode(_component_Search)
                ]))
              : _createCommentVNode("", true),
            (_ctx.expandedSearch === 'notify')
              ? (_openBlock(), _createElementBlock("div", _hoisted_18, [
                  _createVNode(_component_Notifications)
                ]))
              : _createCommentVNode("", true),
            (_ctx.expandedSearch === 'favor')
              ? (_openBlock(), _createElementBlock("div", _hoisted_19, [
                  _createVNode(_component_Favorite)
                ]))
              : _createCommentVNode("", true),
            (_ctx.expandedSearch === 'cart')
              ? (_openBlock(), _createElementBlock("div", _hoisted_20, [
                  _createVNode(_component_Cart)
                ]))
              : _createCommentVNode("", true),
            (_ctx.expandedSearch === 'profile')
              ? (_openBlock(), _createElementBlock("div", _hoisted_21, [
                  _createVNode(_component_Profile)
                ]))
              : _createCommentVNode("", true),
            (_ctx.expandedSearch === 'network')
              ? (_openBlock(), _createElementBlock("div", _hoisted_22, [
                  _createVNode(_component_Network)
                ]))
              : _createCommentVNode("", true)
          ]))
        : _createCommentVNode("", true)
    ], 4)
  ]))
}