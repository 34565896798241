import { defineComponent as _defineComponent } from 'vue'
import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, normalizeClass as _normalizeClass, withCtx as _withCtx, createVNode as _createVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createBlock as _createBlock, unref as _unref, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = { class: "intro" }
const _hoisted_2 = { class: "flex justify-center px-10" }
const _hoisted_3 = { class: "flex flex-col items-center" }
const _hoisted_4 = { class: "container" }
const _hoisted_5 = { id: "image" }
const _hoisted_6 = {
  key: 0,
  class: "h-96 bg-gray-50 flex items-center justify-center flex-col text-2xl font-medium text-gray-300"
}
const _hoisted_7 = {
  key: 1,
  class: "h-96 flex items-center justify-center flex-col text-2xl gap-3 font-medium text-gray-300"
}

import { computed, onMounted, ref, watch } from 'vue';
import { SectionTabs } from '@/router/types';
import Tabs from '@/components/Tabs.vue';
import ItemCard from '@/components/ItemCard.vue';
import { useHomeStore } from "@/store/home"
import { useUserStore } from "@/store/user"
import Spinner from '@/components/Spinner.vue';


export default /*@__PURE__*/_defineComponent({
  __name: 'Products',
  setup(__props) {

const userStore = useUserStore();
const activeMixedTab = ref(undefined)
const mixedTabList = ref<SectionTabs[]>([
  {
    name: "Popular",
    slug: 'popular-items',
    requiresAuth: false,
  },
  {
    name: "Recently viewed",
    slug: 'recently_viewed',
    requiresAuth: true,
  },
  {
    name: "Favourites",
    slug: 'favourites',
    requiresAuth: true,
  },
  {
    name: "New Arrivals",
    slug: 'new_arrivals',
    requiresAuth: false,
  },
])

const homeStore = useHomeStore();
onMounted(() => {
  Promise.all([
    mixedTabList.value.map(tab => homeStore.fetchMixedItems({
      endpointName: tab.slug.replace('-', '_'),
      requiresAuth: Boolean(tab.requiresAuth),
    }))
  ])
})
const renderedMixedItems = computed(()=>{
  return homeStore.getMixedItems(
    String(mixedTabList.value.find(tab => tab.slug === activeMixedTab.value)?.slug).replace('-', '_') ||
    'popular_items'
  ) || []
})

return (_ctx: any,_cache: any) => {
  const _component_router_link = _resolveComponent("router-link")!

  return (_openBlock(), _createElementBlock("section", null, [
    _createElementVNode("div", _hoisted_1, [
      _createElementVNode("div", _hoisted_2, [
        _createVNode(Tabs, {
          tab_list: mixedTabList.value,
          value: activeMixedTab.value,
          query_name: "mixed",
          onModelValue: _cache[0] || (_cache[0] = ($event) => activeMixedTab.value = $event),
          class: "inline-flex gap-x-1 sm:gap-x-2 justify-between select-none rounded-[100px] [&>*]:flex-grow [&>*]:max-w-[50%] text-base border border-gray-400 max-w-[90vw] overflow-x-auto hide-scrollbar"
        }, {
          tab: _withCtx(({ tab, index: i, is_active }) => [
            _createElementVNode("div", {
              class: _normalizeClass([[is_active ? 'bg-primary text-white' : 'bg-transparent hover:text-primary'], "p-3 transition duration-300 font-medium cursor-pointer rounded-[100px] min-w-max"])
            }, [
              _createElementVNode("span", _hoisted_3, [
                _createElementVNode("span", null, _toDisplayString(tab?.name), 1)
              ])
            ], 2)
          ]),
          _: 1
        }, 8, ["tab_list", "value"])
      ])
    ]),
    _createElementVNode("div", _hoisted_4, [
      _createElementVNode("div", _hoisted_5, [
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(renderedMixedItems.value, (item, i) => {
          return (_openBlock(), _createBlock(ItemCard, {
            key: item.id,
            id: activeMixedTab.value === 'new_arrivals' ? item.item.id : item.id,
            favorite_id: activeMixedTab.value === 'new_arrivals' ? item.item.favorite_id : item.favorite_id,
            name: activeMixedTab.value === 'new_arrivals' ? item.item.name : item.name,
            price: activeMixedTab.value === 'new_arrivals' ? item.item.price : item.price,
            new_price: activeMixedTab.value === 'new_arrivals' ? item.item.new_price : item.new_price,
            image: activeMixedTab.value === 'new_arrivals' ? item.item.image : item.image,
            slug: activeMixedTab.value === 'new_arrivals' ? item.item.slug : item.slug,
            is_favourite: activeMixedTab.value === 'new_arrivals' ? item.item.is_favourite : item.is_favourite
          }, null, 8, ["id", "favorite_id", "name", "price", "new_price", "image", "slug", "is_favourite"]))
        }), 128))
      ])
    ]),
    (renderedMixedItems.value.length === 0 && !_unref(homeStore).requestingMixedItem)
      ? (_openBlock(), _createElementBlock("div", _hoisted_6, [
          _createElementVNode("span", null, _toDisplayString(_unref(userStore).getUser.token ? 'No item in here yet' :
          `Sign in to see your ${String(activeMixedTab.value).replace(/-/g, ' ')} items`), 1),
          (!_unref(userStore).getUser.token)
            ? (_openBlock(), _createBlock(_component_router_link, {
                key: 0,
                to: "/signin",
                class: "text-gray-500"
              }, {
                default: _withCtx(() => _cache[1] || (_cache[1] = [
                  _createTextVNode("Sign in")
                ])),
                _: 1
              }))
            : _createCommentVNode("", true)
        ]))
      : _createCommentVNode("", true),
    (_unref(homeStore).requestingMixedItem)
      ? (_openBlock(), _createElementBlock("div", _hoisted_7, [
          _cache[2] || (_cache[2] = _createElementVNode("span", null, " Fetching products... ", -1)),
          _createVNode(Spinner, { class: "w-8 text-gray-500" })
        ]))
      : _createCommentVNode("", true)
  ]))
}
}

})