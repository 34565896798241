<script lang="ts">
import { defineComponent } from 'vue';
import axios from 'axios';
import { toggleLoading } from '@/event-bus';

export default defineComponent({
    name: 'OrderSummaryProducts',
    data() {
        return {
            inc_quantity: +1,
            dec_quantity: -1,
        }
    },
    props: {
        photo: String,
        product_name: String,
        price: Number,
        order_number: Number,
        product_id: String,
    },
    methods: {
        increaseQuantity() {
            toggleLoading(true);
//            if (this.inc_quantity < 1) {
//
//            } else {
//                this.inc_quantity++;
//            }
            const token = localStorage.getItem('AuthToken');

            axios.post('/api/carts/add_to_cart/', {product_id: this.product_id, quantity: this.inc_quantity}
            )
            .then(response => {
                toggleLoading(false);
                console.log('Product quantity increased:', response.data);
            //this.$emit('reloadCart');
                window.location.reload();
            })
            .catch(error => {
                toggleLoading(false);
                console.error('Error increasing product:', error);
            });
        },
        decreaseQuantity() {
            toggleLoading(true);
//            if (this.dec_quantity <= 1) {
//
//            } else {
//                this.dec_quantity--;
//            }
            const token = localStorage.getItem('AuthToken');

            axios.post('/api/carts/add_to_cart/', {product_id: this.product_id, quantity: this.dec_quantity}
            )
            .then(response => {
                toggleLoading(false);
                console.log('Product quantity decreased:', response.data);
                this.$emit('reloadCart');
                window.location.reload();
            })
            .catch(error => {
                toggleLoading(false);
                console.error('Error decreasing product:', error);
            });
        },
    },
})
</script>

<template>
   <div class="fc-product-details-container">
        <div class="product-details-picture">
            <img :src="photo" loading="lazy">
        </div>
        <div class="product-details-name-price-container">
            <span class="product-details-name-text font-class">{{ product_name }}</span>
            <span class="product-details-price-text font-class">₦{{ price }}</span>
        </div>
        <div class="product-details-order-number-container">
            <!-- <button @click="decreaseQuantity" class="order-number-container font-class">
                <box-icon name='minus'></box-icon>
            </button> -->
            <div class="order-number-text-container">
                <span class="order-number-text font-class">{{ order_number }}</span>
            </div>
            <!-- <button @click="increaseQuantity" class="order-number-container font-class">
                <box-icon name='plus'></box-icon>
            </button> -->
        </div>
    </div>
</template>

<style lang="scss" scoped>
.font-class {
    font-family: 'DM Sans', sans-serif;
    font-style: normal;
}
.fc-product-details-container {
    display: flex;
    width: 100%;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 1.5rem;
    .product-details-picture {
        display: flex;
        border-radius: 4px;
        width: 4.0625rem;
        height: 5rem;
        img {
            width: 100%;
            height: 100%;
            object-fit: cover;
        }
    }
    .product-details-name-price-container {
        display: grid;
        //height: 60%;
        margin-left: 0.25rem;
        margin-right: 1.5rem;
        gap: .3rem;
        .product-details-name-text {
            color: var(--black-100, #0F172A);
font-family: "DM Sans";
font-size: 1.25rem;
font-style: normal;
font-weight: 300;
line-height: 2.5rem; /* 200% */
        }
        .product-details-price-text {
            color: var(--black-100, #0F172A);
font-family: "DM Sans";
font-size: 1.25rem;
font-style: normal;
font-weight: 500;
line-height: 2.5rem;
        }
    }
    .product-details-order-number-container {
        display: flex;
        gap: 1.19rem;
        align-items: center;
        .order-number-text-container {
            display: flex;
            align-items: center;
            justify-content: center;
            background: transparent;
            outline: 0;
            color: var(--black-100, #0F172A);

/* Medium */
font-family: DM Sans;
font-size: 1.25rem;
font-style: normal;
font-weight: 500;
line-height: normal;
            .order-number-text {
                font-weight: 500;
                font-size: 20px;
                line-height: 41.66px;
            }
        }
        .order-number-container {
            display: flex;
            width: 16px;
            height: 16px;
            justify-content: center;
            align-items: center;
            background: transparent;
        }
    }
}
</style>