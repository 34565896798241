<template>
  <div id="app">
    <Loading v-if="isLoading" />
    <Header v-if="showHeader === true" />
    <div class="min-h-[calc(100dvh-300px)]">
      <router-view />
    </div>
    <Footer v-if="showHeader === true" />
  </div>
</template>

<style lang="scss">
@tailwind base;
@tailwind components;
@tailwind utilities;

@import url("https://fonts.googleapis.com/css2?family=DM+Sans:opsz,wght@9..40,300;9..40,400;9..40,500;9..40,600;9..40,700;9..40,800;9..40,900&display=swap");

* {
  margin: 0px;
  padding: 0px;
  box-sizing: border-box;
}

body {
  font-family: "DM Sans", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #0f172a;
  overflow-x: hidden;
  font-style: normal;
  line-height: normal;
}

ul {
  list-style: none;
}

button {
  border: none;
  box-shadow: none;
}

header,
footer {
  display: none;
}

@media only screen and (max-width: 940px) {
  body {
    overflow-y: scroll;
  }
}

.hide-scrollbar::-webkit-scrollbar {
  display: none;
}
</style>

<script lang="ts">
import Footer from "@/components/Footer.vue";
import Header from "@/components/Header.vue";
import Loading from "@/components/Loading.vue";
import { isLoading } from "@/event-bus";
import axios from "axios";
import { defineComponent, ref, watch } from "vue";

export default defineComponent({
  name: "App",
  components: {
    Header,
    Footer,
    Loading,
  },
  data() {
    return {
      showHeader: true,
      //      isLoading: false,
    };
  },
  methods: {
    verifyToken() {
      const token = localStorage.getItem("AuthToken");
      const refreshToken = localStorage.getItem("ReToken");

      if (token) {
        axios
          .post("/auth/jwt/verify", {
            token: token,
          })
          .then((response) => {
            console.log(response);
            const answer = response.data;
          })
          .catch((error) => {
            console.error("Error verifying token:", error);
          });
      } else {
        console.log("No token");
      }
    },
  },
  created() {
    this.verifyToken();
    this.$router.beforeEach((to, from, next) => {
      this.showHeader = ![
        "/signin",
        "/signup",
        "/forgotpassword",
        "/core/password/reset/confirm",
      ].includes(to.path);
      next();
    });
    //    this.verifyToken();
  },
  setup() {
    // Use the isLoading variable in the setup function
    const isLoadingValue = ref(isLoading);

    // Watch for changes in the global loading state and update the local variable
    watch(isLoading, (newLoadingState) => {
      if (!newLoadingState) {
        document.body.classList.remove("no-scroll");
      } else {
        document.body.classList.add("no-scroll");
        window.scrollTo(0, 0);
      }
      isLoadingValue.value = newLoadingState;
    });

    return {
      isLoading: isLoadingValue,
    };
  },
});
</script>
