import { createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "page-not-found" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_router_link = _resolveComponent("router-link")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _cache[3] || (_cache[3] = _createElementVNode("h1", null, " Link broken ", -1)),
    _createElementVNode("p", null, [
      _cache[1] || (_cache[1] = _createTextVNode("We could not find that page. But you can go to the ")),
      _createVNode(_component_router_link, {
        class: "router-link",
        to: "/"
      }, {
        default: _withCtx(() => _cache[0] || (_cache[0] = [
          _createTextVNode(" homepage")
        ])),
        _: 1
      }),
      _cache[2] || (_cache[2] = _createTextVNode(". Please ensure the page exists. "))
    ])
  ]))
}