/* stores/user.js */
import { defineStore } from 'pinia';
import axios from 'axios';
import { useProductStore } from './product';

type User = {
  username?: null | string
  email: null | string;
  token: string;
  refreshToken: string;
}
type AuthHeader = {
  headers: {
    Authorization: string;
  }
} | {}
export const useUserStore = defineStore('user', {
  state: () => ({
    token: '',
    refreshToken: '',
    username: '',
    email: '',
  }),
  getters: {
    getUser: (state):User => {
      if(!!state.token) {
        return {
          username: state.username,
          email: state.email,
          token: state.token,
          refreshToken: state.refreshToken,
        }
      }

      const localUser = localStorage.getItem('user');
      if (!!localUser) {
        return JSON.parse(localUser);
      } else {
        return {
          email: null,
          token: '',
          refreshToken: '',
        } as User;
      }
    },
    getAuthHeader(): AuthHeader {
      const user = this.getUser;
      return !!user.token ? {
        headers: {
          Authorization: `JWT ${user.token}`
        }
      } : {};
    }
  },
  actions: {
    async signIn(
      { username, email, password }:
      { username?: string, email: string, password: string }
    ): Promise<{ token: string, refreshToken: string }> {
      const productStore = useProductStore();

      try {
        const { data } = await axios.post('/auth/jwt/create', {
          username,
          email,
          password,
        })
  
        const token = data.access;
        const refreshToken = data.refresh

        this.token = token
        this.refreshToken = refreshToken
        this.username = username || ''
        this.email = email
        localStorage.setItem('AuthToken', token)
        localStorage.setItem('ReToken', refreshToken)
        localStorage.setItem('user', JSON.stringify({
          username,
          email,
          token,
          refreshToken
        }))

        // fetch cart items (this is how we get the cart id) then merge cart items
        await productStore.mergeCart()
        
        return {
          token,
          refreshToken
        }
      } catch (error) {
        throw error;
      }
    },
    signOut():Promise<boolean> {
      return new Promise((resolve) => {
        this.token = ''
        this.refreshToken = ''
        this.username = ''
        this.email = ''
        localStorage.removeItem('AuthToken')
        localStorage.removeItem('ReToken')
        localStorage.removeItem('user')
        localStorage.removeItem("cartID");
        localStorage.removeItem("searchQueries");
        resolve(true)
      })
    },
    async verifyUser({ token, uId }: { token: string, uId: string }): Promise<boolean> {
      return new Promise(async (resolve, reject)=>{
        try {
          const res = await axios.get(`/core/activation/?uid=${uId}&token=${token}`)
          resolve(true)
        } catch (error) {
          reject(error)
        }
      })
    }
  }
});