<template>
    <div id="register">
        <div v-if="status === 'setnewpassword'" id="details">
            <AuthHeader />
            <h1>New Password</h1>
            <form @submit="setNewPassword" method="post">
                <div class="input">
                    <input
                        v-model="password" type="password" ref="password"
                        placeholder="Enter your new password" id="form-password" required>
                    <span class="error">{{ formatErrorMessage(errors.password) }}</span>
                </div>
                <div class="input">
                    <input
                        v-model="re_password" type="password" ref="re-password"
                        placeholder="Re-enter your new password" id="re-password" required>
                    <span class="error">{{ formatErrorMessage(errors.re_password) }}</span>
                </div>
                <div class="input">
                    <input type="submit" name="submit" :value="buttonValue" id="form-button" :disabled="buttonDisabled" :style="{ opacity: buttonDisabled ? '0.3' : '1' }" >
                    <span class="error">{{ formatErrorMessage(errors.detail) }}</span>
                </div>
            </form>
        </div>
        <div v-if="status === 'passwordsaved'" id="details">
            <AuthHeader />
            <form action="" method="post">
                <h3 class="subbigtext">Password Saved!</h3>
                <div class="input">
                    <input @click="signIn()" type="button" name="submit" :value="buttonValue" id="form-button" :disabled="buttonDisabled" :style="{ opacity: buttonDisabled ? '0.3' : '1' }" >
                    <span class="error">{{ formatErrorMessage(errors.detail) }}</span>
                </div>
            </form>
        </div>
        <div id="photo-div">
            <img src="../assets/images/signin.png" alt="A black shirt with diagonal tiles on a lady">
        </div>
    </div>
</template>

<style lang="scss" scoped>
#register {
    padding: 51px 80px 44px;
    background: var(--white, #FFF);
    font-family: "DM Sans", sans-serif;
    font-style: normal;
    line-height: normal;
    width: 100vw;
    height: 125vh;
    display: flex;
    justify-content: center;
    gap: 18px;
    overflow-y: auto;
    #details {
        height: 100%;
        width: 50%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        header {
            margin-bottom: 5rem;
        }
        h1 {
            font-size: 48px;
            color: var(--black-100, #0F172A);
            font-weight: 500;
            margin-bottom: 7px;
            margin-top: 2rem;
        }
        p {
            color: var(--black-50, rgba(15, 23, 42, 0.50));
            font-size: 14px;
            font-weight: 500;
            span {
                a.router-link {
                    text-decoration: none;
                    color: var(--button-default, #4385F5);
                } 
            }
        }
        form {
            flex-grow: 1;
            margin-top: 47px;
            margin-bottom: 75px;
            font-size: 16px;
            font-weight: 500;
            text-align: center;
            display: flex;
            flex-direction: column;
            gap: 25px;
            width: 366px;
            font-family: "DM Sans", sans-serif;
            color: var(--black-50, rgba(15, 23, 42, 0.50));
            h3 {
                color: var(--black-100, #0F172A);
                font-family: DM Sans;
                font-size: 24px;
                font-style: normal;
                font-weight: 500;
                line-height: normal;
            }
            .input {
                display: flex;
                gap: 10px;
                flex-direction: column;
                width: 100%;
                align-items: center;
                span {
                    font-size: 11px;
                    color: red;
                }
            }
            input {
                width: 100%;
                border-radius: 30px;
                padding: 24px;
                border: 1px solid var(--black-30, rgba(15, 23, 42, 0.30));
            }
            #form-button {
                background: var(--button-default, #4385F5);
                border: none;
                border-radius: 30px;
                color: #fff;
            }
        }
        #socials {
            display: flex;
            gap: 61px;
            margin-bottom: 100px;
        }
    }
    #photo-div {
        width: 50%;
        height: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        img {
            height: 100%;
            border-radius: 15px;
        }
    }
}

@media only screen and (max-width: 940px) {
#register {
    padding: 54px 24px 31px;
    flex-direction: column;
    height: fit-content;
    #details {
        width: 100%;
        align-items: flex-start;
        header {
            margin-bottom: 0px;
        }
        h1 {
            margin-top: 47px;
            margin-bottom: 28px;
            font-size: 32px;
        }
        p {
            font-size: 14px;
        }
        form {
            gap: 19px;
            width: 100%;
            h3 {
                color: var(--black-100, #0F172A);
                text-align: center;
                font-family: DM Sans;
                font-size: 18px;
                font-style: normal;
                font-weight: 500;
                line-height: normal;
            }
        }
        #socials {
            width: 100%;
            justify-content: center;
            margin-bottom: 0px;
        }
    }
    #photo-div {
        display: none;
    }
}
}
</style>

<script lang="ts">
import axios from 'axios'
import AuthHeader from '@/components/AuthHeader.vue';
import { defineComponent } from 'vue'
import { toast } from 'vue3-toastify';
import 'vue3-toastify/dist/index.css';

export default defineComponent({
    name: 'PasswordReset',
    components: {
        AuthHeader,
    },
    data() {
        return {
            status: 'setnewpassword',
            buttonValue: 'Set new password',
            buttonDisabled: false,
//            email: '',
            password: '',
            re_password: '',
            errors: {
//                email: this.email,
                password: this.password,
                re_password: this.re_password,
                detail: '',
            },
        }
    },
    methods: {
        formatErrorMessage(messages: Function | string) {
            if (Array.isArray(messages)) {
                return messages.join(' '); // Join array elements with a space
            }
            return messages; // Return empty string if no error message
        },
        async setNewPassword(e) {
            e.preventDefault();
            this.buttonValue = 'Setting new password'
            this.buttonDisabled = true;

            const userDetails = {
                token: this.$route.params.token,
                uid: this.$route.params.uid,
                new_password: this.password,
                re_new_password: this.re_password,
            }

            try {
                const response = await axios.post('/auth/users/reset_password_confirm/', userDetails);

                this.status = 'passwordsaved'
                const token = response.data.access;
                localStorage.setItem('AuthToken', token)
                //console.log('Login successful:', response.data);
                
            } catch (error) {
                this.buttonValue = 'Sign In'
                this.buttonDisabled = false;

                if (axios.isAxiosError(error)) {
                    toast.error(
                        error?.response?.data?.new_password?.[0] ||
                        // non_field_errors
                        error?.response?.data?.non_field_errors?.[0]
                    )

                    if (error.response && error.response.status === 400) {
                        this.errors = error.response.data;
                    } else if (error.response && error.response.status === 401) {
                        this.errors = error.response.data;
                    } else if (error.response && error.response.status === 403) {
                        this.errors = error.response.data;
                    }
                    
                    } else {
                    this.errors.detail = 'An error occured. Please try again'
                    console.error('Non-Axios error:', error);
                }
                console.error('Login failed:', error);
            }
        },
        signIn() {
            this.$router.push({name: 'home'})
        }
    }
})
</script>