import { createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, vModelText as _vModelText, normalizeClass as _normalizeClass, withDirectives as _withDirectives } from "vue"

const _hoisted_1 = { class: "title-div" }
const _hoisted_2 = { class: "input" }
const _hoisted_3 = { class: "error" }
const _hoisted_4 = {
  key: 0,
  class: "edit-button"
}
const _hoisted_5 = { class: "content-div" }
const _hoisted_6 = { class: "details" }
const _hoisted_7 = { class: "container" }
const _hoisted_8 = ["disabled"]
const _hoisted_9 = { class: "container" }
const _hoisted_10 = ["disabled"]
const _hoisted_11 = { class: "details" }
const _hoisted_12 = { class: "container" }
const _hoisted_13 = ["disabled"]
const _hoisted_14 = { class: "container" }
const _hoisted_15 = ["disabled"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(["info-details", { border: _ctx.settings === true}])
  }, [
    _createElementVNode("div", _hoisted_1, [
      _createElementVNode("div", _hoisted_2, [
        _cache[5] || (_cache[5] = _createElementVNode("span", { class: "title" }, "Address", -1)),
        _createElementVNode("span", _hoisted_3, _toDisplayString(_ctx.formatErrorMessage(_ctx.errors.detail)), 1)
      ]),
      (_ctx.settings === true)
        ? (_openBlock(), _createElementBlock("div", _hoisted_4, [
            _createElementVNode("button", {
              class: "edit",
              style: {"cursor":"pointer"},
              onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.saveUserDetails()))
            }, _cache[6] || (_cache[6] = [
              _createElementVNode("span", { class: "edit-text" }, "Save", -1),
              _createElementVNode("svg", {
                width: "15",
                height: "14",
                viewBox: "0 0 15 14",
                fill: "none",
                xmlns: "http://www.w3.org/2000/svg"
              }, [
                _createElementVNode("path", {
                  "fill-rule": "evenodd",
                  "clip-rule": "evenodd",
                  d: "M13.3603 0.195726C13.0996 -0.065242 12.6769 -0.0652421 12.4162 0.195726L11.8338 0.778677C11.0894 0.422698 10.1707 0.553319 9.55407 1.17054L2.47309 8.25841L6.24961 12.0386L13.3306 4.95074C13.9472 4.33352 14.0777 3.41396 13.7221 2.66878L14.3045 2.08583C14.5652 1.82486 14.5652 1.40174 14.3045 1.14078L13.3603 0.195726ZM10.511 5.88297L6.24961 10.1485L4.36135 8.25841L8.62275 3.99287L10.511 5.88297ZM11.7249 4.6679L12.3865 4.00569C12.6472 3.74472 12.6472 3.32161 12.3865 3.06064L11.4423 2.11559C11.1816 1.85462 10.7589 1.85462 10.4982 2.11559L9.83663 2.7778L11.7249 4.6679Z",
                  fill: "#0F172A",
                  "fill-opacity": "0.5"
                }),
                _createElementVNode("path", {
                  d: "M0.5 14L1.91646 8.80197L5.69272 12.5824L0.5 14Z",
                  fill: "#0F172A"
                })
              ], -1)
            ]))
          ]))
        : _createCommentVNode("", true)
    ]),
    _createElementVNode("div", _hoisted_5, [
      _createElementVNode("div", _hoisted_6, [
        _createElementVNode("div", _hoisted_7, [
          _cache[7] || (_cache[7] = _createElementVNode("p", { class: "content-text" }, "Country", -1)),
          _withDirectives(_createElementVNode("input", {
            disabled: _ctx.inputDisabled,
            "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.country) = $event)),
            class: _normalizeClass({ border: _ctx.settings === true}),
            type: "text",
            name: "country",
            placeholder: "Country"
          }, null, 10, _hoisted_8), [
            [_vModelText, _ctx.country]
          ])
        ]),
        _createElementVNode("div", _hoisted_9, [
          _cache[8] || (_cache[8] = _createElementVNode("p", { class: "content-text" }, "State / city", -1)),
          _withDirectives(_createElementVNode("input", {
            disabled: _ctx.inputDisabled,
            "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.stateCity) = $event)),
            class: _normalizeClass({ border: _ctx.settings === true}),
            type: "text",
            name: "state/city",
            placeholder: "State or City"
          }, null, 10, _hoisted_10), [
            [_vModelText, _ctx.stateCity]
          ])
        ])
      ]),
      _createElementVNode("div", _hoisted_11, [
        _createElementVNode("div", _hoisted_12, [
          _cache[9] || (_cache[9] = _createElementVNode("p", { class: "content-text" }, "Postal code", -1)),
          _withDirectives(_createElementVNode("input", {
            disabled: _ctx.inputDisabled,
            "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => ((_ctx.postalCode) = $event)),
            class: _normalizeClass({ border: _ctx.settings === true}),
            type: "text",
            name: "postcode",
            placeholder: "Postal Code"
          }, null, 10, _hoisted_13), [
            [_vModelText, _ctx.postalCode]
          ])
        ]),
        _createElementVNode("div", _hoisted_14, [
          _cache[10] || (_cache[10] = _createElementVNode("p", { class: "content-text" }, "TAX ID", -1)),
          _withDirectives(_createElementVNode("input", {
            disabled: _ctx.inputDisabled,
            "onUpdate:modelValue": _cache[4] || (_cache[4] = ($event: any) => ((_ctx.taxID) = $event)),
            class: _normalizeClass({ border: _ctx.settings === true}),
            type: "text",
            name: "tax-id",
            placeholder: "Tax ID"
          }, null, 10, _hoisted_15), [
            [_vModelText, _ctx.taxID]
          ])
        ])
      ])
    ])
  ], 2))
}