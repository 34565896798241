<script setup lang="ts">
import ScrollableSection from '@/components/ScrollableSection.vue';
import ScrollableSectionCard from '@/components/ScrollableSectionCard.vue';
import axios from 'axios';
import { onMounted, ref } from 'vue';
import { useRoute } from 'vue-router';
interface BlogPost {
  id: number;
  title: string;
  content: string;
  linked_items: any[]
  created_at: string;
}

const blogPosts = ref<BlogPost | undefined>();
const route = useRoute();
const blogId = route.params?.id as string

const fetchPosts = async () => {
    const res = await axios.get(`/blog/blogposts/${blogId}`);
    if (res.status === 200) {
        blogPosts.value = res.data;
    }
}

const  nl2Br = (text: string) => text.replace(/\n/g, "<br />");

function getTimeDifference(timestamp) {
    const currentDate = new Date();
    const postDate = new Date(timestamp);

    const timeDifference = (currentDate as any) - (postDate as any);
    const daysDifference = Math.floor(timeDifference / (1000 * 60 * 60 * 24));

    if (daysDifference === 0) {
        return 'Today';
    } else if (daysDifference === 1) {
        return 'Yesterday';
    } else if (daysDifference < 7) {
        return `${daysDifference} days ago`;
    } else {
        return postDate.toLocaleDateString('en-US', { year: 'numeric', month: 'long', day: 'numeric' });
    }
}

onMounted(fetchPosts)
</script>

<template>
    <section class="bg-white">
        <div class="py-8 px-4 mx-auto max-w-screen-xl">
            <main class="mt-10">
                <div class="mb-4 md:mb-0 w-full mx-auto relative">
                  <div class="px-4">
                    <h2 class="text-4xl font-semibold text-gray-800 leading-tight">
                      {{ blogPosts?.title }}
                    </h2>
                    <a 
                      href="#"
                      class="py-2 text-green-700 inline-flex items-center justify-center mb-2"
                    >
                      {{ getTimeDifference(blogPosts?.created_at) }}
                    </a>
                  </div>
                </div>
                <div class="flex flex-col lg:flex-row">
                  <div v-if="blogPosts?.content" class="px-4 lg:px-0 mt-12 text-gray-700 text-lg leading-relaxed w-full">
                    <p v-html="nl2Br(blogPosts?.content as string)" class="pb-6"></p>
                  </div>
                </div>

                <div class="mt-10">
                    <h3 class="text-3xl font-semibold text-gray-800 leading-tight">Linked Items</h3>
                     <ScrollableSection class="pb-10">
                        <ScrollableSectionCard
                          v-for="(item, i) in blogPosts?.linked_items"
                          :key="i"
                          :id="item.id"
                          :favorite_id="item.favorite_id"
                          :name="item.name"
                          :price="item.price"
                          :new_price="item.new_price"
                          :image="item.image"
                          :slug="item.slug"
                          :is_favourite="item.is_favourite"
                          class="bg-gray-50/90 rounded-3xl overflow-clip"
                        >
                        </ScrollableSectionCard>
                    </ScrollableSection>
                </div>
            </main>
        </div>
</section>
</template>
