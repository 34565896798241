import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, vModelText as _vModelText, withDirectives as _withDirectives, toDisplayString as _toDisplayString, normalizeStyle as _normalizeStyle, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode } from "vue"
import _imports_0 from '../assets/images/signin.png'


const _hoisted_1 = { id: "register" }
const _hoisted_2 = {
  key: 0,
  id: "details"
}
const _hoisted_3 = {
  action: "",
  method: "post"
}
const _hoisted_4 = { class: "input" }
const _hoisted_5 = { class: "error" }
const _hoisted_6 = { class: "input" }
const _hoisted_7 = ["value", "disabled"]
const _hoisted_8 = { class: "error" }
const _hoisted_9 = {
  key: 1,
  id: "details"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_AuthHeader = _resolveComponent("AuthHeader")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (_ctx.status === 'forgotpassword')
      ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
          _createVNode(_component_AuthHeader),
          _cache[2] || (_cache[2] = _createElementVNode("h1", null, "Forgot Password", -1)),
          _createElementVNode("form", _hoisted_3, [
            _createElementVNode("div", _hoisted_4, [
              _withDirectives(_createElementVNode("input", {
                "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.email) = $event)),
                type: "text",
                name: "email",
                placeholder: "Enter your email address",
                id: "form-email"
              }, null, 512), [
                [_vModelText, _ctx.email]
              ]),
              _createElementVNode("span", _hoisted_5, _toDisplayString(_ctx.formatErrorMessage(_ctx.errors.email)), 1)
            ]),
            _createElementVNode("div", _hoisted_6, [
              _createElementVNode("input", {
                onClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.sendLink())),
                type: "button",
                name: "submit",
                value: _ctx.buttonValue,
                id: "form-button",
                disabled: _ctx.buttonDisabled,
                style: _normalizeStyle({ opacity: _ctx.buttonDisabled ? '0.3' : '1' })
              }, null, 12, _hoisted_7),
              _createElementVNode("span", _hoisted_8, _toDisplayString(_ctx.formatErrorMessage(_ctx.errors.detail)), 1)
            ])
          ])
        ]))
      : _createCommentVNode("", true),
    (_ctx.status === 'emailsent')
      ? (_openBlock(), _createElementBlock("div", _hoisted_9, [
          _createVNode(_component_AuthHeader),
          _cache[3] || (_cache[3] = _createElementVNode("form", {
            action: "",
            method: "post"
          }, [
            _createElementVNode("h3", { class: "subbigtext" }, "Password Reset Link Sent!"),
            _createElementVNode("p", null, "Check your email")
          ], -1))
        ]))
      : _createCommentVNode("", true),
    _cache[4] || (_cache[4] = _createElementVNode("div", { id: "photo-div" }, [
      _createElementVNode("img", {
        src: _imports_0,
        alt: "A black shirt with diagonal tiles on a lady"
      })
    ], -1))
  ]))
}