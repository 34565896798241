import { defineComponent as _defineComponent } from 'vue'
import { openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createTextVNode as _createTextVNode, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "[&>*]:rounded-full [&>*]:p-3 [&>*]:px-6 [&>*]:bg-primary [&>*]:min-w-[140px] text-lg flex gap-4 [&>*]:ring [&>*]:ring-transparent" }
const _hoisted_2 = ["disabled"]
const _hoisted_3 = ["disabled"]

import { PaginatedResponse } from '@/router/types';
import Spinner from './Spinner.vue';
import { computed, ref } from 'vue';


export default /*@__PURE__*/_defineComponent({
  __name: 'Pagination',
  props: {
    data: {},
    loading: { type: Boolean }
  },
  emits: ['fetchMore'],
  setup(__props: any, { emit: __emit }) {

const props = __props
const emits = __emit;

const hasNextPage = computed(() => props.data?.next !== null);
const hasPreviousPage = computed(() => props.data?.previous !== null);

const lastDirection = ref();
const fetchMore = (direction: number) => {
  lastDirection.value = direction;
  if (direction === 1 && hasNextPage.value) {
    const nextPage = props.data?.next;
    // fetch next page
    emits('fetchMore', nextPage);
  } else if (direction === -1 && hasPreviousPage.value) {
    const previousPage = props.data?.previous;
    // fetch previous page
    emits('fetchMore', previousPage);
  }
}

return (_ctx: any,_cache: any) => {
  const _component_Spinnerr = _resolveComponent("Spinnerr")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("button", {
      onClick: _cache[0] || (_cache[0] = ($event: any) => (fetchMore(-1))),
      class: "flex items-center justify-center relative hover:ring-primary/60 disabled:opacity-40 disabled:hover:ring-transparent",
      disabled: !hasPreviousPage.value
    }, [
      _cache[2] || (_cache[2] = _createTextVNode(" Previous ")),
      (_ctx.loading && lastDirection.value===-1)
        ? (_openBlock(), _createBlock(Spinner, {
            key: 0,
            class: "absolute w-8 text-gray-300"
          }))
        : _createCommentVNode("", true)
    ], 8, _hoisted_2),
    _createElementVNode("button", {
      onClick: _cache[1] || (_cache[1] = ($event: any) => (fetchMore(1))),
      class: "flex items-center justify-center relative hover:ring-primary/60 disabled:opacity-40 disabled:hover:ring-transparent",
      disabled: !hasNextPage.value
    }, [
      _cache[3] || (_cache[3] = _createTextVNode(" Next ")),
      (_ctx.loading && lastDirection.value===1)
        ? (_openBlock(), _createBlock(_component_Spinnerr, {
            key: 0,
            class: "absolute w-8 text-gray-300"
          }))
        : _createCommentVNode("", true)
    ], 8, _hoisted_3)
  ]))
}
}

})