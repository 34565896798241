import { createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, normalizeClass as _normalizeClass, toDisplayString as _toDisplayString } from "vue"

const _hoisted_1 = { class: "main-shippingmode-container" }
const _hoisted_2 = { class: "shipping-mode-select-container" }
const _hoisted_3 = { class: "checkbox-select-container" }
const _hoisted_4 = { class: "home-delivery-container" }
const _hoisted_5 = { class: "checkbox-button-container" }
const _hoisted_6 = {
  key: 0,
  class: "button-container"
}
const _hoisted_7 = {
  key: 1,
  class: "button-container"
}
const _hoisted_8 = { class: "main-subtotal-shipping-total-container" }
const _hoisted_9 = { class: "main-subtotal-container" }
const _hoisted_10 = { class: "price-text font-class" }
const _hoisted_11 = { class: "main-shipping-mode-container" }
const _hoisted_12 = { class: "price-text font-class" }
const _hoisted_13 = { class: "main-total-container" }
const _hoisted_14 = { class: "price-text font-class" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_box_icon = _resolveComponent("box-icon")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _cache[5] || (_cache[5] = _createElementVNode("div", { class: "shippingmode-header" }, [
      _createElementVNode("span", { class: "header-text" }, "Shipping Mode")
    ], -1)),
    _createElementVNode("div", _hoisted_2, [
      _cache[1] || (_cache[1] = _createElementVNode("div", { class: "shipping-mode-select-header" }, [
        _createElementVNode("span", { class: "select-header-text" }, "SHIPPING METHOD")
      ], -1)),
      _createElementVNode("div", _hoisted_3, [
        _createElementVNode("div", _hoisted_4, [
          _createElementVNode("div", _hoisted_5, [
            (_ctx.home_delivery_check == true)
              ? (_openBlock(), _createElementBlock("button", _hoisted_6, [
                  _createVNode(_component_box_icon, {
                    type: "solid",
                    color: "#4385F5",
                    name: "circle"
                  })
                ]))
              : (_openBlock(), _createElementBlock("button", _hoisted_7))
          ]),
          _createElementVNode("span", {
            class: _normalizeClass([{ 'bold': _ctx.home_delivery_check == true, 'light': _ctx.home_delivery_check != true }, "home-delivery-text font-class"])
          }, "Home delivery", 2)
        ])
      ])
    ]),
    _createElementVNode("div", _hoisted_8, [
      _createElementVNode("div", _hoisted_9, [
        _cache[2] || (_cache[2] = _createElementVNode("span", { class: "tag-text font-class" }, "SUBTOTAL", -1)),
        _createElementVNode("span", _hoisted_10, "₦" + _toDisplayString(_ctx.subtotal_price), 1)
      ]),
      _createElementVNode("div", _hoisted_11, [
        _cache[3] || (_cache[3] = _createElementVNode("span", { class: "tag-text font-class" }, "SHIPPING", -1)),
        _createElementVNode("span", _hoisted_12, "₦" + _toDisplayString(_ctx.shipping_type), 1)
      ]),
      _createElementVNode("div", _hoisted_13, [
        _cache[4] || (_cache[4] = _createElementVNode("span", { class: "tag-text font-class" }, "TOTAL", -1)),
        _createElementVNode("span", _hoisted_14, "₦" + _toDisplayString(_ctx.total_price), 1)
      ])
    ]),
    _createElementVNode("button", {
      onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.toCheckout && _ctx.toCheckout(...args))),
      class: "checkout-button font-class"
    }, "Checkout")
  ]))
}