<template>
  <section>
    <!-- intro -->
    <div class="intro">
      <!-- categories -->
      <div class="flex justify-center px-10">
        <Tabs
          :tab_list="mixedTabList"
          :value="activeMixedTab"
          query_name="mixed"
          @model-value="($event) => activeMixedTab = $event"
          class="inline-flex gap-x-1 sm:gap-x-2 justify-between select-none
          rounded-[100px] [&>*]:flex-grow [&>*]:max-w-[50%] text-base
          border border-gray-400 max-w-[90vw] overflow-x-auto hide-scrollbar">
          <template v-slot:tab="{ tab, index: i, is_active }">
            <div
              :class="[is_active ? 'bg-primary text-white' : 'bg-transparent hover:text-primary']"
              class="p-3 transition duration-300 font-medium cursor-pointer rounded-[100px] min-w-max">
              <span class="flex flex-col items-center">
                <span>{{ tab?.name }}</span>
              </span>
            </div>
          </template>
        </Tabs>
      </div>
    </div>

    <!-- images -->
    <div class="container">
      <div id="image">
        <ItemCard
          v-for="(item, i) in renderedMixedItems" :key="item.id"
          :id="activeMixedTab === 'new_arrivals' ? item.item.id : item.id"
          :favorite_id="activeMixedTab === 'new_arrivals' ? item.item.favorite_id : item.favorite_id"
          :name="activeMixedTab === 'new_arrivals' ? item.item.name : item.name"
          :price="activeMixedTab === 'new_arrivals' ? item.item.price : item.price"
          :new_price="activeMixedTab === 'new_arrivals' ? item.item.new_price : item.new_price"
          :image="activeMixedTab === 'new_arrivals' ? item.item.image : item.image"
          :slug="activeMixedTab === 'new_arrivals' ? item.item.slug : item.slug"
          :is_favourite="activeMixedTab === 'new_arrivals' ? item.item.is_favourite : item.is_favourite">
        </ItemCard>
      </div>
    </div>
    <div
      v-if="renderedMixedItems.length === 0 && !homeStore.requestingMixedItem"
      class="h-96 bg-gray-50 flex items-center justify-center
      flex-col text-2xl font-medium text-gray-300">
      <span>
        {{
          userStore.getUser.token ? 'No item in here yet' :
          `Sign in to see your ${String(activeMixedTab).replace(/-/g, ' ')} items`
        }}
      </span>
      <router-link
        v-if="!userStore.getUser.token"
        to="/signin"
        class="text-gray-500">Sign in</router-link>
    </div>
    <div
      v-if="homeStore.requestingMixedItem"
      class="h-96 flex items-center justify-center
      flex-col text-2xl gap-3 font-medium text-gray-300">
      <span>
        Fetching products...
      </span>
      <Spinner class="w-8 text-gray-500" />
    </div>
  </section>
</template>

<script setup lang="ts">
import { computed, onMounted, ref, watch } from 'vue';
import { SectionTabs } from '@/router/types';
import Tabs from '@/components/Tabs.vue';
import ItemCard from '@/components/ItemCard.vue';
import { useHomeStore } from "@/store/home"
import { useUserStore } from "@/store/user"
import Spinner from '@/components/Spinner.vue';

const userStore = useUserStore();
const activeMixedTab = ref(undefined)
const mixedTabList = ref<SectionTabs[]>([
  {
    name: "Popular",
    slug: 'popular-items',
    requiresAuth: false,
  },
  {
    name: "Recently viewed",
    slug: 'recently_viewed',
    requiresAuth: true,
  },
  {
    name: "Favourites",
    slug: 'favourites',
    requiresAuth: true,
  },
  {
    name: "New Arrivals",
    slug: 'new_arrivals',
    requiresAuth: false,
  },
])

const homeStore = useHomeStore();
onMounted(() => {
  Promise.all([
    mixedTabList.value.map(tab => homeStore.fetchMixedItems({
      endpointName: tab.slug.replace('-', '_'),
      requiresAuth: Boolean(tab.requiresAuth),
    }))
  ])
})
const renderedMixedItems = computed(()=>{
  return homeStore.getMixedItems(
    String(mixedTabList.value.find(tab => tab.slug === activeMixedTab.value)?.slug).replace('-', '_') ||
    'popular_items'
  ) || []
})
</script>

<style lang="scss">
section {
  margin-top: 3rem;

  .intro {
    display: flex;
    flex-direction: column;
    row-gap: 2rem;
    justify-content: center;
    align-items: center;

    #cats {
      margin-inline: auto;
      display: flex;
      width: fit-content;
      height: fit-content;
      justify-content: center;
      align-items: center;
      border-radius: 3.875rem;
      border: 1px solid var(--black-30, rgba(15, 23, 42, 0.3));
      cursor: pointer;

      span {
        display: flex;
        padding-inline: 2rem;
        padding-block: 0.6rem;
        justify-content: center;
        align-items: center;

        p {
          font-size: 16px;
        }
      }

      span:hover {
        color: white;
        border-radius: 3.3125rem;
        background: var(--Button-Default, #4385f5);
      }
    }

    .filter {
      width: 90%;
      margin-inline: auto;
      display: flex;
      justify-content: space-between;
      align-items: center;

      div {
        display: flex;
        justify-content: space-between;
        align-items: center;
        column-gap: 5rem;

        button {
          display: flex;
          justify-content: space-between;
          align-items: center;
          background: transparent;
          column-gap: 1rem;
          cursor: pointer;
        }
      }

      #filter {
        display: flex;
        justify-content: space-between;
        align-items: center;
        column-gap: 1rem;

        span {
          padding-inline: 1rem;
          padding-block: 0.4rem;
          display: flex;
          justify-content: space-between;
          align-items: center;
          column-gap: 1rem;
          width: fit-content;
          height: fit-content;
          border-radius: 3.875rem;
          border: 1px solid var(--black-30, rgba(15, 23, 42, 0.3));
          cursor: pointer;
        }
      }

      #clear {
        background: transparent;
        text-transform: capitalize;
        cursor: pointer;
      }

      #clear:hover {
        color: #4385f5;
      }
    }
  }

  // products
  .container {
    width: 90%;
    margin-inline: auto;
    margin-bottom: 2rem;

    #image {
      position: relative;
      display: grid;
      padding: 1rem;
      grid-template-columns: repeat(3, 1fr);
      gap: 1rem;

      #single {
        div {
          position: relative;
          background-image: url("../assets/images/product-photos/Rectangle417arrivals.png");
          background-repeat: no-repeat;
          background-size: cover;
          border-top-left-radius: 12px;
          border-top-right-radius: 12px;
          max-width: 24rem;
          height: 29rem;

          span {
            position: absolute;
            bottom: 1rem;
            right: 1.5rem;
            display: flex;
            justify-content: center;
            align-items: center;
            width: 2.5rem;
            height: 2.5rem;
            border-radius: 100%;
            background: #fff;
            cursor: pointer;
          }
        }

        #text {
          display: flex;
          justify-content: space-between;
          align-items: center;
          margin-top: 1rem;

          span {
            text-align: start;
            row-gap: 0.6rem;

            p {
              font-size: 1.1rem;
            }

            #price {
              font-weight: 600;
              font-size: 1.2rem;
            }
          }

          button {
            text-transform: capitalize;
            color: var(--Button-Default, #4385f5);
            font-size: 1rem;
            font-weight: 500;
            cursor: pointer;
            background-color: transparent;
          }
        }
      }
    }
  }
}

@media only screen and (max-width: 940px) {
  section {
    .container {
      #image {
        display: grid;
        grid-template-columns: repeat(2, 1fr);
      }
    }
  }
}

@media only screen and (max-width: 426px) {
  section {
    .intro {
      #cats {
        span {
          display: flex;
          padding-inline: 0.5rem;
          padding-block: 0.5rem;
          justify-content: center;
          align-items: center;

          p {
            font-size: 12px;
          }
        }
      }

      #fil {
        border: 1px solid var(--black-30, rgba(15, 23, 42, 0.3));
        padding-block: 0.5rem;
        padding-inline: 0.8rem;
        border-radius: 10px;
      }
      .filter {
        #filter {
          display: none;
        }
      }
    }
    .container {
      #image {
        padding: 0;
        grid-template-columns: repeat(1, 1fr);
      }
    }
  }
}
</style>
