<script lang="ts">
import { defineComponent } from 'vue';
import axios from 'axios'
import {Product} from '@/router/types'
import HelpCentreFAQ from '@/components/MenuSidebar/HelpCentre.vue'

export default defineComponent({
    name: 'Favorite',
    components: {
        HelpCentreFAQ
    },
    data() {
        return {
            Favorite: [] as Product[],
        }
    },
    methods: {
        getFavorite() {
            axios.get('/manager/faqs/')
            .then(response => {
                this.Favorite = response.data
            })
            .catch(error => {
                console.error('Error retrieving FAQs:', error)
            })
        },
    },
    mounted() {
        this.getFavorite();
    },
})
</script>

<template>
    <div class="group-div">
        <h2>Favorite</h2>
        <div class="queries">
            <HelpCentreFAQ v-for="line in Favorite" :key="line.question"
                 :main_text="line.question"
                 :text_expanded="line.answer" />
        </div>
    </div>
</template>

<style lang="scss" scoped>
.group-div {
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 81px;
    h2 {
        color: var(--black-100, #0F172A);
        font-family: DM Sans;
        font-size: 32px;
        font-style: normal;
        font-weight: 500;
        line-height: normal;
    }
    .queries {
        width: 100%;
        display: flex;
        flex-direction: column;
        gap: 50px;
    }
}

@media only screen and (max-width: 940px) {
    .group-div {
        gap: 51.5px;
        h2 {
            font-size: 16px;
        }
    }
}
</style>