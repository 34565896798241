<script lang="ts">
import Productdetails from "@/components/FavoriteCartComponent/Productdetails.vue";
import Shippingmode from "@/components/FavoriteCartComponent/Shippingmode.vue";
import { isLoading, toggleLoading } from "@/event-bus";
import { Product } from "@/router/types";
import axios from "axios";
import { defineComponent } from "vue";
import SecondHeaderVue from "../components/SecondHeader.vue";

export default defineComponent({
  name: "FavoritePage",
  components: {
    SecondHeaderVue,
    Productdetails,
    Shippingmode,
  },
  data() {
    return {
      faveItems: [] as Product[],
      favouritesExist: false,
      grandtotal: "",
      noShipping: false,
      isLoading,
    };
  },
  props: {
    product_id: String,
  },
  methods: {
    navigate() {
      this.$router.push("/section/male");
    },
    checkUserSignedIn() {
      const token = localStorage.getItem("AuthToken");

      if (token) {
      } else {
        this.$router.replace("/signin");
        alert("Sign in to view favourites");
      }
    },
    ifFavouriteExist() {
      toggleLoading(true);
      const token = localStorage.getItem("AuthToken");
      const product_id = this.product_id;

      axios
        .get("api/favourites/", {
          headers: {
            Authorization: `JWT ${token}`,
          },
        })
        .then((response) => {
          this.favouritesExist = true;
          this.faveItems = response.data;
          if (response.data.length === 0) {
            this.favouritesExist = false;
          }
          toggleLoading(false);
        })
        .catch((error) => {
          console.error("Error retrieving favourites:", error);
          this.favouritesExist = false;
          toggleLoading(false);
        });
    },
    removeItemFromFavoriteList(item_id: string) {
      this.faveItems = this.faveItems.filter((item) => item.id !== item_id);
    },
  },
  mounted() {
    this.ifFavouriteExist();
  },
  created() {
    this.checkUserSignedIn();
    document.title = "Favourites | Fondstore";
  },
});
</script>

<template>
  <div class="main-favorite-page-container mt-20">
    <SecondHeaderVue
      main_header="Favourites Page"
      product_gender="Men"
      bold_link="favorite"
    />
    <div
      v-if="favouritesExist === true"
      class="main-favorite-component-container"
    >
      <div class="main-favorite-product-details-container">
        <Productdetails
          v-for="each in faveItems"
          :key="each.id"
          @update_favorite="removeItemFromFavoriteList"
          :favorite_id="each.favorite_id"
          :product_id="each.id"
          :product_name="each.name"
          :price="each.price"
          :size="each.size"
          :order_number="each.quantity"
          :liked="each.is_favourite"
          :photo="each.image"
          :slug="each.slug"
        />
      </div>
      <!-- <pre>
                <div v-for="item in faveItems">{{ item }}</div>
            </pre> -->
      <div
        v-if="noShipping === true"
        class="main-favorite-shipping-mode-container"
      >
        <Shippingmode
          :subtotal_price="`${grandtotal}`"
          shipping_type="FREE"
          :total_price="`${grandtotal}`"
          :store_pickup_check="true"
          :home_delivery_check="false"
        />
      </div>
    </div>
    <template v-if="isLoading">
      <div class="h-96 flex items-center justify-center">
        <h2 class="text-base sm:text-4xl text-gray-300 font-bold">
          Loading...
        </h2>
      </div>
    </template>
    <template v-else>
      <div
        v-if="favouritesExist === false || !faveItems.length"
        class="page-not-found"
      >
        <div class="text-div">
          <h1>It’s pretty quiet in here. Don’t you think?</h1>
          <p>
            Find your perfect and like any of the product to save it in your
            favorite section
          </p>
        </div>
        <router-link to="/" class="form-div">
          <input
            class="submit !cursor-pointer"
            type="button"
            value="Find the best"
          />
        </router-link>
      </div>
    </template>
  </div>
</template>

<style lang="scss">
.main-favorite-page-container {
  height: auto;
  margin-bottom: 5rem;
  .main-favorite-component-container {
    display: flex;
    margin-left: 80px;
    margin-right: 100px;
    margin-bottom: 1rem;
    justify-content: space-between;
    .main-favorite-product-details-container {
      width: 100%;
    }
    .main-favorite-shipping-mode-container {
      width: 33%;
      height: auto;
    }
  }
  .page-not-found {
    width: 100svw;
    height: 70vh;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    gap: 50px;
    .text-div {
      width: fit-content;
      display: flex;
      flex-direction: column;
      gap: 20px;
      text-align: center;
      padding: 0px 150px;
      h1 {
        color: var(--black-100, #0f172a);
        font-family: DM Sans;
        font-size: 32px;
        font-style: normal;
        font-weight: 500;
        line-height: normal;
      }
      p {
        color: var(--black-70, rgba(15, 23, 42, 0.7));
        text-align: center;
        font-family: DM Sans;
        font-size: 20px;
        font-style: normal;
        font-weight: 300;
        line-height: normal;
      }
    }

    .form-div {
      width: 30svw;
      display: flex;
      gap: 20px;
      justify-content: center;
      .submit {
        width: fit-content;
        display: flex;
        padding: 16px 24px;
        justify-content: center;
        align-items: center;
        border-radius: 30px;
        background: var(--Button-Default, #4385f5);
        border: none;
        color: var(--White, #fff);
        text-align: center;

        /* Regular */
        font-family: DM Sans;
        font-size: 14px;
        font-style: normal;
        font-weight: 300;
        line-height: 20px; /* 142.857% */
      }
    }
  }
}

@media only screen and (max-width: 940px) {
  .main-favorite-page-container {
    .main-favorite-component-container {
      display: flex;
      flex-direction: column;
      gap: 50px;
      margin: 0px;
      padding: 0px 23px;
      .main-favorite-product-details-container {
        gap: 0.62rem;
        width: 100%;
      }
    }
    .page-not-found {
      height: 90vh;
      padding: 0px 50px;
      .text-div {
        padding: 0px;
        h1 {
          color: var(--black-100, #0f172a);
          text-align: center;

          /* Medium */
          font-family: DM Sans;
          font-size: 18px;
          font-style: normal;
          font-weight: 500;
          line-height: normal;
        }
        p {
          color: var(--black-50, rgba(15, 23, 42, 0.5));
          text-align: center;

          /* Regular */
          font-family: DM Sans;
          font-size: 14px;
          font-style: normal;
          font-weight: 300;
          line-height: 20px; /* 142.857% */
        }
      }
      .form-div {
        width: 100%;
      }
    }
  }
}
</style>
