import { defineComponent as _defineComponent } from 'vue'
import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = { class: "max-w-6xl mx-auto py-10 mt-20" }
const _hoisted_2 = { class: "flex mb-5 justify-center items-center text-4xl sm:text-xl font-bold" }
const _hoisted_3 = { class: "capitalize" }
const _hoisted_4 = { class: "px-6 md:px-0" }
const _hoisted_5 = ["innerHTML"]
const _hoisted_6 = {
  key: 1,
  class: "py-40 flex justify-center bg-slate-50"
}

import { usePolicyStore } from "@/store/policy";
import { computed, ref, onMounted, watch } from "vue";
import { useRoute, useRouter } from "vue-router";


export default /*@__PURE__*/_defineComponent({
  __name: 'Policy',
  setup(__props) {

const route = useRoute();
const router = useRouter();
const policyStore = usePolicyStore();
const policyList = [
  { name: "Privacy Policy", endpoint: "privacy_policy" },
  { name: "Terms and Conditions", endpoint: "terms_and_condition" },
  { name: "Return and Exchange Policy", endpoint: "return_and_exchange_policy" },
  // { name: 'Cookie Policy', endpoint: undefined}
];
const policyParam = computed(
  () =>
    !!route.params?.policy && (route.params.policy as string).replace(/-/g, " ")
);
const policy = ref();
const renderedPolicy = computed(
  () =>
    Object.keys(policy.value?.[0] || {}).map((key) => policy.value?.[0][key])[0]
);

const loading = ref(false);
const initPage = async () => {
  loading.value = true;
  policy.value = undefined;
  const check =
    policyParam.value &&
    policyList.find(
      (p) => p.name.toLowerCase() === String(policyParam.value).toLowerCase()
    );
  if (!check) return router.push("/");

  policy.value = policyStore.getPolicy(check.endpoint);
  if (policy.value) return (loading.value = false);

  const endpoints = policyList.map((p) => p.endpoint);
  const res = await policyStore.fetchPolicies(endpoints);
  console.log(res);

  if (res) policy.value = policyStore.getPolicy(check.endpoint);
  loading.value = false;
};
 const nl2Br = (text: string) => text.replace(/\n/g, "<br />");
onMounted(initPage);
watch(() => route.params.policy, initPage);

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (!loading.value && renderedPolicy.value)
      ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
          _createElementVNode("div", _hoisted_2, [
            _createElementVNode("p", _hoisted_3, _toDisplayString(policyParam.value), 1)
          ]),
          _createElementVNode("div", _hoisted_4, [
            _createElementVNode("span", {
              class: "description-subtext-text font-class",
              innerHTML: nl2Br(renderedPolicy.value)
            }, null, 8, _hoisted_5)
          ])
        ], 64))
      : (_openBlock(), _createElementBlock("div", _hoisted_6, _cache[0] || (_cache[0] = [
          _createElementVNode("p", { class: "text-gray-400 text-3xl" }, "fetching...", -1)
        ])))
  ]))
}
}

})