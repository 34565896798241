/* stores/policy.js */
import { defineStore } from 'pinia';
import axios from 'axios'

export const usePolicyStore = defineStore('policy', {
  state: () => ({
    policies: {}
  }),
  getters: {
    getPolicy: (state) => (endpoint: undefined | string) => {
      if(!endpoint) return null
      return state.policies?.[endpoint]
    }
  },
  actions: {
    async fetchPolicies(endpoints: (undefined | string)[]): Promise<any> {
      const promises = endpoints.map((endpoint) => {
        if(!endpoint) return Promise.resolve({data: {}})
        return axios.get(`/manager/${endpoint}`)
      })
      return new Promise(async (resolve, reject) => {
        try {
          const res = await Promise.all(promises)
          const policies = res.reduce((acc, {data}, index) => {
            const endpoint = endpoints[index]
            if(!endpoint) return acc
            acc[endpoint] = data
            return acc
          }, {})
          this.policies = policies
          resolve(policies)
        } catch (error) {
          reject(error)
        }
      })
    }
  }
});