import { resolveComponent as _resolveComponent, createVNode as _createVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createBlock as _createBlock, createElementVNode as _createElementVNode, createCommentVNode as _createCommentVNode, withCtx as _withCtx } from "vue"

const _hoisted_1 = { class: "main-favorite-page-container mt-20" }
const _hoisted_2 = {
  key: 0,
  class: "main-favorite-component-container"
}
const _hoisted_3 = { class: "main-favorite-product-details-container" }
const _hoisted_4 = {
  key: 0,
  class: "main-favorite-shipping-mode-container"
}
const _hoisted_5 = {
  key: 1,
  class: "h-96 flex items-center justify-center"
}
const _hoisted_6 = {
  key: 0,
  class: "page-not-found"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_SecondHeaderVue = _resolveComponent("SecondHeaderVue")!
  const _component_Productdetails = _resolveComponent("Productdetails")!
  const _component_Shippingmode = _resolveComponent("Shippingmode")!
  const _component_router_link = _resolveComponent("router-link")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_SecondHeaderVue, {
      main_header: "Favourites Page",
      product_gender: "Men",
      bold_link: "favorite"
    }),
    (_ctx.favouritesExist === true)
      ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
          _createElementVNode("div", _hoisted_3, [
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.faveItems, (each) => {
              return (_openBlock(), _createBlock(_component_Productdetails, {
                key: each.id,
                onUpdate_favorite: _ctx.removeItemFromFavoriteList,
                favorite_id: each.favorite_id,
                product_id: each.id,
                product_name: each.name,
                price: each.price,
                size: each.size,
                order_number: each.quantity,
                liked: each.is_favourite,
                photo: each.image,
                slug: each.slug
              }, null, 8, ["onUpdate_favorite", "favorite_id", "product_id", "product_name", "price", "size", "order_number", "liked", "photo", "slug"]))
            }), 128))
          ]),
          (_ctx.noShipping === true)
            ? (_openBlock(), _createElementBlock("div", _hoisted_4, [
                _createVNode(_component_Shippingmode, {
                  subtotal_price: `${_ctx.grandtotal}`,
                  shipping_type: "FREE",
                  total_price: `${_ctx.grandtotal}`,
                  store_pickup_check: true,
                  home_delivery_check: false
                }, null, 8, ["subtotal_price", "total_price"])
              ]))
            : _createCommentVNode("", true)
        ]))
      : _createCommentVNode("", true),
    (_ctx.isLoading)
      ? (_openBlock(), _createElementBlock("div", _hoisted_5, _cache[0] || (_cache[0] = [
          _createElementVNode("h2", { class: "text-base sm:text-4xl text-gray-300 font-bold" }, " Loading... ", -1)
        ])))
      : (_openBlock(), _createElementBlock(_Fragment, { key: 2 }, [
          (_ctx.favouritesExist === false || !_ctx.faveItems.length)
            ? (_openBlock(), _createElementBlock("div", _hoisted_6, [
                _cache[2] || (_cache[2] = _createElementVNode("div", { class: "text-div" }, [
                  _createElementVNode("h1", null, "It’s pretty quiet in here. Don’t you think?"),
                  _createElementVNode("p", null, " Find your perfect and like any of the product to save it in your favorite section ")
                ], -1)),
                _createVNode(_component_router_link, {
                  to: "/",
                  class: "form-div"
                }, {
                  default: _withCtx(() => _cache[1] || (_cache[1] = [
                    _createElementVNode("input", {
                      class: "submit !cursor-pointer",
                      type: "button",
                      value: "Find the best"
                    }, null, -1)
                  ])),
                  _: 1
                })
              ]))
            : _createCommentVNode("", true)
        ], 64))
  ]))
}