<template>
    <div id="user-username">
        <p>@{{ username }}</p>
    </div>
</template>

<style lang="scss" scoped>
    #user-username {
      font-size: 20px;
        font-family: 'DM Sans', sans-serif;
        font-style: normal;
        font-weight: 300;
        line-height: normal;
        color: var(--black-50, rgba(15, 23, 42, 0.5))
    }

    @media only screen and (max-width: 940px) {
      #user-username {
        font-size: 0.875rem;
      }
    }
</style>

<script lang="ts">
import { defineComponent } from "vue";

export default defineComponent ({
  name: 'username',
  props: {
    username: String
  }
})
</script>