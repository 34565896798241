import { createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createBlock as _createBlock, createTextVNode as _createTextVNode, toDisplayString as _toDisplayString, normalizeStyle as _normalizeStyle } from "vue"
import _imports_0 from '../assets/images/icons/logonew.png'
import _imports_1 from '../assets/images/icons/search.svg'
import _imports_2 from '../assets/images/icons/heart.svg'
import _imports_3 from '../assets/images/icons/shopping-bag.svg'
import _imports_4 from '../assets/images/icons/profile.svg'
import _imports_5 from '../assets/images/etty-fidele-J1jYLLlRpA4-unsplash1profile-photo.png'
import _imports_6 from '../assets/images/icons/details-more.svg'
import _imports_7 from '../assets/images/icons/close.svg'


const _hoisted_1 = {
  key: 0,
  id: "logo"
}
const _hoisted_2 = {
  key: 1,
  id: "nav-menu"
}
const _hoisted_3 = {
  key: 2,
  id: "nav-socials"
}
const _hoisted_4 = {
  id: "hide",
  class: "desktop"
}
const _hoisted_5 = {
  key: 3,
  class: "sidebar"
}
const _hoisted_6 = { class: "menu" }
const _hoisted_7 = { class: "men" }
const _hoisted_8 = { class: "men" }
const _hoisted_9 = { class: "men" }
const _hoisted_10 = {
  key: 0,
  id: "signin"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_router_link = _resolveComponent("router-link")!

  return (_openBlock(), _createElementBlock("header", {
    style: _normalizeStyle(_ctx.showMenu === true ? 'padding: 0px;' : 'color: inherit;')
  }, [
    (_ctx.showMenu === false)
      ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
          _createVNode(_component_router_link, { to: "/" }, {
            default: _withCtx(() => _cache[4] || (_cache[4] = [
              _createElementVNode("img", {
                src: _imports_0,
                alt: "Fondstore logo"
              }, null, -1)
            ])),
            _: 1
          })
        ]))
      : _createCommentVNode("", true),
    (_ctx.showMenu === false)
      ? (_openBlock(), _createElementBlock("nav", _hoisted_2, [
          _createElementVNode("ul", null, [
            _createVNode(_component_router_link, {
              class: "router-link font-class",
              to: "/categories",
              "active-class": "active"
            }, {
              default: _withCtx(() => _cache[5] || (_cache[5] = [
                _createElementVNode("p", null, "Categories", -1)
              ])),
              _: 1
            }),
            _createVNode(_component_router_link, {
              class: "router-link font-class",
              to: "/product",
              "active-class": "active"
            }, {
              default: _withCtx(() => _cache[6] || (_cache[6] = [
                _createElementVNode("p", null, "Product", -1)
              ])),
              _: 1
            }),
            _createVNode(_component_router_link, {
              class: "router-link font-class",
              to: "/trending",
              "active-class": "active"
            }, {
              default: _withCtx(() => _cache[7] || (_cache[7] = [
                _createElementVNode("p", null, "Trending", -1)
              ])),
              _: 1
            }),
            _createVNode(_component_router_link, {
              class: "router-link font-class",
              to: "/blog",
              "active-class": "active"
            }, {
              default: _withCtx(() => _cache[8] || (_cache[8] = [
                _createElementVNode("p", null, "Blog", -1)
              ])),
              _: 1
            }),
            _createVNode(_component_router_link, {
              class: "router-link font-class",
              to: "/latest-arrival",
              "active-class": "active"
            }, {
              default: _withCtx(() => _cache[9] || (_cache[9] = [
                _createElementVNode("p", null, "New Arrivals", -1)
              ])),
              _: 1
            })
          ])
        ]))
      : _createCommentVNode("", true),
    (_ctx.showMenu === false)
      ? (_openBlock(), _createElementBlock("nav", _hoisted_3, [
          _createElementVNode("ul", null, [
            _createElementVNode("li", {
              onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.goToSearch && _ctx.goToSearch(...args)))
            }, _cache[10] || (_cache[10] = [
              _createElementVNode("img", {
                src: _imports_1,
                alt: "",
                class: "twenty-four"
              }, null, -1)
            ])),
            _createElementVNode("div", _hoisted_4, [
              _createElementVNode("li", null, [
                _createVNode(_component_router_link, { to: "/favourites" }, {
                  default: _withCtx(() => _cache[11] || (_cache[11] = [
                    _createElementVNode("img", {
                      src: _imports_2,
                      alt: "",
                      class: "twenty-four notify"
                    }, null, -1)
                  ])),
                  _: 1
                })
              ]),
              _createElementVNode("li", null, [
                _createVNode(_component_router_link, { to: "/cart" }, {
                  default: _withCtx(() => _cache[12] || (_cache[12] = [
                    _createElementVNode("img", {
                      src: _imports_3,
                      alt: "",
                      class: "twenty"
                    }, null, -1)
                  ])),
                  _: 1
                })
              ]),
              _createElementVNode("li", null, [
                (_ctx.user_logged_in === false)
                  ? (_openBlock(), _createBlock(_component_router_link, {
                      key: 0,
                      class: "userphoto",
                      to: "/my-profile"
                    }, {
                      default: _withCtx(() => _cache[13] || (_cache[13] = [
                        _createElementVNode("img", {
                          style: {"border-radius":"50%","max-width":"24px","max-height":"25px"},
                          src: _imports_4,
                          alt: "",
                          class: "userphoto twenty-four"
                        }, null, -1)
                      ])),
                      _: 1
                    }))
                  : _createCommentVNode("", true)
              ]),
              _createElementVNode("li", null, [
                (_ctx.user_logged_in === true)
                  ? (_openBlock(), _createBlock(_component_router_link, {
                      key: 0,
                      class: "userphoto",
                      to: "/my-profile"
                    }, {
                      default: _withCtx(() => _cache[14] || (_cache[14] = [
                        _createElementVNode("img", {
                          style: {"border-radius":"50%","max-width":"24px","max-height":"24px"},
                          src: _imports_5,
                          alt: "User",
                          class: "userphoto twenty-four"
                        }, null, -1)
                      ])),
                      _: 1
                    }))
                  : _createCommentVNode("", true)
              ])
            ]),
            _createElementVNode("li", {
              onClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.showMenuFunc()))
            }, _cache[15] || (_cache[15] = [
              _createElementVNode("img", {
                src: _imports_6,
                alt: "",
                class: "twenty-four mobile-only"
              }, null, -1)
            ]))
          ])
        ]))
      : _createCommentVNode("", true),
    (_ctx.showMenu === true)
      ? (_openBlock(), _createElementBlock("div", _hoisted_5, [
          _createElementVNode("div", _hoisted_6, [
            _createElementVNode("ul", null, [
              _createElementVNode("li", {
                class: "signin",
                onClick: _cache[2] || (_cache[2] = ($event: any) => (_ctx.showMenuFunc()))
              }, _cache[16] || (_cache[16] = [
                _createElementVNode("div", null, [
                  _createElementVNode("img", { src: _imports_7 })
                ], -1)
              ])),
              _createElementVNode("li", _hoisted_7, [
                _createVNode(_component_router_link, {
                  onClick: _ctx.setMenuFalse,
                  to: "/categories"
                }, {
                  default: _withCtx(() => _cache[17] || (_cache[17] = [
                    _createTextVNode("Categories")
                  ])),
                  _: 1
                }, 8, ["onClick"])
              ]),
              _createElementVNode("li", _hoisted_8, [
                _createVNode(_component_router_link, {
                  onClick: _ctx.setMenuFalse,
                  to: {
                path: '/product',
                query: { mixed: 'popular-items' },
              }
                }, {
                  default: _withCtx(() => _cache[18] || (_cache[18] = [
                    _createTextVNode("Popular")
                  ])),
                  _: 1
                }, 8, ["onClick"])
              ]),
              _createElementVNode("li", _hoisted_9, [
                _createVNode(_component_router_link, {
                  onClick: _ctx.setMenuFalse,
                  to: "/trending"
                }, {
                  default: _withCtx(() => _cache[19] || (_cache[19] = [
                    _createTextVNode("Trending")
                  ])),
                  _: 1
                }, 8, ["onClick"])
              ]),
              _createElementVNode("li", null, [
                _createVNode(_component_router_link, {
                  onClick: _ctx.setMenuFalse,
                  class: "ico",
                  to: "/favourites"
                }, {
                  default: _withCtx(() => _cache[20] || (_cache[20] = [
                    _createElementVNode("img", {
                      src: _imports_2,
                      alt: ""
                    }, null, -1),
                    _createElementVNode("p", null, "Favourite", -1)
                  ])),
                  _: 1
                }, 8, ["onClick"])
              ]),
              _createElementVNode("li", null, [
                _createVNode(_component_router_link, {
                  onClick: _ctx.setMenuFalse,
                  class: "ico",
                  to: "/cart"
                }, {
                  default: _withCtx(() => _cache[21] || (_cache[21] = [
                    _createElementVNode("img", {
                      src: _imports_3,
                      alt: ""
                    }, null, -1),
                    _createElementVNode("p", null, "Cart", -1)
                  ])),
                  _: 1
                }, 8, ["onClick"])
              ]),
              _createElementVNode("li", null, [
                _createVNode(_component_router_link, {
                  onClick: _ctx.setMenuFalse,
                  class: "ico",
                  to: "/my-profile"
                }, {
                  default: _withCtx(() => _cache[22] || (_cache[22] = [
                    _createElementVNode("img", {
                      src: _imports_4,
                      alt: ""
                    }, null, -1),
                    _createElementVNode("p", null, "Profile", -1)
                  ])),
                  _: 1
                }, 8, ["onClick"])
              ])
            ])
          ]),
          (_ctx.Status === 'Sign in')
            ? (_openBlock(), _createElementBlock("div", _hoisted_10, [
                _createVNode(_component_router_link, {
                  class: "signing",
                  to: "/signin"
                }, {
                  default: _withCtx(() => [
                    _createElementVNode("p", null, _toDisplayString(_ctx.Status), 1)
                  ]),
                  _: 1
                })
              ]))
            : _createCommentVNode("", true),
          (_ctx.Status === 'Log out')
            ? (_openBlock(), _createElementBlock("div", {
                key: 1,
                onClick: _cache[3] || (_cache[3] = 
//@ts-ignore
(...args) => (_ctx.logUserOut && _ctx.logUserOut(...args))),
                class: "logout mt-3"
              }, [
                _createElementVNode("p", null, _toDisplayString(_ctx.Status), 1)
              ]))
            : _createCommentVNode("", true)
        ]))
      : _createCommentVNode("", true)
  ], 4))
}