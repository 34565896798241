<template>
  <div
    class="[&>*]:rounded-full [&>*]:p-3 [&>*]:px-6 [&>*]:bg-primary
    [&>*]:min-w-[140px] text-lg flex gap-4 [&>*]:ring [&>*]:ring-transparent">
    <button
      @click="fetchMore(-1)"
      class="flex items-center justify-center relative
      hover:ring-primary/60 disabled:opacity-40 disabled:hover:ring-transparent"
      :disabled="!hasPreviousPage">
      Previous
      <Spinner v-if="loading && lastDirection===-1" class="absolute w-8 text-gray-300" />
    </button>
    <button
      @click="fetchMore(1)"
      class="flex items-center justify-center relative
      hover:ring-primary/60 disabled:opacity-40 disabled:hover:ring-transparent"
      :disabled="!hasNextPage">
      Next
      <Spinnerr v-if="loading && lastDirection===1" class="absolute w-8 text-gray-300" />
    </button>
  </div>
</template>

<script setup lang="ts">
import { PaginatedResponse } from '@/router/types';
import Spinner from './Spinner.vue';
import { computed, ref } from 'vue';

const props = defineProps<{
  data: PaginatedResponse<unknown>;
  loading: boolean;
}>()
const emits = defineEmits(['fetchMore']);

const hasNextPage = computed(() => props.data?.next !== null);
const hasPreviousPage = computed(() => props.data?.previous !== null);

const lastDirection = ref();
const fetchMore = (direction: number) => {
  lastDirection.value = direction;
  if (direction === 1 && hasNextPage.value) {
    const nextPage = props.data?.next;
    // fetch next page
    emits('fetchMore', nextPage);
  } else if (direction === -1 && hasPreviousPage.value) {
    const previousPage = props.data?.previous;
    // fetch previous page
    emits('fetchMore', previousPage);
  }
}
</script>