import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, vModelText as _vModelText, withDirectives as _withDirectives, toDisplayString as _toDisplayString, normalizeStyle as _normalizeStyle, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode } from "vue"
import _imports_0 from '../assets/images/signin.png'


const _hoisted_1 = { id: "register" }
const _hoisted_2 = {
  key: 0,
  id: "details"
}
const _hoisted_3 = { class: "input" }
const _hoisted_4 = { class: "error" }
const _hoisted_5 = { class: "input" }
const _hoisted_6 = { class: "error" }
const _hoisted_7 = { class: "input" }
const _hoisted_8 = ["value", "disabled"]
const _hoisted_9 = { class: "error" }
const _hoisted_10 = {
  key: 1,
  id: "details"
}
const _hoisted_11 = {
  action: "",
  method: "post"
}
const _hoisted_12 = { class: "input" }
const _hoisted_13 = ["value", "disabled"]
const _hoisted_14 = { class: "error" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_AuthHeader = _resolveComponent("AuthHeader")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (_ctx.status === 'setnewpassword')
      ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
          _createVNode(_component_AuthHeader),
          _cache[4] || (_cache[4] = _createElementVNode("h1", null, "New Password", -1)),
          _createElementVNode("form", {
            onSubmit: _cache[2] || (_cache[2] = 
//@ts-ignore
(...args) => (_ctx.setNewPassword && _ctx.setNewPassword(...args))),
            method: "post"
          }, [
            _createElementVNode("div", _hoisted_3, [
              _withDirectives(_createElementVNode("input", {
                "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.password) = $event)),
                type: "password",
                ref: "password",
                placeholder: "Enter your new password",
                id: "form-password",
                required: ""
              }, null, 512), [
                [_vModelText, _ctx.password]
              ]),
              _createElementVNode("span", _hoisted_4, _toDisplayString(_ctx.formatErrorMessage(_ctx.errors.password)), 1)
            ]),
            _createElementVNode("div", _hoisted_5, [
              _withDirectives(_createElementVNode("input", {
                "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.re_password) = $event)),
                type: "password",
                ref: "re-password",
                placeholder: "Re-enter your new password",
                id: "re-password",
                required: ""
              }, null, 512), [
                [_vModelText, _ctx.re_password]
              ]),
              _createElementVNode("span", _hoisted_6, _toDisplayString(_ctx.formatErrorMessage(_ctx.errors.re_password)), 1)
            ]),
            _createElementVNode("div", _hoisted_7, [
              _createElementVNode("input", {
                type: "submit",
                name: "submit",
                value: _ctx.buttonValue,
                id: "form-button",
                disabled: _ctx.buttonDisabled,
                style: _normalizeStyle({ opacity: _ctx.buttonDisabled ? '0.3' : '1' })
              }, null, 12, _hoisted_8),
              _createElementVNode("span", _hoisted_9, _toDisplayString(_ctx.formatErrorMessage(_ctx.errors.detail)), 1)
            ])
          ], 32)
        ]))
      : _createCommentVNode("", true),
    (_ctx.status === 'passwordsaved')
      ? (_openBlock(), _createElementBlock("div", _hoisted_10, [
          _createVNode(_component_AuthHeader),
          _createElementVNode("form", _hoisted_11, [
            _cache[5] || (_cache[5] = _createElementVNode("h3", { class: "subbigtext" }, "Password Saved!", -1)),
            _createElementVNode("div", _hoisted_12, [
              _createElementVNode("input", {
                onClick: _cache[3] || (_cache[3] = ($event: any) => (_ctx.signIn())),
                type: "button",
                name: "submit",
                value: _ctx.buttonValue,
                id: "form-button",
                disabled: _ctx.buttonDisabled,
                style: _normalizeStyle({ opacity: _ctx.buttonDisabled ? '0.3' : '1' })
              }, null, 12, _hoisted_13),
              _createElementVNode("span", _hoisted_14, _toDisplayString(_ctx.formatErrorMessage(_ctx.errors.detail)), 1)
            ])
          ])
        ]))
      : _createCommentVNode("", true),
    _cache[6] || (_cache[6] = _createElementVNode("div", { id: "photo-div" }, [
      _createElementVNode("img", {
        src: _imports_0,
        alt: "A black shirt with diagonal tiles on a lady"
      })
    ], -1))
  ]))
}