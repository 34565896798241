<template>
  <footer class="bg-white">
    <div class="mx-0 w-full max-w-screen-xl py-6 px-4 lg:p-4 lg:py-8">
      <div class="md:flex md:justify-between">
        <div class="mb-6 md:mb-0">
          <div>
            <img
              src="../assets/images/icons/logonew.png"
              alt="Fondstore logo"
              class="w-52" />

            <div class="my-6 gap-4 flex flex-col">
              <h2
                class="text-sm font-bold text-gray-900 uppercase">
                ConTact
              </h2>
              <div class="flex flex-col gap-2" v-if="helpCenterStore.helpCenterData">
                <b>Email: </b>
                <router-link v-for="data in helpCenterStore.helpCenterData" to=""
                  >{{ data.email }}</router-link
                >
              </div>
              <div class="flex flex-col gap-2" v-if="helpCenterStore.helpCenterData">
                <b>Phone: </b>
                <router-link v-for="data in helpCenterStore.helpCenterData" to=""
                  >{{ data.phone_number }}</router-link
                >
              </div>
              <div class="flex flex-col gap-2" v-if="helpCenterStore.helpCenterData">
                <b>Address: </b>
                <router-link v-for="data in helpCenterStore.helpCenterData" to=""
                  >{{ data.address }}</router-link
                >
              </div>
            </div>
          </div>

          <div id="newsletter-container">
            <p>Subscribe to our newsletter</p>
            <form @submit.prevent="subScribe">
              <input
                v-model="email"
                type="email"
                name="Email"
                id="emailfield"
                placeholder="Enter your email address" />
              <button id="emailbutton">{{ subBtn }}</button>
            </form>
          </div>
        </div>
        <div class="grid grid-cols-1 gap-8 w-full lg:w-[60%] md:w-[70%] sm:gap-6 sm:grid-cols-3">
          <div>
            <ul class="text-gray-500 font-medium">
              <li class="mb-4">
                <router-link to="/categories" class="router-link"
                  >Categories</router-link
                >
              </li>
              <li class="mb-4">
                <router-link to="/trending" class="router-link"
                  >Trending</router-link
                >
              </li>
              <li class="mb-4">
                <router-link to="/blog" class="router-link">Blog</router-link>
              </li>
              <li class="mb-4">
                <router-link to="/latest-arrival" class="router-link"
                  >Latest Arriival</router-link
                >
              </li>
              <li class="mb-4">
                <router-link
                  :to="{
                    path: '/product',
                    query: { mixed: 'popular-items' },
                  }"
                  class="router-link"
                  >Popular</router-link
                >
              </li>
            </ul>
          </div>
          <div>
            <h2
              class="mb-6 text-sm font-semibold text-gray-900 uppercase">
              Company
            </h2>
            <ul class="text-gray-500 font-medium">
              <li class="mb-4">
                <router-link to="/policy/privacy-policy"
                  >Privacy Policy</router-link
                >
              </li>
              <li class="mb-4">
                <router-link to="/policy/terms-and-conditions"
                  >Terms and Conditions</router-link
                >
              </li>
              <li class="mb-4">
                <router-link to="/policy/return-and-exchange-policy"
                  >Return and Exchange Policy</router-link
                >
              </li>
            </ul>
          </div>
          <div>
            <h2
              class="mb-6 text-sm font-semibold text-gray-900 uppercase">
              Product
            </h2>
            <ul class="text-gray-500 font-medium">
              <li class="mb-4">
                <router-link to="/help-center" class="router-link"
                  >Help Center</router-link
                >
              </li>
            </ul>
            <div class="flex mt-4 sm:mt-0">
              <div v-if="loadingLinks">
                <div class="h-10 w-full bg-gray-300 animate-pulse"></div>
              </div>
              <div
                v-else
                class="flex gap-2 text-primary h-4 w-4 [&>*]:disabled:cursor-progress">
                <a v-for="item in helpCenterStore.getSocials" :href="item.url">
                  <mdicon :name="item.icon" />
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
      <hr
        class="mt-6 border-gray-200 sm:mx-auto lg:mt-8" />
      <div class="sm:flex sm:items-center sm:justify-between">
       <section class="phone my-0">
        <p>Download our App</p>
        <div>
          <span id="butt">
            <img src="../assets/images/icons/appstore.png" alt="" />
            <!-- <p>Download App</p> -->
            <p>Coming soon</p>
          </span>
          <span id="butt">
            <img src="../assets/images/icons/playstore.png" alt="" />
            <!-- <p>Download App</p> -->
            <p>Coming soon</p>
          </span>
        </div>
      </section>
        
      </div>
    </div>
  </footer>
</template>

<style lang="scss" scoped>
li:hover {
  color: #4385f5;
}

 #newsletter-container {
      display: flex;
      flex-direction: column;
      gap: 1rem;
      width: 100%;
      p {
        font-size: 18px;
        font-weight: 300;
        font-style: normal;
      }
      form {
        display: flex;
        flex-direction: column;
        font-weight: 300;
        gap: 34px;
        #emailfield {
          padding: 10px 20px;
          border-radius: 10px;
          border: 1px solid rgba(122, 125, 156, 0.5);
          background: #fff;
          font-size: 16px;
          font-family: "DM Sans", sans-serif;
          text-align: left;
          width: 15rem;
        }
        #emailbutton {
          padding: 10px 32px;
          font-family: "DM Sans", sans-serif;
          border-radius: 30px;
          background: var(--button-hover, #4385f5);
          // background: var(--button-hover, #3468c0);
          box-shadow: none;
          color: #fff;
          border: none;
          font-size: 16px;
          width: fit-content;
          cursor: pointer;
        }
        #emailbutton:hover {
          background: var(--button-default, #1e67e6);
        }
      }
    }
footer {
  display: flex;
}

.phone {
  // margin-block: 6em;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  img {
    margin-inline: auto;
  }

  div {
    display: flex;
    gap: 1em;
    margin-top: 1em;

    #butt {
      padding-block: 10px;
      padding-inline: 20px;
      display: flex;
      align-items: center;
      column-gap: 14px;
      border: 1px solid #4385f5;
      color: #4385f5;
      cursor: pointer;
      border-radius: 12px;
      width: fit-content;

      img {
        width: 1em;
        height: 1em;
      }

      p {
        margin: 0;
        font-size: 1rem;
      }
    }
  }

  #butt:hover {
    background-color: #4385f5;
    color: white;
  }
}

@media only screen and (max-width: 940px) {
  footer {
    padding-inline: 10px;
    #lower-container {
      // gap: 5rem;
      flex-direction: column;
      #newsletter-container {
        width: 100%;
      }
      #second-container {
        flex-wrap: wrap;
        margin-left: 0;
        // margin-top: 0;
        margin-top: 3rem;

        row-gap: 3rem;
        #company li {
          white-space: nowrap;
        }
      }
    }
  }

  .phone {
    // margin-block: 6em;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    img {
      margin-inline: auto;
    }

    div {
      display: flex;
      gap: 1em;

      #butt {
        padding-block: 10px;
        padding-inline: 10px;
        display: flex;
        align-items: center;
        column-gap: 14px;
        border: 1px solid #4385f5;
        color: #4385f5;
        cursor: pointer;
        border-radius: 12px;
        width: fit-content;

        img {
          width: 1em;
          height: 1em;
        }

        p {
          margin: 0;
          font-size: 0.7rem;
        }
      }
    }

    #butt:hover {
      background-color: #4385f5;
      color: white;
    }
  }
}
</style>

<script lang="ts">
import { useHelpCenterStore } from "@/store/help-center";
import axios from "axios";
import { defineComponent } from "vue";
import { toast } from "vue3-toastify";
import "vue3-toastify/dist/index.css";

export default defineComponent({
  name: "Footer",
  data() {
    return {
      privacy_policy: "",
      tandc: "",
      email: "",
      subBtn: "Subscribe",
      iconStyles: {
        fill: "#4385f5",
      },
    };
  },
  setup() {
    const helpCenterStore = useHelpCenterStore();
    return {
      helpCenterStore,
    };
  },
  methods: {
    subScribe() {
      this.subBtn = "Subscribing...";
      var formdata = new FormData();
      formdata.append("email", this.email);
      const id = toast.loading("subscribing to newsletter...", {
        position: toast.POSITION.TOP_RIGHT,
      });

      axios
        .post("/manager/news_letter/", formdata)
        .then((response) => {
          this.subBtn = "Subscribed";
          toast.update(id, {
            render: "Subscribed to newsletter",
            type: "success",
            isLoading: false,
          });
          console.log("Subscribed!", response);
        })
        .catch((error) => {
          this.subBtn = "Error subscribing";
          toast.update(id, {
            render: "Error subscribing",
            type: "error",
            isLoading: false,
          });
          console.error("Error subscribing:", error);
        })
        .finally(() => {
          setTimeout(() => toast.remove(id), 1000);
          this.email = "";
        });
    },
    async initHelpCenterData() {
      if (!this.helpCenterStore.helpCenterData) {
        try {
          this.helpCenterStore.fetchHelpCenterData();
          this.helpCenterStore.fetchSocials();
        } catch (error) {
          toast.error("Error occured");
        }
      }
    },
  },
  computed: {
    loadingLinks() {
      return this.helpCenterStore.fetching;
    },
  },
  created() {
    this.initHelpCenterData();
  },
});
</script>
