import { resolveComponent as _resolveComponent, createVNode as _createVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createBlock as _createBlock, createElementVNode as _createElementVNode } from "vue"

const _hoisted_1 = { class: "main-product-category-container" }
const _hoisted_2 = { class: "pc-item-component-container" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_MainHeaderVue = _resolveComponent("MainHeaderVue")!
  const _component_FilterComponentVue = _resolveComponent("FilterComponentVue")!
  const _component_ItemComponentVue = _resolveComponent("ItemComponentVue")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_MainHeaderVue, {
      main_selected_header: _ctx.page_choice,
      main_subtext_header: _ctx.body_description,
      main_list_header: _ctx.list_header,
      selectedItem: _ctx.selectedItem,
      onItemSelected: _ctx.setSelectedItem,
      onClick: _ctx.fetchSpecificProducts
    }, null, 8, ["main_selected_header", "main_subtext_header", "main_list_header", "selectedItem", "onItemSelected", "onClick"]),
    _createVNode(_component_FilterComponentVue),
    _createElementVNode("div", _hoisted_2, [
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.products, (product) => {
        return (_openBlock(), _createBlock(_component_ItemComponentVue, {
          key: product.id,
          id: product.id,
          item_name: product.name,
          price: product.price,
          slug: product.slug,
          picture_url: product.image,
          liked_to_favorite: product.is_favorite
        }, null, 8, ["id", "item_name", "price", "slug", "picture_url", "liked_to_favorite"]))
      }), 128))
    ])
  ]))
}