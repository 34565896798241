<script lang="ts">
import DisplayProducts from "@/components/DisplayProducts.vue";
import { toggleLoading } from "@/event-bus";
import { Product } from "@/router/types";
import axios from "axios";
import { defineComponent } from "vue";

export default defineComponent({
  name: "Search",
  components: {
    DisplayProducts,
  },
  data() {
    return {
      state: "no_state",
      //            state: 'recent_popular_state',
      //            state: 'item_found',
      searchQuery: "",
      products: [] as Product[],
      searchQueries: [] as string[],
    };
  },
  methods: {
    firstCharacter() {
      if (this.searchQuery.length >= 1) {
        this.state = "recent_popular_state";
        if (typeof localStorage !== "undefined") {
          const storedQueries = localStorage.getItem("searchQueries");
          if (storedQueries) {
            this.searchQueries = JSON.parse(storedQueries);
          }
        } else {
          console.error("Local storage is not available in this browser.");
        }
      }
    },
    async saveSearchQuery() {
      if (typeof localStorage !== "undefined") {
        this.searchQueries.unshift(this.searchQuery);
        if (this.searchQueries.length > 5) {
          this.searchQueries.pop();
        }
        localStorage.setItem(
          "searchQueries",
          JSON.stringify(this.searchQueries)
        );
      } else {
        console.error("Local storage is not available in this browser.");
      }
      toggleLoading(true);

      try {
        const response = await axios.get("/api/item_search/", {
          params: {
            query: this.searchQuery,
          },
        });
        this.state = "item_found";
        this.products = response.data.results;
        if (this.products.length === 0) {
          this.state = "item_not_found";
        }
        toggleLoading(false);
      } catch (error) {
        toggleLoading(false);
        console.error("Error searching for products:", error);
        alert("Error");
      }
    },
  },
  mounted() {
    document.title = "Search | Fondstore";
  },
});
</script>

<template>
  <div class="search_parent_container mt-24">
    <div class="top-search-frame">
      <label class="search_frame">
        <input
          v-model="searchQuery"
          @input="firstCharacter"
          @keyup.enter="saveSearchQuery"
          type="text"
          placeholder="Search"
          class="search_text" />
      </label>
      <div @click="saveSearchQuery" class="filter-div bg-primary p-2 rounded-md cursor-pointer">
        <svg
          width="25px"
          height="25px"
          viewBox="0 0 24 24"
          fill="none"
          xmlns="http://www.w3.org/2000/svg">
          <g id="SVGRepo_bgCarrier" stroke-width="0"></g>
          <g
            id="SVGRepo_tracerCarrier"
            stroke-linecap="round"
            stroke-linejoin="round"></g>
          <g id="SVGRepo_iconCarrier">
            <g id="Interface / Search_Magnifying_Glass">
              <path
                id="Vector"
                d="M15 15L21 21M10 17C6.13401 17 3 13.866 3 10C3 6.13401 6.13401 3 10 3C13.866 3 17 6.13401 17 10C17 13.866 13.866 17 10 17Z"
                stroke="#fff"
                stroke-width="2"
                stroke-linecap="round"
                stroke-linejoin="round"></path>
            </g>
          </g>
        </svg>
      </div>
    </div>
    <div
      class="recent-popular-searches"
      v-if="state === 'recent_popular_state'">
      <div v-if="searchQueries.length > 0" class="recent-searches">
        <h5>Recent Searches</h5>
        <ul>
          <li
            v-for="(search, index) in searchQueries"
            :key="index"
            @click="saveSearchQuery">
            {{ search }}
          </li>
        </ul>
      </div>
      <!--
                <div class="popular-searches">
                <h5>Popular Searches</h5>
                <ul>
                    <li>Men's shirts</li>
                    <li>Women's shorts</li>
                </ul>
            </div>
            -->
    </div>
    <div class="no-results-found-div" v-if="state === 'item_not_found'">
      <h5>No results for "{{ searchQuery }}"</h5>
      <!--
                <p>See related to {{ searchQuery }}</p>
            <ul>
                <li>Women's blouses</li>
                <li>Casual wear</li>
            </ul>
            -->
    </div>
    <div class="results-found-div" v-if="state === 'item_found'">
      <h5>Search results for "{{ searchQuery }}"</h5>
      <DisplayProducts
        v-for="item in products"
        :key="item.id"
        :product_name="item.name"
        :price="item.price"
        :product_id="item.id"
        :photo="item.image"
        :slug="item.slug"
        :description="item.description" />
    </div>
  </div>
</template>

<style lang="scss" scoped>
.search_parent_container {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 20px;
  padding: 0px 80px;
  margin-bottom: 85px;
  .top-search-frame {
    width: 100%;
    display: flex;
    gap: 10px;
    align-items: center;
    margin-bottom: 70px;
    label {
      position: relative;
    }

    label:before {
      content: "";
      position: absolute;
      left: 30px;
      top: 0;
      bottom: 0;
      width: 20px;
      background: url('data:image/svg+xml,<svg width="21" height="21" viewBox="0 0 21 21" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M17.6541 16.5951L20.5836 19.5231C20.7202 19.6646 20.7958 19.854 20.7941 20.0507C20.7924 20.2473 20.7135 20.4354 20.5745 20.5745C20.4354 20.7135 20.2473 20.7924 20.0507 20.7941C19.854 20.7958 19.6646 20.7202 19.5231 20.5836L16.5936 17.6541C14.6793 19.2943 12.204 20.1292 9.68734 19.9838C7.17065 19.8383 4.80812 18.7237 3.0955 16.8739C1.38287 15.024 0.453292 12.5828 0.501808 10.0624C0.550324 7.54193 1.57317 5.13825 3.35571 3.35571C5.13825 1.57317 7.54193 0.550324 10.0624 0.501808C12.5828 0.453292 15.024 1.38287 16.8739 3.0955C18.7237 4.80812 19.8383 7.17065 19.9838 9.68734C20.1292 12.204 19.2943 14.6793 17.6541 16.5936V16.5951ZM10.2501 18.5001C12.4382 18.5001 14.5366 17.6309 16.0838 16.0838C17.6309 14.5366 18.5001 12.4382 18.5001 10.2501C18.5001 8.06209 17.6309 5.96367 16.0838 4.41649C14.5366 2.86932 12.4382 2.00012 10.2501 2.00012C8.06209 2.00012 5.96367 2.86932 4.41649 4.41649C2.86932 5.96367 2.00012 8.06209 2.00012 10.2501C2.00012 12.4382 2.86932 14.5366 4.41649 16.0838C5.96367 17.6309 8.06209 18.5001 10.2501 18.5001Z" fill="%230F172A" fill-opacity="0.3"/></svg>')
        center / contain no-repeat;
    }
    .search_frame {
      flex-grow: 1;
      width: 100%;
      border: none;

      input {
        width: 100%;
        font-family: DM Sans;
        font-size: 16px;
        font-style: normal;
        font-weight: 300;
        line-height: 22px; /* 137.5% */
        padding: 12px 58px;
        border-radius: 50px;
        background: #f1f1f1;
        border: none;
      }
      input::placeholder {
        color: var(--black-30, rgba(15, 23, 42, 0.3));
      }
    }
  }
  .recent-popular-searches {
    display: flex;
    flex-direction: column;
    gap: 52px;
    .recent-searches {
      display: flex;
      flex-direction: column;
      gap: 23px;
      h5 {
        color: var(--black-100, #0f172a);

        /* Medium */
        font-family: DM Sans;
        font-size: 18px;
        font-style: normal;
        font-weight: 500;
        line-height: normal;
      }
      ul {
        display: flex;
        flex-direction: column;
        gap: 16px;

        li {
          color: var(--black-100, #0f172a);
          padding: 0px 47px;
          font-family: DM Sans;
          font-size: 18px;
          font-style: normal;
          font-weight: 300;
          line-height: normal;
          position: relative;
          cursor: pointer;
        }
        li:before {
          content: "";
          position: absolute;
          left: 10px;
          top: 0px;
          bottom: 0px;
          width: 22px;
          background: url('data:image/svg+xml,<svg width="22" height="22" viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M13 12.9633H15V6.96331H9V8.96331H11.5858L6.25623 14.2929L7.67044 15.7071L13 10.3775L13 12.9633Z" fill="%230F172A" fill-opacity="0.5"/><path fill-rule="evenodd" clip-rule="evenodd" d="M0 11C0 4.92487 4.92487 0 11 0C17.0751 0 22 4.92487 22 11C22 17.0751 17.0751 22 11 22C4.92487 22 0 17.0751 0 11ZM2 11C2 6.02944 6.02944 2 11 2C15.9706 2 20 6.02944 20 11C20 15.9706 15.9706 20 11 20C6.02944 20 2 15.9706 2 11Z" fill="%230F172A" fill-opacity="0.5"/></svg>')
            center / contain no-repeat;
        }
      }
    }
    .popular-searches {
      display: flex;
      flex-direction: column;
      gap: 23px;
      h5 {
        color: var(--black-100, #0f172a);

        /* Medium */
        font-family: DM Sans;
        font-size: 18px;
        font-style: normal;
        font-weight: 500;
        line-height: normal;
      }
      ul {
        display: flex;
        flex-direction: column;
        gap: 16px;

        li {
          color: var(--black-100, #0f172a);
          padding: 0px 47px;
          font-family: DM Sans;
          font-size: 18px;
          font-style: normal;
          font-weight: 300;
          line-height: normal;
          position: relative;
          cursor: pointer;
        }
        li:before {
          content: "";
          position: absolute;
          left: 10px;
          top: 0px;
          bottom: 0px;
          width: 22px;
          background: url('data:image/svg+xml,<svg width="22" height="22" viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M13 12.9633H15V6.96331H9V8.96331H11.5858L6.25623 14.2929L7.67044 15.7071L13 10.3775L13 12.9633Z" fill="%230F172A" fill-opacity="0.5"/><path fill-rule="evenodd" clip-rule="evenodd" d="M0 11C0 4.92487 4.92487 0 11 0C17.0751 0 22 4.92487 22 11C22 17.0751 17.0751 22 11 22C4.92487 22 0 17.0751 0 11ZM2 11C2 6.02944 6.02944 2 11 2C15.9706 2 20 6.02944 20 11C20 15.9706 15.9706 20 11 20C6.02944 20 2 15.9706 2 11Z" fill="%230F172A" fill-opacity="0.5"/></svg>')
            center / contain no-repeat;
        }
      }
    }
  }
  .no-results-found-div {
    display: flex;
    flex-direction: column;
    gap: 13px;
    h5 {
      color: var(--black-100, #0f172a);

      /* Medium */
      font-family: DM Sans;
      font-size: 18px;
      font-style: normal;
      font-weight: 500;
      line-height: normal;
    }
    p {
      color: var(--black-100, #0f172a);

      /* Regular */
      font-family: DM Sans;
      font-size: 16px;
      font-style: normal;
      font-weight: 300;
      line-height: 22px; /* 137.5% */
      margin-bottom: 16px;
    }
    ul {
      display: flex;
      flex-direction: column;
      gap: 16px;

      li {
        color: var(--black-100, #0f172a);
        padding: 0px 47px;
        font-family: DM Sans;
        font-size: 18px;
        font-style: normal;
        font-weight: 300;
        line-height: normal;
        position: relative;
        cursor: pointer;
      }
      li:before {
        content: "";
        position: absolute;
        left: 10px;
        top: 0px;
        bottom: 0px;
        width: 22px;
        background: url('data:image/svg+xml,<svg width="22" height="22" viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M13 12.9633H15V6.96331H9V8.96331H11.5858L6.25623 14.2929L7.67044 15.7071L13 10.3775L13 12.9633Z" fill="%230F172A" fill-opacity="0.5"/><path fill-rule="evenodd" clip-rule="evenodd" d="M0 11C0 4.92487 4.92487 0 11 0C17.0751 0 22 4.92487 22 11C22 17.0751 17.0751 22 11 22C4.92487 22 0 17.0751 0 11ZM2 11C2 6.02944 6.02944 2 11 2C15.9706 2 20 6.02944 20 11C20 15.9706 15.9706 20 11 20C6.02944 20 2 15.9706 2 11Z" fill="%230F172A" fill-opacity="0.5"/></svg>')
          center / contain no-repeat;
      }
    }
  }
  .results-found-div {
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 18px;
    h5 {
      color: var(--black-100, #0f172a);

      /* Medium */
      font-family: DM Sans;
      font-size: 28px;
      font-style: normal;
      font-weight: 500;
      line-height: normal;
    }
  }
}

@media only screen and (max-width: 940px) {
  .search_parent_container {
    padding: 0px 24px;
    .top-search-frame {
      margin-bottom: 30px;
    }
    .results-found-div {
      h5 {
        font-size: 22px;
      }
    }
  }
}
</style>
