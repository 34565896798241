import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, normalizeClass as _normalizeClass } from "vue"

const _hoisted_1 = { class: "main-header" }
const _hoisted_2 = { class: "main-header-topic" }
const _hoisted_3 = { class: "topic-text" }
const _hoisted_4 = { class: "main-subtext" }
const _hoisted_5 = { class: "subtext-text" }
const _hoisted_6 = { class: "main-btn" }
const _hoisted_7 = { class: "main-btn-container" }
const _hoisted_8 = ["onClick"]
const _hoisted_9 = { class: "btn-button-text" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("span", _hoisted_3, _toDisplayString(_ctx.main_selected_header), 1)
    ]),
    _createElementVNode("div", _hoisted_4, [
      _createElementVNode("span", _hoisted_5, _toDisplayString(_ctx.main_subtext_header), 1)
    ]),
    _createElementVNode("div", _hoisted_6, [
      _createElementVNode("div", _hoisted_7, [
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.main_list_header, (button) => {
          return (_openBlock(), _createElementBlock("button", {
            onClick: ($event: any) => (_ctx.selectItem(button)),
            class: _normalizeClass([{ selected: button === _ctx.selectedItem }, "btn-button"]),
            key: button
          }, [
            _createElementVNode("span", _hoisted_9, _toDisplayString(button), 1)
          ], 10, _hoisted_8))
        }), 128))
      ])
    ])
  ]))
}