<template>
    <div id="user-name">
        <h3>
          <span class="first-name">{{ first_name }}</span>
          <span>&nbsp;</span>
          <span class="last-name">{{ last_name }}</span>
        </h3>
    </div>
</template>

<script lang="ts">
import { defineComponent } from "vue";

export default defineComponent ({
  name: 'name',
  props: {
    first_name: String,
    last_name: String,
  }
})
</script>

<style lang="scss" scoped>
    #user-name {
        font-family: 'DM Sans', sans-serif;
        font-style: normal;
        font-weight: 500;
        line-height: normal;
        font-size: 24px;
        color: var(--black-100, rgba(15, 23, 42, 1)); 
    }

    @media only screen and (max-width: 940px) {
      #user-name {
        font-size: 1.125rem;
      }
    }
</style>