import { defineComponent as _defineComponent } from 'vue'
import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createBlock as _createBlock, unref as _unref, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, createVNode as _createVNode, withCtx as _withCtx, toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent } from "vue"

const _hoisted_1 = { class: "home mt-20" }
const _hoisted_2 = {
  key: 0,
  class: "sm:col-span-3 w-full h-full bg-gray-50 flex items-center justify-center text-2xl font-medium text-gray-300"
}
const _hoisted_3 = {
  key: 1,
  class: "sm:col-span-3 h-full w-full flex items-center justify-center flex-col text-2xl gap-3 font-medium text-gray-300"
}
const _hoisted_4 = { class: "categories mt-0" }
const _hoisted_5 = { class: "deals mt-5" }
const _hoisted_6 = {
  key: 0,
  class: "sm:col-span-3 w-full h-full bg-gray-50 flex items-center justify-center text-2xl font-medium text-gray-300"
}
const _hoisted_7 = {
  key: 1,
  class: "sm:col-span-3 h-full w-full flex items-center justify-center flex-col text-2xl gap-3 font-medium text-gray-300"
}
const _hoisted_8 = { class: "trending mt-5" }
const _hoisted_9 = { class: "flex justify-center px-10" }
const _hoisted_10 = { class: "flex flex-col items-center" }
const _hoisted_11 = {
  key: 0,
  class: "sm:col-span-3 w-full h-full bg-gray-50 flex items-center justify-center text-2xl font-medium text-gray-300"
}
const _hoisted_12 = {
  key: 1,
  class: "sm:col-span-3 h-full w-full flex items-center justify-center flex-col text-2xl gap-3 font-medium text-gray-300"
}
const _hoisted_13 = { class: "flex justify-center px-10" }
const _hoisted_14 = { class: "flex flex-col items-center" }
const _hoisted_15 = {
  key: 0,
  class: "sm:col-span-3 w-full h-full bg-gray-50 flex items-center justify-center flex-col text-2xl font-medium text-gray-300 text-center"
}
const _hoisted_16 = {
  key: 1,
  class: "sm:col-span-3 h-full w-full flex items-center justify-center flex-col text-2xl gap-3 font-medium text-gray-300"
}

import { onMounted, computed, ref } from "vue";
import Carousel from "@/components/Carousel.vue";
import CategoriesSection from "@/components/CategoriesSection.vue";
import Footer from "@/components/Footer.vue";
import Header from "@/components/Header.vue";
import BestOf from "@/components/HomeViewComponents/BestOfTheDay.vue";
import ProductGallery from "@/components/HomeViewComponents/ProductsGallery.vue";
import ScrollableSection from "@/components/ScrollableSection.vue";
import ScrollableSectionCard from "@/components/ScrollableSectionCard.vue";
import Spinner from "@/components/Spinner.vue";
import Tabs from "@/components/Tabs.vue";
import { toggleLoading } from "@/event-bus";
import { SectionTabs } from "@/router/types";
import { useHomeStore } from "@/store/home";
import { useProductStore } from "@/store/product";
import { useUserStore } from "@/store/user";

// Store setup

export default /*@__PURE__*/_defineComponent({
  __name: 'HomeView',
  setup(__props) {

const homeStore = useHomeStore();
const userStore = useUserStore();
const productStore = useProductStore();

// Data properties
const products = ref([]); // Product[]
const selectedCategoryProducts = ref([]); // Product[]
const product = ref([]); // Category[]
const photo = ref("@/assets/images/product-photos/Rectangle407item.png");
const selected = ref({});
const selectedSection = ref("");
const currentIndex = ref(0);
const translateValue = ref(0);
const autoScrollInterval = ref(0); // as NodeJS.Timeout | null

// mixed
const activeMixedTab = ref<string | undefined>(undefined);
const mixedTabList = ref<SectionTabs[]>([
  {
    name: "Popular",
    slug: "popular-items",
    requiresAuth: false,
  },
  {
    name: "Favourites",
    slug: "favourites",
    requiresAuth: true,
  },
  {
    name: "New Arrivals",
    slug: "new_arrivals",
    requiresAuth: false,
  },
  {
    name: "Recently viewed",
    slug: "recently_viewed",
    requiresAuth: true,
  },
]);

// Mounted lifecycle hook
onMounted(() => {
  document.title = "Fondstore";
  toggleLoading(false);

  Promise.all([
    productStore.categories.length === 0 && productStore.fetchCategories(),
    homeStore.initTrending(),
    mixedTabList.value.map((tab) =>
      homeStore.fetchMixedItems({
        endpointName: tab.slug.replace("-", "_"),
        requiresAuth: Boolean(tab.requiresAuth),
      })
    ),
    homeStore.fetchBestDeals(),
    productStore.fetchAllItems(),
    productStore.fetchRandomItems(),
  ]).finally(() => toggleLoading(false));
});

// Computed properties
const renderedTrendingItems = computed(() =>
  homeStore.getTrendingItems(
    String(homeStore.activeTrendingTab).toLowerCase() || "clothes"
  ) || []
);

const renderedMixedItems = computed(() =>
  homeStore.getMixedItems(
    String(
      mixedTabList.value.find((tab) => tab.slug === activeMixedTab.value)?.slug
    ).replace("-", "_") || "popular_items"
  ) || []
);

const bestDeals = computed(() => homeStore.getMixedItems("best_deals") || []);

const renderedRandomItems = computed(() => productStore.getRandomItems());

const requestingAllItems = computed(() => productStore.requestingAllItems);

return (_ctx: any,_cache: any) => {
  const _component_router_link = _resolveComponent("router-link")!

  return (_openBlock(), _createElementBlock("main", _hoisted_1, [
    _createVNode(ScrollableSection, { class: "pt-10" }, {
      default: _withCtx(() => [
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(renderedRandomItems.value, (item, i) => {
          return (_openBlock(), _createBlock(ScrollableSectionCard, {
            key: i,
            id: item.id,
            favorite_id: item.favorite_id,
            name: item.name,
            price: item.price,
            new_price: item.new_price,
            image: item.image,
            is_favourite: item.is_favourite,
            category: item.category,
            sub_category: item.sub_category,
            section: item.section,
            slug: item.slug,
            class: "bg-gray-50/90 rounded-3xl overflow-clip"
          }, null, 8, ["id", "favorite_id", "name", "price", "new_price", "image", "is_favourite", "category", "sub_category", "section", "slug"]))
        }), 128)),
        (
          renderedRandomItems.value.length === 0 && !_unref(productStore).requestingAllItems
        )
          ? (_openBlock(), _createElementBlock("div", _hoisted_2, " No item in here yet "))
          : _createCommentVNode("", true),
        (_unref(productStore).requestingAllItems)
          ? (_openBlock(), _createElementBlock("div", _hoisted_3, [
              _cache[2] || (_cache[2] = _createElementVNode("span", null, " Fetching items... ", -1)),
              _createVNode(Spinner, { class: "w-8 text-gray-500" })
            ]))
          : _createCommentVNode("", true)
      ]),
      _: 1
    }),
    _createElementVNode("section", _hoisted_4, [
      _cache[3] || (_cache[3] = _createElementVNode("div", { id: "cats" }, [
        _createElementVNode("h1", null, "Categories"),
        _createElementVNode("p", null, " At FondStore, our diverse collection and categories caters to every style and preference, ensuring that there's something extraordinary waiting for everyone. ")
      ], -1)),
      _createVNode(CategoriesSection, {
        categories: _unref(productStore).categories
      }, null, 8, ["categories"])
    ]),
    _createElementVNode("section", _hoisted_5, [
      _cache[5] || (_cache[5] = _createElementVNode("div", { id: "deals" }, [
        _createElementVNode("h1", null, "Best Deals"),
        _createElementVNode("p", null, " Step into a world where style, accessories and gadgets are celebrated and individuality shines. ")
      ], -1)),
      _createVNode(ScrollableSection, { class: "pb-10" }, {
        default: _withCtx(() => [
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(bestDeals.value, (item, i) => {
            return (_openBlock(), _createBlock(ScrollableSectionCard, {
              key: i,
              id: item.id,
              favorite_id: item.favorite_id,
              name: item.name,
              price: item.price,
              new_price: item.new_price,
              image: item.image,
              is_favourite: item.is_favourite,
              category: item.category,
              sub_category: item.sub_category,
              slug: item.slug,
              section: item.section,
              class: "bg-gray-50/90 rounded-3xl overflow-clip"
            }, null, 8, ["id", "favorite_id", "name", "price", "new_price", "image", "is_favourite", "category", "sub_category", "slug", "section"]))
          }), 128)),
          (bestDeals.value.length === 0 && !_unref(homeStore).requestingMixedItem)
            ? (_openBlock(), _createElementBlock("div", _hoisted_6, " No item in here yet "))
            : _createCommentVNode("", true),
          (_unref(homeStore).requestingMixedItem)
            ? (_openBlock(), _createElementBlock("div", _hoisted_7, [
                _cache[4] || (_cache[4] = _createElementVNode("span", null, " Fetching items... ", -1)),
                _createVNode(Spinner, { class: "w-8 text-gray-500" })
              ]))
            : _createCommentVNode("", true)
        ]),
        _: 1
      })
    ]),
    _createElementVNode("section", _hoisted_8, [
      _cache[7] || (_cache[7] = _createElementVNode("div", { id: "trending" }, [
        _createElementVNode("h1", null, "Trending"),
        _createElementVNode("p", null, " Trending Now, Experience a sustainable shopping in cloths, gadgets and accessories that are tailored to you. ")
      ], -1)),
      _createElementVNode("div", _hoisted_9, [
        _createVNode(Tabs, {
          tab_list: _unref(homeStore).trendingTabList,
          value: _unref(homeStore).activeTrendingTab,
          query_name: "trending",
          onModelValue: _cache[0] || (_cache[0] = ($event) => (_unref(homeStore).activeTrendingTab = $event)),
          class: "inline-flex gap-x-1 sm:gap-x-2 justify-between select-none rounded-[100px] [&>*]:flex-grow [&>*]:max-w-[50%] text-base border border-gray-400 max-w-[90vw] overflow-x-auto hide-scrollbar"
        }, {
          tab: _withCtx(({ tab, index: i, is_active }) => [
            _createElementVNode("div", {
              class: _normalizeClass([[
                is_active
                  ? 'bg-primary text-white'
                  : 'bg-transparent hover:text-primary',
              ], "p-3 transition duration-300 font-medium cursor-pointer rounded-[100px] min-w-max"])
            }, [
              _createElementVNode("span", _hoisted_10, [
                _createElementVNode("span", null, _toDisplayString(tab?.name), 1)
              ])
            ], 2)
          ]),
          _: 1
        }, 8, ["tab_list", "value"])
      ]),
      _createVNode(ScrollableSection, { class: "pb-10" }, {
        default: _withCtx(() => [
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(renderedTrendingItems.value.results, (item, i) => {
            return (_openBlock(), _createBlock(ScrollableSectionCard, {
              key: i,
              id: item.id,
              favorite_id: item.favorite_id,
              name: item.name,
              price: item.price,
              new_price: item.new_price,
              image: item.image,
              is_favourite: item.is_favourite,
              category: item.category,
              sub_category: item.sub_category,
              section: item.section,
              slug: item.slug,
              class: "bg-gray-50/90 rounded-3xl overflow-clip"
            }, null, 8, ["id", "favorite_id", "name", "price", "new_price", "image", "is_favourite", "category", "sub_category", "section", "slug"]))
          }), 128)),
          (
            Array.isArray(renderedTrendingItems.value.results) &&
            renderedTrendingItems.value.results.length === 0 &&
            !_unref(homeStore).requestingTrending
          )
            ? (_openBlock(), _createElementBlock("div", _hoisted_11, " No item in here yet "))
            : _createCommentVNode("", true),
          (_unref(homeStore).requestingTrending)
            ? (_openBlock(), _createElementBlock("div", _hoisted_12, [
                _cache[6] || (_cache[6] = _createElementVNode("span", null, " Fetching items... ", -1)),
                _createVNode(Spinner, { class: "w-8 text-gray-500" })
              ]))
            : _createCommentVNode("", true)
        ]),
        _: 1
      }),
      _createElementVNode("div", _hoisted_13, [
        _createVNode(Tabs, {
          tab_list: mixedTabList.value,
          value: activeMixedTab.value,
          query_name: "mixed",
          onModelValue: _cache[1] || (_cache[1] = ($event) => (activeMixedTab.value = $event)),
          class: "inline-flex gap-x-1 sm:gap-x-2 justify-between select-none rounded-[100px] [&>*]:flex-grow [&>*]:max-w-[50%] text-base border border-gray-400 max-w-[90vw] overflow-x-auto hide-scrollbar"
        }, {
          tab: _withCtx(({ tab, index: i, is_active }) => [
            _createElementVNode("div", {
              class: _normalizeClass([[
                is_active
                  ? 'bg-primary text-white'
                  : 'bg-transparent hover:text-primary',
                (tab?.name === 'Favourites' ? 'mx-4' : 'mx-5'),
              ], "p-3 transition duration-300font-medium cursor-pointer rounded-[100px] min-w-max"])
            }, [
              _createElementVNode("span", _hoisted_14, [
                _createElementVNode("span", null, _toDisplayString(tab?.name), 1)
              ])
            ], 2)
          ]),
          _: 1
        }, 8, ["tab_list", "value"])
      ]),
      _cache[8] || (_cache[8] = _createElementVNode("p", { id: "opt2" }, " Discover the uncompromised quality at fondstore that are affordable with elegance to express yourself. ", -1))
    ]),
    _createVNode(ScrollableSection, { class: "pb-10" }, {
      default: _withCtx(() => [
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(renderedMixedItems.value, (item, i) => {
          return (_openBlock(), _createBlock(ScrollableSectionCard, {
            key: i,
            id: activeMixedTab.value === 'new_arrivals' ? item.item.id : activeMixedTab.value === 'recently_viewed' ? item.results.id : item.id,
            favorite_id: activeMixedTab.value === 'new_arrivals' ? item.item.favorite_id : activeMixedTab.value === 'recently_viewed' ? item.results.favourite_id : item.favorite_id,
            name: activeMixedTab.value === 'new_arrivals' ? item.item.name  : activeMixedTab.value === 'recently_viewed' ? item.results.name : item.name,
            price: activeMixedTab.value === 'new_arrivals' ? item.item.price  : activeMixedTab.value === 'recently_viewed' ? item.results.price : item.price,
            new_price: activeMixedTab.value === 'new_arrivals' ? item.item.new_price  : activeMixedTab.value === 'recently_viewed' ? item.results.new_price : item.new_price,
            image: activeMixedTab.value === 'new_arrivals' ? item.item.image  : activeMixedTab.value === 'recently_viewed' ? item.results.image : item.image,
            is_favourite: activeMixedTab.value === 'new_arrivals' ? item.item.is_favourite  : activeMixedTab.value === 'recently_viewed' ? item.results.is_favourite : item.is_favourite,
            slug: activeMixedTab.value === 'new_arrivals' ? item.item.slug  : activeMixedTab.value === 'recently_viewed' ? item.results.slug : item.slug,
            class: "bg-gray-50/90 rounded-3xl overflow-clip"
          }, null, 8, ["id", "favorite_id", "name", "price", "new_price", "image", "is_favourite", "slug"]))
        }), 128)),
        (renderedMixedItems.value.length === 0 && !_unref(homeStore).requestingMixedItem)
          ? (_openBlock(), _createElementBlock("div", _hoisted_15, [
              _createElementVNode("span", null, _toDisplayString(_unref(userStore).getUser.token
              ? "No item in here yet"
              : `Sign in to see your ${String(activeMixedTab.value).replace(
                  /-/g,
                  " "
                )} items`), 1),
              (!_unref(userStore).getUser.token)
                ? (_openBlock(), _createBlock(_component_router_link, {
                    key: 0,
                    to: "/signin",
                    class: "text-gray-500"
                  }, {
                    default: _withCtx(() => _cache[9] || (_cache[9] = [
                      _createTextVNode("Sign in")
                    ])),
                    _: 1
                  }))
                : _createCommentVNode("", true)
            ]))
          : _createCommentVNode("", true),
        (_unref(homeStore).requestingMixedItem)
          ? (_openBlock(), _createElementBlock("div", _hoisted_16, [
              _cache[10] || (_cache[10] = _createElementVNode("span", null, " Fetching items... ", -1)),
              _createVNode(Spinner, { class: "w-8 text-gray-500" })
            ]))
          : _createCommentVNode("", true)
      ]),
      _: 1
    })
  ]))
}
}

})