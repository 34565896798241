<script lang="ts">
import { defineComponent, PropType } from 'vue'

export default defineComponent({
    name: 'MainHeader',
    props: {
        main_selected_header: {
            type: String,
            required: true
        },
        main_subtext_header:{
            type: String,
            required: true
        },
        main_list_header: {
            type: Array as PropType<String[]>,
            required: true
        },
        selectedItem : String,
    },
    methods: {
        selectItem(item: String) {
            this.$emit('item-selected', item);
        },
    },
})
</script>

<template>
    <div class="main-header">
        <div class="main-header-topic">
            <span class="topic-text">{{ main_selected_header }}</span>
        </div>
        <div class="main-subtext">
            <span class="subtext-text">{{ main_subtext_header }}</span>
        </div>
        <div class="main-btn">
            <div class="main-btn-container">
                <button @click="selectItem(button)" :class="{ selected: button === selectedItem }" class="btn-button" v-for="button in main_list_header" :key="button">
                    <span class="btn-button-text">{{ button }}</span>
                </button>
            </div>
        </div>
    </div>
</template>

<style lang="scss" scoped>
.main-header {
    font-family: 'DM Sans', sans-serif;
    font-style: normal;
    margin-top: 1rem;
    margin-bottom: 2.5rem;
    margin-left: 70px;
    margin-right: 100px;
    height: auto;
    .main-header-topic {
        color: var(--black-100, #0F172A);
        display: flex;
        font-weight: 500;
        font-size: 48px;
        width: 100%;
        height: auto;
        justify-content: center;
        .topic-text {
            line-height: 62.5px;
            text-align: center;
        }
    }
    .main-subtext {
        display: flex;
        width: 100%;
        height: auto;
        justify-content: center;
        font-size: 18px;
        font-weight: 300;
        .subtext-text {
            width: 580px;
            text-align: center;
            line-height: 23.44px;
        }
    }
    .main-btn {
        display: flex;
        width: 100%;
        height: auto;
        justify-content: center;
        margin-top: 2.5rem;
        margin-bottom: 0.5rem;
        .main-btn-container {
            display: flex;
            border: 1px solid var(--black-30, rgba(15, 23, 42, 0.30)); 
            border-radius: 40px;
            width: auto;
            height: auto;
            justify-content: space-between;
            .selected {
                background: #4385F5;
                color: #FFFFFF;
            }
            button {
                border-radius: 40px;
                display: flex;
                font-family: 'DM Sans', sans-serif;
                margin-right: 1.5rem;
                height: 45px;
                padding: 12px 16px 12px 16px;
                background: transparent;
                color: var(--black-70, rgba(15, 23, 42, 1)); 
                font-size: 16px;
                line-height: 20.83px;
                justify-content: center;
                align-items: center;
                span {
                    font-weight: 300;
                }
            }
            button:nth-last-child(1) {
                margin-right: 0;
            }
        }
    }
}

@media only screen and (max-width: 940px) {
    .main-header {
        padding: 24px;
        margin: 0px;
        display: flex;
        flex-direction: column;
        align-items: center;
        gap: 11px;
        .main-header-topic {
            color: var(--black-100, #0F172A);
            text-align: center;
            font-family: DM Sans;
            font-size: 24px;
            font-style: normal;
            font-weight: 500;
            line-height: normal;
            width: fit-content;
            .topic-text {
                line-height: inherit;
            }
        }
        .main-subtext {
            color: var(--black-70, rgba(15, 23, 42, 0.70));
            text-align: center;
            font-family: DM Sans;
            font-size: 16px;
            font-style: normal;
            font-weight: 300;
            line-height: 22px; /* 137.5% */
            .subtext-text {
                width: fit-content;
            }
        }
        .main-btn {
            margin: 0px;
            .main-btn-container {
                overflow-x: scroll;
            }
            .main-btn-container::-webkit-scrollbar {
                height: 0;
                background: transparent;
            }
        }
    }
}
</style>