<script lang="ts">
import HelpCentreFAQ from "@/components/MenuSidebar/HelpCentre.vue";
import { Product } from "@/router/types";
import axios from "axios";
import { defineComponent } from "vue";

export default defineComponent({
  // name: 'Notifications',
  components: {
    HelpCentreFAQ,
  },
  data() {
    return {
      Notify: [] as Product[],
    };
  },
  methods: {
    getNotification() {
      axios
        .get("https://fondstoremain-api.onrender.com/manager/faqs/")
        .then((response) => {
          this.Notify = response.data;
        })
        .catch((error) => {
          console.error("Error retrieving FAQs:", error);
        });
    },
  },
  mounted() {
    this.getNotification();
  },
});
</script>

<template>
  <div class="group-div">
    <h2>Notifications</h2>
    <div class="queries">
      <HelpCentreFAQ
        v-for="line in Notify"
        :key="line.question"
        :main_text="line.question"
        :text_expanded="line.answer"
      />
    </div>
  </div>
</template>

<style lang="scss" scoped>
.group-div {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 81px;
  h2 {
    color: var(--black-100, #0f172a);
    font-family: DM Sans;
    font-size: 32px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
  }
  .queries {
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 50px;
  }
}

@media only screen and (max-width: 940px) {
  .group-div {
    gap: 51.5px;
    h2 {
      font-size: 16px;
    }
  }
}
</style>
