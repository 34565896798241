<script setup lang="ts">
import axios from 'axios';
import { onMounted, ref } from 'vue';
interface BlogPost {
  id: number;
  title: string;
  content: string;
  linked_items: any[]
  created_at: string;
}

const blogPosts = ref<BlogPost[]>([]);

const fetchPosts = async () => {
    const res = await axios.get("/blog/blogposts/");
    if (res.status === 200) {
        blogPosts.value = res.data;
    }
}

function getTimeDifference(timestamp) {
    const currentDate = new Date();
    const postDate = new Date(timestamp);

    const timeDifference = (currentDate as any) - (postDate as any);
    const daysDifference = Math.floor(timeDifference / (1000 * 60 * 60 * 24));

    if (daysDifference === 0) {
        return 'Today';
    } else if (daysDifference === 1) {
        return 'Yesterday';
    } else if (daysDifference < 7) {
        return `${daysDifference} days ago`;
    } else {
        return postDate.toLocaleDateString('en-US', { year: 'numeric', month: 'long', day: 'numeric' });
    }
}

onMounted(fetchPosts)
</script>

<template>
    <section class="bg-white">
        <div class="px-4 mx-auto max-w-screen-xl lg:px-6">
            <div class="mx-auto max-w-screen-sm text-center lg:mb-16 mb-8">
                <h2 class="mb-4 text-3xl lg:text-4xl tracking-tight font-extrabold text-gray-900">Our Blog</h2>
                <p class="font-light text-gray-500 sm:text-xl">Read our blog posts which are carefully crafted to aid your decision making when it comes to shopping.</p>
            </div>
            <div class="grid gap-8 lg:grid-cols-2">
                <article v-for="post in blogPosts" :key="post.id" class="p-6 bg-white rounded-lg border border-gray-200 hover:shadow-md cursor-pointer">
                     <router-link class="absolute inset-0" :to="`/blog/${post.id}`"></router-link>
                    <div class="flex justify-between items-center mb-5 text-gray-500">
                        <span class="bg-primary-100 text-primary-800 text-xs font-medium inline-flex items-center px-2.5 py-0.5 rounded">Article</span>
                        <span class="text-sm">{{ getTimeDifference(post.created_at) }}</span>
                    </div>
                    <h2 class="mb-2 text-2xl font-bold tracking-tight text-gray-900">{{post.title}}</h2>
                    <p class="mb-5 font-light max-h-20 text-ellipsis overflow-clip text-gray-500">{{post.content}}</p>
                    <div class="flex justify-between items-center">
                        <div class="flex items-center space-x-4"></div>
                        <a href="#" class="inline-flex items-center font-medium text-primary-600 hover:underline">
                            Read more
                            <svg class="ml-2 w-4 h-4" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" d="M10.293 3.293a1 1 0 011.414 0l6 6a1 1 0 010 1.414l-6 6a1 1 0 01-1.414-1.414L14.586 11H3a1 1 0 110-2h11.586l-4.293-4.293a1 1 0 010-1.414z" clip-rule="evenodd"></path></svg>
                        </a>
                    </div>
                </article>
            </div>
        </div>
</section>
</template>
