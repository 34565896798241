import { defineComponent as _defineComponent } from 'vue'
import { createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createBlock as _createBlock, unref as _unref, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createCommentVNode as _createCommentVNode, createVNode as _createVNode } from "vue"

const _hoisted_1 = { class: "mt-[2rem]" }
const _hoisted_2 = { class: "container" }
const _hoisted_3 = { id: "image" }
const _hoisted_4 = {
  key: 0,
  class: "h-96 bg-gray-50 flex items-center justify-center flex-col text-2xl font-medium text-gray-300"
}
const _hoisted_5 = {
  key: 1,
  class: "h-96 flex items-center justify-center flex-col text-2xl gap-3 font-medium text-gray-300"
}

import ItemCard from '@/components/ItemCard.vue';
import { useHomeStore } from '@/store/home';
import { useUserStore } from '@/store/user';
import { computed, onMounted, ref } from 'vue';


export default /*@__PURE__*/_defineComponent({
  __name: 'LatestArrival',
  setup(__props) {

const userStore = useUserStore();
const homeStore = useHomeStore();

onMounted(() => {
  homeStore.fetchMixedItems({
      endpointName: "new_arrivals",
      requiresAuth: false,
    })
  })
const renderedMixedItems = computed(()=>{
  return homeStore.getMixedItems("new_arrivals") || []
})

return (_ctx: any,_cache: any) => {
  const _component_router_link = _resolveComponent("router-link")!
  const _component_Spinner = _resolveComponent("Spinner")!

  return (_openBlock(), _createElementBlock("section", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _cache[0] || (_cache[0] = _createElementVNode("div", { class: "flex items-center justify-center my-4" }, [
        _createElementVNode("h1", { class: "font-extrabold text-2xl md:text-4xl" }, "New Arrivals")
      ], -1)),
      _createElementVNode("div", _hoisted_3, [
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(renderedMixedItems.value, ({item}) => {
          return (_openBlock(), _createBlock(ItemCard, {
            key: item.id,
            id: item.id,
            favorite_id: item.favorite_id,
            name: item.name,
            price: item.price,
            new_price: item.new_price,
            image: item.image,
            slug: item.slug,
            is_favourite: item.is_favourite
          }, null, 8, ["id", "favorite_id", "name", "price", "new_price", "image", "slug", "is_favourite"]))
        }), 128))
      ])
    ]),
    (renderedMixedItems.value.length === 0 && !_unref(homeStore).requestingMixedItem)
      ? (_openBlock(), _createElementBlock("div", _hoisted_4, [
          _cache[2] || (_cache[2] = _createElementVNode("span", null, _toDisplayString('No item in here yet'), -1)),
          (!_unref(userStore).getUser.token)
            ? (_openBlock(), _createBlock(_component_router_link, {
                key: 0,
                to: "/signin",
                class: "text-gray-500"
              }, {
                default: _withCtx(() => _cache[1] || (_cache[1] = [
                  _createTextVNode("Sign in")
                ])),
                _: 1
              }))
            : _createCommentVNode("", true)
        ]))
      : _createCommentVNode("", true),
    (_unref(homeStore).requestingMixedItem)
      ? (_openBlock(), _createElementBlock("div", _hoisted_5, [
          _cache[3] || (_cache[3] = _createElementVNode("span", null, " Fetching products... ", -1)),
          _createVNode(_component_Spinner, { class: "w-8 text-gray-500" })
        ]))
      : _createCommentVNode("", true)
  ]))
}
}

})