<template>
   <article class="p-6 text-base bg-white border-t border-gray-200">
        <footer class="flex justify-between items-center mb-2">
            <div class="flex items-center">
                <p class="inline-flex items-center mr-3 text-sm text-gray-900 font-semibold">{{ username ? username : generateRandomName() }}</p>
                <p class="text-sm text-gray-600"><time pubdate datetime="2022-06-23"
                        title="June 23rd, 2022">{{ created_at }}</time></p>
            </div>
        </footer>
        <p class="text-gray-500" v-html="nl2Br(description)"></p>
        <div class="flex items-center mt-4 space-x-4">
            <StarRating :value="rating" :disabled="true" />
        </div>
    </article>
</template>

<script setup>
import { defineProps, ref } from 'vue';
import StarRating from './StarRating.vue';

const props = defineProps( [ 'created_at', 'description', 'rating', 'username' ] );
function getTimeDifference(timestamp) {
    const currentDate = new Date();
    const postDate = new Date(timestamp);

    const timeDifference = (currentDate) - (postDate);
    const daysDifference = Math.floor(timeDifference / (1000 * 60 * 60 * 24));

    if (daysDifference === 0) {
        return 'Today';
    } else if (daysDifference === 1) {
        return 'Yesterday';
    } else if (daysDifference < 7) {
        return `${daysDifference} days ago`;
    } else {
        return postDate.toLocaleDateString('en-US', { year: 'numeric', month: 'long', day: 'numeric' });
    }
}

const  nl2Br = (text) => text.replace(/\n/g, "<br />");

const created_at = ref( getTimeDifference(props.created_at) );
const description = ref( props.description ?? "" );
const rating = ref( props.rating );
const username = ref( props.username );

const adjectives = ['Clever', 'Funny', 'Witty', 'Sneaky', 'Kind', 'Brave', 'Gentle', 'Silly', 'Lively', 'Charming'];
const nouns = ['Penguin', 'Sunflower', 'Moonlight', 'Rainbow', 'Whisper', 'Meadow', 'Starfish', 'Breeze', 'Harmony', 'Cupcake'];

function generateRandomName() {
  const randomAdjective = adjectives[Math.floor(Math.random() * adjectives.length)];
  const randomNoun = nouns[Math.floor(Math.random() * nouns.length)];
  return `${randomAdjective} ${randomNoun}`;
}
</script>