<template>
  <div>
    <div
      ref="tab"
      v-for="(tab, i) in tab_list"
      :key="i"
      @click="handleTabClicks(tab, true)"
      class="text-center sm:text-left"
    >
      <slot
        name="tab"
        :tab="tab"
        :index="i"
        :is_active="active_tab === tab.slug"
      ></slot>
    </div>
  </div>
</template>

<script setup>
import {
  defineProps,
  defineEmits,
  ref,
  computed,
  watch,
  onMounted,
} from "vue";
import { useRouter } from "vue-router";

const props = defineProps({
  tab_list: {
    type: Array,
    required: true,
  },
  value: {
    type: String,
    required: true,
  },
  auto_add_query: {
    type: Boolean,
    default: true,
  },
  query_name: {
    type: String,
    default: "tab",
  },
});

const emits = defineEmits(["model-value", "tab-click"]);
const router = useRouter();
const active_tab = ref(props.value);
let firstQuerySet = true;

const serialize_query = (query) => query.replace(/ /g, "_");

const handleTabClicks = (tab) => {
  if (!tab || !tab.slug) return;

  active_tab.value = tab.slug;

  if (props.auto_add_query) {
    const method = firstQuerySet ? "replace" : "push";
    router[method]({
      query: {
        ...router.currentRoute.value.query,
        [props.query_name]: serialize_query(tab.slug),
      },
    });

    firstQuerySet = false;
  }
  emits("model-value", tab.slug);
  emits("tab-click", tab.slug);
};

const setActiveTabFromQuery = () => {
  const { [props.query_name]: tab_q } = router.currentRoute.value.query;
  if (tab_q && props.tab_list.length) {
    const matchingTab = props.tab_list.find(
      (tab) => serialize_query(tab.slug) === tab_q
    );
    if (matchingTab) {
      active_tab.value = matchingTab.slug;
      emits("model-value", matchingTab.slug);
      emits("tab-click", matchingTab.slug);
      return matchingTab; // Return the matching tab
    }
  }
  return null;
};

// Check and set active tab on mount
onMounted(() => {
  const matchingTab = setActiveTabFromQuery();
  if (!matchingTab && !active_tab.value && props.tab_list.length) {
    // No query match or active tab, fallback to the first tab
    handleTabClicks(props.tab_list[0]);
  }
});

// Watch for query changes (back button, navigation, etc.)
watch(
  () => router.currentRoute.value.query,
  (newVal, oldVal) => {
    const queryTab = newVal[props.query_name];
    if (queryTab && queryTab !== oldVal[props.query_name]) {
      setActiveTabFromQuery(); // Handle query change
    }
  }
);

// Watch for changes in tab list, in case tabs are dynamically loaded
watch(
  () => props.tab_list,
  (newVal, oldVal) => {
    const isEqual = JSON.stringify(newVal) === JSON.stringify(oldVal);
    if (newVal.length && !isEqual) {
      const matchingTab = setActiveTabFromQuery();
      if (!matchingTab) {
        // If no tab matched query, set the first tab as active
        handleTabClicks(newVal[0]);
      }
    }
  }
);

// Watch for changes in the value prop (model value)
watch(
  () => props.value,
  (newVal) => {
    if (newVal && newVal !== active_tab.value) {
      active_tab.value = newVal;
    }
  }
);
</script>

