<script lang="ts">
import { defineComponent } from 'vue'
//import {PaystackButton} from 'paystack';
import paystack from 'paystack';
import axios from 'axios'
import { useProductStore } from '@/store/product';
import { useUserStore } from '@/store/user';

export default defineComponent({
    name: 'Shippingmode',
    data() {
        return {
            delivery_time: '20',
            store_pickup_price: 'FREE',
            home_delivery_price: '2.45',
            store_pickup_check: false,
            home_delivery_check: true,
            user_mail: '',
        }
    },
    props: {
        subtotal_price: String,
        shipping_type: String,
        total_price: String,
        store_pickup_check: Boolean,
        home_delivery_check: Boolean,
    },
    setup() {
        const productStore = useProductStore();
        const userStore = useUserStore()
        return {
            productStore,
            userStore,
        };
    },
    methods: {
        async getUserDetails() {
            const token = localStorage.getItem('AuthToken');

            try {
            const responseEmail = await axios.get('/auth/users/me', {
                headers: {
                    Authorization: `JWT ${token}`,
                },
            });     
            this.user_mail = responseEmail.data.email;
            console.log('User mail gotten:', this.user_mail)
            } catch (error) {
                console.error('There was an error getting the user email for Paystack:', error)
            }
        },
        initializePayment() {
//        if (this.total_price !== undefined && this.total_price !== null) {
//            
//        }
//        const amount = +this.total_price! * 100;
//
//        const paymentOptions = {
//            key: 'pk_test_3a1319eab46a31b488c3e5261e0700e2fd19d670', //my account public key
//            email: `${this.user_mail}`,
//            amount: amount, // Amount in kobo
//            currency: 'NGN', // Change to your currency
//            ref: 'unique-transaction-reference', // Generate a unique reference
//            callback: (response: any) => {
//            // Handle payment success
//            this.$router.push('/orders')
//            console.log(response);
//            },
//            onClose: () => {
//            // Handle payment close or failure
//            console.log('Payment closed.');
//            },
//        };
//
//        const paystack = PaystackButton(paymentOptions);
//        paystack.openIframe();
        },
        async makePayment() {
//            const paymentObject: any = {
//                key: 'pk_test_3a1319eab46a31b488c3e5261e0700e2fd19d670', //my account public key
//                email: this.user_mail,
//                amount: +this.total_price! * 100,
//                currency: 'NGN',
//                ref: 'unique-transaction-reference',
//            };
//            function payWithPaystack(paymentObject: any) {
//            return new Promise((resolve, reject) => {
//                paystack.inlinePay(paymentObject, (response: any) => {
//                if (response.status === 'successful') {
//                    resolve(response);
//                } else {
//                    reject(new Error('Payment failed'));
//                }
//                });
//            });
//            }
//                paystack.inlinePay(paymentObject, (response: any) => {
//                if (response.status === 'successful') {
//                    console.log(response);
//                } else {
//                    console.error('There is an error somewhere...')
//                }
//                });

//            try {
//                const response = await paystack.payWithPaystack(paymentObject);
//                console.log(response);
//            } catch (error) {
//                console.error('There is a payment error:', error)
//            }
        },
        toCheckout() {
            if (this.userStore.getAuthHeader) {
                this.$router.push('/checkout')
            } else {
                this.$router.push("/signin")
            }
        }
    },
    created() {
        this.getUserDetails();
//        paystack('pk_test_3a1319eab46a31b488c3e5261e0700e2fd19d670');
    },
})
</script>

<template>
    <div class="main-shippingmode-container">
        <div class="shippingmode-header">
            <span class="header-text">Shipping Mode</span>
        </div>
        <div class="shipping-mode-select-container">
            <div class="shipping-mode-select-header">
                <span class="select-header-text">SHIPPING METHOD</span>
            </div>
            <div class="checkbox-select-container">
                <!--
                    <div class="store-pickup-container">
                    <div class="checkbox-button-container">
                        <button class="button-container" v-if="store_pickup_check == true">
                            <box-icon type='solid' color="#4385F5" name='circle'></box-icon>
                        </button>
                        <button class="button-container" v-else>
                            <box-icon name='circle' ></box-icon>
                        </button>
                    </div>
                    <span :class="{ 'bold': store_pickup_check == true, 'light': store_pickup_check != true }" class="store-pickup-text font-class">Store Pickup (in {{ delivery_time }} min)</span>
                    <span :class="{ 'bold': store_pickup_check == true, 'light': store_pickup_check != true }" class="store-pickup-price-text font-class">{{ store_pickup_price }}</span>
                </div>
                -->
                <div class="home-delivery-container">
                    <div class="checkbox-button-container">
                        <button class="button-container" v-if="home_delivery_check == true">
                            <box-icon type='solid' color="#4385F5" name='circle'></box-icon>
                        </button>
                        <button class="button-container" v-else>
                        </button>
                    </div>
                    <span :class="{ 'bold': home_delivery_check == true, 'light': home_delivery_check != true }" class="home-delivery-text font-class">Home delivery</span>
                    <!--
                        <span  class="home-delivery-price font-class">${{ home_delivery_price }}</span>
                    -->
                </div>
            </div>
        </div>
        <div class="main-subtotal-shipping-total-container">
            <div class="main-subtotal-container">
                <span class="tag-text font-class">SUBTOTAL</span>
                <span class="price-text font-class">₦{{ subtotal_price }}</span>
            </div>
            <div class="main-shipping-mode-container">
                <span class="tag-text font-class">SHIPPING</span>
                <span class="price-text font-class">₦{{ shipping_type }}</span>
            </div>
            <div class="main-total-container">
                <span class="tag-text font-class">TOTAL</span>
                <span class="price-text font-class">₦{{ total_price }}</span>
            </div>
        </div>
        <button @click="toCheckout" class="checkout-button font-class">Checkout</button>
    </div>
</template>

<style lang="scss" scoped>
.bold {
    font-weight: 500;
}
.light {
    font-weight: 300;
}
.font-class {
    font-family: 'DM Sans', sans-serif;
    font-style: normal;
}
.main-shippingmode-container {
    display: grid;
    height: auto;
    padding: 2.13rem 1.69rem 1.87rem;
    border-radius: 1.5rem;
    border: 1px solid var(--black-30, rgba(15, 23, 42, 0.30));
    .shippingmode-header {
        display: flex;
        .header-text {
            font-weight: 500;
            font-size: 18px;
            line-height: 26.04px;
        }
    }
    .shipping-mode-select-container {
        display: grid;
        margin-top: 1.06rem;
        gap: 2.81rem;
        .shipping-mode-select-header {
            display: flex;
            .select-header-text {
                font-weight: 300;
                font-size: 16px;
                line-height: 20.83px;
            }
        }
        .checkbox-select-container {
            display: grid;
            margin-top: 1.5rem;
            div {
                display: flex;
                margin-bottom: 0.3rem;
                .checkbox-button-container {
                    display: flex;
                    width: 24px;
                    height: 24px;
                    justify-content: center;
                    align-items: center;
                    margin-right: 0.5rem;
                    border: 1.5px solid;
                    border-radius: 50%;
                    button {
                        display: flex;
                        background: transparent;
                        width: 20px;
                        height: 20px;
                        justify-content: center;
                        align-items: center;
                    }
                }
                span {
                    font-size: 16px;
                    line-height: 20.83px;
                    margin-right: 0.5rem;
                }
            }
        }
    }
    .main-subtotal-shipping-total-container {
        display: grid;
        margin-top: 4rem;
        margin-bottom: 3.37rem;
        div {
            display: flex;
            justify-content: space-between;
            align-items: center;
            padding-top: 0.7rem;
            padding-bottom: 0.7rem;
            margin-left: 6px;
            margin-right: 6px;
            .tag-text {
                color: var(--black-100, #0F172A);

/* Regular */
font-family: DM Sans;
font-size: 1rem;
font-style: normal;
font-weight: 300;
line-height: 1.375rem; /* 137.5% */
            }
            .price-text {
                color: var(--black-100, #0F172A);

/* Medium */
font-family: DM Sans;
font-size: 1.125rem;
font-style: normal;
font-weight: 500;
line-height: normal;
            }
        }
    }
    .checkout-button {
        display: flex;
        border: 1px solid;
        background: #4385F5;
        justify-content: center;
        color: #FFFFFF;
        align-items: center;
        height: 48px;
        border-radius: 30px;
        color: var(--White, #FFF);
font-family: DM Sans;
font-size: 1.125rem;
font-style: normal;
font-weight: 500;
line-height: normal;
    }
}

@media only screen and (max-width: 940px) {
.main-shippingmode-container {
    border-radius: 1.5rem;
    border: 1px solid var(--black-30, rgba(15, 23, 42, 0.30));
    .shippingmode-header {
        span {
            color: var(--black-100, #0F172A);

/* Medium */
font-family: DM Sans;
font-size: 1rem;
font-style: normal;
font-weight: 500;
line-height: normal;
        }
    }
    .shipping-mode-select-container {
        .shipping-mode-select-header {
            .select-header-text {
                color: var(--black-100, #0F172A);

/* Regular */
font-family: DM Sans;
font-size: 1rem;
font-style: normal;
font-weight: 300;
line-height: 1.375rem; /* 137.5% */
            }
        }
    }
}
}
</style>