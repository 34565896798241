<script lang="ts">
import { defineComponent } from 'vue'
import 'boxicons'

export default defineComponent({
    name: 'FilterComponent',
    data() {
        return {
            expand_filter: false,
        }
    },
    methods: {
        expandFilter() {
            if (this.expand_filter == false) {
                this.expand_filter = true
            } else {
                this.expand_filter = false
            }
        }
    }
})
</script>

<template>
    <div class="main-filter-container">
        <div class="filter-text-button-container">
            <div class="filter-text-container">
                <button :onclick="expandFilter" class="filter-text-button">
                    <span class="filter-button-text font-class">Filters</span>
                    <box-icon color="#494949" name='filter'></box-icon>
                </button>
                <div class="filter-overlay" v-if="expand_filter == true">
                    <div class="header-container">
                        <div class="filter-header-text">
                            <span class="font-class">Filters</span>
                        </div>
                        <button :onclick="expandFilter" class="header-container-close">
                            <box-icon class="box-icon" type='solid' color="#4385F5" name='x-circle'></box-icon>
                        </button>
                    </div>
                    <button class="filter-size filter-choice">
                        <span class="font-class">Size</span>
                        <div class="boxicon">
                            <box-icon name='chevron-down'></box-icon>
                        </div>
                    </button>
                    <button class="filter-style filter-choice">
                        <span class="font-class">Style</span>
                        <div class="boxicon">
                            <box-icon name='chevron-down'></box-icon>
                        </div>
                    </button>
                    <button class="clr-btn">
                        <span class="clr-fltr-btn-text font-class">Clear all filters</span>
                    </button>
                </div>
            </div>
            <div class="filter-button-container">
                <button class="filter-size filter-button">
                    <span class="font-class">Size</span>
                    <box-icon name='chevron-down'></box-icon>
                </button>
                <button class="filter-size filter-button">
                    <span class="font-class">Style</span>
                    <box-icon name='chevron-down'></box-icon>
                </button>
            </div>
        </div>
        <div class="filter-clr-btn-container">
            <button class="clr-fltr-btn">
                <span class="clr-fltr-btn-text font-class">Clear all filters</span>
            </button>
        </div>
    </div>
</template>

<style lang="scss" scoped>
//@import url('https://fonts.googleapis.com/css2?family=DM+Sans:opsz,wght@9..40,300;9..40,400;9..40,500;9..40,600;9..40,700;9..40,800;9..40,900&display=swap');

.font-class {
    font-family: 'DM Sans', sans-serif;
    font-style: normal;
}

.main-filter-container {
    display: flex;
    margin-left: 80px;
    margin-right: 100px;
    margin-top: 1rem;
    margin-bottom: 2.5rem;
    justify-content: space-between;
    align-items: center;
    .filter-text-button-container {
        display: flex;
        width: 300px;
        justify-content: space-between;
        align-items: center;
        .filter-text-container {
            display: flex;
            width: 100px;
            button {
                display: flex;
                outline: 0;
                border: 0;
                background: transparent;
                height: 48px;
                font-size: 16px;
                font-weight: 500;
                line-height: 20.83px;
                text-align: center;
                margin-left: 0.5rem;
                align-items: center;
                span {
                    margin-right: 0.5rem;
                }
            }
            .filter-overlay {
                display: none;
            }
        }
        .filter-button-container {
            display: flex;
            width: 200px;
            justify-content: space-between;
            button {
                display: flex;
                outline: 0;
                border: 1px solid #0F172A;
                height: 45px;
                align-items: center;
                background: transparent;
                border-radius: 40px;
                padding: 12px 16px 12px 16px;
                span {
                    margin-right: 0.2rem;
                    margin-left: 0.2rem;
                }
            }
        }
    }
    .filter-clr-btn-container {
        display: flex;
        align-items: center;
        justify-content: center;
        button {
            display: flex;
            background: transparent;
            outline: 0;
            border: 0;
            height: 45px;
            padding: 12px 16px 12px 16px;
            align-items: center;
            justify-content: center;
            span {
                font-weight: 500;
                font-size: 16px;
                line-height: 20.83px;
                border-bottom: 1px solid #0F172A;
            }
        }
    }
}

@media only screen and (max-width: 940px) {
    .main-filter-container {
        margin-left: 24px;
        margin-right: 24px;
        .filter-text-button-container {
            width: auto;
            .filter-text-container {

                .filter-text-button {
                    border: 1px solid #0F172A80;
                    margin: 0;
                    padding: 10px 20px 10px 20px;
                    border-radius: 5px;
                    span {
                        color: #0F172AB2;
                        font-size: 16px;
                        font-weight: 500;
                        line-height: 20.83px;
                    }
                }
                .filter-overlay {
                    display: block;
                    position: absolute;
                    padding: 30px 24px 10px 24px;
                    top: 0rem;
                    background: #FFFFFF;
                    width: 100%;
                    height: 70%;
                    left: 0;
                    .header-container {
                        display: flex;
                        align-items: center;
                        justify-content: space-between;
                        padding-bottom: 10px;
                        margin-bottom: 1.5rem;
                        border: 0;
                        border-bottom: 1px solid #DCD7D7;
                        .filter-header-text {
                            justify-content: left;
                            align-items: center;
                            span {
                                font-size: 28px;
                                font-weight: 500;
                            }
                        }
                        .header-container-close {
                            display: flex;
                            width: 40px;
                            height: 40px;
                            align-items: center;
                            justify-content: center;
                            .box-icon {
                                height: 40px;
                                width: 40px;
                            }
                        }
                    }
                    .filter-choice {
                        width: 100%;
                        margin: 0;
                        padding-right: 8px;
                        padding-bottom: 7.5px;
                        border-bottom: 1px solid #DCD7D74D;
                        display: flex;
                        margin-bottom: 1rem;
                        justify-content: space-between;
                        span {
                            font-size: 16px;
                            font-weight: 300;
                            line-height: 22px;
                        }
                        .boxicon {
                            width: 24px;
                            height: 24px;
                            border: 1px solid;
                            display: flex;
                            justify-content: center;
                            align-items: center;
                            border-radius: 50%;
                        }
                    }
                    .clr-btn {
                        border: 1px solid #0F172AB2;
                        padding: 10px 20px 10px 20px;
                        display: flex;
                        justify-content: center;
                        align-items: center;
                        margin: 0;
                        border-radius: 5px;
                        span {
                            color: #0F172A;
                            font-size: 16px;
                            font-weight: 500;
                            line-height: 20.83px;
                        }
                    }
                }
            }
            .filter-button-container {
                display: none;
            }
        }
        .filter-clr-btn-container {
            display: none;
        }
    }
}
</style>