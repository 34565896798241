<script lang="ts">
import Spinner from "@/components/Spinner.vue";
import { isLoading, toggleLoading } from "@/event-bus";
import { useProductStore } from "@/store/product";
import { useUserStore } from "@/store/user";
import { defineComponent } from "vue";
import { toast } from "vue3-toastify";
import "vue3-toastify/dist/index.css";
import SecondHeaderVue from "../components/SecondHeader.vue";
import StarRating from "../components/StarRating.vue"
import ReviewComponent from "../components/ReviewComponent.vue"

export default defineComponent({
  name: "Product Page",
  components: {
    SecondHeaderVue,
    Spinner,
    StarRating,
    ReviewComponent,
  },
  setup() {
    const productStore = useProductStore();
    const userStore = useUserStore();
    return {
      productStore,
      userStore,
    };
  },
  data() {
    return {
      product: {
        id: "",
        name: "",
        price: "",
        new_price: null,
        average_rating: 0,
        is_favourite: false,
        has_bought: false,
        order_number: null,
        size_number: null,
        description: "",
        image: "",
        additional_images: [""],
        favorite_id: null,
        colour: "",
        size: [{id: "", name: "", quantity: 0}],
        ratings: [{created_at: "", description: "", rating: 0, username: ""}]
      },
      sub_pictures_list: ["1", "2", "3", "4", "5"],
      //Note that the following should be in a dictionary when calling from the backend.
      product_name: "Bed",
      price: "30.00",
      is_favorite: false,
      rating_number: 4.5,
      order_number: 1,
      size_number: 35,
      buy_btn: "Buy Now",
      cart_btn: "Add to Cart",
      addingToCart: false,
      picture: "",
      logged_in: true,
      productSlugFromRoute: this.$route.params?.slug as string,
      favoriting: false,
      isLoading,
      activePictureIndex: 0,
      renderedBigImage: "",
      justFavouritedId: null as null | string,
      imagePreviewIsOpen: false,
      sizeSelected: undefined as number | undefined,
      ratings_value: 0,
      review_comments: [{created_at: "", description: "", rating: 0, username: ""}],
    };
  },
  methods: {
    share() {
      const urlToShare = window.location.href;

      if (navigator.share) {
        navigator
          .share({
            title: document.title,
            url: urlToShare,
          })
          .then(() => console.log("Successfully shared"))
          .catch((error) => console.error("Error sharing:", error));
      } else {
        // Fallback for browsers that do not support the Web Share API
        alert(`Share this URL: ${urlToShare}`);
      }
    },

    async addToCart() {
      this.addingToCart = true;
      if (this.sizeSelected && this.product.size[this.sizeSelected-1]?.quantity <= 0) {
          toast.error("Unable to add to cart... This is because the item is out of stock.", {
          autoClose: 2000,
        });
        return;
      }
      try {
        await this.productStore.addItemToCart({
          item_id: this.product.id,
          quantity: this.order_number,
          size: this.sizeSelected && this.product.size[this.sizeSelected-1]?.quantity > 0 ? this.product.size[this.sizeSelected-1].id : "",
        });
        toast.success("Added to cart", {
          autoClose: 1000,
        });
      } catch (error) {
        console.log(error);
      } finally {
        this.addingToCart = false;
      }
    },

    async handleFavourite() {
      this.favoriting = true;
      try {
        const res = await this.productStore.favoriteItem({
          item_id: this.product.id!,
          favorite_id: this.justFavouritedId || this.product.favorite_id!,
          isFavorite: this.is_favorite,
        });
        this.is_favorite = !this.is_favorite;
        this.justFavouritedId = res?.favorite_id;
      } catch (error) {
        console.log(error);
      } finally {
        this.favoriting = false;
      }
    },

    incrementNumber() {
      if (this.order_number < 1) return;
      this.order_number++;
    },

    decrementNumber() {
      if (this.order_number <= 1) return;
      this.order_number--;
    },

    changeSelectedSize(size: number) {
      this.sizeSelected = size
    },

    checkUserSignedIn() {
      const token = localStorage.getItem("AuthToken");

      if (token) {
      } else {
        this.logged_in = false;
      }
    },

    setActiveBigImage(index: number) {
      this.activePictureIndex = index;
      this.renderedBigImage = this.subPictures[index];
    },

    seek(direction: "prev" | "next") {
      const currentIndex = this.activePictureIndex;
      const lastIndex = this.subPictures.length - 1;
      if (direction === "prev") {
        if (currentIndex === 0) {
          this.setActiveBigImage(lastIndex);
        } else {
          this.setActiveBigImage(currentIndex - 1);
        }
      } else {
        if (currentIndex === lastIndex) {
          this.setActiveBigImage(0);
        } else {
          this.setActiveBigImage(currentIndex + 1);
        }
      }
    },

    autoPlay() {
      setInterval(() => {
        this.seek("next");
      }, 5000);
    },

    closePreview() {
      this.imagePreviewIsOpen = false;
    },

    openPreview() {
      this.imagePreviewIsOpen = true;
    },
  },
  async mounted() {
    document.title = "Product | Fondstore";
    toggleLoading(true);

    console.log(this.productSlugFromRoute);

    this.product = await this.productStore.fetchItemDetails({
      id: this.productSlugFromRoute,
    });
    toggleLoading(false);
    this.renderedBigImage = this.subPictures[0] || this.product?.image;
    this.is_favorite = this.product?.is_favourite;
    this.autoPlay();
    this.productStore.fetchCartItems();
    this.ratings_value = this.product?.average_rating;
    this.review_comments = this.product?.ratings;
    this.sizeSelected = this.product.size[0].name.split('-')[0] === "No Size" ? 1 : undefined;
  },
  created() {
    this.checkUserSignedIn();

    // if product id is not available, redirect to home page
    if (!this.productSlugFromRoute) this.$router.push("/");
  },
  computed: {
    subPictures() {
      return (
        this.product?.additional_images ||
        (!!this.product?.image && [this.product.image]) ||
        []
      );
    },
    nl2Br() {
      return (text: string) => text?.replace(/\n/g, "<br />");
    },
  },
});
</script>

<template>
  <div class="main-product-page-container mt-20">
    <SecondHeaderVue
      main_header="Product Page"
      product_gender="Men"
      bold_link="productpage"
    />

    <div class="main-product-picture-details-container">
      <div class="mp-main-picture-container">
        <div class="mp-main-picture bg-gray-50">
          <img loading="lazy"
            @click="openPreview"
            :class="{ '!w-full !h-full': !isLoading }"
            class="h-0 w-0 object-cover object-top cursor-pointer"
            :src="renderedBigImage"
          />
          <button @click="seek('prev')" class="arrow-container left">
            <box-icon name="chevron-left" color="#FFFFFF"></box-icon>
          </button>
          <button @click="seek('next')" class="arrow-container right">
            <box-icon name="chevron-right" color="#FFFFFF"></box-icon>
          </button>
        </div>

        <div class="mp-sub-pictures-container sm:ml-4">
          <div
            v-for="(image, i) in subPictures"
            class="mp-sub-pictures relative"
            :key="image"
          >
            <img :src="image" loading="lazy" />
            <button
              @click="setActiveBigImage(i)"
              :class="{ '!bg-black/40': i === activePictureIndex }"
              class="absolute inset-0 bg-transparent"
            ></button>
          </div>
        </div>
      </div>
      <div class="mp-main-details-container">
        <div class="mp-main-details-header">
          <span class="details-header-text font-class">{{ product?.name }}</span>
        </div>
        <div class="mp-main-details-price-liked-container">
          <div class="mp-price-container flex flex-col gap-2 !items-start">
            <span
              :class="{'line-through text-red-500 !text-base !text-start' : !!product?.new_price }"
              class="price-text font-class">
              ₦{{ product?.price }}
            </span>
            <span
              v-if="!!product?.new_price"
              class="price-text font-class !leading-none">
              ₦{{ product?.new_price }}
            </span>
          </div>
          <button
            v-if="logged_in === true"
            @click="handleFavourite"
            :disabled="favoriting"
            class="flex items-center justify-center h-9 w-9 disabled:cursor-not-allowed"
          >
            <svg
              v-if="!favoriting"
              viewBox="0 0 26 24"
              :fill="is_favorite ? 'currentColor' : 'none'"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M13.723 22.2789C13.3263 22.4189 12.673 22.4189 12.2763 22.2789C8.89301 21.1239 1.33301 16.3055 1.33301 8.13885C1.33301 4.53385 4.23801 1.61719 7.81967
                          1.61719C9.94301 1.61719 11.8213 2.64385 12.9997 4.23052C14.178 2.64385 16.068 1.61719 18.1797 1.61719C21.7613 1.61719 24.6663
                          4.53385 24.6663 8.13885C24.6663 16.3055 17.1063 21.1239 13.723 22.2789Z"
                stroke="currentColor"
                stroke-width="1"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
            </svg>
            <Spinner v-else />
          </button>
        </div>
        <div class="mp-main-rating-number-size-container">
          <div class="mp-rating-container flex items-center justify-start gap-4">
            <div v-if="product?.average_rating" class="w-fit">
              <StarRating :value="ratings_value ? Math.ceil(ratings_value) : 0" :disabled="!product?.has_bought" :itemId="product.id" />
          </div>
            <div v-else class="w-fit">
              <StarRating :value="0" :disabled="!product?.has_bought" />
          </div>

            <!-- share icon -->
            <button @click="share">
              <img loading="lazy"
                src="../assets/images/icons/share.svg"
                alt=""
                class="w-5 mt-0.5 lg:mt-0"
              />
            </button>
          </div>

          <div class="mp-order-number-container">
            <button
              @click="decrementNumber"
              class="number-container font-class"
            >
              -
            </button>
            <div class="order-number-container">
              <span class="order-number-text font-class">{{
                order_number
              }}</span>
            </div>
            <button
              @click="incrementNumber"
              class="number-container font-class"
            >
              +
            </button>
          </div>
          <!--
                        <div class="mp-size-container">
                        <span class="size-number-text font-class">{{ size_number }} L</span>
                        <button class="size-number-button">
                            <box-icon name='chevron-down' color="#0F172A"></box-icon>
                        </button>
                    </div>
                    -->
        </div>
        <div class="mp-description-container">
          <div class="description-text-button-container">
            <span class="description-text font-class">Description</span>
          </div>
          <div class="description-subtext">
            <span
              class="description-subtext-text font-class"
              v-html="nl2Br(product?.description)"
            ></span>
          </div>
        </div>
        <!-- sizes -->
       <div v-show="product?.size?.length > 0">
          <p v-if="product?.size.some((sz) => sz.name.split('-')[0] !== 'No Size')" class="font-semibold">Sizes</p>
          <div class="mt-3 flex gap-x-2">
          <span
          v-for="(sz, i) in product?.size"
          @click="sz?.quantity <= 0 ? console.log('first') : changeSelectedSize(i+1)"
          :class="{
            'border p-3 lg:p-2 cursor-pointer border-gray-400': true,
            'border-blue-500 hover:bg-blue-200 bg-blue-100': sizeSelected === (i+1) && sz?.quantity,
            'border-gray-400 hover:bg-gray-100': sizeSelected !== (i+1),
            'cursor-not-allowed': sz?.quantity,
          }">
            <p class="text-2xl">{{ sz.name.split('-')[0] === "No Size" ? "" : sz.name.split('-')[0] }}</p>
            <p class="italic text-xs">{{ sz?.quantity }} units left</p>
          </span>
  </div>
</div>


        <!-- color -->
        <div v-show="product?.colour?.length">
          <p class="mt-2 font-semibold">Colors</p>

          <span
            v-for="colour in product?.colour"
            class="w-5 h-5 cursor-pointer rounded-sm"
            :style="`background:${colour.toLowerCase()}`"
          >
            {{ colour }}
          </span>
        </div>
        <div class="button-container flex gap-10 w-auto sm:!justify-start">

          <button
             class="px-6 p-3 rounded-3xl text-gray-800 relative flex items-center bg-gray-300 border border-gray-400 justify-center"
             :class="{
               'cursor-not-allowed bg-gray-300 border border-gray-400': sizeSelected && product.size[sizeSelected - 1]?.quantity <= 0,
               'bg-primary text-white': (sizeSelected && product.size[sizeSelected - 1]?.quantity > 0) || (product.size[0].name.split('-')[0] === 'No Size' && sizeSelected && product.size[0]?.quantity > 0)
             }"
             :disabled="!sizeSelected || product.size[sizeSelected - 1]?.quantity <= 0"
             @click="addToCart">
             Add to Cart
             <span v-if="addingToCart" class="absolute text-black">
               <Spinner class="w-7" />
             </span>
          </button>



        </div>
      </div>
    </div>

    <div
      @click="closePreview"
      :class="{ hidden: !imagePreviewIsOpen }"
      class="fixed inset-0 bg-black/10 backdrop-blur-sm z-10 overflow-y-auto flex items-center justify-center overscroll-x-contain"
    >
      <img loading="lazy"
        v-for="image in subPictures"
        @click="(e) => e.stopPropagation()"
        :src="image"
        :class="{ hidden: !(renderedBigImage === image) }"
        class="object-cover"
      />
    </div>
  </div>

  <div class="flex ml-0 pl-2 md:ml-12 md:pl-6 flex-col gap-4 w-4/6">
      <div>
        <h3 class="text-2xl lg:text-3xl tracking-tight font-extrabold text-gray-900">Reviews and Comments ({{ review_comments.length }})</h3>
      </div>
    <ReviewComponent v-if="review_comments.length > 0" v-for="review in review_comments" :key="review.description" :created_at="review.created_at" :description="review.description" :username="review.username" :rating="review.rating"></ReviewComponent>
    <div v-else>
       <div class="h-96 px-4 flex items-center justify-center flex-col text-2xl font-medium text-gray-300">
          <span>
            No commennts or reviews on this item yet... come back later.
          </span>
        </div>
    </div>
  </div>
</template>

<style lang="scss">
.font-class {
  font-family: "DM Sans", sans-serif;
  font-style: normal;
}
.main-product-page-container {
  .main-product-picture-details-container {
    display: flex;
    margin-left: 80px;
    margin-right: 80px;
    margin-top: 0.5rem;
    margin-bottom: 2.5rem;
    // height: 392px;
    height: 550px;
    //align-items: center;
    justify-content: space-between;
    .mp-main-picture-container {
      display: flex;
      width: 45%;
      //   height: 390px;
      height: 548px;
      border-radius: 8px;
      justify-content: space-between;
      .mp-main-picture {
        position: relative;
        width: 100%;
        height: 548px;
        // height: 390px;
        border-radius: 8px;
        img {
          width: 100%;
          height: 100%;
          max-height: 100%;
          max-width: 100%;
          border-radius: 8px;
          object-fit: cover;
        }
        .arrow-container {
          display: flex;
          position: absolute;
          background: transparent;
          width: 26px;
          height: 26px;
          border: 2px solid #ffffff;
          border-radius: 50%;
          top: 45%;
        }
        .left {
          left: 10px;
        }
        .right {
          right: 10px;
        }
      }
      .mp-sub-pictures-container {
        display: grid;
        width: 25%;
        height: 390px;
        grid-row-gap: 7px;
        .mp-sub-pictures {
          width: 72px;
          height: 72px;
          border-radius: 4px;
          img {
            height: 100%;
            width: 100%;
            max-height: 100%;
            max-width: 100%;
            border-radius: 4px;
            //box-shadow: 1px 1px 19px -10px rgba(0,0,0,0.42);
          }
        }
      }
    }
    .mp-main-details-container {
      display: grid;
      width: 50%;
      height: 350px;
      grid-row-gap: 10px;
      .mp-main-details-header {
        display: flex;
        justify-content: left;
        align-items: center;
        height: 40px;
        .details-header-text {
          font-size: 32px;
          font-weight: 300;
          line-height: 41.66px;
        }
      }
      .mp-main-details-price-liked-container {
        display: flex;
        height: 46px;
        justify-content: space-between;
        align-items: center;
        .mp-price-container {
          display: flex;
          align-items: center;
          .price-text {
            font-size: 38px;
            font-weight: 700;
            line-height: 62.5px;
          }
        }
        .mp-liked-container {
          display: flex;
          width: 36px;
          height: 36px;
          justify-content: center;
          align-items: center;
          margin-right: 0.5rem;
          svg {
            width: 36px;
            height: 36px;
          }
        }
      }
      .mp-main-rating-number-size-container {
        display: flex;
        margin-top: 1.5rem;
        align-items: center;
        justify-content: space-between;
        .mp-rating-container {
          display: flex;
          align-items: center;
          .rating-star-container {
            height: 30px;
            width: 30px;
            margin-right: 0.5rem;
          }
          .rating-number-container {
            display: flex;
            .rating-number-text {
              font-weight: 300;
              font-size: 20px;
              line-height: 26.04px;
            }
          }
        }
        .mp-order-number-container {
          display: flex;
          width: 102px;
          height: 42px;
          justify-content: space-between;
          align-items: center;
          .number-container {
            display: flex;
            width: 30.6px;
            font-weight: 500;
            font-size: 18px;
            align-items: center;
            justify-content: center;
            background: transparent;
            outline: 0;
          }
          .order-number-container {
            display: flex;
            width: 30.6px;
            justify-content: center;
            align-items: center;
            .order-number-text {
              font-weight: 500;
              font-size: 24px;
              line-height: 41.66px;
            }
          }
        }
        .mp-size-container {
          display: flex;
          align-items: center;
          width: 65px;
          justify-content: space-between;
          margin-right: 0.5rem;
          span {
            font-size: 16px;
            font-weight: 500;
            line-height: 31.25px;
          }
          .size-number-button {
            display: flex;
            border: 1.5px solid #0f172a;
            width: 24px;
            height: 24px;
            border-radius: 50%;
            background: transparent;
            justify-content: center;
            align-items: center;
          }
        }
      }
      .mp-description-container {
        display: grid;
        border: 0;
        .description-text-button-container {
          border: 0;
          display: flex;
          justify-content: space-between;
          align-items: center;
          padding-bottom: 0.3rem;
          border-bottom: 1px solid rgba(15, 23, 42, 0.1);
          .description-text {
            font-weight: 500;
            font-size: 18px;
            line-height: 23.44px;
          }
          .description-button {
            display: flex;
            margin-right: 0.5rem;
            height: 24px;
            width: 24px;
            background: transparent;
            border-radius: 50%;
            border: 1.5px solid #0f172a;
            align-items: center;
            justify-content: center;
          }
        }
      }
      .button-container {
        display: flex;
        height: 60px;
        margin-top: 1.5rem;
        width: 40%;
        justify-content: space-between;
        align-items: center;
        .add-to-cart-button {
          width: auto;
          height: 48px;
          padding: 6px 12px 6px 12px;
          font-size: 16px;
          font-weight: 500;
          line-height: 20.83px;
          background: #4385f5;
          border-radius: 40px;
          color: #ffffff;
        }
        .buy-now-button {
          color: #4385f5;
          font-size: 16px;
          font-weight: 300;
          line-height: 20.83px;
          background: transparent;
        }
      }
    }
  }
}
@media only screen and (max-width: 940px) {
  .main-product-page-container {
    height: auto;
    display: grid;
    justify-items: center;
    .main-product-picture-details-container {
      display: grid;
      height: auto;
      row-gap: 1rem;
      align-content: start;
      justify-items: center;
      width: 90%;
      margin-left: 30px;
      margin-right: 30px;
      padding: 0;
      .mp-main-picture-container {
        display: grid;
        width: 100%;
        justify-items: center;
        justify-content: center;
        padding: 0;
        height: fit-content;
        border-radius: 0;
        .mp-main-picture {
          width: 110%;
          padding: 0;
          border-radius: 0;
          img {
            width: 100%;
            border-radius: 0;
          }
          .arrow-container {
            display: none;
          }
        }
        .mp-sub-pictures-container {
          margin: 0;
          display: flex;
          width: 110%;
          padding: 0;
          overflow: scroll;
          height: 74px;
          align-items: center;
          justify-content: space-between;
        }
      }
      .mp-main-details-container {
        width: 100%;
        padding: 0;
        height: fit-content;
        .mp-main-details-header {
          width: 100%;
          height: auto;
          .details-header-text {
            font-size: 24px;
            font-weight: 300;
            line-height: 27px;
          }
        }
        .mp-main-details-price-liked-container {
          //position: relative;
          padding: 0;
          .mp-price-container {
            height: 45px;
            .price-text {
              font-size: 24px;
            }
          }
          .mp-liked-container {
            //position: absolute;
            right: 0;
            top: -1.5rem;
          }
        }
        .mp-main-rating-number-size-container {
          width: 100%;
          justify-content: space-between;
          .mp-rating-container {
            display: flex;
            align-content: center;
            .rating-star-container {
              display: flex;
              align-items: center;
              justify-content: center;
              width: 20px;
              height: 20px;
            }
            .rating-number-container {
              .rating-number-text {
                font-size: 16px;
              }
            }
          }
          .mp-order-number-container {
            .order-number-container {
              .order-number-text {
                font-size: 20px;
              }
            }
          }
        }
        .mp-description-container {
          display: grid;
          width: auto;
          margin: 0;
          justify-items: center;
          align-items: start;
          row-gap: 0.5rem;
          .description-text-button-container {
            display: flex;
            width: 100%;
            justify-content: space-between;
            margin-right: 0;
          }
          .description-subtext {
            width: 100%;
            height: auto;
          }
        }
        .button-container {
          display: grid;
          row-gap: 1rem;
          width: 100%;
          //margin-bottom: 70px;
          height: auto;
          justify-content: center;
          justify-items: center;
          .add-to-cart-button {
            width: 340px;
          }
        }
      }
    }
  }
}
</style>
