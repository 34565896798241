<script lang="ts">
import HelpCenter from "@/components/MenuSidebar/HelpCenterComponent.vue";
import MyProfile from "@/components/MenuSidebar/MyProfileComponent.vue";
import Notifications from "@/components/MenuSidebar/Notifications.vue";
import Orders from "@/components/MenuSidebar/OrdersPage.vue";
import Settings from "@/components/MenuSidebar/SettingsComponent.vue";
import { toggleLoading } from "@/event-bus";
import { defineComponent } from "vue";
import { useRoute } from "vue-router";

export default defineComponent({
  name: "ActualComponent",
  components: {
    Settings,
    HelpCenter,
    Orders,
    MyProfile,
    Notifications,
  },
  data() {
    return {
      menu_choice: "HelpCenter",
    };
  },
  methods: {
    getMenuChoice() {
      toggleLoading(true);
      const route = useRoute();
      const routerChoice = route.name;

      if (routerChoice === "MyProfile") {
        this.menu_choice = "Profile";
        toggleLoading(false);
      } else if (routerChoice === "Orders") {
        this.menu_choice = "Orders";
        toggleLoading(false);
      } else if (routerChoice === "HelpCenter") {
        this.menu_choice = "HelpCenter";
        toggleLoading(false);
      } else if (routerChoice === "Settings") {
        this.menu_choice = "Settings";
        toggleLoading(false);
      } else if (routerChoice === "OrdersOngoing") {
        this.menu_choice = "Ongoing";
        toggleLoading(false);
      } else if (routerChoice === "OrderHistory") {
        this.menu_choice = "History";
        toggleLoading(false);
      } else if (routerChoice === "OrderDrafts") {
        this.menu_choice = "Drafts";
        toggleLoading(false);
      } else if (routerChoice === "Notifications") {
        this.menu_choice = "Notifications";
        toggleLoading(false);
      }
    },
  },
  watch: {
    $route(to: { name: string }, from: any) {
      if (to.name === "MyProfile") {
        this.menu_choice = "Profile";
      } else if (to.name === "Orders") {
        this.menu_choice = "Orders";
      } else if (to.name === "HelpCenter") {
        this.menu_choice = "HelpCenter";
      } else if (to.name === "Settings") {
        this.menu_choice = "Settings";
      } else if (to.name === "OrdersOngoing") {
        this.menu_choice = "Ongoing";
      } else if (to.name === "OrderHistory") {
        this.menu_choice = "History";
      } else if (to.name === "OrderDrafts") {
        this.menu_choice = "Drafts";
      } else if (to.name === "Notifications") {
        this.menu_choice = "Notifications";
      }
    },
  },
  created() {
    this.getMenuChoice();
  },
});
</script>

<template class="">
  <div>
    <div v-if="menu_choice === 'Profile'">
      <MyProfile id="profile" />
    </div>
    <div v-if="menu_choice === 'HelpCenter'">
      <HelpCenter id="help" />
    </div>
    <div
      v-if="
        menu_choice === 'Orders' ||
        menu_choice === 'Ongoing' ||
        menu_choice === 'History' ||
        menu_choice === 'Drafts'
      "
    >
      <Orders id="orders" />
    </div>
    <div v-if="menu_choice === 'Settings'">
      <Settings id="settings" />
    </div>
    <div v-if="menu_choice === 'Notifications'">
      <Notifications id="notifications" />
    </div>
  </div>
</template>
