<template>
    <div id="register">
        <div v-if="status === 'linksent'" id="details">
            <AuthHeader />
            <form action="" method="post">
                <h3 class="subbigtext">You have been verified!</h3>
                <p>Proceed to login</p>
                    <div class="input">
                    <input @click="signIn()" type="button" name="submit" :value="buttonValue" id="form-button">
                </div>
            </form>
        </div >
        
        <div id="photo-div">
            <img src="../assets/images/signup1.png" alt="A black shirt with diagonal tiles on a lady" >
        </div>
    </div>
</template>

<style lang="scss" scoped>
#register {
    padding: 51px 80px 44px;
    background: var(--white, #FFF);
    font-family: "DM Sans", sans-serif;
    font-style: normal;
    line-height: normal;
    width: 100vw;
    height: 150vh;
    display: flex;
    justify-content: center;
    gap: 18px;
    overflow-y: auto;
    #details {
        height: 100%;
        width: 50%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        h1 {
            font-size: 48px;
            color: var(--black-100, #0F172A);
            font-weight: 500;
            margin-bottom: 7px;
            margin-top: 2rem;
        }
        p {
            color: var(--black-50, rgba(15, 23, 42, 0.50));
            font-size: 14px;
            font-weight: 500;
            span {
                a.router-link {
                    text-decoration: none;
                    color: var(--button-default, #4385F5);
                } 
            }
        }
        form {
            flex-grow: 1;
            margin-top: 47px;
            margin-bottom: 75px;
            font-size: 16px;
            font-weight: 500;
            text-align: center;
            display: flex;
            flex-direction: column;
            gap: 25px;
            width: 366px;
            font-family: "DM Sans", sans-serif;
            color: var(--black-50, rgba(15, 23, 42, 0.50));
            h3 {
                color: var(--black-100, #0F172A);
                font-family: DM Sans;
                font-size: 32px;
                font-style: normal;
                font-weight: 500;
                line-height: normal;
            }
            .input {
                display: flex;
                gap: 10px;
                flex-direction: column;
                width: 100%;
                align-items: center;
                span {
                    font-size: 11px;
                    color: red;
                }
            }
            input {
                width: 100%;
                border-radius: 30px;
                padding: 24px;
                border: 1px solid var(--black-30, rgba(15, 23, 42, 0.30));
            }
            #form-button {
                background: var(--button-default, #4385F5);
                border: none;
                border-radius: 30px;
                color: #fff;
            }
        }
        #socials {
            display: flex;
            gap: 61px;
            margin-bottom: 100px;
        }
    }
    #photo-div {
        width: 50%;
        height: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        img {
            height: 100%;
            border-radius: 15px;
        }
    }
}

@media only screen and (max-width: 940px) {
#register {
    padding: 54px 24px 31px;
    flex-direction: column;
    height: fit-content;
    height: auto;
    #details {
        width: 100%;
        align-items: flex-start;
        height: fit-content;
        h1 {
            margin-top: 47px;
            margin-bottom: 28px;
            font-size: 32px;
        }
        p {
            font-size: 14px;
        }
        form {
            gap: 19px;
            width: 100%;
            h3 {
                font-size: 24px;
            }
        }
        #socials {
            width: 100%;
            justify-content: center;
            margin-bottom: 0px;
        }
    }
    #photo-div {
        display: none;
    }
}
}
</style>

<script lang="ts">
import axios from 'axios'
import AuthHeader from '@/components/AuthHeader.vue';
import { Product } from '@/router/types'
import { defineComponent } from 'vue'
import { toggleLoading } from '@/event-bus';
import { useUserStore } from '@/store/user';

export default defineComponent({
    name: 'SignUpVerified',
    components: {
        AuthHeader,
    },
    data() {
        return {
            status: 'linksent',
            buttonValue: 'Sign In',
        }
    },
    methods: {
        signIn() {
            this.$router.push('/signin')
        },
    },
    setup() {
        const userStore = useUserStore()
        return {
            userStore,
        }
    },
    mounted() {
        // hit the verification endpoint
        this.userStore.verifyUser({
            token: this.$route.params.token as string,
            uId: this.$route.params.uid as string,
        })
    }
})
</script>