import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, withCtx as _withCtx, vModelText as _vModelText, withDirectives as _withDirectives, toDisplayString as _toDisplayString, normalizeStyle as _normalizeStyle, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode } from "vue"
import _imports_0 from '../assets/images/signup1.png'


const _hoisted_1 = { id: "register" }
const _hoisted_2 = {
  key: 0,
  id: "details"
}
const _hoisted_3 = {
  action: "",
  method: "post"
}
const _hoisted_4 = { class: "input" }
const _hoisted_5 = { class: "error" }
const _hoisted_6 = { class: "input" }
const _hoisted_7 = { class: "error" }
const _hoisted_8 = { class: "input" }
const _hoisted_9 = { class: "error" }
const _hoisted_10 = { class: "input" }
const _hoisted_11 = { class: "error" }
const _hoisted_12 = { class: "input" }
const _hoisted_13 = ["value", "disabled"]
const _hoisted_14 = { class: "error" }
const _hoisted_15 = {
  key: 1,
  id: "details"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_AuthHeader = _resolveComponent("AuthHeader")!
  const _component_router_link = _resolveComponent("router-link")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (_ctx.status === 'nolink')
      ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
          _createVNode(_component_AuthHeader),
          _cache[7] || (_cache[7] = _createElementVNode("h1", null, "Sign Up", -1)),
          _createElementVNode("p", null, [
            _cache[6] || (_cache[6] = _createTextVNode(" Already have an account? ")),
            _createElementVNode("span", null, [
              _createVNode(_component_router_link, {
                class: "router-link",
                to: "/signin"
              }, {
                default: _withCtx(() => _cache[5] || (_cache[5] = [
                  _createTextVNode(" Sign in to your Account ")
                ])),
                _: 1
              })
            ])
          ]),
          _createElementVNode("form", _hoisted_3, [
            _createElementVNode("div", _hoisted_4, [
              _withDirectives(_createElementVNode("input", {
                "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.username) = $event)),
                type: "text",
                ref: "name",
                placeholder: "Enter your username",
                id: "form-name"
              }, null, 512), [
                [_vModelText, _ctx.username]
              ]),
              _createElementVNode("span", _hoisted_5, _toDisplayString(_ctx.formatErrorMessage(_ctx.errors.username)), 1)
            ]),
            _createElementVNode("div", _hoisted_6, [
              _withDirectives(_createElementVNode("input", {
                "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.email) = $event)),
                type: "email",
                ref: "email",
                placeholder: "Enter your email",
                id: "form-email"
              }, null, 512), [
                [_vModelText, _ctx.email]
              ]),
              _createElementVNode("span", _hoisted_7, _toDisplayString(_ctx.formatErrorMessage(_ctx.errors.email)), 1)
            ]),
            _createElementVNode("div", _hoisted_8, [
              _withDirectives(_createElementVNode("input", {
                "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.password) = $event)),
                type: "password",
                ref: "password",
                placeholder: "Enter your password",
                id: "form-password"
              }, null, 512), [
                [_vModelText, _ctx.password]
              ]),
              _createElementVNode("span", _hoisted_9, _toDisplayString(_ctx.formatErrorMessage(_ctx.errors.password)), 1)
            ]),
            _createElementVNode("div", _hoisted_10, [
              _withDirectives(_createElementVNode("input", {
                "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => ((_ctx.re_password) = $event)),
                type: "password",
                ref: "re-password",
                placeholder: "Re-enter your password",
                id: "re-password"
              }, null, 512), [
                [_vModelText, _ctx.re_password]
              ]),
              _createElementVNode("span", _hoisted_11, _toDisplayString(_ctx.formatErrorMessage(_ctx.errors.non_field_errors)), 1)
            ]),
            _createElementVNode("div", _hoisted_12, [
              _createElementVNode("input", {
                onClick: _cache[4] || (_cache[4] = ($event: any) => (_ctx.registerUser())),
                type: "button",
                name: "submit",
                value: _ctx.buttonValue,
                id: "form-button",
                disabled: _ctx.buttonDisabled,
                style: _normalizeStyle({ opacity: _ctx.buttonDisabled ? '0.3' : '1' })
              }, null, 12, _hoisted_13),
              _createElementVNode("span", _hoisted_14, _toDisplayString(_ctx.formatErrorMessage(_ctx.errors.detail)), 1)
            ])
          ])
        ]))
      : _createCommentVNode("", true),
    (_ctx.status === 'linksent')
      ? (_openBlock(), _createElementBlock("div", _hoisted_15, [
          _createVNode(_component_AuthHeader),
          _cache[8] || (_cache[8] = _createElementVNode("form", {
            action: "",
            method: "post"
          }, [
            _createElementVNode("h3", { class: "subbigtext" }, "Verification Link Sent!"),
            _createElementVNode("p", null, "Check your email")
          ], -1))
        ]))
      : _createCommentVNode("", true),
    _cache[9] || (_cache[9] = _createElementVNode("div", { id: "photo-div" }, [
      _createElementVNode("img", {
        src: _imports_0,
        alt: "A black shirt with diagonal tiles on a lady"
      })
    ], -1))
  ]))
}