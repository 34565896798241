import { defineComponent as _defineComponent } from 'vue'
import { createElementVNode as _createElementVNode, unref as _unref, toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, withCtx as _withCtx, createVNode as _createVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = { class: "intro" }
const _hoisted_2 = { class: "flex justify-center px-10 my-10" }
const _hoisted_3 = { class: "flex flex-col items-center" }
const _hoisted_4 = { class: "container" }
const _hoisted_5 = { id: "image" }
const _hoisted_6 = { class: "w-full flex justify-center pb-10 mt-10" }
const _hoisted_7 = {
  key: 0,
  class: "h-96 flex items-center justify-center flex-col text-2xl gap-3 font-medium text-gray-300"
}

import ItemCard from "@/components/ItemCard.vue";
import Pagination from "@/components/Pagination.vue";
import Spinner from "@/components/Spinner.vue";
import Tabs from "@/components/Tabs.vue";
import { useHomeStore } from "@/store/home";
import axios from "axios";
import { computed, onMounted, ref } from "vue";


export default /*@__PURE__*/_defineComponent({
  __name: 'Trending',
  setup(__props) {

const homeStore = useHomeStore();
const fetching = ref(false);
onMounted(async () => {
  fetching.value = true;
  await homeStore.initTrending();
  fetching.value = false;
});
const renderedTrendingItems = computed(() => {
  return (
    homeStore.getTrendingItems(
      String(homeStore.activeTrendingTab).toLowerCase() || "clothes"
    ) || []
  );
});

const fetchingMore = ref(false);
const fetchMore = (link: string) => {
  fetchingMore.value = true;
  axios
    .get(link)
    .then(({ data }) => {
      homeStore.setTrendingItems({
        category:
          String(homeStore.activeTrendingTab).toLowerCase() || "clothes",
        data,
      });
    })
    .finally(() => {
      fetchingMore.value = false;
    });
};

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("section", null, [
    _createElementVNode("div", _hoisted_1, [
      _cache[1] || (_cache[1] = _createElementVNode("h4", null, "Trending", -1)),
      _cache[2] || (_cache[2] = _createElementVNode("p", null, " Step into a world where style is celebrated and individuality shines. ", -1)),
      _createElementVNode("div", _hoisted_2, [
        _createVNode(Tabs, {
          tab_list: _unref(homeStore).trendingTabList,
          value: _unref(homeStore).activeTrendingTab,
          query_name: "trending",
          onModelValue: _cache[0] || (_cache[0] = ($event) => (_unref(homeStore).activeTrendingTab = $event)),
          class: "inline-flex gap-x-1 sm:gap-x-2 justify-between select-none rounded-[100px] [&>*]:flex-grow [&>*]:max-w-[50%] text-xs sm:text-base border border-gray-400 max-w-[90vw] overflow-x-auto hide-scrollbar"
        }, {
          tab: _withCtx(({ tab, index: i, is_active }) => [
            _createElementVNode("div", {
              class: _normalizeClass([[
                is_active
                  ? 'bg-primary text-white'
                  : 'bg-transparent hover:text-primary',
              ], "p-3 transition duration-300 font-medium cursor-pointer rounded-[100px] min-w-max"])
            }, [
              _createElementVNode("span", _hoisted_3, [
                _createElementVNode("span", null, _toDisplayString(tab?.name), 1)
              ])
            ], 2)
          ]),
          _: 1
        }, 8, ["tab_list", "value"])
      ])
    ]),
    _cache[4] || (_cache[4] = _createElementVNode("div", null, null, -1)),
    _createElementVNode("div", _hoisted_4, [
      _createElementVNode("div", _hoisted_5, [
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(renderedTrendingItems.value.results, (item) => {
          return (_openBlock(), _createBlock(ItemCard, {
            key: item.id,
            id: item.id,
            favorite_id: item.favorite_id,
            name: item.name,
            price: item.price,
            new_price: item.new_price,
            image: item.image,
            slug: item.slug,
            is_favourite: item.is_favourite
          }, null, 8, ["id", "favorite_id", "name", "price", "new_price", "image", "slug", "is_favourite"]))
        }), 128))
      ]),
      _createElementVNode("div", _hoisted_6, [
        _createVNode(Pagination, {
          data: renderedTrendingItems.value,
          onFetchMore: fetchMore,
          loading: fetchingMore.value
        }, null, 8, ["data", "loading"])
      ])
    ]),
    (fetching.value)
      ? (_openBlock(), _createElementBlock("div", _hoisted_7, [
          _cache[3] || (_cache[3] = _createElementVNode("span", null, " Fetching Trending items... ", -1)),
          _createVNode(Spinner, { class: "w-8 text-gray-500" })
        ]))
      : _createCommentVNode("", true)
  ]))
}
}

})