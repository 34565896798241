import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "second-header-nav" }
const _hoisted_2 = { class: "header-nav-main-text-container" }
const _hoisted_3 = { class: "main-text font-class" }
const _hoisted_4 = { class: "header-nav-pages-link" }
const _hoisted_5 = { class: "home" }
const _hoisted_6 = { class: "arrow-container" }
const _hoisted_7 = { class: "product-gender-container" }
const _hoisted_8 = { class: "arrow-container" }
const _hoisted_9 = { class: "product-page-container" }
const _hoisted_10 = { class: "arrow-container" }
const _hoisted_11 = { class: "shopping-cart-container" }
const _hoisted_12 = { class: "arrow-container" }
const _hoisted_13 = { class: "favorite-page-container" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_router_link = _resolveComponent("router-link")!
  const _component_box_icon = _resolveComponent("box-icon")!

  return (_openBlock(), _createElementBlock("nav", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("span", _hoisted_3, _toDisplayString(_ctx.main_header), 1)
    ]),
    _createElementVNode("div", _hoisted_4, [
      _createElementVNode("div", _hoisted_5, [
        _createVNode(_component_router_link, {
          class: "router-link",
          to: "/"
        }, {
          default: _withCtx(() => _cache[0] || (_cache[0] = [
            _createElementVNode("span", { class: "home-link-text font-class" }, "Home", -1)
          ])),
          _: 1
        })
      ]),
      _createElementVNode("div", _hoisted_6, [
        _createVNode(_component_box_icon, { name: "chevron-right" })
      ]),
      _createElementVNode("div", _hoisted_7, [
        _createVNode(_component_router_link, {
          class: "router-link",
          to: "/categories"
        }, {
          default: _withCtx(() => [
            _createElementVNode("span", {
              class: _normalizeClass([{ 'bold': _ctx.bold_link == 'categories', 'light': _ctx.bold_link != 'categories' }, "font-class"])
            }, "Categories", 2)
          ]),
          _: 1
        })
      ]),
      _createElementVNode("div", _hoisted_8, [
        _createVNode(_component_box_icon, { name: "chevron-right" })
      ]),
      _createElementVNode("div", _hoisted_9, [
        _createVNode(_component_router_link, {
          class: "router-link",
          to: "/products/previous/"
        }, {
          default: _withCtx(() => [
            _createElementVNode("span", {
              class: _normalizeClass([{ 'bold': _ctx.bold_link == 'productpage', 'light': _ctx.bold_link != 'productpage' }, "font-class"])
            }, "Product Page", 2)
          ]),
          _: 1
        })
      ]),
      _createElementVNode("div", _hoisted_10, [
        _createVNode(_component_box_icon, { name: "chevron-right" })
      ]),
      _createElementVNode("div", _hoisted_11, [
        _createVNode(_component_router_link, {
          class: "router-link",
          to: "/cart"
        }, {
          default: _withCtx(() => [
            _createElementVNode("span", {
              class: _normalizeClass([{ 'bold': _ctx.bold_link == 'cart', 'light': _ctx.bold_link != 'cart' }, "font-class"])
            }, "Shopping Cart", 2)
          ]),
          _: 1
        })
      ]),
      _createElementVNode("div", _hoisted_12, [
        _createVNode(_component_box_icon, { name: "chevron-right" })
      ]),
      _createElementVNode("div", _hoisted_13, [
        _createVNode(_component_router_link, {
          class: "router-link",
          to: "/favourites"
        }, {
          default: _withCtx(() => [
            _createElementVNode("span", {
              class: _normalizeClass([{ 'bold': _ctx.bold_link == 'favorite', 'light': _ctx.bold_link != 'favorite' }, "font-class"])
            }, "Favorite Page", 2)
          ]),
          _: 1
        })
      ])
    ])
  ]))
}