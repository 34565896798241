import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, normalizeClass as _normalizeClass, normalizeStyle as _normalizeStyle, withCtx as _withCtx, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, vModelSelect as _vModelSelect, withDirectives as _withDirectives, createBlock as _createBlock } from "vue"

const _hoisted_1 = { id: "menu" }
const _hoisted_2 = { id: "menu-sidebar" }
const _hoisted_3 = {
  key: 0,
  id: "signin"
}
const _hoisted_4 = { value: "my-profile" }
const _hoisted_5 = { value: "orders" }
const _hoisted_6 = { value: "notifications" }
const _hoisted_7 = { value: "favourites" }
const _hoisted_8 = { value: "cart" }
const _hoisted_9 = { value: "help-center" }
const _hoisted_10 = { value: "settings" }
const _hoisted_11 = { value: "signin" }
const _hoisted_12 = {
  key: 0,
  id: "signin"
}
const _hoisted_13 = { id: "page-content" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ThirdHeader = _resolveComponent("ThirdHeader")!
  const _component_router_link = _resolveComponent("router-link")!
  const _component_FourthHeader = _resolveComponent("FourthHeader")!
  const _component_ActualComponent = _resolveComponent("ActualComponent")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_ThirdHeader, {
      class: "!mt-24 sm:!mt-0",
      mainf_header: "Account"
    }),
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("ul", null, [
        _createElementVNode("li", null, [
          _createVNode(_component_router_link, {
            class: _normalizeClass(["router-link profile", { selected: _ctx.menu_choice === 'Profile' }]),
            style: _normalizeStyle(
              _ctx.menu_choice === 'Profile'
                ? 'font-weight: 500;'
                : 'color: var(--white, #FFF);'
            ),
            to: "/my-profile"
          }, {
            default: _withCtx(() => _cache[4] || (_cache[4] = [
              _createElementVNode("span", { class: "font-class" }, "My profile", -1)
            ])),
            _: 1
          }, 8, ["class", "style"])
        ]),
        _createElementVNode("li", null, [
          _createVNode(_component_router_link, {
            class: _normalizeClass(["router-link orders", {
              selected:
                _ctx.menu_choice === 'Orders' ||
                _ctx.menu_choice === 'Ongoing' ||
                _ctx.menu_choice === 'History' ||
                _ctx.menu_choice === 'Drafts',
            }]),
            to: "/orders"
          }, {
            default: _withCtx(() => _cache[5] || (_cache[5] = [
              _createElementVNode("span", {
                class: "font-class span-selected",
                id: "select"
              }, "Orders", -1)
            ])),
            _: 1
          }, 8, ["class"])
        ]),
        _createElementVNode("li", null, [
          _createVNode(_component_router_link, {
            class: "router-link notifications",
            to: "/notifications"
          }, {
            default: _withCtx(() => _cache[6] || (_cache[6] = [
              _createElementVNode("span", { class: "font-class" }, "Notifications", -1)
            ])),
            _: 1
          })
        ]),
        _createElementVNode("li", null, [
          _createVNode(_component_router_link, {
            class: "router-link favourites",
            to: "/favourites"
          }, {
            default: _withCtx(() => _cache[7] || (_cache[7] = [
              _createElementVNode("span", { class: "font-class" }, "Favourites", -1)
            ])),
            _: 1
          })
        ]),
        _createElementVNode("li", null, [
          _createVNode(_component_router_link, {
            class: "router-link cart",
            to: "/cart"
          }, {
            default: _withCtx(() => _cache[8] || (_cache[8] = [
              _createElementVNode("span", { class: "font-class" }, "Your cart", -1)
            ])),
            _: 1
          })
        ]),
        _createElementVNode("li", null, [
          _createVNode(_component_router_link, {
            class: _normalizeClass(["router-link help-centre", { selected: _ctx.menu_choice === 'HelpCenter' }]),
            to: "/help-center"
          }, {
            default: _withCtx(() => _cache[9] || (_cache[9] = [
              _createElementVNode("span", { class: "font-class" }, "Help center", -1)
            ])),
            _: 1
          }, 8, ["class"])
        ]),
        _createElementVNode("li", null, [
          _createVNode(_component_router_link, {
            class: _normalizeClass(["router-link settings", { selected: _ctx.menu_choice === 'Settings' }]),
            to: "/settings"
          }, {
            default: _withCtx(() => _cache[10] || (_cache[10] = [
              _createElementVNode("span", { class: "font-class" }, "Settings", -1)
            ])),
            _: 1
          }, 8, ["class"])
        ]),
        (_ctx.Status === 'Log out')
          ? (_openBlock(), _createElementBlock("li", {
              key: 0,
              onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.logUserOut()))
            }, _cache[11] || (_cache[11] = [
              _createElementVNode("span", {
                style: {"color":"#fa0909","text-decoration":"none"},
                class: "prodpage-link-text font-class logout"
              }, "Log out", -1)
            ])))
          : _createCommentVNode("", true),
        _createElementVNode("li", null, [
          (_ctx.Status === 'Sign in')
            ? (_openBlock(), _createElementBlock("div", _hoisted_3, [
                _createVNode(_component_router_link, {
                  class: "signing",
                  to: "/signin"
                }, {
                  default: _withCtx(() => [
                    _createElementVNode("p", null, _toDisplayString(_ctx.Status), 1)
                  ]),
                  _: 1
                })
              ]))
            : _createCommentVNode("", true)
        ])
      ]),
      _withDirectives(_createElementVNode("select", {
        "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.selectedOption) = $event)),
        onChange: _cache[3] || (_cache[3] = 
//@ts-ignore
(...args) => (_ctx.navigate && _ctx.navigate(...args)))
      }, [
        _createElementVNode("option", _hoisted_4, [
          _createVNode(_component_router_link, {
            class: _normalizeClass(["router-link profile", { selected: _ctx.menu_choice === 'Profile' }]),
            style: _normalizeStyle(
              _ctx.menu_choice === 'Profile'
                ? 'font-weight: 500;'
                : 'color: var(--white, #FFF);'
            ),
            to: "/my-profile"
          }, {
            default: _withCtx(() => _cache[12] || (_cache[12] = [
              _createElementVNode("span", { class: "font-class" }, "My profile", -1)
            ])),
            _: 1
          }, 8, ["class", "style"])
        ]),
        _createElementVNode("option", _hoisted_5, [
          _createVNode(_component_router_link, {
            class: _normalizeClass(["router-link orders", {
              selected:
                _ctx.menu_choice === 'Orders' ||
                _ctx.menu_choice === 'Ongoing' ||
                _ctx.menu_choice === 'History' ||
                _ctx.menu_choice === 'Drafts',
            }]),
            to: "/orders"
          }, {
            default: _withCtx(() => _cache[13] || (_cache[13] = [
              _createElementVNode("span", {
                class: "font-class span-selected",
                id: "select"
              }, "Orders", -1)
            ])),
            _: 1
          }, 8, ["class"])
        ]),
        _createElementVNode("option", _hoisted_6, [
          _createVNode(_component_router_link, {
            class: _normalizeClass(["router-link notifications", { selected: _ctx.menu_choice === 'Notifications' }]),
            to: "/notifications"
          }, {
            default: _withCtx(() => _cache[14] || (_cache[14] = [
              _createElementVNode("span", { class: "font-class" }, "Notifications", -1)
            ])),
            _: 1
          }, 8, ["class"])
        ]),
        _createElementVNode("option", _hoisted_7, [
          _createVNode(_component_router_link, {
            class: "router-link favourites",
            to: "/favourites"
          }, {
            default: _withCtx(() => _cache[15] || (_cache[15] = [
              _createElementVNode("span", { class: "font-class" }, "Favourites", -1)
            ])),
            _: 1
          })
        ]),
        _createElementVNode("option", _hoisted_8, [
          _createVNode(_component_router_link, {
            class: "router-link cart",
            to: "/cart"
          }, {
            default: _withCtx(() => _cache[16] || (_cache[16] = [
              _createElementVNode("span", { class: "font-class" }, "Your cart", -1)
            ])),
            _: 1
          })
        ]),
        _createElementVNode("option", _hoisted_9, [
          _createVNode(_component_router_link, {
            class: _normalizeClass(["router-link help-centre", { selected: _ctx.menu_choice === 'HelpCenter' }]),
            to: "/help-center"
          }, {
            default: _withCtx(() => _cache[17] || (_cache[17] = [
              _createElementVNode("span", { class: "font-class" }, "Help center", -1)
            ])),
            _: 1
          }, 8, ["class"])
        ]),
        _createElementVNode("option", _hoisted_10, [
          _createVNode(_component_router_link, {
            class: _normalizeClass(["router-link settings", { selected: _ctx.menu_choice === 'Settings' }]),
            to: "/settings"
          }, {
            default: _withCtx(() => _cache[18] || (_cache[18] = [
              _createElementVNode("span", { class: "font-class" }, "Settings", -1)
            ])),
            _: 1
          }, 8, ["class"])
        ]),
        (_ctx.Status === 'Log out')
          ? (_openBlock(), _createElementBlock("option", {
              key: 0,
              value: "logout",
              onClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.logUserOut())),
              style: {"color":"#fa0909","text-decoration":"none"},
              class: "prodpage-link-text font-class logout"
            }, " Log out "))
          : _createCommentVNode("", true),
        _createElementVNode("option", _hoisted_11, [
          (_ctx.Status === 'Sign in')
            ? (_openBlock(), _createElementBlock("div", _hoisted_12, [
                _createVNode(_component_router_link, {
                  class: "signing",
                  to: "/signin"
                }, {
                  default: _withCtx(() => [
                    _createElementVNode("p", null, _toDisplayString(_ctx.Status), 1)
                  ]),
                  _: 1
                })
              ]))
            : _createCommentVNode("", true)
        ])
      ], 544), [
        [_vModelSelect, _ctx.selectedOption]
      ]),
      _createElementVNode("div", _hoisted_13, [
        (_ctx.menu_choice === 'Profile')
          ? (_openBlock(), _createBlock(_component_FourthHeader, {
              key: 0,
              main_header: "My profile"
            }))
          : _createCommentVNode("", true),
        (_ctx.menu_choice === 'Settings')
          ? (_openBlock(), _createBlock(_component_FourthHeader, {
              key: 1,
              main_header: "Settings"
            }))
          : _createCommentVNode("", true),
        (
            _ctx.menu_choice === 'Orders' ||
            _ctx.menu_choice === 'Ongoing' ||
            _ctx.menu_choice === 'History' ||
            _ctx.menu_choice === 'Drafts'
          )
          ? (_openBlock(), _createBlock(_component_FourthHeader, {
              key: 2,
              main_header: "Orders"
            }))
          : _createCommentVNode("", true),
        (_ctx.menu_choice === 'HelpCenter')
          ? (_openBlock(), _createBlock(_component_FourthHeader, {
              key: 3,
              main_header: "Help Center"
            }))
          : _createCommentVNode("", true),
        (_ctx.menu_choice === 'Notifications')
          ? (_openBlock(), _createBlock(_component_FourthHeader, {
              key: 4,
              main_header: "Notifications"
            }))
          : _createCommentVNode("", true),
        _createVNode(_component_ActualComponent)
      ])
    ])
  ]))
}