<script lang="ts">
import { useUserStore } from "@/store/user";
import { defineComponent } from "vue";
// import logo from '../assets/images/icons/logonew.png'

export default defineComponent({
  name: "Header",
  data() {
    return {
      user_logged_in: false,
      showMenu: false,
      Status: "Sign in",
      // sections: [] as string[],
    };
  },
  setup() {
    const userStore = useUserStore();
    return {
      userStore,
    };
  },
  methods: {
    // loadSections() {
    //   axios
    //     .get("/api/sections")
    //     .then((response) => {
    //       const sections = response.data;
    //       this.sections = sections.map(
    //         (section: { name: string }) => section.name
    //       );
    //       // This method below is to get their categories. Will come in handy later!
    //       //                this.categoryNames = data.reduce((acc, section) => {
    //       //                section.categories.forEach((category) => {
    //       //                    acc.push(category.name);
    //       //                });
    //       //                return acc;
    //       //                }, []);
    //     })
    //     .catch((error) => {
    //       console.error("Error fetching sections:", error);
    //     });
    // },
    showMenuFunc() {
      if (this.showMenu === false || this.showMenu === true) {
        this.showMenu = !this.showMenu;
        console.log(this.showMenu);
        // if (this.showMenu === false) {
        //   document.body.style.overflow = "auto";
        // } else {
        //   document.body.style.overflow = "hidden";
        // }
      }
    },
    setMenuFalse() {
      this.showMenu = false;
    },
    checkUserSignedIn() {
      const token = localStorage.getItem("AuthToken");

      if (token) {
        this.Status = "Log out";
      } else {
        this.Status = "Sign in";
      }
    },
    async logUserOut() {
      await this.userStore.signOut();
      window.location.href = "/";
    },
    goToSearch() {
      this.$router.push("/search");
    },
  },
  //    computed: {
  //        lowercaseSections() {
  //            return this.sections.map(section => section.toLowerCase());
  //        }
  //    },
  created() {
    this.checkUserSignedIn();
    // this.loadSections();
  },
});
</script>

<template>
  <header :style="showMenu === true ? 'padding: 0px;' : 'color: inherit;'">
    <div id="logo" v-if="showMenu === false">
      <router-link to="/">
        <img src="../assets/images/icons/logonew.png" alt="Fondstore logo" />
        <!-- <img src="../assets/logofondstore.png" alt="Fondstore logo" /> -->
      </router-link>
    </div>
    <nav id="nav-menu" v-if="showMenu === false">
      <ul>
        <router-link
          class="router-link font-class"
          to="/categories"
          active-class="active"
          ><p>Categories</p></router-link
        >
        <router-link
          class="router-link font-class"
          to="/product"
          active-class="active"
          ><p>Product</p></router-link
        >
        <router-link
          class="router-link font-class"
          to="/trending"
          active-class="active"
          ><p>Trending</p></router-link
        >
        <router-link
          class="router-link font-class"
          to="/blog"
          active-class="active"
          ><p>Blog</p></router-link
        >
        <router-link
          class="router-link font-class"
          to="/latest-arrival"
          active-class="active"
          ><p>New Arrivals</p></router-link
        >

        <!-- <router-link
          v-for="(section, index) in sections"
          :key="index"
          class="router-link font-class"
          :to="{ name: 'Section', params: { section: section.toLowerCase() } }"
          >{{ section }}</router-link
        > -->
      </ul>
    </nav>
    <nav id="nav-socials" v-if="showMenu === false">
      <ul>
        <li @click="goToSearch">
          <img
            src="../assets/images/icons/search.svg"
            alt=""
            class="twenty-four"
          />
        </li>
        <div id="hide" class="desktop">
          <!--<li><img src="../assets/images/icons/bell.svg" alt="" class="sixteen "></li>-->
          <li>
            <router-link to="/favourites"
              ><img
                src="../assets/images/icons/heart.svg"
                alt=""
                class="twenty-four notify"
            /></router-link>
          </li>
          <li>
            <router-link to="/cart"
              ><img
                src="../assets/images/icons/shopping-bag.svg"
                alt=""
                class="twenty"
            /></router-link>
          </li>
          <li>
            <router-link
              class="userphoto"
              to="/my-profile"
              v-if="user_logged_in === false"
            >
              <img
                style="border-radius: 50%; max-width: 24px; max-height: 25px"
                src="../assets/images/icons/profile.svg"
                alt=""
                class="userphoto twenty-four"
              />
            </router-link>
          </li>
          <li>
            <router-link
              class="userphoto"
              to="/my-profile"
              v-if="user_logged_in === true"
            >
              <img
                style="border-radius: 50%; max-width: 24px; max-height: 24px"
                src="../assets/images/etty-fidele-J1jYLLlRpA4-unsplash1profile-photo.png"
                alt="User"
                class="userphoto twenty-four"
              />
            </router-link>
          </li>
        </div>
        <li @click="showMenuFunc()">
          <img
            src="../assets/images/icons/details-more.svg"
            alt=""
            class="twenty-four mobile-only"
          />
        </li>
      </ul>
    </nav>
    <div class="sidebar" v-if="showMenu === true">
      <div class="menu">
        <ul>
          <li class="signin" @click="showMenuFunc()">
            <div>
              <!--<span>Sign in</span>-->
              <img src="../assets/images/icons/close.svg" />
            </div>
          </li>
          <!--
                        <li class="men">
                        <router-link @click="setMenuFalse" to="/product-category/men">Men</router-link>
                        </li>
                        <li class="men">
                            <router-link @click="setMenuFalse" to="/product-category/women">Women</router-link>
                        </li>
                        <li class="men">
                            <router-link @click="setMenuFalse" to="/blog">Blogs</router-link>
                        </li>
                    -->
          <!-- <li v-for="(section, index) in sections" :key="index" class="men">
            <router-link
              @click="setMenuFalse"
              :to="{
                name: 'Section',
                params: { section: section.toLowerCase() },
              }"
              >{{ section }}</router-link
            >
          </li> -->

          <li class="men">
            <router-link @click="setMenuFalse" to="/categories"
              >Categories</router-link
            >
          </li>
          <li class="men">
            <router-link
              @click="setMenuFalse"
              :to="{
                path: '/product',
                query: { mixed: 'popular-items' },
              }"
              >Popular</router-link
            >
          </li>
          <li class="men">
            <router-link @click="setMenuFalse" to="/trending"
              >Trending</router-link
            >
          </li>
          <!--          <li>-->
          <!--            <router-link @click="setMenuFalse" class="ico" to="/notifications">-->
          <!--              <img src="../assets/images/icons/bell.svg" alt="" />-->
          <!--              <p>Notifications</p>-->
          <!--            </router-link>-->
          <!--          </li>-->
          <li>
            <router-link @click="setMenuFalse" class="ico" to="/favourites">
              <img src="../assets/images/icons/heart.svg" alt="" />
              <p>Favourite</p>
            </router-link>
          </li>
          <li>
            <router-link @click="setMenuFalse" class="ico" to="/cart">
              <img src="../assets/images/icons/shopping-bag.svg" alt="" />
              <p>Cart</p>
            </router-link>
          </li>
          <li>
            <router-link @click="setMenuFalse" class="ico" to="/my-profile">
              <img src="../assets/images/icons/profile.svg" alt="" />
              <p>Profile</p>
            </router-link>
          </li>
        </ul>
      </div>
      <div id="signin" v-if="Status === 'Sign in'">
        <router-link class="signing" to="/signin">
          <p>{{ Status }}</p>
        </router-link>
      </div>
      <div @click="logUserOut" class="logout mt-3" v-if="Status === 'Log out'">
        <p>{{ Status }}</p>
      </div>
    </div>
  </header>
</template>

<style lang="scss" scoped>
.font-class {
  font-family: "DM Sans", sans-serif;
  font-style: normal;
}
header {
  border-bottom: 1px solid rgba(0, 0, 0, 0.4);

  background-color: white !important;
  padding: 20px 30px;
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 9;
  display: flex;
  justify-content: space-between;
  align-items: center;
  .sidebar {
    background: white;
    width: 100vw;
    height: 100vh;
    display: flex;
    flex-direction: column;
    padding: 2rem;
    touch-action: none;

    .menu {
      ul {
        list-style: none;
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        width: 100%;
        gap: 1.5rem;
        li {
          color: var(--black-100, #0f172a);
          font-family: DM Sans;
          font-size: 16px;
          font-style: normal;
          font-weight: 500;
          line-height: normal;
          a {
            text-decoration: none;
            color: inherit;
          }
        }

        li:hover {
          color: #4385f5;
        }

        li.signin {
          align-self: flex-end;
          max-width: 50px;
          max-height: 50px;
        }
      }
    }
    .logout {
      height: 20%;
      p {
        color: #fe0707;
        font-family: DM Sans;
        font-size: 20px;
        font-style: normal;
        font-weight: 300;
        line-height: normal;
        cursor: pointer;
      }
    }
    #signin {
      margin-top: 3rem;
      a.signing {
        text-decoration: none;
        p {
          border-radius: 30px;
          background: var(--button-default, #4385f5);
          color: var(--white, #fff);
          font-family: "DM Sans", sans-serif;
          font-size: 15px;
          font-weight: 500;
          line-height: normal;
          padding: 10px 30px;
          width: fit-content;
          cursor: pointer;
          // margin-top: 1rem;
        }
        p:hover {
          background: var(--button-default, #1e67e6);
        }
      }
    }
  }
  #logo {
    width: 178px;
    height: 30px;
  }
  #nav-menu {
    ul {
      display: flex;
      gap: 28px;
      align-items: center;
      overflow: none;
      width: 100%;
      white-space: nowrap;
      border-radius: 40px;

      .router-link {
        position: relative;
        text-decoration: none;
        color: black;
        font-size: 16px;
        font-weight: 500;
        line-height: 20.83px;
        cursor: pointer;
      }

      .router-link:hover {
        color: #2760e1;
      }

      .router-link::after {
        content: "";
        position: absolute;
        width: 100%;
        height: 0.175rem;
        background-color: #2760e1;
        left: 0;
        top: 2.7rem;
        transform: scale(0, 1);
        transition: transform 0.3s ease;
      }

      .router-link:hover::after {
        transform: scale(1, 1);
      }
    }

    @keyframes scrollImages {
      0% {
        transform: translateX(0%);
      }
      100% {
        transform: translateX(-30%);
      }
    }
    .active p {
      font-weight: 600 !important;
      color: #4385f5;
    }
  }
  #nav-socials {
    ul {
      display: flex;
      gap: 24px;
      align-items: center;
      .desktop {
        display: flex;
        gap: 24px;
        align-items: center;
      }

      li {
        position: relative;
        cursor: pointer;
        .twenty-four {
          max-width: 24px;
          max-height: 24px;
        }
        .twenty {
          max-width: 20px;
          max-height: 20px;
        }
        .sixteen {
          max-width: 16px;
          max-height: 22px;
        }
        .mobile-only {
          display: none;
        }
        a.userphoto {
          max-width: 24px;
          max-height: 24px;
          img {
            border-radius: 50%;
          }
        }
      }

      li::after {
        content: "";
        position: absolute;
        width: 100%;
        height: 0.175rem;
        background-color: #2760e1;
        left: 0;
        top: 2.9rem;
        transform: scale(0, 1);
        transition: transform 0.3s ease;
      }

      li:hover::after {
        transform: scale(1, 1);
        color: #2760e1;
      }
    }
  }
}

@media only screen and (max-width: 940px) {
  header {
    padding: 25px;

    #logo img {
      width: 150px;
      height: 30px;
    }

    #nav-menu {
      display: none;
    }

    .menu {
      row-gap: 1.5rem;
    }

    .ico {
      display: flex;
      justify-content: center;
      align-items: center;
      row-gap: 2em;
      column-gap: 10px;

      img {
        width: 16px;
        height: 16px;
      }

      li:hover {
        color: #4385f5;
      }
    }

    #nav-socials {
      ul {
        gap: 18px;
        justify-content: flex-start;
        #hide {
          display: none;
        }

        li {
          .desktop {
            display: none;
          }
          .mobile-only {
            display: block;
          }
        }
      }
    }
  }
}
</style>
