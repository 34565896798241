import { defineComponent as _defineComponent } from 'vue'
import { createElementVNode as _createElementVNode, unref as _unref, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = { class: "categories" }
const _hoisted_2 = {
  key: 0,
  class: "h-96 flex items-center justify-center flex-col text-2xl gap-3 font-medium text-gray-300"
}

import Spinner from "@/components/Spinner.vue";
import CategoriesSection from "@/components/CategoriesSection.vue";
import { useQuery } from "@tanstack/vue-query";
import { fetchCategories } from "@/utils";


export default /*@__PURE__*/_defineComponent({
  __name: 'Categories',
  setup(__props) {

const { data, isLoading, isFetching, } = useQuery({
  queryKey: ["categories"],
  queryFn: fetchCategories,
})


return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("section", _hoisted_1, [
    _cache[1] || (_cache[1] = _createElementVNode("div", { id: "cats" }, [
      _createElementVNode("h1", null, "Categories"),
      _createElementVNode("p", null, " At FondStore, our diverse collection and categories caters to every style and preference, ensuring that there's something extraordinary waiting for everyone. ")
    ], -1)),
    _createVNode(CategoriesSection, { categories: _unref(data) }, null, 8, ["categories"]),
    (_unref(isLoading) || _unref(isFetching))
      ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
          _cache[0] || (_cache[0] = _createElementVNode("span", null, " Fetching Categories... ", -1)),
          _createVNode(Spinner, { class: "w-8 text-gray-500" })
        ]))
      : _createCommentVNode("", true)
  ]))
}
}

})