import { defineComponent as _defineComponent } from 'vue'
import { createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = { class: "fc-product-details-container" }
const _hoisted_2 = { class: "product-details-picture" }
const _hoisted_3 = ["src"]
const _hoisted_4 = { class: "product-details-name-price-container" }
const _hoisted_5 = { class: "product-details-name-text font-class" }
const _hoisted_6 = { class: "product-details-price-text font-class" }
const _hoisted_7 = { class: "product-details-order-number-container" }
const _hoisted_8 = { class: "order-number-text-container" }
const _hoisted_9 = { class: "order-number-text font-class" }
const _hoisted_10 = {
  key: 0,
  class: "remove-button-container"
}
const _hoisted_11 = { class: "remove-button" }


export default /*@__PURE__*/_defineComponent({
  __name: 'OrdersComponent',
  props: {
    product_name: {},
    image: {},
    price: {},
    size: {},
    order_number: {},
    is_history: { type: Boolean },
    can_delete: { type: Boolean }
  },
  setup(__props: any) {

const props = __props

return (_ctx: any,_cache: any) => {
  const _component_box_icon = _resolveComponent("box-icon")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("img", {
        src: _ctx.image,
        alt: "product image",
        loading: "lazy"
      }, null, 8, _hoisted_3)
    ]),
    _createElementVNode("div", _hoisted_4, [
      _createElementVNode("span", _hoisted_5, _toDisplayString(_ctx.product_name), 1),
      _createElementVNode("span", _hoisted_6, "₦" + _toDisplayString(_ctx.price), 1)
    ]),
    _createElementVNode("div", _hoisted_7, [
      _createElementVNode("div", _hoisted_8, [
        _createElementVNode("span", _hoisted_9, _toDisplayString(_ctx.order_number), 1)
      ])
    ]),
    (_ctx.can_delete)
      ? (_openBlock(), _createElementBlock("div", _hoisted_10, [
          _createElementVNode("button", _hoisted_11, [
            _createVNode(_component_box_icon, { name: "x" })
          ])
        ]))
      : _createCommentVNode("", true)
  ]))
}
}

})