import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, vModelText as _vModelText, withDirectives as _withDirectives, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createCommentVNode as _createCommentVNode, createTextVNode as _createTextVNode, withCtx as _withCtx, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "main-checkout-page-container mt-20" }
const _hoisted_2 = { class: "user-input-detail-container" }
const _hoisted_3 = { class: "user-input-container" }
const _hoisted_4 = { class: "delivery-details-container" }
const _hoisted_5 = { class: "delivery-name-number-container" }
const _hoisted_6 = { class: "name-container" }
const _hoisted_7 = ["bind"]
const _hoisted_8 = { class: "phone-container" }
const _hoisted_9 = { class: "phone-dropdown-input-container" }
const _hoisted_10 = { class: "email-zip-state" }
const _hoisted_11 = { class: "email-container" }
const _hoisted_12 = { class: "zip-state-container" }
const _hoisted_13 = { class: "state-container" }
const _hoisted_14 = { class: "delivery-state-dropdown" }
const _hoisted_15 = {
  key: 0,
  class: "states-menu-container bg-white !h-auto border border-gray-400 max-h-[calc(100vh/2)] overflow-y-auto"
}
const _hoisted_16 = ["onClick"]
const _hoisted_17 = { class: "state-container" }
const _hoisted_18 = { class: "delivery-state-dropdown" }
const _hoisted_19 = {
  key: 0,
  class: "states-menu-container bg-white !h-auto border border-gray-400 max-h-[calc(100vh/2)] overflow-y-auto"
}
const _hoisted_20 = ["onClick"]
const _hoisted_21 = { class: "address-container" }
const _hoisted_22 = { class: "main-shippingmode-container" }
const _hoisted_23 = { class: "shipping-mode-select-container" }
const _hoisted_24 = { class: "shipping-mode-select-header" }
const _hoisted_25 = { class: "order-summary-products" }
const _hoisted_26 = { class: "main-subtotal-shipping-total-container" }
const _hoisted_27 = { class: "main-subtotal-container" }
const _hoisted_28 = { class: "price-text font-class" }
const _hoisted_29 = { class: "main-shipping-mode-container" }
const _hoisted_30 = { class: "price-text font-class" }
const _hoisted_31 = { class: "main-total-container" }
const _hoisted_32 = { class: "price-text font-class" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ThirdHeaderVue = _resolveComponent("ThirdHeaderVue")!
  const _component_box_icon = _resolveComponent("box-icon")!
  const _component_router_link = _resolveComponent("router-link")!
  const _component_OrderSummaryProducts = _resolveComponent("OrderSummaryProducts")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_ThirdHeaderVue, {
      mainf_header: "Checkout",
      bold_link: "checkout"
    }),
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, [
        _createElementVNode("div", _hoisted_4, [
          _createElementVNode("form", {
            id: "a-form",
            onSubmit: _cache[9] || (_cache[9] = 
//@ts-ignore
(...args) => (_ctx.makePayment && _ctx.makePayment(...args)))
          }, [
            _cache[16] || (_cache[16] = _createElementVNode("div", { class: "delivery-header" }, [
              _createElementVNode("span", { class: "delivery-header-text font-class" }, "DELIVERY INFORMATION")
            ], -1)),
            _createElementVNode("div", _hoisted_5, [
              _createElementVNode("div", _hoisted_6, [
                _cache[10] || (_cache[10] = _createElementVNode("label", {
                  for: "name",
                  class: "delivery-name-label"
                }, [
                  _createElementVNode("span", { class: "delivery-name-text font-class" }, "Name")
                ], -1)),
                _createElementVNode("input", {
                  type: "text",
                  class: "font-class",
                  name: "name",
                  bind: _ctx.delivery_name,
                  placeholder: "Enter your name",
                  required: ""
                }, null, 8, _hoisted_7)
              ]),
              _createElementVNode("div", _hoisted_8, [
                _cache[11] || (_cache[11] = _createElementVNode("label", {
                  for: "phone",
                  class: "delivery-phone-label"
                }, [
                  _createElementVNode("span", { class: "delivery-phone-text font-class" }, "Mobile Number")
                ], -1)),
                _createElementVNode("div", _hoisted_9, [
                  _withDirectives(_createElementVNode("input", {
                    type: "tel",
                    class: "font-class phone-input",
                    name: "phone",
                    "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.delivery_mobile_number) = $event)),
                    placeholder: "080 1234 5678",
                    required: ""
                  }, null, 512), [
                    [_vModelText, _ctx.delivery_mobile_number]
                  ])
                ])
              ])
            ]),
            _createElementVNode("div", _hoisted_10, [
              _createElementVNode("div", _hoisted_11, [
                _cache[12] || (_cache[12] = _createElementVNode("label", {
                  for: "Email",
                  class: "delivery-email"
                }, [
                  _createElementVNode("span", { class: "delivery-email-text font-class" }, "Email Address")
                ], -1)),
                _withDirectives(_createElementVNode("input", {
                  "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.delivery_email_address) = $event)),
                  type: "email",
                  name: "Email",
                  class: "font-class",
                  placeholder: "Enter your email",
                  required: ""
                }, null, 512), [
                  [_vModelText, _ctx.delivery_email_address]
                ])
              ]),
              _createElementVNode("div", _hoisted_12, [
                _createElementVNode("div", _hoisted_13, [
                  _cache[13] || (_cache[13] = _createElementVNode("label", {
                    for: "State",
                    class: "delivery-state"
                  }, [
                    _createElementVNode("span", { class: "delivery-state-text font-class" }, "State")
                  ], -1)),
                  _createElementVNode("div", _hoisted_14, [
                    _withDirectives(_createElementVNode("input", {
                      type: "text",
                      name: "State",
                      readonly: "",
                      class: "state-input font-class",
                      placeholder: "Select State",
                      "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.delivery_state) = $event)),
                      onInput: _cache[3] || (_cache[3] = 
//@ts-ignore
(...args) => (_ctx.onInput && _ctx.onInput(...args))),
                      required: ""
                    }, null, 544), [
                      [_vModelText, _ctx.delivery_state]
                    ]),
                    _createElementVNode("button", {
                      type: "button",
                      onClick: _cache[4] || (_cache[4] = 
//@ts-ignore
(...args) => (_ctx.activateStateDropdown && _ctx.activateStateDropdown(...args))),
                      class: "state-dropdown"
                    }, [
                      _createVNode(_component_box_icon, {
                        name: "chevron-down",
                        color: "#0F172A"
                      })
                    ]),
                    (_ctx.active_state_dropdown === true)
                      ? (_openBlock(), _createElementBlock("div", _hoisted_15, [
                          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.states_menu, (state) => {
                            return (_openBlock(), _createElementBlock("button", {
                              type: "button",
                              onClick: ($event: any) => (_ctx.setState(state.name, state.id)),
                              class: "states-menu p-1 px-2",
                              key: state.id
                            }, _toDisplayString(state.name), 9, _hoisted_16))
                          }), 128))
                        ]))
                      : _createCommentVNode("", true)
                  ])
                ]),
                _createElementVNode("div", _hoisted_17, [
                  _cache[14] || (_cache[14] = _createElementVNode("label", {
                    for: "State",
                    class: "delivery-state"
                  }, [
                    _createElementVNode("span", { class: "delivery-state-text font-class" }, "LGA")
                  ], -1)),
                  _createElementVNode("div", _hoisted_18, [
                    _withDirectives(_createElementVNode("input", {
                      type: "text",
                      name: "State",
                      readonly: "",
                      class: "state-input font-class",
                      placeholder: "Choose state first",
                      "onUpdate:modelValue": _cache[5] || (_cache[5] = ($event: any) => ((_ctx.delivery_lga) = $event)),
                      onInput: _cache[6] || (_cache[6] = 
//@ts-ignore
(...args) => (_ctx.onInput && _ctx.onInput(...args))),
                      required: ""
                    }, null, 544), [
                      [_vModelText, _ctx.delivery_lga]
                    ]),
                    _createElementVNode("button", {
                      type: "button",
                      onClick: _cache[7] || (_cache[7] = 
//@ts-ignore
(...args) => (_ctx.activateLGAdropdown && _ctx.activateLGAdropdown(...args))),
                      class: "state-dropdown"
                    }, [
                      _createVNode(_component_box_icon, {
                        name: "chevron-down",
                        color: "#0F172A"
                      })
                    ]),
                    (_ctx.active_lga_dropdown === true)
                      ? (_openBlock(), _createElementBlock("div", _hoisted_19, [
                          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.lga_menu, (lga) => {
                            return (_openBlock(), _createElementBlock("button", {
                              type: "button",
                              onClick: ($event: any) => (_ctx.setLGA(lga.name, lga.id)),
                              class: "states-menu p-1 px-2",
                              key: lga.id
                            }, _toDisplayString(lga.name), 9, _hoisted_20))
                          }), 128))
                        ]))
                      : _createCommentVNode("", true)
                  ])
                ])
              ])
            ]),
            _createElementVNode("div", _hoisted_21, [
              _cache[15] || (_cache[15] = _createElementVNode("label", { for: "address" }, [
                _createElementVNode("span", { class: "address-text font-class" }, "Address")
              ], -1)),
              _withDirectives(_createElementVNode("input", {
                "onUpdate:modelValue": _cache[8] || (_cache[8] = ($event: any) => ((_ctx.userAddress) = $event)),
                type: "text",
                class: "delivery-address font-class",
                placeholder: "2324, Abua Close",
                name: "address",
                id: "address",
                required: ""
              }, null, 512), [
                [_vModelText, _ctx.userAddress]
              ])
            ])
          ], 32)
        ]),
        _createElementVNode("div", _hoisted_22, [
          _createElementVNode("div", _hoisted_23, [
            _createElementVNode("div", _hoisted_24, [
              _cache[18] || (_cache[18] = _createElementVNode("span", { class: "select-header-text" }, "ORDER SUMMARY", -1)),
              _createVNode(_component_router_link, {
                to: "/cart",
                class: "select-header-edit-btn"
              }, {
                default: _withCtx(() => _cache[17] || (_cache[17] = [
                  _createTextVNode("Edit")
                ])),
                _: 1
              })
            ]),
            _createElementVNode("div", _hoisted_25, [
              (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.cartItems, (each, i) => {
                return (_openBlock(), _createBlock(_component_OrderSummaryProducts, {
                  key: i,
                  onReloadCart: _ctx.reloadComponent,
                  product_name: each?.item?.name,
                  order_number: each?.quantity,
                  product_id: each?.item?.id,
                  photo: each?.item?.image || 'https://via.placeholder.com/150',
                  price: each?.item?.price
                }, null, 8, ["onReloadCart", "product_name", "order_number", "product_id", "photo", "price"]))
              }), 128))
            ])
          ]),
          _createElementVNode("div", _hoisted_26, [
            _createElementVNode("div", _hoisted_27, [
              _cache[19] || (_cache[19] = _createElementVNode("span", { class: "tag-text font-class" }, "SUBTOTAL", -1)),
              _createElementVNode("span", _hoisted_28, "₦" + _toDisplayString(_ctx.sub_total || _ctx.grandtotal), 1)
            ]),
            _createElementVNode("div", _hoisted_29, [
              _cache[20] || (_cache[20] = _createElementVNode("span", { class: "tag-text font-class" }, "SHIPPING", -1)),
              _createElementVNode("span", _hoisted_30, "₦" + _toDisplayString(_ctx.shipping_type), 1)
            ]),
            _createElementVNode("div", _hoisted_31, [
              _cache[21] || (_cache[21] = _createElementVNode("span", { class: "tag-text font-class" }, "TOTAL", -1)),
              _createElementVNode("span", _hoisted_32, "₦" + _toDisplayString(_ctx.grandtotal), 1)
            ])
          ]),
          _cache[22] || (_cache[22] = _createElementVNode("button", {
            type: "submit",
            form: "a-form",
            class: "checkout-button font-class"
          }, " Confirm Order ", -1))
        ])
      ]),
      _cache[23] || (_cache[23] = _createElementVNode("hr", { class: "line-break" }, null, -1))
    ])
  ]))
}