<template>
  <!-- give container base height + circular indicator size -->
  <div class="h-[calc(528px + 28px)] sm:h-[calc(500px + 28px)] sm:max-h-[calc(1000px + 28px)] w-full relative mt-6">
    <div>
      <div
        @scroll="detectScrollLimits"
        ref="scrollableContainer"
        :class="{ 'sm:![grid-auto-columns:30%] lg:![grid-auto-columns:30%': !isScrollable }"
        class="grid gap-4 sm:gap-6 grid-flow-col [grid-auto-columns:100%] sm:[grid-auto-columns:50%] lg:[grid-auto-columns:30%]
        overflow-x-auto [overscroll-behavior-inline:contain] [&>*]:snap-center [&>*]:h-[528px] sm:[&>*]:h-[500px] sm:[&>*]:max-h-[1000px]  sm:scroll-px-[40px]
        [scroll-snap-type:inline_mandatory] relative hide-scrollbar px-10">
        <slot></slot>
      </div>
    </div>
    <div
      v-if="isScrollable"
      class="absolute inset-0 pointer-events-none justify-between items-center hidden sm:flex sm:px-16 lg:px-44">
      <button
        :class="{ 'opacity-20': isMaxLeftOfContainer }"
        :disabled="isMaxLeftOfContainer"
        @click="scrollX('left')"
        class="rounded-full bg-white transition-all duration-300 pointer-events-auto disabled:cursor-not-allowed outline-none">
        <svg xmlns="http://www.w3.org/2000/svg" class="w-8" viewBox="0 0 24 24"><path fill="currentColor" d="M11.8 13H15q.425 0 .713-.288T16 12q0-.425-.288-.713T15 11h-3.2l.9-.9q.275-.275.275-.7t-.275-.7q-.275-.275-.7-.275t-.7.275l-2.6 2.6q-.3.3-.3.7t.3.7l2.6 2.6q.275.275.7.275t.7-.275q.275-.275.275-.7t-.275-.7l-.9-.9Zm.2 9q-2.075 0-3.9-.788t-3.175-2.137q-1.35-1.35-2.137-3.175T2 12q0-2.075.788-3.9t2.137-3.175q1.35-1.35 3.175-2.137T12 2q2.075 0 3.9.788t3.175 2.137q1.35 1.35 2.138 3.175T22 12q0 2.075-.788 3.9t-2.137 3.175q-1.35 1.35-3.175 2.138T12 22Zm0-2q3.35 0 5.675-2.325T20 12q0-3.35-2.325-5.675T12 4Q8.65 4 6.325 6.325T4 12q0 3.35 2.325 5.675T12 20Zm0-8Z"/></svg>
      </button>
      <button
        :class="{ 'opacity-20': isMaxRightOfContainer }"
        :disabled="isMaxRightOfContainer"
        @click="scrollX('right')"
        class="rounded-full bg-white transition-all duration-300 pointer-events-auto disabled:cursor-not-allowed outline-none">
        <svg xmlns="http://www.w3.org/2000/svg" class="w-8" viewBox="0 0 24 24"><path fill="currentColor" d="m12.2 13l-.9.9q-.275.275-.275.7t.275.7q.275.275.7.275t.7-.275l2.6-2.6q.3-.3.3-.7t-.3-.7l-2.6-2.6q-.275-.275-.7-.275t-.7.275q-.275.275-.275.7t.275.7l.9.9H9q-.425 0-.713.288T8 12q0 .425.288.713T9 13h3.2Zm-.2 9q-2.075 0-3.9-.788t-3.175-2.137q-1.35-1.35-2.137-3.175T2 12q0-2.075.788-3.9t2.137-3.175q1.35-1.35 3.175-2.137T12 2q2.075 0 3.9.788t3.175 2.137q1.35 1.35 2.138 3.175T22 12q0 2.075-.788 3.9t-2.137 3.175q-1.35 1.35-3.175 2.138T12 22Zm0-2q3.35 0 5.675-2.325T20 12q0-3.35-2.325-5.675T12 4Q8.65 4 6.325 6.325T4 12q0 3.35 2.325 5.675T12 20Zm0-8Z"/></svg>
      </button>
    </div>

    <div class="flex justify-center pt-7 gap-3">
      <div
        v-for="n in slideCount" :key="n"
        @click="scrollToIndex(n - 1)"
        :class="{ '!bg-blue-600': currentSlideIndex === n - 1 }"
        class="h-3 w-3 bg-blue-600/50 rounded-full cursor-pointer"></div>
    </div>
  </div>
</template>

<script setup>
import { ref, useSlots, computed, onMounted, watch } from 'vue'
import useWindowVisibility from '@/composables/useWindowVisibility'

const slots = useSlots()
const slideCount = computed(() => slots.default()[0].children.length)
const currentSlideIndex = ref(0)
const slideWidth = ref(0)

const isTopOfContainer = ref(true)
const isBottomOfContainer = ref(false)
const isMaxRightOfContainer = ref(false)
const isMaxLeftOfContainer = ref(true)
const isOverflowingX = ref(false)
const isOverflowingY = ref(false)
const minForScroll = ref(2)

const detectScrollLimits = (e) => {
  const target = e.target
  const { scrollTop, scrollHeight, offsetHeight, scrollLeft, scrollWidth, offsetWidth } = target
  const isAtBottom = scrollTop + offsetHeight >= scrollHeight
  const isAtTop = scrollTop === 0
  const isInbetween = !isAtBottom && !isAtTop
  const isAtMaxRight = scrollLeft + offsetWidth >= scrollWidth
  const isAtMaxLeft = scrollLeft === 0
  const isInbetweenX = !isAtMaxRight && !isAtMaxLeft

  if(isInbetween) isTopOfContainer.value = false
  if(isAtTop) isTopOfContainer.value = true
  if(isInbetween) isBottomOfContainer.value = false
  if(isAtBottom) isBottomOfContainer.value = true
  if(isAtMaxRight) isMaxRightOfContainer.value = true
  if(isAtMaxLeft) isMaxLeftOfContainer.value = true
  if(isInbetweenX) isMaxRightOfContainer.value = false
  if(isInbetweenX) isMaxLeftOfContainer.value = false
  // this is currently not so useful because function is called on scroll event
  // so it isn't accurate on first load
  if(scrollWidth > offsetWidth) isOverflowingX.value = true
  if(scrollWidth <= offsetWidth) isOverflowingX.value = false
  if(scrollHeight > offsetHeight) isOverflowingY.value = true
  if(scrollHeight <= offsetHeight) isOverflowingY.value = false

  // get the index of the current slide for the slide indicators
  slideWidth.value = target.children[0].offsetWidth
  const Width = slideWidth.value * slideCount.value
  const Left = target.scrollLeft
  currentSlideIndex.value = Math.round(scrollLeft / slideWidth.value)
}

// direction: 'left' | 'right'
const scrollableContainer = ref(null)
const scrollX = (direction) => {
  const scrollBy = window.innerWidth < 768 ? 300 : 1200
  //if screen size is mobile, scroll by 300px
  scrollableContainer.value.scrollBy({
    left: direction === 'left' ? -scrollBy : scrollBy,
    behavior: 'smooth',
  })
}
const isScrollable = computed(()=>{
  return slideCount.value > minForScroll.value
})
onMounted(()=>slideWidth.value = scrollableContainer.value.children[0]?.offsetWidth || 0) // this is necessary for when component children are not async loaded
const scrollToIndex = (index) => {
  !!scrollableContainer.value &&
  scrollableContainer.value.scrollTo({
    left: index * slideWidth.value,
    behavior: 'smooth',
  })
}

const slide_duration = ref(5000) // milliseconds
const auto_play_interval = ref(null)

const next = () => {
  currentSlideIndex.value = Math.abs((currentSlideIndex.value + 1) % slideCount.value)
  scrollToIndex(currentSlideIndex.value)
  // clearing intervals so that autoplaying would reset
  pauseAutoPlay()
  autoPlay()
}
const prev = () => {
  currentSlideIndex.value = Math.abs((currentSlideIndex.value - 1) % slideCount.value)
  scrollToIndex(currentSlideIndex.value)
  // clearing intervals so that autoplaying would reset
  pauseAutoPlay()
  autoPlay()
}
const autoPlay = () =>  auto_play_interval.value = setInterval(() => next(), slide_duration.value)
const pauseAutoPlay = () => clearInterval(auto_play_interval.value)

onMounted(() => {
  autoPlay()
  // pause autoplay on mousedown
  scrollableContainer.value.addEventListener('mouseover', () => {
    pauseAutoPlay()
  })
  // resume autoplay on mouseup
  scrollableContainer.value.addEventListener('mouseout', () => {
    autoPlay()
  })
  // pause autoplay on touchstart and resume on touchend
  scrollableContainer.value.addEventListener('touchstart', () => {
    pauseAutoPlay()
  })
  scrollableContainer.value.addEventListener('touchend', () => {
    autoPlay()
  })
})

// pause autoplay when tab is not visible
const { is_visible } = useWindowVisibility();
watch(is_visible, val => val ? autoPlay() : pauseAutoPlay())
</script>