<script lang="ts">
import { defineComponent } from 'vue'

export default defineComponent({
    name: 'ThirdHeader',
    props: {
        mainf_header: String,
    }
})
</script>

<template>
    <nav class="second-header-nav">
        <div class="header-nav-main-text-container">
            <span class="main-text font-class">{{ mainf_header }}</span>
        </div>
        <div class="header-nav-pages-link">
            <div class="home">
                <router-link class="router-link" to="/cart">
                    <span :class="{ 'bold': mainf_header == 'Account', 'light': mainf_header != 'Account' }" class="home-link-text font-class">Cart</span>
                </router-link>
            </div>
            <div class="arrow-container">
                <box-icon name='chevron-right'></box-icon>
            </div>
            <div class="home">
                <router-link class="router-link" to="/settings">
                    <span :class="{ 'bold': mainf_header == 'Account', 'light': mainf_header != 'Account' }" class="home-link-text font-class">Account</span>
                </router-link>
            </div>
            <div class="arrow-container">
                <box-icon name='chevron-right'></box-icon>
            </div>
            <div class="product-page-container">
                <router-link class="router-link" to="/checkout">
                    <span :class="{ 'bold': mainf_header == 'Checkout', 'light': mainf_header != 'Checkout' }" class="prodpage-link-text font-class">Payment</span>
                </router-link>
            </div>
            <!--
                <div class="arrow-container">
                <box-icon name='chevron-right'></box-icon>
            </div>
            <div class="shopping-cart-container">
                <router-link class="router-link" to="/blog">
                    <span :class="{ 'bold': mainf_header == 'Summary', 'light': mainf_header != 'Summary' }" class="sc-link-text font-class">Blog</span>
                </router-link>
            </div>
            -->
        </div>
    </nav>
</template>

<style lang="scss" scoped>
.bold {
    font-weight: 500;
}
.light {
    font-weight: 300;
}
.font-class {
    font-family: 'DM Sans', sans-serif;
    font-style: normal;
}
.second-header-nav {
    display: flex;
    margin-left: 80px;
    margin-right: 100px;
    margin-bottom: 2.5rem;
    justify-content: space-between;
    align-items: center;
    border: 0;
    border-bottom: 1px solid rgba(15, 23, 42, 0.1);
    padding-bottom: 0.25rem;
    .header-nav-main-text-container {
        display: flex;
        height: auto;
        span {
            font-size: 32px;
            font-weight: 500;
            line-height:41.66px;
        }
    }
    .header-nav-pages-link {
        display: flex;
        align-items: center;
        div {
            .router-link {
                text-decoration: none;
                color: #0F172A;
                span {
                    font-size: 14px;
                    line-height: 18.23px;
                }
            }
        }
        .arrow-container {
            display: flex;
            border: 1px solid #0F172A;
            border-radius: 50%;
            width: 12px;
            height: 12px;
            justify-content: center;
            align-items: center;
            margin-left: 0.5rem;
            margin-right: 0.5rem;
        }
    }
}

@media only screen and (max-width: 940px) {
    .second-header-nav {
        margin: 0px 24px 28px 24px;
        .header-nav-main-text-container {
            span {
                color: var(--black-100, #0F172A);
                font-family: DM Sans;
                font-size: 1.125rem;
                font-style: normal;
                font-weight: 500;
                line-height: normal; 
            }
        }
        
        .header-nav-pages-link {
            display: none;
        }
    }
}
</style>