import { defineComponent as _defineComponent } from 'vue'
import { openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "relative flex" }
const _hoisted_2 = { class: "absolute inset-0" }
const _hoisted_3 = ["src"]
const _hoisted_4 = { class: "absolute bottom-0 inset-x-0 flex justify-between items-center flex-1 px-8 pb-7" }
const _hoisted_5 = { class: "[text-shadow:_0_0_2px_var(--tw-shadow-color),_0_0_2px_var(--tw-shadow-color),_0_0_2px_var(--tw-shadow-color)] shadow-black text-white" }
const _hoisted_6 = { class: "text-lg" }
const _hoisted_7 = { class: "mp-price-container flex flex-col !items-start" }
const _hoisted_8 = {
  key: 0,
  class: "price-text font-class !leading-none"
}
const _hoisted_9 = { class: "flex items-center gap-4 text-blue-700 font-medium" }
const _hoisted_10 = ["disabled"]
const _hoisted_11 = {
  key: 0,
  xmlns: "http://www.w3.org/2000/svg",
  class: "w-8",
  viewBox: "0 0 512 512"
}
const _hoisted_12 = ["fill"]

import { ref, computed, onMounted } from 'vue';
import Spinner from '@/components/Spinner.vue';
import axios from 'axios';
import { useUserStore } from "@/store/user"
import { useProductStore } from "@/store/product"
import { toast } from 'vue3-toastify';
import 'vue3-toastify/dist/index.css';
import { useRouter } from 'vue-router';

type Props = {
  id: null | string
  favorite_id: null | string
  name: string
  image: null | string
  slug: null | string
  price: string
  new_price: null | string
  is_favourite: boolean
}


export default /*@__PURE__*/_defineComponent({
  __name: 'ScrollableSectionCard',
  props: {
    id: { default: null },
    favorite_id: {},
    name: { default: '--' },
    image: { default: null },
    slug: { default: null },
    price: { default: '--' },
    new_price: {},
    is_favourite: { type: Boolean, default: false }
  },
  setup(__props: any) {

const props = __props

const renderedPrice = computed(()=>Number(props.price).toLocaleString('en-NG', { style: 'currency', currency: 'NGN' }))
const renderedNewPrice = computed(()=>!!props.new_price ? Number(props.new_price).toLocaleString('en-NG', { style: 'currency', currency: 'NGN' }) : null)

const userStore = useUserStore();
const productStore = useProductStore();
const router = useRouter();

const favoriting = ref(false);
const justFavouritedId = ref<string>();
const isFavourite = ref(false)
onMounted(()=>isFavourite.value = props.is_favourite);
const handleFavourite = async () => {
  if (!userStore.getUser.token) {
    router.push("signin");
    return
  }
  favoriting.value = true;
  try {
    const res = await productStore.favoriteItem({
      item_id: props.id!,
      favorite_id: justFavouritedId.value || props.favorite_id!,
      isFavorite: isFavourite.value
    })
    isFavourite.value = !isFavourite.value;
    justFavouritedId.value = res?.favorite_id;
  } catch(error) {
    console.log(error)
  } finally {
    favoriting.value = false;
  }
}

const addingToCart = ref(false);
const addToCart = async () =>{
  addingToCart.value = true;
  const id = toast.loading(
      'Adding to cart...',
      {
          position: toast.POSITION.TOP_RIGHT,
      },
  );

  try {
    await productStore.addItemToCart({ item_id: props.id!, quantity: 1 })
    toast.update(id, {
        render: "Item added to cart",
        type: 'success',
        isLoading: false,
    });
  } catch(error) {
    toast.update(id, {
        render: "Error adding item to cart",
        type: 'error',
        isLoading: false,
    });
    console.log(error)
  } finally {
    addingToCart.value = false;
    setTimeout(() => toast.remove(id), 1000);
  }
}

return (_ctx: any,_cache: any) => {
  const _component_router_link = _resolveComponent("router-link")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      (!!_ctx.image)
        ? (_openBlock(), _createElementBlock("img", {
            key: 0,
            src: _ctx.image,
            loading: "lazy",
            class: "h-full w-full object-cover object-top"
          }, null, 8, _hoisted_3))
        : _createCommentVNode("", true)
    ]),
    _createVNode(_component_router_link, {
      class: "absolute inset-0",
      to: `/products/${_ctx.slug}`
    }, null, 8, ["to"]),
    _createElementVNode("div", _hoisted_4, [
      _createElementVNode("div", _hoisted_5, [
        _createElementVNode("h2", _hoisted_6, _toDisplayString(_ctx.name), 1),
        _createElementVNode("div", _hoisted_7, [
          _createElementVNode("span", {
            class: _normalizeClass([{'line-through text-red-500 !text-base !text-start' : !!renderedNewPrice.value }, "price-text font-class"])
          }, _toDisplayString(renderedPrice.value), 3),
          (!!renderedNewPrice.value)
            ? (_openBlock(), _createElementBlock("span", _hoisted_8, _toDisplayString(renderedNewPrice.value), 1))
            : _createCommentVNode("", true)
        ])
      ]),
      _createElementVNode("div", null, [
        _createElementVNode("div", _hoisted_9, [
          _createElementVNode("button", {
            onClick: handleFavourite,
            disabled: favoriting.value,
            className: "w-9 h-9 p-1.5 rounded-full bg-white transition-all duration-300 flex items-center justify-center"
          }, [
            (!favoriting.value)
              ? (_openBlock(), _createElementBlock("svg", _hoisted_11, [
                  _createElementVNode("path", {
                    fill: isFavourite.value ? 'currentColor' : 'none',
                    stroke: "currentColor",
                    "stroke-linecap": "round",
                    "stroke-linejoin": "round",
                    "stroke-width": "32",
                    d: "M352.92 80C288 80 256 144 256 144s-32-64-96.92-64c-52.76 0-94.54 44.14-95.08 96.81c-1.1 109.33 86.73 187.08 183 252.42a16 16 0 0 0 18 0c96.26-65.34 184.09-143.09 183-252.42c-.54-52.67-42.32-96.81-95.08-96.81Z"
                  }, null, 8, _hoisted_12)
                ]))
              : (_openBlock(), _createBlock(Spinner, { key: 1 }))
          ], 8, _hoisted_10)
        ])
      ])
    ])
  ]))
}
}

})