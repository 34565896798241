import { vModelText as _vModelText, withKeys as _withKeys, createElementVNode as _createElementVNode, withDirectives as _withDirectives, openBlock as _openBlock, createElementBlock as _createElementBlock, renderList as _renderList, Fragment as _Fragment, toDisplayString as _toDisplayString, createCommentVNode as _createCommentVNode, resolveComponent as _resolveComponent, createBlock as _createBlock, createStaticVNode as _createStaticVNode } from "vue"

const _hoisted_1 = { class: "search_parent_container mt-24" }
const _hoisted_2 = { class: "top-search-frame" }
const _hoisted_3 = { class: "search_frame" }
const _hoisted_4 = {
  key: 0,
  class: "recent-popular-searches"
}
const _hoisted_5 = {
  key: 0,
  class: "recent-searches"
}
const _hoisted_6 = {
  key: 1,
  class: "no-results-found-div"
}
const _hoisted_7 = {
  key: 2,
  class: "results-found-div"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_DisplayProducts = _resolveComponent("DisplayProducts")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("label", _hoisted_3, [
        _withDirectives(_createElementVNode("input", {
          "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.searchQuery) = $event)),
          onInput: _cache[1] || (_cache[1] = 
//@ts-ignore
(...args) => (_ctx.firstCharacter && _ctx.firstCharacter(...args))),
          onKeyup: _cache[2] || (_cache[2] = _withKeys(
//@ts-ignore
(...args) => (_ctx.saveSearchQuery && _ctx.saveSearchQuery(...args)), ["enter"])),
          type: "text",
          placeholder: "Search",
          class: "search_text"
        }, null, 544), [
          [_vModelText, _ctx.searchQuery]
        ])
      ]),
      _createElementVNode("div", {
        onClick: _cache[3] || (_cache[3] = 
//@ts-ignore
(...args) => (_ctx.saveSearchQuery && _ctx.saveSearchQuery(...args))),
        class: "filter-div bg-primary p-2 rounded-md cursor-pointer"
      }, _cache[5] || (_cache[5] = [
        _createStaticVNode("<svg width=\"25px\" height=\"25px\" viewBox=\"0 0 24 24\" fill=\"none\" xmlns=\"http://www.w3.org/2000/svg\" data-v-cb60dd7a><g id=\"SVGRepo_bgCarrier\" stroke-width=\"0\" data-v-cb60dd7a></g><g id=\"SVGRepo_tracerCarrier\" stroke-linecap=\"round\" stroke-linejoin=\"round\" data-v-cb60dd7a></g><g id=\"SVGRepo_iconCarrier\" data-v-cb60dd7a><g id=\"Interface / Search_Magnifying_Glass\" data-v-cb60dd7a><path id=\"Vector\" d=\"M15 15L21 21M10 17C6.13401 17 3 13.866 3 10C3 6.13401 6.13401 3 10 3C13.866 3 17 6.13401 17 10C17 13.866 13.866 17 10 17Z\" stroke=\"#fff\" stroke-width=\"2\" stroke-linecap=\"round\" stroke-linejoin=\"round\" data-v-cb60dd7a></path></g></g></svg>", 1)
      ]))
    ]),
    (_ctx.state === 'recent_popular_state')
      ? (_openBlock(), _createElementBlock("div", _hoisted_4, [
          (_ctx.searchQueries.length > 0)
            ? (_openBlock(), _createElementBlock("div", _hoisted_5, [
                _cache[6] || (_cache[6] = _createElementVNode("h5", null, "Recent Searches", -1)),
                _createElementVNode("ul", null, [
                  (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.searchQueries, (search, index) => {
                    return (_openBlock(), _createElementBlock("li", {
                      key: index,
                      onClick: _cache[4] || (_cache[4] = 
//@ts-ignore
(...args) => (_ctx.saveSearchQuery && _ctx.saveSearchQuery(...args)))
                    }, _toDisplayString(search), 1))
                  }), 128))
                ])
              ]))
            : _createCommentVNode("", true)
        ]))
      : _createCommentVNode("", true),
    (_ctx.state === 'item_not_found')
      ? (_openBlock(), _createElementBlock("div", _hoisted_6, [
          _createElementVNode("h5", null, "No results for \"" + _toDisplayString(_ctx.searchQuery) + "\"", 1)
        ]))
      : _createCommentVNode("", true),
    (_ctx.state === 'item_found')
      ? (_openBlock(), _createElementBlock("div", _hoisted_7, [
          _createElementVNode("h5", null, "Search results for \"" + _toDisplayString(_ctx.searchQuery) + "\"", 1),
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.products, (item) => {
            return (_openBlock(), _createBlock(_component_DisplayProducts, {
              key: item.id,
              product_name: item.name,
              price: item.price,
              product_id: item.id,
              photo: item.image,
              slug: item.slug,
              description: item.description
            }, null, 8, ["product_name", "price", "product_id", "photo", "slug", "description"]))
          }), 128))
        ]))
      : _createCommentVNode("", true)
  ]))
}