<template>
  <div :class="['flex cursor-pointer', {'cursor-not-allowed': disabled && !userStore.getUser.token}]">
    <label
      class="star-rating__star"
      v-for="rating in ratings"
      :class="{
        'is-selected': value >= rating && value !== null,
        'is-disabled': disabled && !userStore.getUser.token
      }"
      @click="set(rating)"
      @mouseover="starOver(rating)"
      @mouseout="starOut"
    >
      <input
        class="star-rating__checkbox"
        type="radio"
        :value="rating"
        :name="name"
        v-model="value"
        :disabled="disabled"
      />
      ★
    </label>
  </div>


  <div v-if="showRatingDescription" class="overflow-y-auto overflow-x-hidden flex fixed top-0 right-0 left-0 z-50 bg-black/75 justify-center items-center w-full md:inset-0 h-[calc(100%-1rem)] max-h-full">
    <div class="relative p-4 w-full max-w-2xl max-h-full">
        <!-- Modal content -->
        <div class="relative bg-white rounded-lg shadow">
            <!-- Modal header -->
            <div class="flex items-center justify-between p-4 md:p-5 border-b rounded-t">
                <h3 class="text-xl font-semibold text-gray-900">
                   Rating and Review
                </h3>
                <button @click="showRatingDescription = false" type="button" class="text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm w-8 h-8 ms-auto inline-flex justify-center items-center">
                    <svg class="w-3 h-3" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 14 14">
                        <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="m1 1 6 6m0 0 6 6M7 7l6-6M7 7l-6 6"/>
                    </svg>
                    <span class="sr-only">Close modal</span>
                </button>
            </div>
            <!-- Modal body -->
            <div class="p-4 md:p-5 space-y-4">
              <div :class="['flex cursor-pointer', {'cursor-not-allowed': disabled === true}]">
                <label
                  class="star-rating__star"
                  v-for="rating in ratings"
                  :class="{
                    'is-selected': value >= rating && value !== null,
                    'is-disabled': disabled
                  }"
                  @click="set(rating)"
                  @mouseover="starOver(rating)"
                  @mouseout="starOut"
                >
                  <input
                    class="star-rating__checkbox"
                    type="radio"
                    :value="rating"
                    :name="name"
                    v-model="value"
                    :disabled="disabled"
                  />
                  ★
                </label>
              </div>

              <div>
                <label for="review_description" class="block mb-2 text-sm font-medium text-gray-900 ">Product Review</label>
                <input v-model="description" type="text" id="review_description" class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 " placeholder="Write a review for the product" required />
              </div>
            </div>
            <!-- Modal footer -->
            <div class="flex items-center p-4 md:p-5 border-t border-gray-200 rounded-b">
                <button @click="updateRating" type="button" class="text-white bg-blue-600 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center">Save Review</button>
                <button @click="showRatingDescription = false" type="button" class="ms-3 text-gray-500 bg-white hover:bg-gray-100 focus:ring-4 focus:outline-none focus:ring-blue-300 rounded-lg border border-gray-200 text-sm font-medium px-5 py-2.5 hover:text-gray-900 focus:z-10">Cancel</button>
            </div>
        </div>
    </div>
</div>
</template>

<script setup>
import { useUserStore } from '@/store/user';
import axios from 'axios';
import { ref } from 'vue';
import { defineProps } from 'vue';
import { useRoute } from 'vue-router';
import { toast } from 'vue3-toastify';
import 'vue3-toastify/dist/index.css';

const props = defineProps(['value', 'disabled', 'itemId']);

const name = ref('');
const value = ref(props.value);
const itemId = ref(props.itemId);
const disabled = ref(props.disabled);
const tempValue = ref(null);
const description = ref("");
const ratings = [ 1, 2, 3, 4, 5 ];
const showRatingDescription = ref( false );
const userStore = useUserStore();
const router = useRoute();

const id = router.params.id;

const starOver = (index) => {
  if (!disabled.value) {
    tempValue.value = value.value;
    value.value = index;
  }
};

const starOut = () => {
  if (!disabled.value) {
    value.value = tempValue.value;
  }
};

const set = (newValue) => {
  if (!props.disabled) {
    tempValue.value = newValue;
    value.value = newValue;
    showRatingDescription.value = true;
    // // updateRating(newValue)
    // console.log({newValue, value: props.value, itemId: id, modal: showRatingDescription.value})
  } else {
    alert("You cannot rate a product you haven't purchased.")
  }
};

const updateRating = async () => {
  const res = await axios.post( `/api/items/${ id }/ratings/`, {
    id: id,
    rating: value.value,
    description: description.value
  }, userStore.getAuthHeader );

  if ( res.data ){
    toast( "Rating saved successfully", { autoClose: 1000 } );
    showRatingDescription.value = false;
  }
}
</script>

<style scoped>

.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.5s ease;
}

.fade-enter-from,
.fade-leave-to {
  opacity: 0;
}

.star-rating__star {
  display: inline-flex;
  padding: 3px;
  vertical-align: middle;
  line-height: 1;
  font-size: 1.5em;
  color: #ABABAB;
  transition: color 0.2s ease-out;
}

.star-rating__star:hover {
  cursor: pointer;
}

.star-rating__star.is-selected {
  color: #FFD700;
}

.star-rating__star.is-disabled:hover {
  cursor: not-allowed;
}

.star-rating__checkbox {
  visibility: hidden;
  position: absolute;
  overflow: hidden;
  clip: rect(0 0 0 0);
  height: 1px; width: 1px;
  margin: -1px; padding: 0; border: 0;
}
</style>
