<template>
    <div id="profile-photo">
        <img v-if="user_logged_in === false" src="@/assets/images/icons/profile.svg" alt="Profile icon">
        <!--The user's photo is stored here. When they upload it, it replaces the one stored here.-->
        <img v-if="user_logged_in === true" src="@/assets/images/etty-fidele-J1jYLLlRpA4-unsplash1profile-photo.png" alt="User profile photo">
    </div>
</template>

<style lang="scss" scoped>
#profile-photo {
    height: 100%;
    width: 100%;
    img {
        border-radius: 50%;
        height: 100%;
        width: 100%;
        opacity: 1;
    }
}
</style>

<script lang="ts">
import { defineComponent } from "vue";

export default defineComponent ({
  name: 'userphoto',
  data() {
    return {
        user_logged_in: false,
    }
  }
})
</script>