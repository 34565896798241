/* stores/paystack.js */
import { defineStore } from 'pinia';
import axios from 'axios';
import { useUserStore } from './user';
import { useProductStore } from './product';

export const usePaymentStore = defineStore('payment', {
  state: () => ({
    banks: [] as any[],
    publicKey: "pk_test_3a1319eab46a31b488c3e5261e0700e2fd19d670",
    privateKey: "sk_test_b06dea1f05b9f077249089f45a380470dbec93f9",
  }),
  getters: {
    
  },
  actions: {
    async fetchBanks() {
      try {
        const {data} = await axios.get("https://api.paystack.co/bank", {
          params: {
            country: "nigeria",
            pay_with_bank_transfer: false,
            perPage: 100,
            pay_with_bank: true,
          },
          headers: {
            Authorization: `Bearer ${process.env.VUE_APP_PAYSTACK_PRIVATE_KEY}`
          }
        })
        this.banks = data.data
        return data.data
      } catch (error) {
        console.log({error})
      }
    },
    async makePayment({orderId}: {orderId: string}): Promise<any> {
      const user = useUserStore()

      return new Promise(async (resolve, reject) => {
        try {
          const {data} = await axios.post(`/api/orders/${orderId}/pay/`, undefined, user.getAuthHeader)
          resolve(data)
        } catch(error) {
          reject(error)
        }
      })
    },
    async setShippingAddress(
      {street_address,lga,state,phone,email} :
      {street_address: string, lga: string, state: string, phone: string, email: string}
    ) {
      const user = useUserStore()

      try {
        const form = new FormData();
        form.append('street_address', street_address)
        form.append('lga', lga)
        form.append('state', state)
        form.append('phone', phone)
        form.append('email', email)
        
        const { data } = await axios.post(`/api/address/`, form, user.getAuthHeader)
        console.log({data})
      } catch(error) {
        console.log({error})
      }
    },
  }
});