import { defineComponent as _defineComponent } from 'vue'
import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, renderList as _renderList, Fragment as _Fragment, createBlock as _createBlock, withCtx as _withCtx, createVNode as _createVNode } from "vue"

const _hoisted_1 = { class: "bg-white" }
const _hoisted_2 = { class: "py-8 px-4 mx-auto max-w-screen-xl" }
const _hoisted_3 = { class: "mt-10" }
const _hoisted_4 = { class: "mb-4 md:mb-0 w-full mx-auto relative" }
const _hoisted_5 = { class: "px-4" }
const _hoisted_6 = { class: "text-4xl font-semibold text-gray-800 leading-tight" }
const _hoisted_7 = {
  href: "#",
  class: "py-2 text-green-700 inline-flex items-center justify-center mb-2"
}
const _hoisted_8 = { class: "flex flex-col lg:flex-row" }
const _hoisted_9 = {
  key: 0,
  class: "px-4 lg:px-0 mt-12 text-gray-700 text-lg leading-relaxed w-full"
}
const _hoisted_10 = ["innerHTML"]
const _hoisted_11 = { class: "mt-10" }

import ScrollableSection from '@/components/ScrollableSection.vue';
import ScrollableSectionCard from '@/components/ScrollableSectionCard.vue';
import axios from 'axios';
import { onMounted, ref } from 'vue';
import { useRoute } from 'vue-router';
interface BlogPost {
  id: number;
  title: string;
  content: string;
  linked_items: any[]
  created_at: string;
}


export default /*@__PURE__*/_defineComponent({
  __name: 'Blogpost',
  setup(__props) {

const blogPosts = ref<BlogPost | undefined>();
const route = useRoute();
const blogId = route.params?.id as string

const fetchPosts = async () => {
    const res = await axios.get(`/blog/blogposts/${blogId}`);
    if (res.status === 200) {
        blogPosts.value = res.data;
    }
}

const  nl2Br = (text: string) => text.replace(/\n/g, "<br />");

function getTimeDifference(timestamp) {
    const currentDate = new Date();
    const postDate = new Date(timestamp);

    const timeDifference = (currentDate as any) - (postDate as any);
    const daysDifference = Math.floor(timeDifference / (1000 * 60 * 60 * 24));

    if (daysDifference === 0) {
        return 'Today';
    } else if (daysDifference === 1) {
        return 'Yesterday';
    } else if (daysDifference < 7) {
        return `${daysDifference} days ago`;
    } else {
        return postDate.toLocaleDateString('en-US', { year: 'numeric', month: 'long', day: 'numeric' });
    }
}

onMounted(fetchPosts)

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("section", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("main", _hoisted_3, [
        _createElementVNode("div", _hoisted_4, [
          _createElementVNode("div", _hoisted_5, [
            _createElementVNode("h2", _hoisted_6, _toDisplayString(blogPosts.value?.title), 1),
            _createElementVNode("a", _hoisted_7, _toDisplayString(getTimeDifference(blogPosts.value?.created_at)), 1)
          ])
        ]),
        _createElementVNode("div", _hoisted_8, [
          (blogPosts.value?.content)
            ? (_openBlock(), _createElementBlock("div", _hoisted_9, [
                _createElementVNode("p", {
                  innerHTML: nl2Br(blogPosts.value?.content as string),
                  class: "pb-6"
                }, null, 8, _hoisted_10)
              ]))
            : _createCommentVNode("", true)
        ]),
        _createElementVNode("div", _hoisted_11, [
          _cache[0] || (_cache[0] = _createElementVNode("h3", { class: "text-3xl font-semibold text-gray-800 leading-tight" }, "Linked Items", -1)),
          _createVNode(ScrollableSection, { class: "pb-10" }, {
            default: _withCtx(() => [
              (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(blogPosts.value?.linked_items, (item, i) => {
                return (_openBlock(), _createBlock(ScrollableSectionCard, {
                  key: i,
                  id: item.id,
                  favorite_id: item.favorite_id,
                  name: item.name,
                  price: item.price,
                  new_price: item.new_price,
                  image: item.image,
                  slug: item.slug,
                  is_favourite: item.is_favourite,
                  class: "bg-gray-50/90 rounded-3xl overflow-clip"
                }, null, 8, ["id", "favorite_id", "name", "price", "new_price", "image", "slug", "is_favourite"]))
              }), 128))
            ]),
            _: 1
          })
        ])
      ])
    ])
  ]))
}
}

})