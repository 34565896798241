import { openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode } from "vue"
import _imports_0 from '@/assets/images/icons/profile.svg'
import _imports_1 from '@/assets/images/etty-fidele-J1jYLLlRpA4-unsplash1profile-photo.png'


const _hoisted_1 = { id: "profile-photo" }
const _hoisted_2 = {
  key: 0,
  src: _imports_0,
  alt: "Profile icon"
}
const _hoisted_3 = {
  key: 1,
  src: _imports_1,
  alt: "User profile photo"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (_ctx.user_logged_in === false)
      ? (_openBlock(), _createElementBlock("img", _hoisted_2))
      : _createCommentVNode("", true),
    (_ctx.user_logged_in === true)
      ? (_openBlock(), _createElementBlock("img", _hoisted_3))
      : _createCommentVNode("", true)
  ]))
}