import { createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "group-div" }
const _hoisted_2 = { class: "queries" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_HelpCentreFAQ = _resolveComponent("HelpCentreFAQ")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _cache[0] || (_cache[0] = _createElementVNode("h2", null, "Search", -1)),
    _createElementVNode("div", _hoisted_2, [
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.Search, (line) => {
        return (_openBlock(), _createBlock(_component_HelpCentreFAQ, {
          key: line.question,
          main_text: line.question,
          text_expanded: line.answer
        }, null, 8, ["main_text", "text_expanded"]))
      }), 128))
    ])
  ]))
}