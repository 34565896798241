/* stores/trending.js */
import { defineStore } from 'pinia';
import axios from 'axios';
import { Category, PaginatedResponse, SectionTabs } from '@/router/types';
import { useUserStore } from './user';

export const useHomeStore = defineStore('home', {
  state: () => ({
    activeTrendingTab: undefined as unknown as string,
    trendingTabList: [] as SectionTabs[],
    trendingData: [] as PaginatedResponse<any[]>[], // each index matches the trendingTabList in terns of the category
    mixedItems: {} as { [key: string]: any[] },
    requestingMixedItem: false,
    requestingTrending: false,
  }),
  getters: {
    getTrendingItems: (state) => (category: string) => {
      const index = state.trendingTabList.findIndex((item: SectionTabs) => {
        return String(item.name).toLocaleLowerCase() === String(category).toLocaleLowerCase()
      })
      return state.trendingData?.[index] ?? []
    },
    getMixedItems: (state) => (endpointName: string) => {
      return state.mixedItems[endpointName]
    }
  },
  actions: {
    async fetchTrendingCategories(): Promise<Category[]> {
      return new Promise(async (resolve, reject) => {
        try {
          const { data } = await axios.get("/api/trending_categories/")
          const res =  data as Category[]
          // update trending tab list
          this.trendingTabList = res.map((item: Category) => ({
            name: item.name,
            slug: item.name.toLowerCase().replace(/\s/g, '-')
          }))
          this.activeTrendingTab = this.trendingTabList[0].name.toLowerCase().replace(/\s/g, '-')
          resolve(res)
        } catch (error) {
          reject(error)
        }
      })
    },
    async initTrending() {
      this.requestingTrending = true
      const user = useUserStore()
      const cat = await this.fetchTrendingCategories();

      // fetch trending data for each category
      const promises = cat.map((category: Category) => {
        return new Promise<PaginatedResponse<any[]>>(async (resolve, reject) => {
          try {
            const { data } = await axios.get(`/api/trending_categories/${category.id}/items/`, user.getAuthHeader)
            resolve(data as PaginatedResponse<any[]>)
          } catch (error) {
            reject(error)
          } finally {
            this.requestingTrending = false
          }
        })
      })
      this.trendingData = await Promise.all(promises)
    },
    setTrendingItems({ category, data }: { category: string, data: PaginatedResponse<any[]> }) {
      const index = this.trendingTabList.findIndex((item: SectionTabs) => {
        return String(item.name).toLocaleLowerCase() === String(category).toLocaleLowerCase()
      })
      this.trendingData[index] = data
    },
    async fetchMixedItems({ endpointName, requiresAuth }: { endpointName: string, requiresAuth: boolean }) {
      const user = useUserStore()
      const jwt = user.getUser.token
      if (requiresAuth && !jwt) return

      this.requestingMixedItem = true
      try {
        const { data } = await axios.get(`/api/${endpointName}/`, user.getAuthHeader)
        this.mixedItems[endpointName] =
          endpointName === "recently_viewed" ? data.results : data;
        console.log(endpointName === "recently_viewed" ? data.results : data);
      } catch (error) {
        console.log(error)
      } finally {
        this.requestingMixedItem = false
      }
    },
    async fetchBestDeals() {
      await this.fetchMixedItems({ endpointName: 'best_deals', requiresAuth: false })
    }
  }
});