<template>
  <div id="register">
    <div v-if="status === 'forgotpassword'" id="details">
      <AuthHeader />
      <h1>Forgot Password</h1>
      <form action="" method="post">
        <div class="input">
          <input
            v-model="email"
            type="text"
            name="email"
            placeholder="Enter your email address"
            id="form-email"
          />
          <span class="error">{{ formatErrorMessage(errors.email) }}</span>
        </div>
        <div class="input">
          <input
            @click="sendLink()"
            type="button"
            name="submit"
            :value="buttonValue"
            id="form-button"
            :disabled="buttonDisabled"
            :style="{ opacity: buttonDisabled ? '0.3' : '1' }"
          />
          <span class="error">{{ formatErrorMessage(errors.detail) }}</span>
        </div>
      </form>
    </div>
    <div v-if="status === 'emailsent'" id="details">
      <AuthHeader />
      <form action="" method="post">
        <h3 class="subbigtext">Password Reset Link Sent!</h3>
        <p>Check your email</p>
        <!--
                    <div class="input">
                    <input @click="signIn()" type="button" name="submit" :value="buttonValue" id="form-button" :disabled="buttonDisabled" :style="{ opacity: buttonDisabled ? '0.3' : '1' }" >
                    <span class="error">{{ formatErrorMessage(errors.detail) }}</span>
                </div>
                -->
      </form>
    </div>
    <div id="photo-div">
      <img
        src="../assets/images/signin.png"
        alt="A black shirt with diagonal tiles on a lady"
      />
    </div>
  </div>
</template>

<style lang="scss" scoped>
#register {
  padding: 51px 80px 44px;
  background: var(--white, #fff);
  font-family: "DM Sans", sans-serif;
  font-style: normal;
  line-height: normal;
  width: 100vw;
  // height: 125vh;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 18px;
  overflow-y: auto;
  #details {
    height: 100%;
    width: 50%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    header {
      margin-bottom: 5rem;
    }
    h1 {
      font-size: 48px;
      color: var(--black-100, #0f172a);
      font-weight: 500;
      margin-bottom: 7px;
      margin-top: 2rem;
    }
    p {
      color: var(--black-50, rgba(15, 23, 42, 0.5));
      font-size: 14px;
      font-weight: 500;
      span {
        a.router-link {
          text-decoration: none;
          color: var(--button-default, #4385f5);
        }
      }
    }
    form {
      flex-grow: 1;
      margin-top: 47px;
      margin-bottom: 75px;
      font-size: 16px;
      font-weight: 500;
      text-align: center;
      display: flex;
      flex-direction: column;
      gap: 25px;
      width: 366px;
      font-family: "DM Sans", sans-serif;
      color: var(--black-50, rgba(15, 23, 42, 0.5));
      h3 {
        color: var(--black-100, #0f172a);
        font-family: DM Sans;
        font-size: 24px;
        font-style: normal;
        font-weight: 500;
        line-height: normal;
      }
      .input {
        display: flex;
        gap: 10px;
        flex-direction: column;
        width: 100%;
        align-items: center;
        span {
          font-size: 11px;
          color: red;
        }
      }
      input {
        width: 100%;
        border-radius: 30px;
        padding: 24px;
        border: 1px solid var(--black-30, rgba(15, 23, 42, 0.3));
      }
      #form-button {
        background: var(--button-default, #4385f5);
        border: none;
        border-radius: 30px;
        color: #fff;
      }
    }
    #socials {
      display: flex;
      gap: 61px;
      margin-bottom: 100px;
    }
  }
  #photo-div {
    width: 50%;
    height: 400px;
    display: flex;
    justify-content: center;
    align-items: center;
    img {
      height: 100%;
      border-radius: 15px;
    }
  }
}

@media only screen and (max-width: 940px) {
  #register {
    padding: 54px 24px 31px;
    flex-direction: column;
    height: fit-content;
    #details {
      width: 100%;
      align-items: flex-start;
      header {
        margin-bottom: 0px;
      }
      h1 {
        margin-top: 47px;
        margin-bottom: 28px;
        font-size: 32px;
      }
      p {
        font-size: 14px;
      }
      form {
        gap: 19px;
        width: 100%;
        h3 {
          color: var(--black-100, #0f172a);
          text-align: center;
          font-family: DM Sans;
          font-size: 18px;
          font-style: normal;
          font-weight: 500;
          line-height: normal;
        }
      }
      #socials {
        width: 100%;
        justify-content: center;
        margin-bottom: 0px;
      }
    }
    #photo-div {
      display: none;
    }
  }
}
</style>

<script lang="ts">
import AuthHeader from "@/components/AuthHeader.vue";
import axios from "axios";
import { defineComponent } from "vue";
import { toast } from "vue3-toastify";

export default defineComponent({
  name: "ForgotPassword",
  components: {
    AuthHeader,
  },
  data() {
    return {
      status: "forgotpassword",
      buttonValue: "Send the link",
      buttonDisabled: false,
      email: "",
      password: "",
      errors: {
        email: this.email,
        detail: "",
      },
    };
  },
  methods: {
    formatErrorMessage(messages: Function | string) {
      if (Array.isArray(messages)) {
        return messages.join(" "); // Join array elements with a space
      }
      return messages; // Return empty string if no error message
    },
    async sendLink() {
      this.buttonValue = "Sending you the link...";
      this.buttonDisabled = true;

      const userDetails = {
        email: this.email,
        //                password: this.password
      };

      try {
        const response = await axios.post(
          "/auth/users/reset_password/",
          userDetails
        );

        this.status = "emailsent";
        //                const token = response.data.access;
        //                localStorage.setItem('AuthToken', token)
        //console.log('Login successful:', response.data);
        //                this.$router.push({name: 'home'})
      } catch (error) {
        this.buttonValue = "Send the link";
        this.buttonDisabled = false;

        if (axios.isAxiosError(error)) {
          toast.error(error.message || "An error occured. Please try again", {
            position: toast.POSITION.TOP_RIGHT,
          });
          if (error.response && error.response.status === 400) {
            this.errors = error.response.data;
          } else if (error.response && error.response.status === 401) {
            this.errors = error.response.data;
          } else if (error.response && error.response.status === 403) {
            this.errors = error.response.data;
          }
        } else {
          this.errors.detail = "An error occured. Please try again";
          console.error("Non-Axios error:", error);
        }
        console.error("Sending link failed:", error);
      }
    },
  },
});
</script>
