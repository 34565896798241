<script>
import axios from "axios";
import { defineComponent } from "vue";

export default defineComponent({
  data() {
    return {
      notifications: [],
      showImg: false,
      showImage: false,
    };
  },
  methods: {
    // showImgFunc() {
    //   if (this.showImg === false || this.showImg === true) {
    //     this.showImg = !this.showImg;
    //     console.log(this.showImg);
    //   }
    // },
    // setImgFalse() {
    //   this.showImg = false;
    // },

    // showImageFunc() {
    //   if (this.showImage === false || this.showImage === true) {
    //     this.showImage = !this.showImage;
    //     console.log(this.showImage);
    //   }
    // },
    // setImageFalse() {
    //   this.showImage = false;
    // },

    // check this p
    async fetchNotifications() {
      const token = localStorage.getItem("AuthToken");

      try {
        axios
          .get("https://fondstoremain-api.onrender.com/manager/notification/", {
            headers: {
              Authorization: `JWT ${token}`,
            },
          })
          .then((response) => {
            this.notifications = response.data;
          });
        console.log(this.notifications);
      } catch (error) {
        console.error("Error fetching notifications:", error);
      }
    },

    deleteNotify() {
      // from api
      // const token = localStorage.getItem("AuthToken");
      // try {
      //   axios
      //     .delete(`https://fondstoremain-api.onrender.com/manager/notification/${id}`, {
      //       headers: {
      //         Authorization: `JWT ${token}`,
      //       },
      //     })
      //     .then((response) => {
      //       this.notifications = response.data;
      //     });
      //   console.log(this.notifications);
      // } catch (error) {
      //   console.error("Error fetching notifications:", error);
      // }
    },
  },

  mounted() {
    this.fetchNotifications();
  },
});
</script>

<template>
  <section class="p-0">
    <div class="mb-6">
      <div
        class="font-light md:ml-5 lg:ml-10 flex justify-between items-center md:gap-y-4 mb-4 bg-gray-100"
        v-for="notification in notifications"
        :key="notification.id"
      >
        <p class="py-1 text-sm md:text-xl w-[90%] capitalize">
          {{ notification.notice }}
        </p>

        <div class="w-[10%] flex justify-center">
          <img
            src="../../assets/images/icons/close.png"
            alt=""
            class="cursor-pointer"
          />
        </div>
        <!-- <span class="md:hidden">
          <p
            @click="showImageFunc"
            :style="{ display: showImage ? 'block' : 'none' }"
            class="md:hidden mt-3 text-blue-500 text-xs"
          >
            view
          </p>
          <p
            @click="setImageFalse"
            :style="{ display: !showImage ? 'block' : 'none' }"
            class="md:hidden mt-3 text-red-500 text-xs"
          >
            close
          </p>
        </span> -->
      </div>
    </div>
    <!-- <div class="mb-6 bg-gray-100">
      <div class="flex justify-between py-3 md:py-0">
        <div class="flex items-center">
          <img
            src="../../assets/images/fit.png"
            alt=""
            class="hidden md:block w-24 h-20 rounded-lg"
          />
          <div class="flex gap-x-5">
            <span
              class="font-light md:ml-5 lg:ml-10 flex flex-col md:gap-y-4"
              v-for="notification in notifications"
              :key="notification.id"
            >
              <p class="whitespace-nowrap text-md md:text-xl">
                {{ notification.notice }}
              </p>
              <p class="text-xs md:text-sm">Style for every occassion</p>
              <span class="md:hidden">
                <p
                  @click="showImageFunc"
                  :style="
                    showImage === false ? 'display: block;' : 'display: none;'
                  "
                  class="md:hidden mt-3 text-blue-500 text-xs"
                >
                  view
                </p>
                <p
                  @click="setImageFalse"
                  :style="
                    showImage === true ? 'display: block;' : 'display: none;'
                  "
                  class="md:hidden mt-3 text-red-500 text-xs"
                >
                  close
                </p>
              </span>
            </span>
            <span class="hidden md:block">
              <img
                @click="showImgFunc"
                :style="
                  showImg === false ? 'display: block;' : 'display: none;'
                "
                src="../../assets/images/icons/chevron-down.png"
                alt=""
                class="self-start w-4 h-3 mt-1.5 cursor-pointer"
              />
              <img
                @click="setImgFalse"
                :style="showImg === true ? 'display: block;' : 'display: none;'"
                src="../../assets/images/icons/chevron-up.png"
                alt=""
                class="self-start w-4 h-3 mt-1.5 cursor-pointer"
              />
            </span>
          </div>
        </div>
        <img
          src="../../assets/images/icons/close.png"
          alt="close"
          class="w-7 h-7 self-center cursor-pointer"
        />
      </div>
      <img
        :style="
          showImage === true || showImg === true
            ? 'display: block;'
            : 'display: none;'
        "
        src="../../assets/images/fit.png"
        alt="big img"
        class="w-96 h-36 rounded-lg md:mt-4"
      />
    </div> -->
  </section>
</template>

<style></style>
