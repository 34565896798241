<script lang="ts">
import { defineComponent } from 'vue'

export default defineComponent({
    name: 'SecondHeader',
    props: {
        main_header: String,
        product_gender: String,
        bold_link: String,
        id: String,
    },
})
</script>

<template>
    <nav class="second-header-nav">
        <div class="header-nav-main-text-container">
            <span class="main-text font-class">{{ main_header }}</span>
        </div>
        <div class="header-nav-pages-link">
            <div class="home">
                <router-link class="router-link" to="/">
                    <span class="home-link-text font-class">Home</span>
                </router-link>
            </div>
            <div class="arrow-container">
                <box-icon name='chevron-right'></box-icon>
            </div>
            <div class="product-gender-container">
                <!--(this is correct way to link with a slug in vue (for backend devs ;) )) to = /product-category/{{ product_gender }}-->
                <router-link class="router-link" to="/categories">
                    <span :class="{ 'bold': bold_link == 'categories', 'light': bold_link != 'categories' }" class="font-class">Categories</span>
                </router-link>
            </div>
            <div class="arrow-container">
                <box-icon name='chevron-right'></box-icon>
            </div>
            <div class="product-page-container">
                <!--(this is correct way to link with a slug in vue (for backend devs ;) )) to = /product/{{ product }}    " + id + '/'"-->
                <router-link class="router-link" to="/products/previous/">
                    <span :class="{ 'bold': bold_link == 'productpage', 'light': bold_link != 'productpage' }" class= "font-class">Product Page</span>
                </router-link>
            </div>
            <div class="arrow-container">
                <box-icon name='chevron-right'></box-icon>
            </div>
            <div class="shopping-cart-container">
                <router-link class="router-link" to="/cart">
                    <span :class="{ 'bold': bold_link == 'cart', 'light': bold_link != 'cart' }" class="font-class">Shopping Cart</span>
                </router-link>
            </div>
            <div class="arrow-container">
                <box-icon name='chevron-right'></box-icon>
            </div>
            <div class="favorite-page-container">
                <router-link class="router-link" to="/favourites">
                    <span :class="{ 'bold': bold_link == 'favorite', 'light': bold_link != 'favorite' }" class="font-class">Favorite Page</span>
                </router-link>
            </div>
        </div>
    </nav>
</template>

<style lang="scss" scoped>
.bold {
    font-weight: 500;
}
.light {
    font-weight: 300;
}
.font-class {
    font-family: 'DM Sans', sans-serif;
    font-style: normal;
}
.second-header-nav {
    display: flex;
    margin-left: 80px;
    margin-right: 100px;
    margin-bottom: 2.5rem;
    justify-content: space-between;
    align-items: center;
    border: 0;
    border-bottom: 1px solid rgba(15, 23, 42, 0.1);
    padding-bottom: 0.25rem;
    .header-nav-main-text-container {
        display: flex;
        height: auto;
        span {
            font-size: 32px;
            font-weight: 500;
            line-height:41.66px;
        }
    }
    .header-nav-pages-link {
        display: flex;
        align-items: center;
        div {
            .router-link {
                text-decoration: none;
                color: #0F172A;
                span {
                    font-size: 14px;
                    line-height: 18.23px;
                }
            }
        }
        .arrow-container {
            display: flex;
            border: 1px solid #0F172A;
            border-radius: 50%;
            width: 12px;
            height: 12px;
            justify-content: center;
            align-items: center;
            margin-left: 0.5rem;
            margin-right: 0.5rem;
        }
    }
}
@media only screen and (max-width: 940px) {
    .second-header-nav {
        width: 90%;
        margin-right: 40px;
        margin-left: 40px;
        .header-nav-main-text-container {
            span {
                font-size: 20px;
            }
        }
        .header-nav-pages-link {
            display: none;
        }
    }
}
</style>