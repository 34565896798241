import { createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = { class: "fc-product-details-container" }
const _hoisted_2 = { class: "product-details-picture" }
const _hoisted_3 = ["src"]
const _hoisted_4 = { class: "product-details-name-price-container" }
const _hoisted_5 = { class: "product-details-name-text font-class" }
const _hoisted_6 = { class: "product-details-description-text font-class" }
const _hoisted_7 = { class: "product-details-price-text font-class" }
const _hoisted_8 = { class: "product-details-price-container" }
const _hoisted_9 = { class: "product-details-price-text font-class" }
const _hoisted_10 = {
  key: 0,
  class: "remove-button-container"
}
const _hoisted_11 = ["disabled"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_router_link = _resolveComponent("router-link")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_router_link, {
      class: "photo",
      to: '/products/' + _ctx.slug + '/'
    }, {
      default: _withCtx(() => [
        _createElementVNode("div", _hoisted_2, [
          _createElementVNode("img", {
            src: _ctx.photo,
            loading: "lazy"
          }, null, 8, _hoisted_3)
        ])
      ]),
      _: 1
    }, 8, ["to"]),
    _createVNode(_component_router_link, {
      class: "flex-grow",
      to: '/products/' + _ctx.slug + '/'
    }, {
      default: _withCtx(() => [
        _createElementVNode("div", _hoisted_4, [
          _createElementVNode("span", _hoisted_5, _toDisplayString(_ctx.product_name), 1),
          _createElementVNode("span", _hoisted_6, _toDisplayString(_ctx.description), 1),
          _createElementVNode("span", _hoisted_7, "₦" + _toDisplayString(_ctx.price), 1)
        ])
      ]),
      _: 1
    }, 8, ["to"]),
    _createVNode(_component_router_link, {
      class: "flex-grow price",
      to: '/products/' + _ctx.slug + '/'
    }, {
      default: _withCtx(() => [
        _createElementVNode("div", _hoisted_8, [
          _createElementVNode("span", _hoisted_9, "₦" + _toDisplayString(_ctx.price), 1)
        ])
      ]),
      _: 1
    }, 8, ["to"]),
    (_ctx.userStatus === true)
      ? (_openBlock(), _createElementBlock("div", _hoisted_10, [
          _createElementVNode("button", {
            disabled: _ctx.buttonDisabled,
            onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.addToCart && _ctx.addToCart(...args))),
            class: "remove-button"
          }, [
            _createElementVNode("span", null, _toDisplayString(_ctx.cartbtn), 1)
          ], 8, _hoisted_11)
        ]))
      : _createCommentVNode("", true)
  ]))
}