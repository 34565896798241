<template>
  <main class="home mt-20">
    <ScrollableSection class="pt-10">
      <ScrollableSectionCard
        v-for="(item, i) in renderedRandomItems"
        :key="i"
        :id="item.id"
        :favorite_id="item.favorite_id"
        :name="item.name"
        :price="item.price"
        :new_price="item.new_price"
        :image="item.image"
        :is_favourite="item.is_favourite"
        :category="item.category"
        :sub_category="item.sub_category"
        :section="item.section"
        :slug="item.slug"
        class="bg-gray-50/90 rounded-3xl overflow-clip"
      >
      </ScrollableSectionCard> 
      <div
        v-if="
          renderedRandomItems.length === 0 && !productStore.requestingAllItems
        "
        class="sm:col-span-3 w-full h-full bg-gray-50 flex items-center justify-center text-2xl font-medium text-gray-300"
      >
        No item in here yet
      </div>
      <div
        v-if="productStore.requestingAllItems"
        class="sm:col-span-3 h-full w-full flex items-center justify-center flex-col text-2xl gap-3 font-medium text-gray-300"
      >
        <span> Fetching items... </span>
        <Spinner class="w-8 text-gray-500" />
      </div>
    </ScrollableSection>

    <!-- categories -->
    <section class="categories mt-0">
      <div id="cats">
        <h1>Categories</h1>
        <p>
          At FondStore, our diverse collection and categories caters to every
          style and preference, ensuring that there's something extraordinary
          waiting for everyone.
        </p>
      </div>
      <CategoriesSection :categories="productStore.categories" />
    </section>

    <!-- app -->
    <!-- <section class="phone !my-0">
      <h1>Our app will be availabe for download soon!</h1>
      <div class="img">
        <img src="../assets/images/phone.png" alt="" class="bg" />
        <img src="../assets/images/main.png" alt="" />
      </div>
      <div>
        <span id="butt">
          <img src="../assets/images/icons/appstore.png" alt="" />
          <p>Coming soon</p>
        </span>
        <span id="butt">
          <img src="../assets/images/icons/playstore.png" alt="" />
          <p>Coming soon</p>
        </span>
      </div>
    </section> -->

    <!-- best deals -->
    <section class="deals mt-5">
      <div id="deals">
        <h1>Best Deals</h1>
        <p>
          Step into a world where style, accessories and gadgets are celebrated
          and individuality shines.
        </p>
      </div>

      <ScrollableSection class="pb-10">
        <ScrollableSectionCard
          v-for="(item, i) in bestDeals"
          :key="i"
          :id="item.id"
          :favorite_id="item.favorite_id"
          :name="item.name"
          :price="item.price"
          :new_price="item.new_price"
          :image="item.image"
          :is_favourite="item.is_favourite"
          :category="item.category"
          :sub_category="item.sub_category"
        :slug="item.slug"
          :section="item.section"
          class="bg-gray-50/90 rounded-3xl overflow-clip"
        >
        </ScrollableSectionCard>
        <div
          v-if="bestDeals.length === 0 && !homeStore.requestingMixedItem"
          class="sm:col-span-3 w-full h-full bg-gray-50 flex items-center justify-center text-2xl font-medium text-gray-300"
        >
          No item in here yet
        </div>
        <div
          v-if="homeStore.requestingMixedItem"
          class="sm:col-span-3 h-full w-full flex items-center justify-center flex-col text-2xl gap-3 font-medium text-gray-300"
        >
          <span> Fetching items... </span>
          <Spinner class="w-8 text-gray-500" />
        </div>
      </ScrollableSection>
    </section>

    <!-- trending -->
    <section class="trending mt-5">
      <div id="trending">
        <h1>Trending</h1>
        <p>
          Trending Now, Experience a sustainable shopping in cloths, gadgets and
          accessories that are tailored to you.
        </p>
      </div>
      <div class="flex justify-center px-10">
        <Tabs
          :tab_list="homeStore.trendingTabList"
          :value="homeStore.activeTrendingTab"
          query_name="trending"
          @model-value="($event) => (homeStore.activeTrendingTab = $event)"
          class="inline-flex gap-x-1 sm:gap-x-2 justify-between select-none rounded-[100px] [&>*]:flex-grow [&>*]:max-w-[50%] text-base border border-gray-400 max-w-[90vw] overflow-x-auto hide-scrollbar"
        >
          <template v-slot:tab="{ tab, index: i, is_active }">
            <div
              :class="[
                is_active
                  ? 'bg-primary text-white'
                  : 'bg-transparent hover:text-primary',
              ]"
              class="p-3 transition duration-300 font-medium cursor-pointer rounded-[100px] min-w-max"
            >
              <span class="flex flex-col items-center">
                <span>{{ tab?.name }}</span>
              </span>
            </div>
          </template>
        </Tabs>
      </div>

      <ScrollableSection class="pb-10">
        <ScrollableSectionCard
          v-for="(item, i) in renderedTrendingItems.results"
          :key="i"
          :id="item.id"
          :favorite_id="item.favorite_id"
          :name="item.name"
          :price="item.price"
          :new_price="item.new_price"
          :image="item.image"
          :is_favourite="item.is_favourite"
          :category="item.category"
          :sub_category="item.sub_category"
          :section="item.section"
        :slug="item.slug"
          class="bg-gray-50/90 rounded-3xl overflow-clip"
        >
        </ScrollableSectionCard>
        <div
          v-if="
            Array.isArray(renderedTrendingItems.results) &&
            renderedTrendingItems.results.length === 0 &&
            !homeStore.requestingTrending
          "
          class="sm:col-span-3 w-full h-full bg-gray-50 flex items-center justify-center text-2xl font-medium text-gray-300"
        >
          <!-- <div
          v-if="
            renderedTrendingItems.results === 0 && !homeStore.requestingTrending
          "
          class="w-full h-full bg-gray-50 flex items-center justify-center text-2xl font-medium text-gray-300"
        > -->
          No item in here yet
        </div>
        <div
          v-if="homeStore.requestingTrending"
          class="sm:col-span-3 h-full w-full flex items-center justify-center flex-col text-2xl gap-3 font-medium text-gray-300"
        >
          <span> Fetching items... </span>
          <Spinner class="w-8 text-gray-500" />
        </div>
      </ScrollableSection>

      <div class="flex justify-center px-10">
        <Tabs
          :tab_list="mixedTabList"
          :value="activeMixedTab"
          query_name="mixed"
          @model-value="($event) => (activeMixedTab = $event)"
          class="inline-flex gap-x-1 sm:gap-x-2 justify-between select-none rounded-[100px] [&>*]:flex-grow [&>*]:max-w-[50%] text-base border border-gray-400 max-w-[90vw] overflow-x-auto hide-scrollbar"
        >
          <template v-slot:tab="{ tab, index: i, is_active }">
            <div
              :class="[
                is_active
                  ? 'bg-primary text-white'
                  : 'bg-transparent hover:text-primary',
                (tab?.name === 'Favourites' ? 'mx-4' : 'mx-5'),
              ]"
              class="p-3 transition duration-300font-medium cursor-pointer rounded-[100px] min-w-max"
            >
              <span class="flex flex-col items-center">
                <span>{{ tab?.name }}</span>
              </span>
            </div>
          </template>
        </Tabs>
      </div>
      <p id="opt2">
        Discover the uncompromised quality at fondstore that are affordable with
        elegance to express yourself.
      </p>
    </section>

    <ScrollableSection class="pb-10">
       <ScrollableSectionCard
          v-for="(item, i) in renderedMixedItems"
          :key="i"
          :id="activeMixedTab === 'new_arrivals' ? item.item.id : activeMixedTab === 'recently_viewed' ? item.results.id : item.id"
          :favorite_id="activeMixedTab === 'new_arrivals' ? item.item.favorite_id : activeMixedTab === 'recently_viewed' ? item.results.favourite_id : item.favorite_id"
          :name="activeMixedTab === 'new_arrivals' ? item.item.name  : activeMixedTab === 'recently_viewed' ? item.results.name : item.name"
          :price="activeMixedTab === 'new_arrivals' ? item.item.price  : activeMixedTab === 'recently_viewed' ? item.results.price : item.price"
          :new_price="activeMixedTab === 'new_arrivals' ? item.item.new_price  : activeMixedTab === 'recently_viewed' ? item.results.new_price : item.new_price"
          :image="activeMixedTab === 'new_arrivals' ? item.item.image  : activeMixedTab === 'recently_viewed' ? item.results.image : item.image"
          :is_favourite="activeMixedTab === 'new_arrivals' ? item.item.is_favourite  : activeMixedTab === 'recently_viewed' ? item.results.is_favourite : item.is_favourite"
          :slug="activeMixedTab === 'new_arrivals' ? item.item.slug  : activeMixedTab === 'recently_viewed' ? item.results.slug : item.slug"
          class="bg-gray-50/90 rounded-3xl overflow-clip"
        >
        </ScrollableSectionCard>
      <div
        v-if="renderedMixedItems.length === 0 && !homeStore.requestingMixedItem"
        class="sm:col-span-3 w-full h-full bg-gray-50 flex items-center justify-center flex-col text-2xl font-medium text-gray-300 text-center"
      >
        <span>
          {{
            userStore.getUser.token
              ? "No item in here yet"
              : `Sign in to see your ${String(activeMixedTab).replace(
                  /-/g,
                  " "
                )} items`
          }}
        </span>
        <router-link
          v-if="!userStore.getUser.token"
          to="/signin"
          class="text-gray-500"
          >Sign in</router-link
        >
      </div>
      <div
        v-if="homeStore.requestingMixedItem"
        class="sm:col-span-3 h-full w-full flex items-center justify-center flex-col text-2xl gap-3 font-medium text-gray-300"
      >
        <span> Fetching items... </span>
        <Spinner class="w-8 text-gray-500" />
      </div>
    </ScrollableSection>
  </main>
</template>

<script lang="ts" setup>
import { onMounted, computed, ref } from "vue";
import Carousel from "@/components/Carousel.vue";
import CategoriesSection from "@/components/CategoriesSection.vue";
import Footer from "@/components/Footer.vue";
import Header from "@/components/Header.vue";
import BestOf from "@/components/HomeViewComponents/BestOfTheDay.vue";
import ProductGallery from "@/components/HomeViewComponents/ProductsGallery.vue";
import ScrollableSection from "@/components/ScrollableSection.vue";
import ScrollableSectionCard from "@/components/ScrollableSectionCard.vue";
import Spinner from "@/components/Spinner.vue";
import Tabs from "@/components/Tabs.vue";
import { toggleLoading } from "@/event-bus";
import { SectionTabs } from "@/router/types";
import { useHomeStore } from "@/store/home";
import { useProductStore } from "@/store/product";
import { useUserStore } from "@/store/user";

// Store setup
const homeStore = useHomeStore();
const userStore = useUserStore();
const productStore = useProductStore();

// Data properties
const products = ref([]); // Product[]
const selectedCategoryProducts = ref([]); // Product[]
const product = ref([]); // Category[]
const photo = ref("@/assets/images/product-photos/Rectangle407item.png");
const selected = ref({});
const selectedSection = ref("");
const currentIndex = ref(0);
const translateValue = ref(0);
const autoScrollInterval = ref(0); // as NodeJS.Timeout | null

// mixed
const activeMixedTab = ref<string | undefined>(undefined);
const mixedTabList = ref<SectionTabs[]>([
  {
    name: "Popular",
    slug: "popular-items",
    requiresAuth: false,
  },
  {
    name: "Favourites",
    slug: "favourites",
    requiresAuth: true,
  },
  {
    name: "New Arrivals",
    slug: "new_arrivals",
    requiresAuth: false,
  },
  {
    name: "Recently viewed",
    slug: "recently_viewed",
    requiresAuth: true,
  },
]);

// Mounted lifecycle hook
onMounted(() => {
  document.title = "Fondstore";
  toggleLoading(false);

  Promise.all([
    productStore.categories.length === 0 && productStore.fetchCategories(),
    homeStore.initTrending(),
    mixedTabList.value.map((tab) =>
      homeStore.fetchMixedItems({
        endpointName: tab.slug.replace("-", "_"),
        requiresAuth: Boolean(tab.requiresAuth),
      })
    ),
    homeStore.fetchBestDeals(),
    productStore.fetchAllItems(),
    productStore.fetchRandomItems(),
  ]).finally(() => toggleLoading(false));
});

// Computed properties
const renderedTrendingItems = computed(() =>
  homeStore.getTrendingItems(
    String(homeStore.activeTrendingTab).toLowerCase() || "clothes"
  ) || []
);

const renderedMixedItems = computed(() =>
  homeStore.getMixedItems(
    String(
      mixedTabList.value.find((tab) => tab.slug === activeMixedTab.value)?.slug
    ).replace("-", "_") || "popular_items"
  ) || []
);

const bestDeals = computed(() => homeStore.getMixedItems("best_deals") || []);

const renderedRandomItems = computed(() => productStore.getRandomItems());

const requestingAllItems = computed(() => productStore.requestingAllItems);
</script>

<style lang="scss" scoped>
#hero-section {
  padding-top: 5rem;
  width: 100svw;
  display: flex;
  flex-direction: column;

  #hero-section-title {
    border-radius: 1.5rem;
    border: 1px solid rgba(67, 133, 245, 0.4);
    background: rgba(67, 133, 245, 0.08);
    margin-inline: auto;
    display: flex;
    gap: 24px;
    justify-content: center;
    align-items: center;
    padding: 8px 24px;
    color: black;
    margin-top: 1rem;

    p {
      text-align: center;
      font-size: 18px;
    }

    img {
      width: 1em;
    }
  }

  #hero-section-carousel {
    width: 100%;
    height: 50%;
    padding-top: 118px;
    display: flex;
    overflow-x: auto;
    scrollbar-width: none;
    -ms-overflow-style: none;
    img {
      height: 22.375rem;
      width: 21.5rem;
      animation: scrollImages 200s linear infinite;
    }

    @keyframes scrollImages {
      0% {
        transform: translateX(0%);
      }
      100% {
        transform: translateX(-8.9999%);
      }
    }
  }
  #hero-section-carousel::-webkit-scrollbar {
    display: none;
  }

  .image-container {
    width: 100%;
    overflow: hidden;
    position: relative;
    .images {
      display: flex;
      transition: transform 0.3s ease-in-out;
      .image {
        flex: 0 0 auto;
        width: 200px; /* Set the width of each image in pixels */
        margin-right: 20px; /* Adjust as needed for spacing between images */
      }
      .image img {
        max-width: 100%;
        height: auto;
      }
    }
  }
}

.services {
  display: flex;
  flex-direction: row;
  column-gap: 3.5em;
  justify-content: center;
  align-items: center;
  padding-top: 3.5em;

  div {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    column-gap: 1em;
  }

  img {
    width: 1.5em;
    height: 1.5em;
  }

  #title {
    font-size: 12px;
    font-weight: 800;
    text-transform: capitalize;
    text-align: start;
  }

  #desc {
    font-size: 10px;
    text-transform: capitalize;
    text-align: start;
  }
}

.servicess {
  display: flex;
  flex-direction: row;
  column-gap: 3.5em;
  justify-content: center;
  align-items: center;
  padding-top: 2.5em;
  padding-bottom: 3.5em;

  div {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    column-gap: 1em;
  }

  img {
    width: 1.5em;
    height: 1.5em;
  }

  #title {
    font-size: 12px;
    font-weight: 800;
    text-transform: capitalize;
    text-align: start;
  }

  #desc {
    font-size: 10px;
    text-transform: capitalize;
    text-align: start;
  }
}

.categories {
  width: 62em;
  // margin-inline: auto;

  #cats h1 {
    text-align: center;
    font-weight: 800;
    font-size: 30px;
  }

  #cats p {
    padding-block: 1.5em;
    padding-inline: 2em;
    text-align: center;
    font-size: 15px;
  }

  .cats {
    position: relative;
    display: grid;
    grid-template-rows: 2;
    grid-template-columns: 2;
    // gap: 1em;

    p {
      bottom: 1em;
      left: 50%;
      transform: translate(-50%, -50%);
      color: white;
      font-size: 1em;
      position: absolute;
    }

    .cat1,
    .cat2,
    .cat3 {
      position: relative;
      background-size: cover;
      background-position: center;
      background-repeat: no-repeat;
      margin-inline: auto;
      border-radius: 10px;
      // width: max-content;
      // height: max-content;
    }
  }
}

.phone {
  margin-block: 6em;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  h1 {
    text-align: center;
    margin-bottom: 2em;
    font-size: 16px;
    font-weight: 400;
    color: #4385f5;
  }

  .img {
    position: relative;
  }

  img {
    margin-inline: auto;
  }

  .bg {
    position: absolute;
    left: 1.8rem;
    top: 0.5rem;
  }

  div {
    display: flex;
    // flex-direction: column;
    column-gap: 4em;
    margin-top: 3em;

    #butt {
      padding-block: 10px;
      padding-inline: 20px;
      display: flex;
      align-items: center;
      column-gap: 14px;
      border: 1px solid #4385f5;
      color: #4385f5;
      cursor: pointer;
      border-radius: 30px;
      width: fit-content;

      img {
        width: 1.5em;
        height: 1.5em;
      }

      p {
        margin: 0;
      }
    }
  }

  #butt:hover {
    background-color: #4385f5;
    color: white;
  }
}

.deals {
  // margin-top: 3rem;

  #deals {
    width: 41rem;
    margin-inline: auto;
  }

  #deals h1 {
    text-align: center;
    font-weight: 800;
    font-size: 30px;
  }

  #deals p {
    padding-block: 1.5em;
    padding-inline: 2em;
    text-align: center;
    font-size: 15px;
  }
}
.trending {
  // margin-top: 3rem;

  #trending {
    width: 41rem;
    margin-inline: auto;
  }

  #trending h1 {
    text-align: center;
    font-weight: 800;
    font-size: 30px;
  }

  #trending p {
    padding-block: 1.5em;
    padding-inline: 2em;
    text-align: center;
    font-size: 15px;
  }

  #opt {
    margin-top: 5rem;
    margin-inline: auto;
    display: flex;
    width: fit-content;
    height: fit-content;
    // height: 3.5rem;
    justify-content: center;
    align-items: center;
    flex-shrink: 0;
    border-radius: 3.875rem;
    border: 1px solid var(--black-30, rgba(15, 23, 42, 0.3));

    span {
      display: flex;
      width: 12.75rem;
      height: 3.5rem;
      justify-content: center;
      align-items: center;
      flex-shrink: 0;

      p {
        font-size: 16px;
      }
    }

    span:hover {
      color: white;
      border-radius: 3.3125rem;
      background: var(--Button-Default, #4385f5);
    }
  }

  #opt2 {
    text-align: center;
    font-size: 16px;
    margin-block: 2rem;
    margin-inline: auto;
    width: 41rem;
  }
}

@media only screen and (max-width: 940px) {
  #hero-section {
    padding-top: 7rem;
    #hero-section-title {
      padding: 8px 14px;
      p {
        font-size: 12px;
      }
    }
    #hero-section-carousel {
      padding-top: 83px;
      gap: 16px;
      overflow-x: hidden;
      img {
        width: 172px;
        height: 179px;
      }
    }
  }

  .services {
    display: flex;
    flex-direction: column;
    row-gap: 2.5em;
    justify-content: center;
    align-items: center;
    padding-top: 3.5em;

    div {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      row-gap: 0.1em;
    }

    img {
      width: 1.5em;
      height: 1.5em;
      margin-bottom: 0.4em;
    }

    #title {
      font-size: 10px;
      font-weight: 800;
      text-transform: capitalize;
      text-align: center;
    }

    #desc {
      font-size: 8px;
      text-transform: capitalize;
      text-align: center;
    }
  }

  .servicess {
    display: flex;
    flex-direction: column;
    row-gap: 2.5em;
    justify-content: center;
    align-items: center;

    padding-bottom: 3.5em;

    div {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      row-gap: 0.1em;
    }

    img {
      width: 1.5em;
      height: 1.5em;
      margin-bottom: 0.4em;
    }

    #title {
      font-size: 10px;
      font-weight: 800;
      text-transform: capitalize;
      text-align: center;
    }

    #desc {
      font-size: 8px;
      text-transform: capitalize;
      text-align: center;
    }
  }

  .categories {
    width: 20em;
    #cats h1 {
      text-align: center;
      font-weight: 800;
      font-size: 20px;
    }

    #cats p {
      padding-block: 1.5em;
      padding-inline: 2em;
      text-align: center;
      font-size: 10px;
    }
    .cats {
      display: flex;
      flex-direction: column;
      padding-inline: 10px;

      .cat1,
      .cat2,
      .cat3 {
        width: 18rem;
        height: 19rem;
      }

      div {
        border-radius: 10px;
        max-width: 20em;
        max-height: 20em;
      }
    }
  }

  .phone {
    // margin-block: 6em;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    h1 {
      text-align: center;
      margin-bottom: 2em;
      font-size: 16px;
      font-weight: 400;
      color: #4385f5;
    }

    img {
      margin-inline: auto;
    }

    div {
      display: flex;
      flex-direction: column;
      row-gap: 1em;
      margin-top: 3em;

      #butt {
        padding-block: 10px;
        padding-inline: 20px;
        display: flex;
        align-items: center;
        column-gap: 14px;
        border: 1px solid #4385f5;
        color: #4385f5;
        cursor: pointer;
        border-radius: 30px;
        width: fit-content;

        img {
          width: 1.5em;
          height: 1.5em;
        }

        p {
          margin: 0;
        }
      }
    }

    #butt:hover {
      background-color: #4385f5;
      color: white;
    }
  }

  .deals {
    #deals {
      width: 20em;

      h1 {
        text-align: center;
        font-weight: 700;
        font-size: 17px;
      }

      p {
        padding: 1.5em;
        text-align: center;
        font-size: 12px;
      }
    }
  }

  .trending {
    #trending {
      width: 20em;

      h1 {
        text-align: center;
        font-weight: 700;
        font-size: 17px;
      }

      p {
        padding: 1.5em;
        text-align: center;
        font-size: 12px;
      }
    }

    #opt {
      display: flex;
      width: fit-content;
      justify-content: center;
      align-items: center;

      span {
        display: flex;
        width: fit-content;
        padding: 0.8rem;
        justify-content: center;
        align-items: center;
        flex-shrink: 0;

        p {
          font-size: 14px;
        }
      }

      span:hover {
        color: white;
        border-radius: 3.3125rem;
        background: var(--Button-Default, #4385f5);
      }
    }

    #opt2 {
      text-align: center;
      font-size: 14px;
      width: 20rem;
    }
  }
}
</style>
