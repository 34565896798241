import { createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "fc-product-details-container" }
const _hoisted_2 = { class: "product-details-picture" }
const _hoisted_3 = ["src"]
const _hoisted_4 = { class: "product-details-name-price-container" }
const _hoisted_5 = { class: "product-details-name-text font-class" }
const _hoisted_6 = { class: "product-details-price-text font-class" }
const _hoisted_7 = { class: "product-details-order-number-container" }
const _hoisted_8 = { class: "order-number-text-container" }
const _hoisted_9 = { class: "order-number-text font-class" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("img", {
        src: _ctx.photo,
        loading: "lazy"
      }, null, 8, _hoisted_3)
    ]),
    _createElementVNode("div", _hoisted_4, [
      _createElementVNode("span", _hoisted_5, _toDisplayString(_ctx.product_name), 1),
      _createElementVNode("span", _hoisted_6, "₦" + _toDisplayString(_ctx.price), 1)
    ]),
    _createElementVNode("div", _hoisted_7, [
      _createElementVNode("div", _hoisted_8, [
        _createElementVNode("span", _hoisted_9, _toDisplayString(_ctx.order_number), 1)
      ])
    ])
  ]))
}