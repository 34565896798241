<script lang="ts">
import { defineComponent } from 'vue'
import axios from 'axios';
import { useRoute } from 'vue-router';

export default defineComponent({
    name: 'DisplayProducts',
    data() {
        return {
            quantity: 1,
            cartbtn: 'Add to cart',
            buttonDisabled: false,
            menu_choice: '',
            userStatus: false,
            state: '',
        }
    },
    props: {
        product_name: String,
        price: Number,
        product_id: String,
        photo: String,
        description: String,
        slug: String,
    },
    methods: {
        getMenuChoice() {
            const route = useRoute();
            const routerChoice = route?.name;

            if (routerChoice == 'Search') {
                this.menu_choice = 'Search'
            }
        },
        addToCart() {
        const token = localStorage.getItem('AuthToken');
        
        this.cartbtn = 'Adding to cart...'
        this.buttonDisabled = true;

        axios.post('/api/carts/add_to_cart/', {product_id: this.product_id, quantity: this.quantity},
        {
          headers: {
                    Authorization: `JWT ${token}`,
                },
        }
        )
        .then(response => {
          console.log('Product added to cart:', response.data);
          this.cartbtn = 'Added'
          this.buttonDisabled = false;
        })
        .catch(error => {
          console.error('Error adding product to cart:', error);
          this.cartbtn = 'Buy now'
          this.buttonDisabled = false;
        });
    },
    checkUserStatus() {
        const token = localStorage.getItem('AuthToken');

        if (token) {
            this.userStatus = true;
        }
    },
    },
    watch: {
        $route(to: { name: string; }, from: any) {
            if (to.name === 'Search') {
                this.menu_choice = 'Search'
            }
        }
    },
    created() {
        this.getMenuChoice();
        this.checkUserStatus();
    },
})
</script>

<template>
    <div class="fc-product-details-container">
        <router-link class="photo" :to="'/products/' + slug + '/'">
            <div class="product-details-picture">
                <img :src="photo" loading="lazy">
            </div>
        </router-link>
        <router-link class="flex-grow" :to="'/products/' + slug + '/'">
            <div class="product-details-name-price-container">
                <span class="product-details-name-text font-class">{{ product_name }}</span>
                <span class="product-details-description-text font-class">{{ description }}</span>
                <span class="product-details-price-text font-class">₦{{ price }}</span>
            </div>
        </router-link>
        <router-link class="flex-grow price" :to="'/products/' + slug + '/'">
            <div class="product-details-price-container">
                <span class="product-details-price-text font-class">₦{{ price }}</span>
            </div>
        </router-link>
        <div v-if="userStatus === true" class="remove-button-container" >
            <button :disabled="buttonDisabled" @click="addToCart" class="remove-button">
                <span>{{cartbtn}}</span>
            </button>
        </div>
    </div>
</template>

<style lang="scss" scoped>
.font-class {
    font-family: 'DM Sans', sans-serif;
    font-style: normal;
}
.fc-product-details-container {
    display: flex;
    width: 100%;
    align-items: center;
    margin-bottom: 1.5rem;
    a.photo {
        text-decoration: none;
        .product-details-picture {
        display: flex;
        border-radius: 4px;
        width: 6.6875rem;
        height: 6.9376rem;
        img {
            width: 100%;
            height: 100%;
            max-height: 100%;
            max-width: 100%;
            border-radius: 4px;
        }
    }
    }
    a.flex-grow {
        text-decoration: none;
        color: inherit;
        flex-grow: 1;
        cursor: default;
        .product-details-name-price-container {
        height: 60%;
        margin-left: 0.5rem;
        display: flex;
        flex-direction: column;
        gap: 6px;
        max-width: 50svw;
        
        .product-details-name-text {
            width: fit-content;
            font-size: 20px;
            font-weight: 500;
        }
        .product-details-description-text {
            font-size: 11px;
            font-weight: 300;
            max-width: 45%;
        }
        .product-details-price-text {
            display: none;
        }
    }
    }
    a.flex-grow.price {
        text-decoration: none;
        flex-grow: 2;
        cursor: default;
        color: inherit;
        .product-details-price-container {    
        display: flex;
        text-align: center;
        .product-details-price-text {
            font-size: 16px;
            font-weight: 500;
        }
        span {
            font-size: 18px;
            font-weight: 500;
            line-height:26.04px;
        }
    }
    }
    .remove-button-container {
        display: flex;
        margin-right: 0.5rem;
        justify-content: center;
        align-items: center;
        button {
            display: flex;
            justify-content: center;
            align-items: center;
            background: transparent;
            width: 100%;
            height: 100%;
            span {
                color: var(--button-default-70, rgba(67, 133, 245, 0.70));
                font-family: DM Sans;
                font-size: 18px;
                font-style: normal;
                font-weight: 500;
                line-height: normal; 
            }
        }
    }
}

@media only screen and (max-width: 940px) {
.fc-product-details-container {
    a.photo {
        width: 2.7rem;
        height: 5.9rem;
        .product-details-picture {
            width: 100%;
            height: 100%;
            img {
                object-fit: cover;
            }
        }
    }
    .photo, .remove-button-container {
        flex-grow: 2;
    }
    a.flex-grow {
        flex-grow: 4;
        .product-details-name-price-container {
            .product-details-name-text {
                font-size: 18px;
            }
            .product-details-description-text {
                display: none;
            }
            .product-details-price-text {
                display: block;
                font-size: 14px;
                font-weight: 300;
            }
        }
    }
    a.flex-grow.price {
        display: none;
    }
    .remove-button-container {
        button {
            span {
                font-size: 16px;
            }
        }
    }
}
}
</style>