<script lang="ts">
import { defineComponent} from 'vue'
import { useRoute } from 'vue-router';
import axios from 'axios';
import { Product, Section, Category } from '../router/types';
import FilterComponentVue from '../components/ProductCategoryComponent/FilterComponent.vue';
import MainHeaderVue from '../components/ProductCategoryComponent/MainHeader.vue';
import ItemComponentVue from '../components/ProductCategoryComponent/ItemComponent.vue'
import { toggleLoading } from '@/event-bus';

export default defineComponent({
    name: 'product.vue',
    components: {
        MainHeaderVue,
        FilterComponentVue,
        ItemComponentVue,
    },
    data() {
        return {
            list_header: [] as string[],
            page_choice: '' as string,
            products: [] as Product[],
            selectedItem: '',
            sections: [] as Section[],
            body_description: 'No description, returned yet' as string,
        }
    },
    mounted() {
//        this.fetchProducts();
//        this.fetchSpecificProducts();
//        this.selectedItem = this.list_header[0];
//        console.log(this.list_header[0])
//        console.log(this.selectedItem)
        document.title = `${this.page_choice} | Fondstore`
    },
    computed: {
        selectedSectiona() {
            return this.$route.params.section;
        },
    },
    methods: {
        getNameAndList() {
            toggleLoading(true);
            let selectedSection: string;

            if (Array.isArray(this.$route.params.section)) {
                selectedSection = this.$route.params.section.join(',');
            } else {
                selectedSection = this.$route.params.section;
            }
            this.page_choice = selectedSection.charAt(0).toUpperCase() + selectedSection.slice(1);

            axios.get('/api/sections/')
            .then((response) => {
                this.sections = response.data;
                toggleLoading(false);
//                console.log('Successful!')

                if (Array.isArray(this.sections)) {
//                    console.log('this.sections is an array')

                    const selectedSec = this.sections.find(
                        (section) => section.name.toLowerCase() === this.selectedSectiona
                    );
                    this.body_description = selectedSec ? selectedSec.body : 'No description, this.body';
                    if (selectedSec) {
                        this.list_header = selectedSec.categories.map(
                            (category: {
                                name: string
                            }) => category.name
                        );
                    } else {
                        this.list_header = [];
                    }
                    this.selectedItem = this.list_header[0];
                    this.fetchSpecificProducts()
// for testing                    this.fetchProducts()
                } else {
                    console.log('Chai, this.sections is not an array')
                }
            })
            .catch((error) => {
                toggleLoading(false);
                console.error('Error fetching sections:', error);
            });
        },
        fetchProducts() {
            toggleLoading(true);
            axios
                .get('/api/products/')
                .then((response: { data: any; }) => {
                    this.products = response.data;
                    toggleLoading(false);
                })
                .catch((error: any) => {
                    toggleLoading(false);
                    console.log('Error displaying products:', error);
                })
        },
        setSelectedItem(item: string) {        
            this.selectedItem = item;
        },
        fetchSpecificProducts() {
            toggleLoading(true);
            axios
            .get(`/api/categories/`)
            .then((response: { data: any; }) => {
                toggleLoading(false);
                this.products = response.data.find((item: Product) => item.name === `${this.selectedItem}`).products;
            })
            .catch((error: any) => {
                toggleLoading(false);
                console.log('Error displaying products:', error);
            })
        }
    },
    created() {
        this.getNameAndList()
    },
    watch: {
        '$route.params.section'(newSection) {
            toggleLoading(true);
            let reNewSection = newSection.toLowerCase();

            toggleLoading(false);

            this.page_choice = newSection.charAt(0).toUpperCase() + newSection.slice(1);
            const selectedSec = this.sections.find(
                (section) => section.name.toLowerCase() === reNewSection
            );
//            console.log(this.page_choice);
            toggleLoading(false);
            document.title = `${this.page_choice} | Fondstore`
            if (selectedSec) {
            this.list_header = selectedSec.categories.map(
                (category: {
                    name: string
                }) => category.name
            );
            } else {
                this.list_header = [];
            }
            toggleLoading(false);
            this.body_description = selectedSec ? selectedSec.body : 'No description, watch';
            this.selectedItem = this.list_header[0];
            this.fetchSpecificProducts()
        },
    },
})
</script>


<template>
    <div class="main-product-category-container">

        <MainHeaderVue
        :main_selected_header="page_choice"
        :main_subtext_header="body_description"
        :main_list_header="list_header"
        :selectedItem="selectedItem"
        @item-selected="setSelectedItem"
        @click="fetchSpecificProducts" />

        <FilterComponentVue />
        <div class="pc-item-component-container">
            <ItemComponentVue
            v-for="product in products"
            :key="product.id"
            :id="product.id"
            :item_name="product.name"
            :price="product.price"
            :slug="product.slug"
            :picture_url="product.image"
            :liked_to_favorite="product.is_favorite"
            />
        </div>
    </div>
</template>


<style lang="scss">
@import url('https://fonts.googleapis.com/css2?family=DM+Sans:opsz,wght@9..40,300;9..40,400;9..40,500;9..40,600;9..40,700;9..40,800;9..40,900&display=swap');
.main-product-category-container {
//    border: 1px solid;
    margin-bottom: 5rem;
    .pc-item-component-container {
        display: grid;
        grid-template-columns: repeat(3, 1fr);
        grid-template-rows: repeat(auto-fill, auto);
        grid-row-gap: 1rem;
        grid-column-gap: 0.5rem;
        margin-left: 70px;
        margin-right: 100px;
    }
}

@media only screen and (max-width: 940px) {
    .main-product-category-container {
        margin: 0px;
        overflow-y: scroll;
        .pc-item-component-container {
            overflow-y: scroll;
            margin: 0px;
            padding: 0px 24px 48px 24px;
            grid-template-columns: repeat(2, 1fr);
            grid-row-gap: 20px;
            grid-column-gap: 20.78px;
            justify-items: space-between;
//            .selected-row { I wanted to use grid-column to target the 2nd column to add the margin top. It did not work. Sigh...found a different way though. :)
//                grid-column: 2;
//                margin-top: 30px;
//            }
        }
        .pc-item-component-container > :nth-child(even) {
            margin-top: 50px;
        }
    }
}
</style>