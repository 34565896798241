<script lang="ts">
import { defineComponent } from 'vue';
import { useRoute } from 'vue-router';
import axios from 'axios';

export default defineComponent({
    name: 'Address',
    data () {
        return {
            settings: true,
            country: '-',
            stateCity: '-',
            postalCode: '-',
            taxID: '-',
            errors: {
                detail: "I'm an error",
            },
            inputDisabled: true,
        }
    },
    props: {
        first_name: String,
        last_name: String,
        email: String,
        phone_number: String,
    },
    methods: {
        formatErrorMessage(messages: string | string[]) {
            if (Array.isArray(messages)) {
                return messages.join(' '); // Join array elements with a space
            }
            return messages; // Return empty string if no error message
        },
        getRoute() {
            const route = useRoute();
            const routerChoice = route?.name;

            if (routerChoice == 'MyProfile') {
                this.settings = false
            } else if (routerChoice == 'Settings') {
                this.settings = true
            }
        },
        async getUserDetails() {
            const token = localStorage.getItem('AuthToken');

            try {
            const responseEmail = await axios.get('/auth/users/me', {
                headers: {
                    Authorization: `JWT ${token}`,
                },
            });     
            const userId = responseEmail.data.id;

                try {
                    const response = await axios.get(`/api/profiles`, {
                        headers: {
                            Authorization: `JWT ${token}`
                        },
                    });
                    this.country = response.data.country;
                    this.stateCity = response.data.stateCity;
                    this.postalCode = response.data.postalCode;
                    this.taxID = response.data.taxID;
                } catch (error) {
                    if (axios.isAxiosError(error)) {

                    if (error.response && error.response.status == 400) {
                        this.errors = error.response.data;
                    } else if (error.response && error.response.status === 401) {
                        this.errors = error.response.data;
                    } else if (error.response && error.response.status === 403) {
                        this.errors = error.response.data;
                    } else if (error.response && error.response.status === 404) {
                        this.errors = error.response.data;
                    }
                    
                    } else {
                    this.errors.detail = 'An error occured. Please try again',
                    console.error('Non-Axios error:', error);
                    }
                }
            } catch (error) {
                           
                console.error('Error fetching user ID:', error);
                if (axios.isAxiosError(error)) {
                    //console.log('Axios error details:');
                    //console.log('Config:', error.config);
                    //console.log('Code:', error.code);
                    //console.log('Request:', error.request);
                    //console.log('Response:', error.response);
                    //alert(error.message);
                    if (error.response && error.response.status == 400) {
                        this.errors = error.response.data;
                    } else if (error.response && error.response.status === 401) {
                        this.errors = error.response.data;
                    } else if (error.response && error.response.status === 403) {
                        this.errors = error.response.data;
                    } else if (error.response && error.response.status === 404) {
                        this.errors = error.response.data;
                    }                    
                    
                    } else {
                        
                    this.errors.detail = 'An error occured. Please try again',
                    console.error('Non-Axios error:', error);
                }
            }
            
        },
        checkRoute() {
            const route = useRoute();
            const routerChoice = route?.name;

            if (routerChoice == 'Settings') {
                this.inputDisabled = false
            }
        },
        async saveUserDetails() {
            const token = localStorage.getItem('AuthToken');
            const uID = this.getUserDetails();
            const userData = {
                country: this.country,
                stateCity: this.stateCity,
                postalCode: this.postalCode,
                taxID: this.taxID,
            };

            try {
                const response = await axios.put(`/api/profiles`, userData, {
                    headers: {
                        Authorization: `JWT ${token}`,
                    }
                });
                this.getUserDetails();
                console.log('Profile updated successfully', response.data);
            } catch (error) {
                console.error('Error updating profile', error);
                if (axios.isAxiosError(error)) {

                if (error.response && error.response.status == 400) {
                    this.errors = error.response.data;
                } else if (error.response && error.response.status === 401) {
                    this.errors = error.response.data;
                } else if (error.response && error.response.status === 403) {
                    this.errors = error.response.data;
                } else if (error.response && error.response.status === 404) {
                    this.errors = error.response.data;
                }

                } else {
                this.errors.detail = 'An error occured. Please try again',
                console.error('Non-Axios error:', error);
                }
            }

        }

    },
    created() {
        this.getRoute();
        this.checkRoute();
    },
    mounted(){
        this.getUserDetails();
    },
    watch: {
        $route(to: { name: string; }, from: any) {
            this.getRoute();
            if (to.name === 'Settings') {
                this.inputDisabled = false
            }
        }
    },
})
</script>

<template>
    <div class="info-details" :class="{ border: settings === true}">
        <div class="title-div">
            <div class="input">
                <span class="title">Address</span>
                <span class="error">{{ formatErrorMessage(errors.detail) }}</span>
            </div>
            <div class="edit-button" v-if="settings === true">
            <button class="edit" style="cursor: pointer;" @click="saveUserDetails()">
                <span class="edit-text">Save</span>
                <svg width="15" height="14" viewBox="0 0 15 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path fill-rule="evenodd" clip-rule="evenodd" d="M13.3603 0.195726C13.0996 -0.065242 12.6769 -0.0652421 12.4162 0.195726L11.8338 0.778677C11.0894 0.422698 10.1707 0.553319 9.55407 1.17054L2.47309 8.25841L6.24961 12.0386L13.3306 4.95074C13.9472 4.33352 14.0777 3.41396 13.7221 2.66878L14.3045 2.08583C14.5652 1.82486 14.5652 1.40174 14.3045 1.14078L13.3603 0.195726ZM10.511 5.88297L6.24961 10.1485L4.36135 8.25841L8.62275 3.99287L10.511 5.88297ZM11.7249 4.6679L12.3865 4.00569C12.6472 3.74472 12.6472 3.32161 12.3865 3.06064L11.4423 2.11559C11.1816 1.85462 10.7589 1.85462 10.4982 2.11559L9.83663 2.7778L11.7249 4.6679Z" fill="#0F172A" fill-opacity="0.5"/>
                    <path d="M0.5 14L1.91646 8.80197L5.69272 12.5824L0.5 14Z" fill="#0F172A" />
                </svg>
            </button>
        </div>
        </div>
        <div class="content-div">
            <div class="details">
                <div class="container">
                    <p class="content-text">Country</p>
                    <!--
                        Make it a prop
                        <input :class="{ border: settings === true}" type="text" name="country" value="Nigeria">
                    -->
                    <input :disabled="inputDisabled" v-model="country" :class="{ border: settings === true}" type="text" name="country" placeholder="Country">
                </div>
                <div class="container">
                    <p class="content-text">State / city</p>
                    <!--
                        Make it a prop
                        <input :class="{ border: settings === true}" type="text" name="state/city" value="Enugu">
                    -->
                    <input :disabled="inputDisabled" v-model="stateCity" :class="{ border: settings === true}" type="text" name="state/city" placeholder="State or City">
                </div>
            </div>
            <div class="details">
                <div class="container">
                    <p class="content-text">Postal code</p>
                    <!--
                        Make it a prop
                        <input :class="{ border: settings === true}" type="email" name="postcode" value="400102">
                    -->
                    <input :disabled="inputDisabled" v-model="postalCode" :class="{ border: settings === true}" type="text" name="postcode" placeholder="Postal Code">
                </div>
                <div class="container">
                    <p class="content-text">TAX ID</p>
                    <!--
                        Make it a prop
                        <input :class="{ border: settings === true}" type="number" name="tax-id" value="023243432232">
                    -->
                    <input :disabled="inputDisabled" v-model="taxID" :class="{ border: settings === true}" type="text" name="tax-id" placeholder="Tax ID">
                </div>
            </div>
        </div>
    </div>
</template>

<style lang="scss" scoped>
.border {
    border-radius: 24px;
    border: 1px solid var(--black-30, rgba(15, 23, 42, 0.30));
}

.info-details {
    width: 100%;
    padding: 38px 47px;
    display: flex;
    flex-direction: column;
    gap: 47px;
    .title-div {
        width: 100%;
        height: fit-content;
        display: flex;
        justify-content: space-between;
        align-items: center;
        .input {
            display: flex;
            flex-direction: column;
            gap: 5px;
            .error {
                font-size: 11px;
                color: red;
            }
        }
        .title {
            color: var(--black-100, #0F172A);
            font-family: DM Sans;
            font-size: 20px;
            font-style: normal;
            font-weight: 500;
            line-height: normal;
        }
        .edit-button {
            opacity: .5;
            .edit {
                padding: 10px;
                background-color: transparent;
                height: fit-content;
                width: fit-content;
                display: flex;
                gap: 9px;
                display: flex;
                justify-content: center;
                align-items: center;
                border-radius: 30px;
                border: 1px solid var(--black-50, rgba(15, 23, 42, 1));
                span {
                    color: var(--black-50, rgba(15, 23, 42, 1));
                    font-family: DM Sans;
                    font-size: 18px;
                    font-style: normal;
                    font-weight: 300;
                    line-height: normal;
                }
            }
        }
        .edit-button:hover {
            opacity: 1;
        }
    }
    .content-div {
        width: 100%;
        height: fit-content;
        display: flex;
        flex-direction: column;
        gap: 32px;
        .details {
            width: fit-content;
            display: flex;
            gap: 108px;
            width: 100%;
            .container {
                width: 30%;
                display: flex;
                flex-direction: column;
                gap: 10px;
                .content-text {
                    color: var(--black-50, rgba(15, 23, 42, 0.50));
                    font-family: DM Sans;
                    font-size: 14px;
                    font-style: normal;
                    font-weight: 500;
                    line-height: normal;
                    text-transform: capitalize;
                }
                input {
                    padding: 16px;
                    width: 100%;
                    color: var(--black-100, #0F172A);
                    font-family: DM Sans;
                    font-size: 16px;
                    font-style: normal;
                    font-weight: 500;
                    line-height: normal;
                    border: 0;
                }
                input.border {
                    border-radius: 24px;
                    border: 1px solid var(--black-30, rgba(15, 23, 42, 0.30));
                }
            }
        }
    }
}

@media only screen and (max-width: 940px) {
    .info-details {
        padding: 0px;
        border: none;
        .input {
            gap: 5px;
            .title {
                font-size: 1.25rem;
            }
        }
        button {
            span {
                font-size: 1.125rem;
            }
        }
        .content-div {
            gap: 2.09rem;
            .details {
                flex-direction: column;
                gap: 2.09rem;
                .container {
                    width: 100%;
                    .content-text {
                        font-size: 0.875rem;
                    }
                    input {
                        font-size: 1rem;
                        border: none;
                    }
                }
            }
        }
    }
}
</style>