<script lang="ts">
import { defineComponent } from "vue";
import 'boxicons'

export default defineComponent ({
    name: 'HelpCentreFAQ',
    data() {
        return {
            expanded: false,
        }
    },
    props: {
        main_text: String,
        text_expanded: String,
    //    expanded: Boolean,
    },
    methods: {
        toggleExpanded() {
            this.expanded = !this.expanded;
        }
    }
})
</script>

<template>
    <div class="total-outers-container">
        <div class="main-container-unexpanded">
            <div class="text-container">
                <div class="main-text">
                    <p class="topic-text">{{ main_text }}</p>
                </div>
            </div>
            <div class="arrow-container">    
                <svg v-if="expanded == true" @click="toggleExpanded" width="17" height="18" viewBox="0 0 17 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M1.93942 0.832753C1.49575 0.389082 0.77642 0.389082 0.332751 0.832753C-0.110917 1.27642 -0.110917 1.99575 0.332751 2.43942L6.89334 9.00003L0.332819 15.5606C-0.110849 16.0042 -0.110849 16.7236 0.332819 17.1672C0.776487 17.6109 1.49582 17.6109 1.93948 17.1672L8.5 10.6067L15.0605 17.1672C15.5042 17.6109 16.2235 17.6109 16.6672 17.1672C17.1109 16.7236 17.1109 16.0042 16.6672 15.5606L10.1067 9.00003L16.6672 2.43942C17.1109 1.99575 17.1109 1.27642 16.6672 0.832753C16.2236 0.389082 15.5043 0.389082 15.0606 0.832753L8.5 7.39336L1.93942 0.832753Z" fill="#0F172A" fill-opacity="0.7"/>
                </svg>
                <svg v-if="expanded == false" @click="toggleExpanded" width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M13.1566 1.58323C13.1566 0.955786 12.6479 0.447144 12.0205 0.447145C11.3931 0.447146 10.8844 0.95579 10.8844 1.58323L10.8844 10.8613L1.60641 10.8613C0.978969 10.8613 0.470324 11.37 0.470323 11.9974C0.470321 12.6249 0.978964 13.1335 1.60641 13.1335L10.8844 13.1335L10.8844 22.4115C10.8844 23.0389 11.393 23.5476 12.0205 23.5476C12.6479 23.5476 13.1565 23.0389 13.1565 22.4115L13.1566 13.1335L22.4347 13.1335C23.0621 13.1335 23.5707 12.6248 23.5707 11.9974C23.5707 11.3699 23.0621 10.8613 22.4347 10.8613L13.1566 10.8613L13.1566 1.58323Z" fill="#0F172A" fill-opacity="0.7"/>
                </svg>
            </div>
        </div>
        <div class="main-container-expanded-content" v-if="expanded == true">
            <div class="top-text">
                <p>{{ text_expanded }}</p>
            </div>
        </div>
    </div>
</template>

<style lang="scss" scoped>
.total-outers-container {
    display: flex;
    flex-direction: column;
    gap: 10px;
    width: 100%;
    .main-container-unexpanded {
        display: flex;
        justify-content: space-between;
        align-items: center;
        width: 100%;
        .text-container {
            display: flex;
            flex-direction: column;
            .main-text {
                color: var(--black-100, #0F172A);
                font-family: DM Sans;
                font-size: 20px;
                font-style: normal;
                font-weight: 300;
                line-height: normal;
            }
        }
        .arrow-container {
            max-width: 17px;
            max-height: 17px;
        }
    }
    .main-container-expanded-content {
        display: flex;
        flex-direction: column;
        width: 100%;
        .top-text {
            display: flex;
            width: 100%;
            p {
                color: var(--black-70, rgba(15, 23, 42, 0.70));
                font-family: DM Sans;
                font-size: 18px;
                font-style: normal;
                font-weight: 300;
                line-height: normal;
            }
        }
    }
}

@media only screen and (max-width: 940px) {
    .total-outers-container {
        gap: 9px;
        .main-container-unexpanded {
            .text-container {
                .main-text {
                    color: var(--black-100, #0F172A);
                    font-family: DM Sans;
                    font-size: 16px;
                    font-style: normal;
                    font-weight: 300;
                    line-height: 22px; /* 137.5% */
                }
            }
        }
        .main-container-expanded-content {
            .top-text {
                p {
                    color: var(--black-70, rgba(15, 23, 42, 0.70));
                    font-family: DM Sans;
                    font-size: 14px;
                    font-style: normal;
                    font-weight: 300;
                    line-height: 20px; /* 142.857% */ 
                }
            }
        }
    }
}
</style>