<script lang="ts">
import { toggleLoading } from "@/event-bus";
import { Bank, Product } from "@/router/types";
import { usePaymentStore } from "@/store/payment";
import { useProductStore } from "@/store/product";
import axios from "axios";
import { defineComponent } from "vue";
import paystack from "vue-paystack";
import OrderSummaryProducts from "../components/CheckOutComponent/OrderSummaryComponent.vue";
import ThirdHeaderVue from "../components/ThirdHeader.vue";
import { useUserStore } from "@/store/user";

export default defineComponent({
  name: "CheckOut",
  components: {
    ThirdHeaderVue,
    OrderSummaryProducts,
    paystack,
  },
  data() {
    return {
      cartItems: [] as any[],
      grandtotal: 0,
      respond: "",
      sub_total: 0,
      metadata: {
        custom_fields: [
          {
            display_name: "Product",
            variable_name: "product",
            value: "Sample Product",
          },
        ],
      },
      reference: "",
      shipping_type: "-",
      phone_code: 234,
      phone_menu: {
        //    'US': '323',
        NGN: "234",
        //   'UK': '111',
      },
      states_menu: [] as Product[],
      banks_menu: [] as Bank[],
      lga_menu: [] as Product[],
      country_selector_code: 234,
      country_selector: String,
      delivery_name: String,
      delivery_mobile_number: "",
      delivery_email_address: "",
      delivery_lga: "",
      delivery_lga_id: "",
      delivery_state: "",
      delivery_state_id: "",
      selectedBank: "",
      delivery_address: String,
      //schedule_delivery_date: Date,
      //schedule_delivery_note: String,
      payment_email_address: String,
      payment_card_number: Number,
      payment_cvv: Number,
      payment_expiry_date: String,
      payment_card_name: String,
      save_card_details: false,
      billing_address: String,
      billing_address_confirm: true,
      active_dropdown: false,
      active_state_dropdown: false,
      active_bank_dropdown: false,
      active_lga_dropdown: false,
      credit_card_select: true,
      cash_on_delivery_select: false,
      pos_on_delivery_select: false,
      acc_number: "",
      birthday: "",
      userAddress: "",
      payment_url: "",
      orderId: "",
    };
  },
  setup() {
    const productStore = useProductStore();
    const paymentStore = usePaymentStore();
    const userStore = useUserStore();

    return {
      productStore,
      paymentStore,
      userStore,
    };
  },
  computed: {
    reference() {
      let text = "";
      let possible =
        "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";

      for (let i = 0; i < 10; i++)
        text += possible.charAt(Math.floor(Math.random() * possible.length));

      return text;
    },
  },
  methods: {
    getCountryPhone() {
      this.country_selector_code = this.phone_code;
      //console.log(this.phone_menu['US'])

      /*for (var countries in this.phone_menu) {
                if (String(this.country_selector_code) == this.phone_menu[countries]) {

                }
            }*/
    },
    activateDropdown() {
      if (this.active_dropdown == false) {
        this.active_dropdown = true;
      } else {
        this.active_dropdown = false;
      }
    },
    activateStateDropdown() {
      if (
        this.active_state_dropdown === false ||
        this.active_state_dropdown === true
      ) {
        this.active_state_dropdown = !this.active_state_dropdown;
        console.log(this.active_state_dropdown);
      }
      //if (this.active_state_dropdown == false) {
      //    this.active_state_dropdown = true
      //}else {
      //    this.active_state_dropdown = false
      //}
    },
    activateBankDropdown() {
      if (
        this.active_bank_dropdown === false ||
        this.active_bank_dropdown === true
      ) {
        this.active_bank_dropdown = !this.active_bank_dropdown;
        console.log(this.active_bank_dropdown);
      }
    },
    activateLGAdropdown() {
      if (
        this.active_lga_dropdown === false ||
        this.active_lga_dropdown === true
      ) {
        this.active_lga_dropdown = !this.active_lga_dropdown;
        console.log(this.active_lga_dropdown);
      }
    },
    creditCardSelect() {
      if (this.credit_card_select == false) {
        this.credit_card_select = true;
        this.pos_on_delivery_select = false;
        this.cash_on_delivery_select = false;
      }
    },
    cashonDeliverySelect() {
      if (this.cash_on_delivery_select == false) {
        this.credit_card_select = false;
        this.pos_on_delivery_select = false;
        this.cash_on_delivery_select = true;
      }
    },
    posonDeliverySelect() {
      if (this.pos_on_delivery_select == false) {
        this.credit_card_select = false;
        this.pos_on_delivery_select = true;
        this.cash_on_delivery_select = false;
      }
    },
    toOrders() {
      this.$router.push("/orders");
    },
    reloadComponent() {
      this.$forceUpdate();
    },
    getStates() {
      toggleLoading(true);
      axios
        .get("/billing/states/")
        .then((response) => {
          toggleLoading(false);
          this.states_menu = response.data.states;
          console.log("States gotten:", this.states_menu);
        })
        .catch((error) => {
          toggleLoading(false);
          console.error("Error fetching states:", error);
        });
    },
    setBank(selectedBank: any, selectedBankCode: any) {
      this.selectedBank = selectedBank; // Set the selected state to the input field
      sessionStorage.setItem("BankC", selectedBankCode);
      this.active_bank_dropdown = false;
    },
    onInput() {
      this.active_state_dropdown = false;
      this.active_lga_dropdown = false;
    },
    setState(selectedState: any, selectedStateID: any) {
      this.delivery_state = selectedState; // Set the selected state to the input field
      this.delivery_state_id = selectedStateID;
      sessionStorage.setItem("State", selectedStateID);
      this.active_state_dropdown = false;
      this.delivery_lga = "";
      this.getLGAs();
    },
    getLGAs() {
      toggleLoading(true);
      const state_id = sessionStorage.getItem("State");
      axios
        .get(`/billing/states/${state_id}/`)
        .then((response) => {
          toggleLoading(false);
          this.lga_menu = response.data.lgas;
          console.log("LGAs gotten:", this.states_menu);
        })
        .catch((error) => {
          toggleLoading(false);
          console.error("Error fetching states:", error);
        });
    },
    setLGA(selectedLGA: any, selectedLGAID: any) {
      this.delivery_lga = selectedLGA; // Set the selected state to the input field
      this.delivery_lga_id = selectedLGAID;
      sessionStorage.setItem("LGA", selectedLGAID);
      this.active_lga_dropdown = false;
      this.getBilling();
    },
    getBilling() {
      toggleLoading(true);
      const lgaID = sessionStorage.getItem("LGA");
      const cartID = localStorage.getItem("cartID");

      const data = {
        lga_id: lgaID,
        cart_id: cartID,
      };

      axios
        .post(`/billing/billing-price/`, data)
        //axios({
        //    method: 'get',
        //    url: '/billing/billing-price/',
        //    data: {
        //        lga_id: lgaID,
        //        cart_id: cartID,
        //    },
        //})
        .then((response) => {
          toggleLoading(false);
          this.shipping_type = response.data.billing_total;
          this.sub_total = response.data.cart_grand_total;
          this.grandtotal = response.data.sum_total;
          console.log("Billing price returned", response);
        })
        .catch((error) => {
          toggleLoading(false);
          console.error("Error fetching total price:", error);
        });
    },
    async makePayment(e) {
      e.preventDefault();
    if (!this.userStore.getAuthHeader) {
      this.$router.push('/signin');
      return
    }
      toggleLoading(true);
      const shippingRes = await this.paymentStore.setShippingAddress({
        street_address: this.userAddress,
        lga: this.delivery_lga_id,
        state: this.delivery_state_id,
        phone: this.delivery_mobile_number,
        email: this.delivery_email_address,
      });
      const order = await this.productStore.createOrder()
      this.orderId = order.id
      const paymentRes = await this.paymentStore.makePayment({
        orderId: this.orderId,
      });
      // redirect to payment url
      toggleLoading(false);
      setTimeout(() => (window.location.href = paymentRes.data.link), 300);
    },
  },
  created() {
    this.getStates();
    document.title = "Checkout | Fondstore";
  },
  async mounted() {
   if (this.userStore.getUser.token) {
     toggleLoading(true);
    const cartDetails = await this.productStore.fetchCartItems();
    this.cartItems = cartDetails?.cart_items || [];
    toggleLoading(false);

    this.grandtotal =
      !!this.cartItems &&
      this.cartItems.reduce((acc: any, curr: any) => {
        return acc + curr.item.price * curr.quantity;
      }, 0);
  } else {
      this.$router.push('/signin');
      return
    }
  },
});
</script>

<template>
  <div class="main-checkout-page-container mt-20">
    <ThirdHeaderVue mainf_header="Checkout" bold_link="checkout" />

    <div class="user-input-detail-container">
      <div class="user-input-container">
        <div class="delivery-details-container">
          <form id="a-form" @submit="makePayment">
            <div class="delivery-header">
              <span class="delivery-header-text font-class"
                >DELIVERY INFORMATION</span
              >
            </div>
            <div class="delivery-name-number-container">
              <div class="name-container">
                <label for="name" class="delivery-name-label">
                  <span class="delivery-name-text font-class">Name</span>
                </label>
                <input
                  type="text"
                  class="font-class"
                  name="name"
                  :bind="delivery_name"
                  placeholder="Enter your name"
                  required
                />
              </div>
              <div class="phone-container">
                <label for="phone" class="delivery-phone-label">
                  <span class="delivery-phone-text font-class"
                    >Mobile Number</span
                  >
                </label>
                <div class="phone-dropdown-input-container">
                  <!--
                                        <button class="phone-dropdown-btn" :onclick="activateDropdown">
                                        <span class="phone-dropdown-default font-class">+ {{ phone_code }}</span>
                                        <div class="arrow-down-icon">
                                            <box-icon type='solid' name='down-arrow'></box-icon>
                                        </div>
                                    </button>
                                    <div class="phone-dropdown-menu" :class="{ 'grid-display': active_dropdown == true, 'none-display': active_dropdown == false }">
                                        <button :onclick="getCountryPhone" class="phone-menu" v-for="(phone, phone_number) in phone_menu" :key="phone">
                                            <span class="phone-menu-country font-class">{{ phone }} </span>
                                            <span class="phone-menu-phone font-class">{{ phone_number }}</span>
                                        </button>
                                    </div>
                                    -->
                  <input
                    type="tel"
                    class="font-class phone-input"
                    name="phone"
                    v-model="delivery_mobile_number"
                    placeholder="080 1234 5678"
                    required
                  />
                </div>
              </div>
            </div>
            <div class="email-zip-state">
              <div class="email-container">
                <label for="Email" class="delivery-email">
                  <span class="delivery-email-text font-class"
                    >Email Address</span
                  >
                </label>
                <input
                  v-model="delivery_email_address"
                  type="email"
                  name="Email"
                  class="font-class"
                  placeholder="Enter your email"
                  required
                />
              </div>
              <div class="zip-state-container">
                <!--
                                    <div class="zip-container">
                                    <label for="Zipcode" class="delivery-zip">
                                        <span class="delivery-zip-text font-class">LGA</span>
                                    </label>
                                    <div class="lga-input-dropdown">
                                        <input type="text" name="Zipcode" placeholder="Select a state first" class="zip-input font-class">
                                        <button @click="activateLGAdropdown" class="state-dropdown">
                                            <box-icon name='chevron-down' color="#0F172A"></box-icon>
                                        </button>
                                        <div class="states-menu-container" v-if="active_lga_dropdown === true">
                                            <button @click="setState(state.name)" class="states-menu" v-for="state in states_menu" :key="state.id">{{state.name}}
                                        </button>
                                    </div>
                                    </div>
                                </div>
                                -->
                <div class="state-container">
                  <label for="State" class="delivery-state">
                    <span class="delivery-state-text font-class">State</span>
                  </label>
                  <div class="delivery-state-dropdown">
                    <input
                      type="text"
                      name="State"
                      readonly
                      class="state-input font-class"
                      placeholder="Select State"
                      v-model="delivery_state"
                      @input="onInput"
                      required
                    />
                    <button
                      type="button"
                      @click="activateStateDropdown"
                      class="state-dropdown"
                    >
                      <box-icon name="chevron-down" color="#0F172A"></box-icon>
                    </button>
                    <div
                      class="states-menu-container bg-white !h-auto border border-gray-400
                      max-h-[calc(100vh/2)] overflow-y-auto"
                      v-if="active_state_dropdown === true">
                      <button
                        type="button"
                        @click="setState(state.name, state.id)"
                        class="states-menu p-1 px-2"
                        v-for="state in states_menu"
                        :key="state.id"
                      >
                        {{ state.name }}
                      </button>
                    </div>
                  </div>
                </div>
                <div class="state-container">
                  <label for="State" class="delivery-state">
                    <span class="delivery-state-text font-class">LGA</span>
                  </label>
                  <div class="delivery-state-dropdown">
                    <input
                      type="text"
                      name="State"
                      readonly
                      class="state-input font-class"
                      placeholder="Choose state first"
                      v-model="delivery_lga"
                      @input="onInput"
                      required
                    />
                    <button
                      type="button"
                      @click="activateLGAdropdown"
                      class="state-dropdown"
                    >
                      <box-icon name="chevron-down" color="#0F172A"></box-icon>
                    </button>
                    <div
                      class="states-menu-container bg-white !h-auto border border-gray-400
                      max-h-[calc(100vh/2)] overflow-y-auto"
                      v-if="active_lga_dropdown === true">
                      <button
                        type="button"
                        @click="setLGA(lga.name, lga.id)"
                        class="states-menu p-1 px-2"
                        v-for="lga in lga_menu"
                        :key="lga.id"
                      >
                        {{ lga.name }}
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="address-container">
              <label for="address"
                ><span class="address-text font-class">Address</span></label
              >
              <input
                v-model="userAddress"
                type="text"
                class="delivery-address font-class"
                placeholder="2324, Abua Close"
                name="address"
                id="address"
                required
              />
            </div>
          </form>
        </div>
        <!--
                    <div class="schedule-container">
                    <div class="schedule-header">
                        <span class="schedule-header-text font-class">SCHEDULE DELIVERY</span>
                    </div>
                    <div class="schedule-input-container">
                        <div class="schedule-date-container">
                            <label for="date"><span class="font-class">Date</span></label>
                            <input type="date" name="date" class="schedule-date font-class" id="date">
                        </div>
                        <div class="schedule-note-container">
                            <label for="note"><span class="font-class">Note</span></label>
                            <input type="text" name="note" placeholder="Note" class="schedule-note font-class" id="note">
                        </div>
                    </div>
                </div>
                -->
        <div class="main-shippingmode-container">
          <div class="shipping-mode-select-container">
            <div class="shipping-mode-select-header">
              <span class="select-header-text">ORDER SUMMARY</span>
              <router-link to="/cart" class="select-header-edit-btn"
                >Edit</router-link
              >
            </div>
            <!--<div>PRODUCTS HEREEE</div>-->
            <div class="order-summary-products">
              <OrderSummaryProducts
                v-for="(each, i) in cartItems"
                :key="i"
                @reloadCart="reloadComponent"
                :product_name="each?.item?.name"
                :order_number="each?.quantity"
                :product_id="each?.item?.id"
                :photo="each?.item?.image || 'https://via.placeholder.com/150'"
                :price="each?.item?.price"
              />
            </div>
          </div>
          <div class="main-subtotal-shipping-total-container">
            <div class="main-subtotal-container">
              <span class="tag-text font-class">SUBTOTAL</span>
              <span class="price-text font-class"
                >₦{{ sub_total || grandtotal }}</span
              >
            </div>
            <div class="main-shipping-mode-container">
              <span class="tag-text font-class">SHIPPING</span>
              <span class="price-text font-class">₦{{ shipping_type }}</span>
            </div>
            <div class="main-total-container">
              <span class="tag-text font-class">TOTAL</span>
              <span class="price-text font-class">₦{{ grandtotal }}</span>
            </div>
          </div>
          <button
            type="submit"
            form="a-form"
            class="checkout-button font-class"
          >
            Confirm Order
          </button>
        </div>
      </div>
      <hr class="line-break" />
    </div>
  </div>
</template>

<style lang="scss" scoped>
.active-choice {
  color: #4385f5;
}
.inactive-choice {
  color: #0f172a80;
}
font-class {
  font-family: "DM Sans", sans-serif;
  font-style: normal;
}
.grid-display {
  display: grid;
}
.none-display {
  display: none;
}
.main-checkout-page-container {
  //border: 1px solid;
  //height: 1024px;
  margin-bottom: 5rem;
  //padding: 0px 3rem;
  .user-input-detail-container {
    display: flex;
    //height: 1000px;
    //border: 1px solid;
    padding: 0px 5.13rem;

    .user-input-container {
      display: flex;
      align-content: start;
      justify-content: space-between;
      width: 100%;
      .delivery-details-container {
        width: 57.5%;
        display: grid;
        align-content: start;
        row-gap: 0.75rem;
        padding-bottom: 5px;
        .delivery-header {
          height: auto;
          display: flex;
          align-items: center;
          .delivery-header-text {
            font-weight: 500;
            font-size: 18px;
            line-height: 23.44px;
          }
        }
        .delivery-name-number-container {
          display: flex;
          margin-top: 0;
          justify-content: space-between;
          .name-container {
            display: grid;
            height: 64px;
            row-gap: 0.5rem;
            label {
              span {
                font-weight: 500;
                font-size: 14px;
              }
            }
            input {
              display: flex;
              align-content: center;
              width: 280px;
              height: 32px;
              padding: 0px 12px 0px 12px;
              outline: 0;
              border: 1px solid #0f172a4d;
            }
            input::placeholder {
              font-weight: 500;
              font-size: 14px;
            }
          }
          .phone-container {
            display: grid;
            height: 64px;
            row-gap: 0.5rem;
            label {
              span {
                font-weight: 500;
                font-size: 14px;
              }
            }
            .phone-dropdown-input-container {
              display: flex;
              border: 1px solid #0f172a4d;
              position: relative;
              height: 32px;
              width: 280px;
              align-items: center;
              justify-content: space-between;
              padding: 0px 0px 0px 0px;
              .phone-dropdown-btn {
                display: flex;
                width: auto;
                padding: 5px;
                align-items: center;
                outline: 0;
                background: transparent;
                span {
                  display: flex;
                  font-size: 14px;
                  font-weight: 500;
                }
                .arrow-down-icon {
                  display: flex;
                  width: 10px;
                  height: 10px;
                  align-items: center;
                  justify-content: center;
                  margin-left: 0.25rem;
                }
              }
              .phone-dropdown-menu {
                position: absolute;
                top: 32.5px;
                left: -3px;
                background: transparent;
                min-width: 200px;
                max-height: 200px;
                background: #ffffff;
                border: 1px solid;
                border-radius: 4px;
                z-index: 1;
                box-shadow: 0px 1px 5px 0px rgba(0, 0, 0, 0.2);
                .phone-menu {
                  padding: 5px;
                  width: auto;
                  height: 24px;
                  background: transparent;
                  display: flex;
                  align-items: center;
                  .phone-menu-country {
                    margin-right: 0.5rem;
                    font-size: 14px;
                    font-weight: 500;
                  }
                  .phone-menu-phone {
                    font-size: 14px;
                    font-weight: 500;
                  }
                }
              }
              .phone-input {
                display: flex;
                height: 30px;
                width: 210px;
                padding-left: 5px;
                padding-right: 5px;
                border: 0;
                outline: 0;
              }
              .phone-input::placeholder {
                font-size: 14px;
                font-weight: 500;
              }
            }
          }
        }
        .email-zip-state {
          display: flex;
          justify-content: space-between;
          .email-container {
            display: grid;
            height: 64px;
            label {
              span {
                font-size: 14px;
                font-weight: 500;
              }
            }
            input {
              width: 280px;
              height: 32px;
              padding: 0px 12px 0px 12px;
              outline: 0;
              border: 1px solid #0f172a4d;
            }
            input::placeholder {
              font-weight: 500;
              font-size: 14px;
            }
          }
          .zip-state-container {
            width: 280px;
            display: flex;
            justify-content: space-between;
            div {
              display: grid;
              height: 64px;
              label {
                span {
                  font-size: 14px;
                  font-weight: 500;
                }
              }
              input {
                width: 130px;
                height: 32px;
                padding: 0px 12px 0px 12px;
                outline: 0;
                border: 1px solid #0f172a4d;
              }
              input::placeholder {
                font-weight: 500;
                font-size: 14px;
              }
              .delivery-state-dropdown {
                width: 130px;
                display: flex;
                height: 32px;
                align-items: center;
                position: relative;
                //padding: 0px 2px 0px 12px;
                border: 1px solid #0f172a4d;
                input {
                  width: 100px;
                  height: 30px;
                  //padding: 0px 12px 0px 12px;
                  outline: 0;
                  border: 0;
                }
                input::placeholder {
                  font-weight: 500;
                  font-size: 14px;
                }
                .state-dropdown {
                  display: flex;
                  align-items: center;
                  justify-content: center;
                  background: #ffffff;
                  border: 1px solid;
                  border-radius: 50%;
                  height: 20px;
                  width: 20px;
                }
                .states-menu-container {
                  display: grid;
                  position: absolute;
                  width: 8.125rem;
                  top: 32.5px;
                }
              }
            }
          }
        }
        .address-container {
          display: grid;
          height: 64px;
          row-gap: 0.5rem;
          label {
            span {
              font-weight: 500;
              font-size: 14px;
            }
          }
          input {
            display: flex;
            align-content: center;
            height: 32px;
            padding: 0px 12px 0px 12px;
            outline: 0;
            border: 1px solid #0f172a4d;
          }
          input::placeholder {
            font-weight: 500;
            font-size: 14px;
          }
        }
      }
      //            .schedule-container {
      //                //border: 1px solid;
      //                display: grid;
      //                align-content: start;
      //                row-gap: 0.5rem;
      //                padding-bottom: 5px;
      //                .schedule-header {
      //                    height: 30px;
      //                    display: flex;
      //                    align-items: center;
      //                    .schedule-header-text {
      //                        font-weight: 500;
      //                        font-size: 18px;
      //                        line-height: 23.44px;
      //                    }
      //                }
      //                .schedule-input-container {
      //                    margin-top: 0;
      //                    display: grid;
      //                    height: 145px;
      //                    div {
      //                        display: grid;
      //                        row-gap: 0.5rem;
      //                        height: 64px;
      //                        label {
      //                            span {
      //                                font-size: 14px;
      //                                font-weight: 500;
      //                            }
      //                        }
      //                        input {
      //                            display: flex;
      //                            align-content: center;
      //                            height: 32px;
      //                            padding: 0px 12px 0px 12px;
      //                            outline: 0;
      //                            border: 1px solid #0F172A4D;
      //                        }
      //                        input::placeholder {
      //                            font-weight: 500;
      //                            font-size: 14px;
      //                        }
      //                    }
      //                }
      //
      //            }
      .payment-method-container {
        display: grid;
        align-content: start;
        row-gap: 1.5rem;
        margin-top: 5.69rem;
        //border: 1px solid;
        .payment-header {
          display: flex;
          height: 30px;
          align-items: center;
          span {
            font-size: 18px;
            font-weight: 500;
            line-height: 23.44px;
          }
        }
        .payment-subnav {
          display: flex;
          align-items: center;
          border: 0;
          border-bottom: 1px solid #0f172a1a;

          height: 40px;
          justify-content: space-between;
          margin-bottom: 1.5rem;
          .subnav-one {
            display: flex;
            align-items: center;
            height: 28px;
            padding: 0px 10px 0px 10px;
            //border: 1px solid;
            .check-circle {
              display: flex;
              align-items: center;
              justify-content: center;
              height: 30px;
              width: 30px;
              margin-right: 0.25rem;
              .check {
                display: flex;
                border: 1px solid;
                align-items: center;
                justify-items: center;
                width: 18px;
                height: 18px;
                border-radius: 50%;
                outline: 0;
                background: #ffffff;
              }
            }
            .subnav-text-container {
              display: flex;
              align-items: center;
              span {
                font-size: 14px;
                font-weight: 500;
              }
            }
          }
        }
        .credit-card-container {
          display: grid;
          //border: 1px solid;
          align-content: start;
          row-gap: 0.75rem;
          .credit-card-header-container {
            display: grid;
            height: 55px;
            .credit-card-header {
              display: flex;
              height: 30px;
              align-items: center;
              span {
                font-size: 18px;
                font-weight: 500;
                line-height: 23.44px;
              }
            }
            .credit-card-subheader {
              display: flex;
              height: 20px;
              align-items: center;
              span {
                font-size: 14px;
                font-weight: 500;
              }
            }
          }
          .card-details-container {
            display: grid;
            align-content: start;
            row-gap: 0.75rem;
            //border: 1px solid;
            .card-email-name-container {
              display: flex;
              height: 64px;
              align-items: center;
              justify-content: space-between;
              div {
                height: 64px;
                display: grid;
                width: 280px;
                label {
                  span {
                    font-size: 14px;
                    font-weight: 500;
                    line-height: 23.44px;
                  }
                }
                input {
                  display: flex;
                  align-content: center;
                  height: 32px;
                  padding: 0px 12px 0px 12px;
                  outline: 0;
                  border: 1px solid #0f172a4d;
                }
                input::placeholder {
                  font-weight: 500;
                  font-size: 14px;
                }
              }
            }
            .number-cvv-expiry-container {
              display: flex;
              height: 64px;
              align-items: center;
              justify-content: space-between;
              //border: 1px solid;
              .number-container {
                display: grid;
                height: 64px;
                width: fit-content;
                label {
                  span {
                    font-size: 14px;
                    font-weight: 500;
                  }
                }
                .input-field {
                  width: 280px;
                  border: 1px solid #0f172a4d;
                  height: 32px;
                  display: flex;
                  align-items: center;
                  padding: 0px 5px 0px 5px;
                  .card-type-field {
                    display: flex;
                    height: 32px;
                    width: 32px;
                    align-items: center;
                    justify-content: center;
                  }
                  input {
                    height: 30px;
                    display: flex;
                    align-content: center;
                    border: 0;
                    outline: 0;
                    font-size: 14px;
                    font-weight: 500;
                    margin-left: 0.5rem;
                    width: 232px;
                  }
                }
                .delivery-state-dropdown {
                  width: fit-content;
                  display: flex;
                  //height: 32px;
                  align-items: center;
                  position: relative;
                  padding: 0px 12px 0px 12px;
                  border: 1px solid #0f172a4d;
                  input {
                    width: 100px;
                    height: 30px;
                    //padding: 0px 12px 0px 12px;
                    outline: 0;
                    border: 0;
                  }
                  input::placeholder {
                    font-weight: 500;
                    font-size: 14px;
                  }
                  .state-dropdown {
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    background: #ffffff;
                    border: 1px solid;
                    border-radius: 50%;
                    height: 20px;
                    width: 20px;
                  }
                  .states-menu-container {
                    //padding: 12px;
                    display: grid;
                    position: absolute;
                    //width: 8.125rem;
                    top: 32px;
                    left: 0px;
                    padding-bottom: 12px;
                    button {
                      padding: 12px;
                      padding-bottom: 0px;
                    }
                    button:last-child {
                      padding-bottom: 12px;
                    }
                  }
                }
              }
              .cvv-expiry-container {
                display: flex;
                width: 280px;
                align-items: center;
                justify-content: space-between;
                div {
                  display: grid;
                  width: 130px;
                  height: 64px;
                  label {
                    span {
                      font-size: 14px;
                      font-weight: 500;
                    }
                  }
                  input {
                    height: 32px;
                    width: 130px;
                    outline: 0;
                    display: flex;
                    align-items: center;
                    font-size: 14px;
                    font-weight: 500;
                    border: 1px solid #0f172a4d;
                    padding: 0px 12px 0px 12px;
                  }
                }
              }
            }
          }
        }
      }
      .bold {
        font-weight: 500;
      }
      .light {
        font-weight: 300;
      }
      .font-class {
        font-family: "DM Sans", sans-serif;
        font-style: normal;
      }
      .main-shippingmode-container {
        width: 33%;
        display: grid;
        height: auto;
        padding: 2.13rem 1.69rem 1.87rem;
        border-radius: 1.5rem;
        border: 1px solid var(--black-30, rgba(15, 23, 42, 0.3));
        .shippingmode-header {
          display: flex;
          .header-text {
            font-weight: 500;
            font-size: 18px;
            line-height: 26.04px;
          }
        }
        .shipping-mode-select-container {
          display: grid;
          margin-top: 1.06rem;
          gap: 2.81rem;
          .shipping-mode-select-header {
            display: flex;
            align-items: center;
            justify-content: space-between;
            .select-header-text {
              font-weight: 300;
              font-size: 16px;
              line-height: 20.83px;
            }
            .select-header-edit-btn {
              cursor: pointer;
              color: var(--Button-Default, #4385f5);

              /* Regular */
              font-family: DM Sans;
              font-size: 1rem;
              font-style: normal;
              font-weight: 300;
              line-height: 1.375rem; /* 137.5% */
            }
            .order-summary-products {
              display: flex;
              flex-direction: column;
              gap: 1.87rem;
            }
          }
          .checkbox-select-container {
            display: grid;
            margin-top: 1.5rem;
            div {
              display: flex;
              margin-bottom: 0.3rem;
              .checkbox-button-container {
                display: flex;
                width: 24px;
                height: 24px;
                justify-content: center;
                align-items: center;
                margin-right: 0.5rem;
                border: 1.5px solid;
                border-radius: 50%;
                button {
                  display: flex;
                  background: transparent;
                  width: 20px;
                  height: 20px;
                  justify-content: center;
                  align-items: center;
                }
              }
              span {
                font-size: 16px;
                line-height: 20.83px;
                margin-right: 0.5rem;
              }
            }
          }
        }
        .main-subtotal-shipping-total-container {
          display: grid;
          margin-top: 4rem;
          margin-bottom: 3.37rem;
          div {
            display: flex;
            justify-content: space-between;
            align-items: center;
            padding-top: 0.7rem;
            padding-bottom: 0.7rem;
            margin-left: 6px;
            margin-right: 6px;
            .tag-text {
              color: var(--black-100, #0f172a);

              /* Regular */
              font-family: "DM Sans", sans-serif;
              font-size: 1rem;
              font-style: normal;
              font-weight: 300;
              line-height: 1.375rem; /* 137.5% */
            }
            .price-text {
              color: var(--black-100, #0f172a);

              /* Medium */
              font-family: "DM Sans", sans-serif;
              font-size: 1.125rem;
              font-style: normal;
              font-weight: 500;
              line-height: normal;
            }
          }
        }
        .checkout-button {
          display: flex;
          border: 1px solid;
          background: #4385f5;
          justify-content: center;
          color: #ffffff;
          align-items: center;
          height: 48px;
          border-radius: 30px;
          color: var(--White, #fff);
          font-family: "DM Sans";
          font-size: 1.125rem;
          font-style: normal;
          font-weight: 500;
          line-height: normal;
        }
      }
    }
  }
}
@media only screen and (max-width: 940px) {
  .main-checkout-page-container {
    margin-bottom: 0px;
    .user-input-detail-container {
      display: grid;
      padding: 0px 1.5rem;

      .user-input-container {
        flex-direction: column;
        width: 100%;
        .delivery-details-container {
          display: grid;
          justify-items: left;
          .delivery-header {
            justify-content: left;
          }
          .delivery-name-number-container {
            width: 100%;
            display: grid;
            align-content: start;
            row-gap: 0.5rem;
            justify-items: center;
          }
          .email-zip-state {
            display: grid;
            width: 100%;
            align-content: start;
            row-gap: 0.5rem;
            justify-items: center;
          }
          .address-container {
            width: 100%;
          }
        }
        .schedule-container {
          width: 100%;
          .schedule-header {
            justify-content: left;
          }
        }
        .payment-method-container {
          row-gap: 0.75rem;
          margin-top: 5.69rem;
          .payment-header {
            justify-content: left;
          }
          .payment-subnav {
            display: grid;
            align-content: start;
            row-gap: 0.5rem;
            height: auto;
            border: 0;
          }
          .credit-card-container {
            .credit-card-header-container {
              justify-content: left;
            }
            .card-details-container {
              .card-email-name-container {
                display: grid;
                align-content: start;
                row-gap: 0.5rem;
                height: auto;
              }
              .number-cvv-expiry-container {
                display: grid;
                height: auto;
              }
            }
          }
        }
        .main-shippingmode-container {
          width: 100%;
          margin-top: 3.81rem;
          padding: 2.81rem 1.25rem 1.44rem;
        }
      }
      .line-break {
        height: 1px;
        width: 100%;
        opacity: 0.3;
        background: rgba(15, 23, 42, 0.3);
        margin-top: 5rem;
      }
    }
  }
}
</style>
