import { resolveComponent as _resolveComponent, createVNode as _createVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createBlock as _createBlock, createElementVNode as _createElementVNode, createCommentVNode as _createCommentVNode, withCtx as _withCtx } from "vue"

const _hoisted_1 = { class: "main-cart-page-container mt-20" }
const _hoisted_2 = {
  key: 0,
  class: "main-cart-component-container"
}
const _hoisted_3 = { class: "main-cart-product-details-container" }
const _hoisted_4 = { class: "main-cart-shipping-mode-container" }
const _hoisted_5 = {
  key: 1,
  class: "page-not-found"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_SecondHeaderVue = _resolveComponent("SecondHeaderVue")!
  const _component_ProductdetailsVue = _resolveComponent("ProductdetailsVue")!
  const _component_ShippingmodeVue = _resolveComponent("ShippingmodeVue")!
  const _component_router_link = _resolveComponent("router-link")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_SecondHeaderVue, {
      main_header: "Shopping Cart",
      product_gender: "Men",
      bold_link: "cart"
    }),
    (_ctx.cartExist === true)
      ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
          _createElementVNode("div", _hoisted_3, [
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.cartItems, (each) => {
              return (_openBlock(), _createBlock(_component_ProductdetailsVue, {
                key: each.id,
                onUpdate_cart: _ctx.handleCartUpdate,
                product_id: each.item.id,
                product_name: each.item.name,
                favorite_id: each.item.favorite_id,
                price: each.item.price,
                size: each.item.size,
                order_number: each.quantity,
                liked: each.item.is_favourite,
                totalprice: each.price_sub_total,
                photo: each.item.image
              }, null, 8, ["onUpdate_cart", "product_id", "product_name", "favorite_id", "price", "size", "order_number", "liked", "totalprice", "photo"]))
            }), 128))
          ]),
          _createElementVNode("div", _hoisted_4, [
            _createVNode(_component_ShippingmodeVue, {
              total_price: `${_ctx.grandtotal}`,
              subtotal_price: `${_ctx.grandtotal}`,
              shipping_type: "-",
              store_pickup_check: true,
              home_delivery_check: false
            }, null, 8, ["total_price", "subtotal_price"])
          ])
        ]))
      : _createCommentVNode("", true),
    (_ctx.cartExist === false && !_ctx.isLoading)
      ? (_openBlock(), _createElementBlock("div", _hoisted_5, [
          _cache[1] || (_cache[1] = _createElementVNode("div", { class: "text-div" }, [
            _createElementVNode("h1", null, "Oops! There's no item in your cart just yet."),
            _createElementVNode("p", null, "Find your perfect and add them to your cart.")
          ], -1)),
          _createVNode(_component_router_link, {
            to: "/categories",
            class: "form-div !w-auto"
          }, {
            default: _withCtx(() => _cache[0] || (_cache[0] = [
              _createElementVNode("input", {
                class: "submit !cursor-pointer",
                type: "button",
                value: "Add item"
              }, null, -1)
            ])),
            _: 1
          })
        ]))
      : _createCommentVNode("", true)
  ]))
}