<script setup lang="ts">
import ItemCard from '@/components/ItemCard.vue';
import { useHomeStore } from '@/store/home';
import { useUserStore } from '@/store/user';
import { computed, onMounted, ref } from 'vue';

const userStore = useUserStore();
const homeStore = useHomeStore();

onMounted(() => {
  homeStore.fetchMixedItems({
      endpointName: "new_arrivals",
      requiresAuth: false,
    })
  })
const renderedMixedItems = computed(()=>{
  return homeStore.getMixedItems("new_arrivals") || []
})
</script>

<template>
  <section class="mt-[2rem]">
    <div class="container">
      <div class="flex items-center justify-center my-4">
        <h1 class="font-extrabold text-2xl  md:text-4xl">New Arrivals</h1>
      </div>
      <div id="image">
          <ItemCard
            v-for="{item} in renderedMixedItems" :key="item.id"
            :id="item.id"
            :favorite_id="item.favorite_id"
            :name="item.name"
            :price="item.price"
            :new_price="item.new_price"
            :image="item.image"
            :slug="item.slug"
            :is_favourite="item.is_favourite">
          </ItemCard>
        </div>
      </div>

      <div
      v-if="renderedMixedItems.length === 0 && !homeStore.requestingMixedItem"
      class="h-96 bg-gray-50 flex items-center justify-center
      flex-col text-2xl font-medium text-gray-300">
      <span>
        {{
         'No item in here yet'
        }}
      </span>
      <router-link
        v-if="!userStore.getUser.token"
        to="/signin"
        class="text-gray-500">Sign in</router-link>
    </div>

    <div
      v-if="homeStore.requestingMixedItem"
      class="h-96 flex items-center justify-center
      flex-col text-2xl gap-3 font-medium text-gray-300">
      <span>
        Fetching products...
      </span>
      <Spinner class="w-8 text-gray-500" />
    </div>
  </section>
</template>

<style>
 .container {
    width: 90%;
    margin-inline: auto;
    margin-bottom: 2rem;

    #image {
      position: relative;
      display: grid;
      padding: 1rem;
      grid-template-columns: repeat(3, 1fr);
      gap: 1rem;

      #single {
        div {
          position: relative;
          background-image: url("../assets/images/product-photos/Rectangle417arrivals.png");
          background-repeat: no-repeat;
          background-size: cover;
          border-top-left-radius: 12px;
          border-top-right-radius: 12px;
          max-width: 24rem;
          height: 29rem;

          span {
            position: absolute;
            bottom: 1rem;
            right: 1.5rem;
            display: flex;
            justify-content: center;
            align-items: center;
            width: 2.5rem;
            height: 2.5rem;
            border-radius: 100%;
            background: #fff;
            cursor: pointer;
          }
        }

        #text {
          display: flex;
          justify-content: space-between;
          align-items: center;
          margin-top: 1rem;

          span {
            text-align: start;
            row-gap: 0.6rem;

            p {
              font-size: 1.1rem;
            }

            #price {
              font-weight: 600;
              font-size: 1.2rem;
            }
          }

          button {
            text-transform: capitalize;
            color: var(--Button-Default, #4385f5);
            font-size: 1rem;
            font-weight: 500;
            cursor: pointer;
            background-color: transparent;
          }
        }
      }
    }
  }
</style>