import { onMounted, ref } from "vue"

export default function () {
  const is_visible = ref(true)
  onMounted(() => {
    document.addEventListener("visibilitychange", () => {
      if (document.visibilityState === 'visible') {
        is_visible.value = true
      } else {
        is_visible.value = false
      }
    })
  })
  return { is_visible }
}