<script lang="ts">
import ProductdetailsVue from "@/components/FavoriteCartComponent/Productdetails.vue";
import ShippingmodeVue from "@/components/FavoriteCartComponent/Shippingmode.vue";
import { isLoading, toggleLoading } from "@/event-bus";
import { useProductStore } from "@/store/product";
import { defineComponent } from "vue";
import SecondHeaderVue from "../components/SecondHeader.vue";

export default defineComponent({
  name: "CartPage",
  components: {
    SecondHeaderVue,
    ProductdetailsVue,
    ShippingmodeVue,
  },
  setup() {
    const productStore = useProductStore();
    return {
      productStore,
    };
  },
  data() {
    return {
      isLoading,
      grandtotal: 0,
      // cartItems: [] as Product[],
      // cartExist: false,
      // shouldGetCart: true,
    };
  },
  computed: {
    cartExist() {
      return this.productStore.cartDetails?.cart_items?.length > 0;
    },
    cartItems() {
      return this.productStore.cartDetails?.cart_items;
    },
  },
  methods: {
    handleCartUpdate({
      cartItemId,
      newCartTotal,
    }: {
      cartItemId: undefined | number;
      newCartTotal: undefined | number;
    }) {
      if (newCartTotal) {
        this.grandtotal += newCartTotal;
      }

      if (cartItemId) {
        this.productStore.cartDetails.cart_items =
          this.productStore.cartDetails.cart_items.filter(
            (item) => item.id !== cartItemId
          );
      }
    },
  },
  async mounted() {
    document.title = "Cart | Fondstore";

    toggleLoading(true);
    await this.productStore.fetchCartItems();
    toggleLoading(false);

    this.grandtotal = this.productStore.cartDetails?.price_grand_total;
  },
});
</script>

<template>
  <div class="main-cart-page-container mt-20">
    <SecondHeaderVue
      main_header="Shopping Cart"
      product_gender="Men"
      bold_link="cart"
    />
    <!--:id="item.product.id"-->
    <div v-if="cartExist === true" class="main-cart-component-container">
      <div class="main-cart-product-details-container">
        <ProductdetailsVue
          v-for="each in cartItems"
          :key="each.id"
          @update_cart="handleCartUpdate"
          :product_id="each.item.id"
          :product_name="each.item.name"
          :favorite_id="each.item.favorite_id"
          :price="each.item.price"
          :size="each.item.size"
          :order_number="each.quantity"
          :liked="each.item.is_favourite"
          :totalprice="each.price_sub_total"
          :photo="each.item.image"
        />
      </div>
      <div class="main-cart-shipping-mode-container">
        <ShippingmodeVue
          :total_price="`${grandtotal}`"
          :subtotal_price="`${grandtotal}`"
          shipping_type="-"
          :store_pickup_check="true"
          :home_delivery_check="false"
        />
      </div>
    </div>
    <div v-if="cartExist === false && !isLoading" class="page-not-found">
      <div class="text-div">
        <h1>Oops! There's no item in your cart just yet.</h1>
        <p>Find your perfect and add them to your cart.</p>
      </div>

      <router-link to="/categories" class="form-div !w-auto">
        <input class="submit !cursor-pointer" type="button" value="Add item" />
      </router-link>
    </div>
  </div>
</template>

<style lang="scss">
.main-cart-page-container {
  height: auto;
  margin-bottom: 5rem;
  .main-cart-component-container {
    display: flex;
    margin-left: 80px;
    margin-right: 100px;
    gap: 2.13rem;
    margin-bottom: 1rem;
    justify-content: space-between;
    .main-cart-product-details-container {
      width: 65%;
    }
    .main-cart-shipping-mode-container {
      width: 33%;
      height: auto;
    }
  }
  .page-not-found {
    width: 100svw;
    height: 70vh;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    gap: 50px;
    .text-div {
      width: fit-content;
      display: flex;
      flex-direction: column;
      gap: 20px;
      text-align: center;
      padding: 0px 150px;
      h1 {
        color: var(--black-100, #0f172a);
        font-family: DM Sans;
        font-size: 32px;
        font-style: normal;
        font-weight: 500;
        line-height: normal;
      }
      p {
        color: var(--black-70, rgba(15, 23, 42, 0.7));
        text-align: center;
        font-family: DM Sans;
        font-size: 20px;
        font-style: normal;
        font-weight: 300;
        line-height: normal;
      }
    }

    .form-div {
      width: 30svw;
      display: flex;
      gap: 20px;
      justify-content: center;
      .submit {
        width: fit-content;
        display: flex;
        padding: 16px 24px;
        justify-content: center;
        align-items: center;
        border-radius: 30px;
        background: var(--Button-Default, #4385f5);
        border: none;
        color: var(--White, #fff);
        text-align: center;

        /* Regular */
        font-family: DM Sans;
        font-size: 14px;
        font-style: normal;
        font-weight: 300;
        line-height: 20px; /* 142.857% */
      }
    }
  }
}

@media only screen and (max-width: 940px) {
  .main-cart-page-container {
    .main-cart-component-container {
      display: flex;
      flex-direction: column;
      gap: 50px;
      margin: 0px;
      padding: 0px 23px;
      .main-cart-product-details-container {
        gap: 0.62rem;
        width: 100%;
      }
      .main-cart-shipping-mode-container {
        width: 100%;
      }
    }
    .page-not-found {
      height: 90vh;
      padding: 0px 50px;
      .text-div {
        padding: 0px;
        h1 {
          color: var(--black-100, #0f172a);
          text-align: center;

          /* Medium */
          font-family: DM Sans;
          font-size: 18px;
          font-style: normal;
          font-weight: 500;
          line-height: normal;
        }
        p {
          color: var(--black-50, rgba(15, 23, 42, 0.5));
          text-align: center;

          /* Regular */
          font-family: DM Sans;
          font-size: 14px;
          font-style: normal;
          font-weight: 300;
          line-height: 20px; /* 142.857% */
        }
      }
      .form-div {
        width: 100%;
      }
    }
  }
}
</style>
