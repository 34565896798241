import { createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, toDisplayString as _toDisplayString, normalizeStyle as _normalizeStyle, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = ["src"]
const _hoisted_2 = { class: "product-details-name-text font-class" }
const _hoisted_3 = { class: "product-details-price-text font-class" }
const _hoisted_4 = {
  key: 0,
  class: "product-details-order-number-container"
}
const _hoisted_5 = ["disabled"]
const _hoisted_6 = { class: "order-number-text-container" }
const _hoisted_7 = { class: "order-number-text font-class" }
const _hoisted_8 = ["disabled"]
const _hoisted_9 = {
  key: 1,
  class: "product-details-price-container"
}
const _hoisted_10 = { class: "product-details-price-text font-class" }
const _hoisted_11 = { class: "product-details-liked-button-container" }
const _hoisted_12 = { class: "liked-button-container" }
const _hoisted_13 = ["fill"]
const _hoisted_14 = ["stroke"]
const _hoisted_15 = { class: "remove-button" }
const _hoisted_16 = {
  key: 3,
  class: "remove-button-container"
}
const _hoisted_17 = ["disabled"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_router_link = _resolveComponent("router-link")!
  const _component_box_icon = _resolveComponent("box-icon")!

  return (_openBlock(), _createElementBlock("div", {
    class: "fc-product-details-container",
    style: _normalizeStyle(_ctx.menu_choice === 'Favorite' ? 'justify-content: none;' : 'gap: 15px;')
  }, [
    _createVNode(_component_router_link, {
      to: `/products/${_ctx.slug}`,
      class: "product-details-picture"
    }, {
      default: _withCtx(() => [
        _createElementVNode("img", {
          src: _ctx.photo,
          loading: "lazy"
        }, null, 8, _hoisted_1)
      ]),
      _: 1
    }, 8, ["to"]),
    _createElementVNode("div", {
      class: "product-details-name-price-container",
      style: _normalizeStyle(_ctx.menu_choice === 'Favorite' ? 'flex-grow: 1;' : 'flex-grow: 1;')
    }, [
      _createElementVNode("span", _hoisted_2, _toDisplayString(_ctx.product_name), 1),
      _createElementVNode("span", _hoisted_3, "₦" + _toDisplayString(_ctx.price), 1)
    ], 4),
    (_ctx.menu_choice === 'Cart')
      ? (_openBlock(), _createElementBlock("div", _hoisted_4, [
          _createElementVNode("button", {
            onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.updateCart(-1))),
            disabled: _ctx.updatingCart,
            class: "order-number-container font-class disabled:cursor-not-allowed"
          }, [
            _createVNode(_component_box_icon, { name: "minus" })
          ], 8, _hoisted_5),
          _createElementVNode("div", _hoisted_6, [
            _createElementVNode("span", _hoisted_7, _toDisplayString(_ctx.unupdated_quantity), 1)
          ]),
          _createElementVNode("button", {
            onClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.updateCart(+1))),
            disabled: _ctx.updatingCart,
            class: "order-number-container font-class disabled:cursor-not-allowed"
          }, [
            _createVNode(_component_box_icon, { name: "plus" })
          ], 8, _hoisted_8)
        ]))
      : _createCommentVNode("", true),
    (_ctx.menu_choice === 'Cart')
      ? (_openBlock(), _createElementBlock("div", _hoisted_9, [
          _createElementVNode("span", _hoisted_10, "₦" + _toDisplayString(_ctx.renderedTotalPrice), 1)
        ]))
      : _createCommentVNode("", true),
    _createElementVNode("div", _hoisted_11, [
      _createElementVNode("div", _hoisted_12, [
        _createElementVNode("button", {
          onClick: _cache[2] || (_cache[2] = 
//@ts-ignore
(...args) => (_ctx.handleFavourite && _ctx.handleFavourite(...args)))
        }, [
          (_openBlock(), _createElementBlock("svg", {
            viewBox: "0 0 26 24",
            fill: _ctx.is_favorite ? '#4385F5' : 'none',
            xmlns: "http://www.w3.org/2000/svg"
          }, [
            _createElementVNode("path", {
              d: "M13.723 22.2789C13.3263 22.4189 12.673 22.4189 12.2763 22.2789C8.89301 21.1239 1.33301 16.3055 1.33301 8.13885C1.33301 4.53385 4.23801 1.61719 7.81967\n                        1.61719C9.94301 1.61719 11.8213 2.64385 12.9997 4.23052C14.178 2.64385 16.068 1.61719 18.1797 1.61719C21.7613 1.61719 24.6663\n                        4.53385 24.6663 8.13885C24.6663 16.3055 17.1063 21.1239 13.723 22.2789Z",
              stroke: !_ctx.is_favorite ? 'currentColor' : 'none',
              "stroke-width": "1",
              "stroke-linecap": "round",
              "stroke-linejoin": "round"
            }, null, 8, _hoisted_14)
          ], 8, _hoisted_13))
        ])
      ])
    ]),
    (_ctx.menu_choice === 'Favorite')
      ? (_openBlock(), _createElementBlock("div", {
          key: 2,
          class: "remove-button-container",
          onClick: _cache[3] || (_cache[3] = 
//@ts-ignore
(...args) => (_ctx.handleFavourite && _ctx.handleFavourite(...args)))
        }, [
          _createElementVNode("button", _hoisted_15, [
            _createVNode(_component_box_icon, { name: "x" })
          ])
        ]))
      : _createCommentVNode("", true),
    (_ctx.menu_choice === 'Cart')
      ? (_openBlock(), _createElementBlock("div", _hoisted_16, [
          _createElementVNode("button", {
            onClick: _cache[4] || (_cache[4] = 
//@ts-ignore
(...args) => (_ctx.removeItemCart && _ctx.removeItemCart(...args))),
            disabled: _ctx.updatingCart,
            class: "remove-button"
          }, [
            _createVNode(_component_box_icon, { name: "x" })
          ], 8, _hoisted_17)
        ]))
      : _createCommentVNode("", true)
  ], 4))
}