<template>
  <section>
    <!-- intro -->
    <div class="intro">
      <h4>Trending</h4>
      <p>
        Step into a world where style is celebrated and individuality shines.
      </p>

      <!-- categories -->
      <div class="flex justify-center px-10 my-10">
        <Tabs
          :tab_list="homeStore.trendingTabList"
          :value="homeStore.activeTrendingTab"
          query_name="trending"
          @model-value="($event) => (homeStore.activeTrendingTab = $event)"
          class="inline-flex gap-x-1 sm:gap-x-2 justify-between select-none rounded-[100px] [&>*]:flex-grow [&>*]:max-w-[50%] text-xs sm:text-base border border-gray-400 max-w-[90vw] overflow-x-auto hide-scrollbar"
        >
          <template v-slot:tab="{ tab, index: i, is_active }">
            <div
              :class="[
                is_active
                  ? 'bg-primary text-white'
                  : 'bg-transparent hover:text-primary',
              ]"
              class="p-3 transition duration-300 font-medium cursor-pointer rounded-[100px] min-w-max"
            >
              <span class="flex flex-col items-center">
                <span>{{ tab?.name }}</span>
              </span>
            </div>
          </template>
        </Tabs>
      </div>
    </div>

    <!-- filter -->
    <div>
      <!-- <div class="filter">
        <div>
          <button id="fil">
            <p>Filter</p>
            <img src="../assets/images/icons/filter.png" alt="" />
          </button>
          <div id="filter">
            <span
              ><p>Size</p>
              <img src="../assets/images/icons/down.png" alt=""
            /></span>
            <span
              ><p>Style</p>
              <img src="../assets/images/icons/down.png" alt=""
            /></span>
            <span
              ><p>Color</p>
              <img src="../assets/images/icons/down.png" alt=""
            /></span>
          </div>
        </div>
        <button id="clear"><p>clear all filters</p></button>
      </div> -->
      <!-- filter options -->
      <!-- <div id="overlay" class="hidden fixed inset-0 bg-black opacity-50"></div>

      <Color />
      <Styles />
      <Size /> -->
      <!-- <div class="filops"> -->
      <!-- </div> -->
    </div>

    <!-- images -->
    <div class="container">
      <div id="image">
        <ItemCard
          v-for="item in renderedTrendingItems.results"
          :key="item.id"
          :id="item.id"
          :favorite_id="item.favorite_id"
          :name="item.name"
          :price="item.price"
          :new_price="item.new_price"
          :image="item.image"
          :slug="item.slug"
          :is_favourite="item.is_favourite"
        >
        </ItemCard>
      </div>

      <div class="w-full flex justify-center pb-10 mt-10">
        <Pagination
          :data="renderedTrendingItems"
          @fetch-more="fetchMore"
          :loading="fetchingMore"
        />
      </div>
    </div>
    <div
      v-if="fetching"
      class="h-96 flex items-center justify-center flex-col text-2xl gap-3 font-medium text-gray-300"
    >
      <span> Fetching Trending items... </span>
      <Spinner class="w-8 text-gray-500" />
    </div>
  </section>
</template>

<script setup lang="ts">
import ItemCard from "@/components/ItemCard.vue";
import Pagination from "@/components/Pagination.vue";
import Spinner from "@/components/Spinner.vue";
import Tabs from "@/components/Tabs.vue";
import { useHomeStore } from "@/store/home";
import axios from "axios";
import { computed, onMounted, ref } from "vue";

const homeStore = useHomeStore();
const fetching = ref(false);
onMounted(async () => {
  fetching.value = true;
  await homeStore.initTrending();
  fetching.value = false;
});
const renderedTrendingItems = computed(() => {
  return (
    homeStore.getTrendingItems(
      String(homeStore.activeTrendingTab).toLowerCase() || "clothes"
    ) || []
  );
});

const fetchingMore = ref(false);
const fetchMore = (link: string) => {
  fetchingMore.value = true;
  axios
    .get(link)
    .then(({ data }) => {
      homeStore.setTrendingItems({
        category:
          String(homeStore.activeTrendingTab).toLowerCase() || "clothes",
        data,
      });
    })
    .finally(() => {
      fetchingMore.value = false;
    });
};
</script>

<style lang="scss">
section {
  margin-top: 3rem;
  width: 100%;

  .intro {
    display: flex;
    flex-direction: column;
    row-gap: 2rem;
    // justify-content: center;
    // align-items: center;

    h4 {
      font-size: 2rem;
    }

    p {
      font-size: 1rem;
      text-align: center;
    }

    #cats {
      margin-inline: auto;
      display: flex;
      width: fit-content;
      height: fit-content;
      justify-content: center;
      align-items: center;
      border-radius: 3.875rem;
      border: 1px solid var(--black-30, rgba(15, 23, 42, 0.3));
      cursor: pointer;

      span {
        display: flex;
        padding-inline: 2rem;
        padding-block: 0.6rem;
        justify-content: center;
        align-items: center;

        p {
          font-size: 16px;
        }
      }

      span:hover {
        color: white;
        border-radius: 3.3125rem;
        background: var(--Button-Default, #4385f5);
      }
    }
  }

  // filter
  .filter {
    width: 90%;
    margin-inline: auto;
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 2rem;

    div {
      display: flex;
      justify-content: space-between;
      align-items: center;
      column-gap: 5rem;

      button {
        display: flex;
        justify-content: space-between;
        align-items: center;
        background: transparent;
        column-gap: 1rem;
        cursor: pointer;
      }
    }

    #filter {
      display: flex;
      justify-content: space-between;
      align-items: center;
      column-gap: 1rem;

      span {
        padding-inline: 1rem;
        padding-block: 0.4rem;
        display: flex;
        justify-content: space-between;
        align-items: center;
        column-gap: 1rem;
        width: fit-content;
        height: fit-content;
        border-radius: 3.875rem;
        border: 1px solid var(--black-30, rgba(15, 23, 42, 0.3));
        cursor: pointer;
      }
    }

    #clear {
      background: transparent;
      text-transform: capitalize;
      cursor: pointer;
    }

    #clear:hover {
      color: #4385f5;
    }
  }

  // products
  .container {
    width: 90%;
    margin-inline: auto;
    margin-bottom: 2rem;

    #image {
      position: relative;
      display: grid;
      padding: 1rem;
      grid-template-columns: repeat(3, 1fr);
      gap: 1rem;

      #single {
        div {
          position: relative;
          background-image: url("../assets/images/product-photos/Rectangle417arrivals.png");
          background-repeat: no-repeat;
          background-size: cover;
          border-top-left-radius: 12px;
          border-top-right-radius: 12px;
          max-width: 24rem;
          height: 29rem;

          span {
            position: absolute;
            bottom: 1rem;
            right: 1.5rem;
            display: flex;
            justify-content: center;
            align-items: center;
            width: 2.5rem;
            height: 2.5rem;
            border-radius: 100%;
            background: #fff;
            cursor: pointer;
          }
        }

        #text {
          display: flex;
          justify-content: space-between;
          align-items: center;
          margin-top: 1rem;

          span {
            text-align: start;
            row-gap: 0.6rem;

            p {
              font-size: 1.1rem;
            }

            #price {
              font-weight: 600;
              font-size: 1.2rem;
            }
          }

          button {
            text-transform: capitalize;
            color: var(--Button-Default, #4385f5);
            font-size: 1rem;
            font-weight: 500;
            cursor: pointer;
            background-color: transparent;
          }
        }
      }
    }
  }
}

@media only screen and (max-width: 940px) {
  section {
    .container {
      #image {
        display: grid;
        grid-template-columns: repeat(2, 1fr);
      }
    }
  }
}

@media only screen and (max-width: 426px) {
  section {
    .intro {
      h4 {
        font-size: 1.5rem;
      }

      p {
        font-size: 14px;
        padding-inline: 10px;
      }
      #cats {
        span {
          display: flex;
          padding-inline: 0.5rem;
          padding-block: 0.5rem;
          justify-content: center;
          align-items: center;

          p {
            font-size: 12px;
          }
        }
      }
    }

    #fil {
      border: 1px solid var(--black-30, rgba(15, 23, 42, 0.3));
      padding-block: 0.5rem;
      padding-inline: 0.8rem;
      border-radius: 10px;
    }
    .filter {
      #filter {
        display: none;
      }
    }
    .container {
      #image {
        padding: 0;
        grid-template-columns: repeat(1, 1fr);
      }
    }
  }
}
</style>
