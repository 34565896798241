<template>
  <section class="categories">
    <div id="cats">
      <h1>Categories</h1>
      <p>
        At FondStore, our diverse collection and categories caters to every
        style and preference, ensuring that there's something extraordinary
        waiting for everyone.
      </p>
    </div>
    <CategoriesSection :categories="data" />
    <div
      v-if="isLoading || isFetching"
      class="h-96 flex items-center justify-center
      flex-col text-2xl gap-3 font-medium text-gray-300">
      <span>
        Fetching Categories...
      </span>
      <Spinner class="w-8 text-gray-500" />
    </div>
  </section>
</template>

<script setup lang="ts">
import Spinner from "@/components/Spinner.vue";
import CategoriesSection from "@/components/CategoriesSection.vue";
import { useQuery } from "@tanstack/vue-query";
import { fetchCategories } from "@/utils";

const { data, isLoading, isFetching, } = useQuery({
  queryKey: ["categories"],
  queryFn: fetchCategories,
})

</script>

<style lang="scss">
section {
  padding-top: 3rem;
}
.categories {
  width: 41rem;
  margin-inline: auto;
  margin-block: 3rem;

  #cats h1 {
    text-align: center;
    font-weight: 800;
    font-size: 30px;
  }

  #cats p {
    padding-block: 1.5em;
    padding-inline: 2em;
    text-align: center;
    font-size: 15px;
  }

  .cats {
    margin-top: 1em;
    position: relative;
    display: grid;
    grid-template-rows: 2;
    grid-template-columns: 2;
    gap: 1em;

    p {
      bottom: 1em;
      left: 50%;
      transform: translate(-50%, -50%);
      color: white;
      font-size: 1em;
      position: absolute;
    }

    .cat1,
    .cat2,
    .cat3 {
      position: relative;
      background-size: cover;
      background-position: center;
      background-repeat: no-repeat;
      margin-inline: auto;
      border-radius: 10px;
      cursor: pointer;
      // width: max-content;
      // height: max-content;
    }
    .cat1 {
      background-image: url("../assets/images/fit.png");
      width: 20rem;
      height: 21rem;
      grid-column-start: 1;
      grid-column-end: 2;
      grid-row-start: 1;
      grid-row-end: 3;
    }

    .cat2 {
      background-image: url("../assets/images/gadgets.png");
      width: 20rem;
      height: 10rem;
      grid-column-start: 2;
      grid-column-end: 3;
      grid-row-start: 1;
      grid-row-end: 2;
    }

    .cat3 {
      background-image: url("../assets/images/shoes.png");
      width: 20rem;
      height: 10rem;
      grid-column-start: 2;
      grid-column-end: 3;
      grid-row-start: 2;
      grid-row-end: 3;
    }
  }
}

@media only screen and (max-width: 940px) {
  .categories {
    width: 20em;
    #cats h1 {
      text-align: center;
      font-weight: 800;
      font-size: 20px;
    }

    #cats p {
      padding-block: 1.5em;
      padding-inline: 2em;
      text-align: center;
      font-size: 10px;
    }
    .cats {
      display: flex;
      flex-direction: column;
      row-gap: 2em;
      padding-inline: 10px;

      .cat1,
      .cat2,
      .cat3 {
        width: 18rem;
        height: 19rem;
      }

      div {
        border-radius: 10px;
        max-width: 20em;
        max-height: 20em;
      }
    }
  }
}
</style>