<script lang="ts">
import { defineComponent } from 'vue'

export default defineComponent({
    name: 'FourthHeader',
    props: {
        main_header: String,
    }
})
</script>

<template>
    <nav class="second-headers-nav">
        <div class="header-nav-main-text-container">
            <span class="main-text font-class">{{ main_header }}</span>
        </div>
    </nav>
</template>

<style lang="scss" scoped>
.font-class {
    font-family: 'DM Sans', sans-serif;
    font-style: normal;
}
.second-headers-nav {
    margin-bottom: 29px; 
    .header-nav-main-text-container {
        display: flex;
        height: auto;
        span {
            font-size: 18px;
            font-weight: 500;
            line-height: normal;
            text-transform: uppercase;
        }
    }
}

@media only screen and (max-width: 940px) {
    .second-headers-nav {
        display: none;
    }
}
</style>