/* stores/helpCenter.js */
import { defineStore } from 'pinia';
import axios from 'axios';

export const useHelpCenterStore = defineStore('help-center', {
  state: () => ({
    helpCenterData: null as any,
    socials: [] as  { name: string, icon: string, url: string }[],
    fetching: true,
  }),
  getters: {
    getSocials: (state) => {
      const all = state.socials.length ? state.socials : [];
      if(!all) return [];
      return all.map((social: any) => {
        return {
          ...social,
          icon: social.icon.toLowerCase()
        }
      })
    }
  },
  actions: {
    async fetchHelpCenterData() {
      this.fetching = true;
      return new Promise((resolve, reject) => {
        // using timeout here because for some reason, without it
        // baseUrl would not be defined (this is the only place it happens)
        setTimeout(async() => {
          try {
            const {data} = await axios.get('/manager/help_centre');
            this.helpCenterData = data;
            resolve(data);
          } catch (error) {
            console.error(error);
            reject(error);
          } finally {
            this.fetching = false;
          }
        })
      })
    },
    async fetchSocials() {
      this.fetching = true;
      return new Promise((resolve, reject) => {
        setTimeout(async() => {
          try {
            const {data} = await axios.get('/manager/socials');
            this.socials = data;
            resolve(data);
          } catch (error) {
            console.error(error);
            reject(error);
          } finally {
            this.fetching = false;
          }
        })
      })
    }
  }
});