<script lang="ts">
import { defineComponent } from 'vue';
import User from '@/components/UserComponents/UserDetails.vue'
import PersonalInfo from '@/components/UserComponents/PersonalInformation.vue'
import Address from '@/components/UserComponents/Address.vue'

export default defineComponent ({
    name: 'MyProfile',
    components: {
        User,
        PersonalInfo,
        Address,
    },
})
</script>

<template>
    <div class="general-container">
        <User />
        <PersonalInfo
        first_name="Sandra"
        last_name="Osondu"
        email="sandraosondu@'gmail.com'"
        phone_number="08012345678" />
        <!--<Address />-->
    </div>
</template>

<style lang="scss" scoped>
.general-container {
    display: flex;
    flex-direction: column;
    gap: 64px;
}
</style>