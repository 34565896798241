<script lang="ts">
export default ({
    name: 'PageNotFound'
})
</script>

<template>
    <div class="page-not-found">
        <h1> Link broken </h1>
        <p>We could not find that page. But you can go to the 
        <router-link class="router-link" to="/"> homepage</router-link>. Please ensure the page exists. </p>
    </div>
</template>

<style lang="scss" scoped>
.page-not-found {
    width: 100svw;
    height: 80svh;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    gap: 1.5rem;
    h1 {
        font-family: 'DM Sans', sans-serif;
        font-size: 3rem;
        font-style: normal;
        color: #0f172a;
        font-weight: 500;
    }
    p {
        font-family: 'DM Sans', sans-serif;
        font-size: 1rem;
        font-style: normal;
        color: #0f172a;
        a.router-link {
            text-decoration: underline;
            color: #0f172a;
        }
    }
}
</style>