import { createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode } from "vue"

const _hoisted_1 = { class: "main-item-component-container" }
const _hoisted_2 = { class: "item-picture" }
const _hoisted_3 = ["src"]
const _hoisted_4 = { class: "liked-button" }
const _hoisted_5 = { class: "item-details-container" }
const _hoisted_6 = { class: "name-price-container" }
const _hoisted_7 = { class: "name-detail-container" }
const _hoisted_8 = { class: "price-detail-container" }
const _hoisted_9 = { class: "price-text font-class" }
const _hoisted_10 = { class: "buy-button-container" }
const _hoisted_11 = ["disabled"]
const _hoisted_12 = { class: "buy-button-text font-class" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_router_link = _resolveComponent("router-link")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createVNode(_component_router_link, {
        to: '/products/' + _ctx.slug + '/'
      }, {
        default: _withCtx(() => [
          _createElementVNode("img", {
            src: _ctx.picture_url,
            alt: "Product Photo",
            loading: "lazy"
          }, null, 8, _hoisted_3)
        ]),
        _: 1
      }, 8, ["to"]),
      _createElementVNode("div", _hoisted_4, [
        (_ctx.liked_to_favorite == false)
          ? (_openBlock(), _createElementBlock("svg", {
              key: 0,
              onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.addToFavorites && _ctx.addToFavorites(...args))),
              viewBox: "0 0 26 24",
              fill: "none",
              xmlns: "http://www.w3.org/2000/svg"
            }, _cache[3] || (_cache[3] = [
              _createElementVNode("path", {
                d: "M13.723 22.2789C13.3263 22.4189 12.673 22.4189 12.2763 22.2789C8.89301 21.1239 1.33301 16.3055 1.33301 8.13885C1.33301 4.53385 4.23801 1.61719 7.81967\n                    1.61719C9.94301 1.61719 11.8213 2.64385 12.9997 4.23052C14.178 2.64385 16.068 1.61719 18.1797 1.61719C21.7613 1.61719 24.6663\n                    4.53385 24.6663 8.13885C24.6663 16.3055 17.1063 21.1239 13.723 22.2789Z",
                stroke: "#0F172A",
                "stroke-width": "1.5",
                "stroke-linecap": "round",
                "stroke-linejoin": "round"
              }, null, -1)
            ])))
          : _createCommentVNode("", true),
        (_ctx.liked_to_favorite == true)
          ? (_openBlock(), _createElementBlock("svg", {
              key: 1,
              onClick: _cache[1] || (_cache[1] = 
//@ts-ignore
(...args) => (_ctx.addToFavorites && _ctx.addToFavorites(...args))),
              viewBox: "0 0 26 24",
              fill: "#4385F5",
              xmlns: "http://www.w3.org/2000/svg"
            }, _cache[4] || (_cache[4] = [
              _createElementVNode("path", {
                d: "M13.723 22.2789C13.3263 22.4189 12.673 22.4189 12.2763 22.2789C8.89301 21.1239 1.33301 16.3055 1.33301 8.13885C1.33301 4.53385 4.23801 1.61719 7.81967\n                    1.61719C9.94301 1.61719 11.8213 2.64385 12.9997 4.23052C14.178 2.64385 16.068 1.61719 18.1797 1.61719C21.7613 1.61719 24.6663\n                    4.53385 24.6663 8.13885C24.6663 16.3055 17.1063 21.1239 13.723 22.2789Z",
                stroke: "#0F172A",
                "stroke-width": "0",
                "stroke-linecap": "round",
                "stroke-linejoin": "round"
              }, null, -1)
            ])))
          : _createCommentVNode("", true)
      ])
    ]),
    _createElementVNode("div", _hoisted_5, [
      _createVNode(_component_router_link, {
        to: '/products/' + _ctx.slug + '/'
      }, {
        default: _withCtx(() => [
          _createElementVNode("div", _hoisted_6, [
            _createElementVNode("div", _hoisted_7, [
              _createVNode(_component_router_link, {
                to: '/products/' + _ctx.slug + '/',
                class: "name-text font-class"
              }, {
                default: _withCtx(() => [
                  _createTextVNode(_toDisplayString(_ctx.item_name), 1)
                ]),
                _: 1
              }, 8, ["to"])
            ]),
            _createElementVNode("div", _hoisted_8, [
              _createElementVNode("span", _hoisted_9, "₦" + _toDisplayString(_ctx.price), 1)
            ])
          ])
        ]),
        _: 1
      }, 8, ["to"]),
      _createElementVNode("div", _hoisted_10, [
        _createElementVNode("button", {
          disabled: _ctx.buttonDisabled,
          onClick: _cache[2] || (_cache[2] = 
//@ts-ignore
(...args) => (_ctx.addToCart && _ctx.addToCart(...args))),
          class: "buy-button"
        }, [
          _createElementVNode("span", _hoisted_12, _toDisplayString(_ctx.cartbtn), 1)
        ], 8, _hoisted_11)
      ])
    ])
  ]))
}