<template>
  <header>
    <router-link to="/" id="logo"
      ><img src="../assets/images/icons/logonew.png" alt="Fondstore logo"
    /></router-link>
    <!-- <div id="learnmore">
            <p>Learn More</p>
        </div> -->
  </header>
</template>

<style lang="scss" scoped>
header {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

#logo {
  width: 178px;
  height: 31px;
}

#learnmore {
  color: var(--button-default, #4385f5);
  font-family: DM Sans;
  font-size: 16px;
  font-style: normal;
  font-weight: 300;
  line-height: normal;
}
</style>
