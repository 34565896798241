import { defineComponent as _defineComponent } from 'vue'
import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, resolveComponent as _resolveComponent, createVNode as _createVNode } from "vue"

const _hoisted_1 = { class: "grid mt-3 relative md:grid-cols-2 grid-rows-3 md:grid-rows-2 gap-3 grid-flow-dense [&>*]:rounded-lg md:[&>*:nth-child(odd)]:row-span-2 [&>*:nth-child(even)]:row-span-1 [&>*]:flex [&>*]:justify-center [&>*]:bg-gray-100 [&>*]:relative [&>*]:overflow-hidden" }
const _hoisted_2 = ["src"]
const _hoisted_3 = { class: "absolute bottom-0 text-2xl mb-6 font-medium text-white drop-shadow shadow-black" }

import { Category } from '@/router/types';


export default /*@__PURE__*/_defineComponent({
  __name: 'CategoriesSection',
  props: {
    categories: {}
  },
  setup(__props: any) {



return (_ctx: any,_cache: any) => {
  const _component_router_link = _resolveComponent("router-link")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.categories, (cat, i) => {
      return (_openBlock(), _createElementBlock("div", { key: i }, [
        _createElementVNode("img", {
          src: cat.image,
          class: "w-full h-full object-cover relative"
        }, null, 8, _hoisted_2),
        _createElementVNode("h3", _hoisted_3, _toDisplayString(cat.name), 1),
        _createVNode(_component_router_link, {
          to: `/categories/${cat.name.toLocaleLowerCase()}`,
          class: "absolute inset-0"
        }, null, 8, ["to"])
      ]))
    }), 128))
  ]))
}
}

})