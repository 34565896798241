import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode } from "vue"
import _imports_0 from '../assets/images/signup1.png'


const _hoisted_1 = { id: "register" }
const _hoisted_2 = {
  key: 0,
  id: "details"
}
const _hoisted_3 = {
  action: "",
  method: "post"
}
const _hoisted_4 = { class: "input" }
const _hoisted_5 = ["value"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_AuthHeader = _resolveComponent("AuthHeader")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (_ctx.status === 'linksent')
      ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
          _createVNode(_component_AuthHeader),
          _createElementVNode("form", _hoisted_3, [
            _cache[1] || (_cache[1] = _createElementVNode("h3", { class: "subbigtext" }, "You have been verified!", -1)),
            _cache[2] || (_cache[2] = _createElementVNode("p", null, "Proceed to login", -1)),
            _createElementVNode("div", _hoisted_4, [
              _createElementVNode("input", {
                onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.signIn())),
                type: "button",
                name: "submit",
                value: _ctx.buttonValue,
                id: "form-button"
              }, null, 8, _hoisted_5)
            ])
          ])
        ]))
      : _createCommentVNode("", true),
    _cache[3] || (_cache[3] = _createElementVNode("div", { id: "photo-div" }, [
      _createElementVNode("img", {
        src: _imports_0,
        alt: "A black shirt with diagonal tiles on a lady"
      })
    ], -1))
  ]))
}