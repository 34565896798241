<script lang="ts">
import { computed, defineComponent, ref } from 'vue'
import axios from 'axios';
import { useRoute } from 'vue-router';
import { toggleLoading } from '@/event-bus';
import { useUserStore } from "@/store/user"
import { useProductStore } from "@/store/product"
import { toast } from 'vue3-toastify';
import 'vue3-toastify/dist/index.css';

export default defineComponent({
    name: 'Productdetails',
    data() {
        return {
            menu_choice: '',
            inc_quantity: +1,
            dec_quantity: -1,
            l_order_number: this.order_number,
            is_favorite: false,
            just_favorited_id: '',
        }
    },
    props: {
        product_name: String,
        price: Number,
        size: Number,
        order_number: Number,
        liked: Boolean,
        product_id: String,
        favorite_id: String,
        totalprice: Number,
        photo: String,
        slug: String,
        favoriting_id: String,
    },
    emits: ['update_favorite', 'update_cart'],
    setup(props){
        const userStore = useUserStore();
        const productStore = useProductStore();
        const updated_quantity = ref(0)
        const unupdated_quantity = ref(0)
        const timeout = ref(0)
        const updatingCart = ref(false)
        const renderedTotalPrice = computed(() => props.price! * unupdated_quantity.value)

        return {
            updated_quantity,
            unupdated_quantity,
            userStore,
            productStore,
            timeout,
            updatingCart,
            renderedTotalPrice,
        }
    },
    mounted() {
        this.is_favorite = this.liked!;
        this.updated_quantity = this.unupdated_quantity = this.order_number!;
    },
    methods: {
        async handleFavourite() {
            const id = toast.loading(
                !!this.is_favorite ? 'Removing from favorites...' : 'Adding to favorites...',
                {
                    position: toast.POSITION.TOP_RIGHT,
                },
            );
            try {
                const res = await this.productStore.favoriteItem({
                    item_id: this.product_id!,
                    favorite_id: this.just_favorited_id || this.favorite_id!,
                    isFavorite: this.is_favorite
                })
                toast.update(id, {
                    render: "Done",
                    type: 'success',
                    isLoading: false,
                });
                this.is_favorite = !this.is_favorite;
                this.just_favorited_id = res?.favorite_id;
                this.$emit('update_favorite', this.product_id);
            } catch(error) {
                toast.update(id, {
                    render: "Error",
                    type: 'error',
                    isLoading: false,
                });
                console.log(error)
            } finally {
                setTimeout(() => toast.remove(id), 1000);
            }
        },
        async updateCart(amount: number){
            if ((this.unupdated_quantity! + amount) < 1) return
            // if the value is negative, it means the user has decreased the quantity
            // if the value is positive, it means the user has increased the quantity
            this.unupdated_quantity = this.unupdated_quantity! + amount;
            
            // using timeouts so that the request is not sent on every keypress, ie
            // immediately user increments/decrements the quantity
            if(!!this.timeout) clearTimeout(this.timeout);
            this.timeout = setTimeout(async () => {
                const id = toast.loading(
                    'Updating cart...',
                    {
                        position: toast.POSITION.TOP_RIGHT,
                    },
                );
                this.updatingCart = true;

                const newUpdatedValue = this.unupdated_quantity - this.updated_quantity
                try {
                    await this.productStore.updateItemInCart({
                        itemId: this.product_id!,
                        newQuantity: newUpdatedValue,
                    })
                    // once the request completes
                    this.$emit('update_cart', {
                        newCartTotal: (this.price! * this.unupdated_quantity) - (this.price! * this.updated_quantity!),
                    });
                    this.updated_quantity = this.unupdated_quantity;
                    toast.update(id, {
                        render: "Cart updated",
                        type: 'success',
                        isLoading: false,
                    });

                } catch(error) {
                    toast.update(id, {
                        render: "Error updating cart",
                        type: 'error',
                        isLoading: false,
                    });
                    console.log(error)
                } finally {
                    this.updatingCart = false;
                    setTimeout(() => toast.remove(id), 1000);
                }
            }, 1000);
        },

        async removeItemCart() {
            const id = toast.loading(
                'Removing from cart...',
                {
                    position: toast.POSITION.TOP_RIGHT,
                },
            );
            this.updatingCart = true;
            try {
                const {cartItemId} = await this.productStore.removeItemFromCart({itemId:this.product_id!})
                toast.update(id, {
                    render: "Item removed from cart",
                    type: 'success',
                    isLoading: false,
                });
                this.$emit('update_cart', {cartItemId, newCartTotal: -(this.price! * this.updated_quantity!)});
            } catch(error) {
                toast.update(id, {
                    render: "Error removing item from cart",
                    type: 'error',
                    isLoading: false,
                });
                console.log(error)
            } finally {
                this.updatingCart = false;
                setTimeout(() => toast.remove(id), 1000);
            }
        },

        getMenuChoice() {
            const route = useRoute();
            const routerChoice = route?.name;

            if (routerChoice == 'FavoritePage') {
                this.menu_choice = 'Favorite'
            } else if (routerChoice == 'CartPage') {
                this.menu_choice = 'Cart'
            }
        },
    },

    watch: {
        $route(to: { name: string; }, from: any) {
            if (to.name === 'CartPage') {
                this.menu_choice = 'Cart'
            } else if (to.name === 'FavoritePage') {
                this.menu_choice = 'Favorite'
            }
        },

    },
    created() {
        this.getMenuChoice();
    },
})
</script>

<template>
    <div class="fc-product-details-container" :style="menu_choice === 'Favorite' ? 'justify-content: none;' : 'gap: 15px;'">
        <router-link
            :to="`/products/${slug}`"
            class="product-details-picture">
            <img :src="photo" loading="lazy">
        </router-link>
        <div class="product-details-name-price-container" :style="menu_choice === 'Favorite' ? 'flex-grow: 1;' : 'flex-grow: 1;'">
            <span class="product-details-name-text font-class">{{ product_name }}</span>
            <span class="product-details-price-text font-class">₦{{ price }}</span>
        </div>
        <!--
            <div v-if="menu_choice === 'Cart'" class="product-details-size-container">
            <span class="product-details-size-text font-class">{{ size }} L</span>
            <button class="product-details-size-button">
                <box-icon name='chevron-down'></box-icon>
            </button>
        </div>
        -->
        <div v-if="menu_choice === 'Cart'" class="product-details-order-number-container">
            <button
                @click="updateCart(-1)"
                :disabled="updatingCart"
                class="order-number-container font-class disabled:cursor-not-allowed">
                <box-icon name='minus'></box-icon>
            </button>
            <div class="order-number-text-container">
                <span class="order-number-text font-class">{{ unupdated_quantity }}</span>
            </div>
            <button
                @click="updateCart(+1)"
                :disabled="updatingCart"
                class="order-number-container font-class disabled:cursor-not-allowed">
                <box-icon name='plus'></box-icon>
            </button>
        </div>
        <div v-if="menu_choice === 'Cart'" class="product-details-price-container">
            <span class="product-details-price-text font-class">₦{{ renderedTotalPrice }}</span>
        </div>

        <div class="product-details-liked-button-container">
            <div class="liked-button-container">
                <button @click="handleFavourite">
                    <svg
                        viewBox="0 0 26 24" :fill="is_favorite ? '#4385F5' : 'none'" xmlns="http://www.w3.org/2000/svg">
                        <path d="M13.723 22.2789C13.3263 22.4189 12.673 22.4189 12.2763 22.2789C8.89301 21.1239 1.33301 16.3055 1.33301 8.13885C1.33301 4.53385 4.23801 1.61719 7.81967
                        1.61719C9.94301 1.61719 11.8213 2.64385 12.9997 4.23052C14.178 2.64385 16.068 1.61719 18.1797 1.61719C21.7613 1.61719 24.6663
                        4.53385 24.6663 8.13885C24.6663 16.3055 17.1063 21.1239 13.723 22.2789Z" :stroke="!is_favorite ? 'currentColor' : 'none'" stroke-width="1" stroke-linecap="round" stroke-linejoin="round"/>
                    </svg>
                </button>
            </div>
        </div>
        <div v-if="menu_choice === 'Favorite'" class="remove-button-container" @click="handleFavourite">
            <button class="remove-button">
                <box-icon name='x'></box-icon>
            </button>
        </div>
        <div
            v-if="menu_choice === 'Cart'"
            class="remove-button-container">
            <button @click="removeItemCart" :disabled="updatingCart" class="remove-button">
                <box-icon name='x'></box-icon>
            </button>
        </div>
    </div>
</template>

<style lang="scss" scoped>
.font-class {
    font-family: 'DM Sans', sans-serif;
    font-style: normal;
}
.fc-product-details-container {
    display: flex;
    width: 100%;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 1.5rem;
    .product-details-picture {
        display: flex;
        border-radius: 4px;
        width: 107px;
        height: 111px;
        img {
            width: 100%;
            height: 100%;
            max-height: 100%;
            max-width: 100%;
            border-radius: 4px;
        }
    }
    .product-details-name-price-container {
        display: grid;
        height: 60%;
        margin-left: 0.5rem;
        gap: .7rem;
        .product-details-name-text {
            font-size: 20px;
            font-weight: 500;
        }
        .product-details-price-text {
            font-size: 16px;
            font-weight: 300;
        }
    }
    .product-details-size-container {
        display: flex;
        align-items: center;
        width: 65px;
        justify-content: space-between;
        span {
            font-size: 16px;
            font-weight: 500;
            line-height: 31.25px;
        }
        .product-details-size-button {
            display: flex;
            border: 1.5px solid #0F172A;
            width: 24px;
            height: 24px;
            border-radius: 50%;
            background: transparent;
            justify-content: center;
            align-items: center;
        }
    }
    .product-details-order-number-container {
        display: flex;
        width: 65px;
        height: 42px;
        justify-content: space-between;
        align-items: center;
        .order-number-text-container {
            display: flex;
            width: 30.6px;
            font-weight: 500;
            font-size: 18px;
            align-items: center;
            justify-content: center;
            background: transparent;
            outline: 0;
            .order-number-text {
                font-weight: 500;
                font-size: 20px;
                line-height: 41.66px;
            }
        }
        .order-number-container {
            display: flex;
            width: 16px;
            height: 16px;
            justify-content: center;
            align-items: center;
            background: transparent;
        }
    }
    .product-details-price-container {
        display: flex;
        text-align: center;
        span {
            font-size: 18px;
            font-weight: 500;
            line-height:26.04px;
        }
    }
    .product-details-liked-button-container {
        display: flex;
        width: 26px;
        height: 26px;
        justify-content: center;
        align-items: center;
        div {
            display: flex;
            height: 100%;
            width: 100%;
            justify-content: center;
            align-items: center;
            svg {
                width: 24px;
                height: 24px;
            }
        }
    }
    .remove-button-container {
        display: flex;
        margin-right: 0.5rem;
        justify-content: center;
        align-items: center;
        width: 24px;
        height: 24px;
        button {
            display: flex;
            justify-content: center;
            align-items: center;
            background: transparent;
            width: 100%;
            height: 100%;
        }
    }
}

@media only screen and (max-width: 940px) {
    .fc-product-details-container {
        gap: 0px;
        .product-details-picture {
            width: fit-content;
            height: fit-content;
            padding-left: 0.62rem;
            img {
                width: 5.625rem;
                height: 6.375rem;
                object-fit: cover;
            }
        }
        .product-details-name-price-container {
            margin-left: .3rem;
        }
        .product-details-order-number-container {
            position: relative;
            right: -2rem;
            bottom: -1rem;
        }
        .product-details-price-container {
            display: none;
        }
        .product-details-liked-button-container {
            display: none;
        }
        .remove-button-container {
            position: relative;
            right: 1rem;
            top: -2.5rem;
        }
    }
}
</style>