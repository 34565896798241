<script lang="ts">
import { defineComponent } from "vue";
import axios from "axios";

export default defineComponent({
  name: "Loading",
  methods: {
    verifyToken() {
      const token = localStorage.getItem("AuthToken");
      const refreshToken = localStorage.getItem("ReToken");

      if (token) {
        axios
          .post("/auth/jwt/verify", token)
          .then((response) => {
            const answer = response.data;
          })
          .catch((error) => {
            console.error("Error verifying token:", error);
          });
      }
    },
  },
  mounted() {
    //this.verifyToken();
    //document.body.classList.add('no-scroll');
  },
  created() {
    this.verifyToken();
  },
  //    beforeDestroy() {
  //        document.body.classList.remove('no-scroll');
  //        console.log('Removed!')
  //    },
});
</script>

<template>
  <div class="full-width-container">
    <div class="circle"></div>
    <div class="circle"></div>
    <div class="circle"></div>
  </div>
</template>

<style lang="scss">
.no-scroll {
  overflow: hidden;
}

.full-width-container {
  z-index: 10;
  overflow: hidden;
  touch-action: none;
  position: absolute;
  width: 100dvw;
  height: 100dvh;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 6px;
  background: rgba(15, 23, 42, 0.3);
  backdrop-filter: blur(2px);
  .circle {
    width: 8.5px;
    height: 8.5px;
    background-color: #4385f5;
    border-radius: 50%;
    animation: wave 1s infinite alternate;
  }

  .circle:nth-child(2) {
    animation-delay: 0.2s;
  }

  .circle:nth-child(3) {
    animation-delay: 0.4s;
  }

  @keyframes wave {
    0% {
      transform: translateY(0);
    }
    100% {
      transform: translateY(-10px);
    }
  }
}
</style>
