<script lang="ts">
import { defineComponent } from "vue";
import { useRoute } from "vue-router";
import FourthHeader from "@/components/FourthHeader.vue";
import ThirdHeader from "@/components/ThirdHeader.vue";
import ActualComponent from "@/components/MenuSidebar/ActualComponent.vue";
import { toggleLoading } from "@/event-bus";
import { useUserStore } from "@/store/user";

export default defineComponent({
  name: "DefaultLayout",
  components: {
    ThirdHeader,
    ActualComponent,
    FourthHeader,
  },
  data() {
    return {
      menu_choice: "Profile",
      Status: "Sign in",
      selectedOption: "my-profile",
    };
  },
  setup() {
    const userStore = useUserStore();
    return {
      userStore,
    };
  },
  methods: {
    getMenuChoice() {
      toggleLoading(true);
      const route = useRoute();
      const routerChoice = route.name;

      if (routerChoice == "MyProfile") {
        this.menu_choice = "Profile";
        this.selectedOption = "My profile";
        toggleLoading(false);
      } else if (routerChoice == "Orders") {
        this.menu_choice = "Orders";
        this.selectedOption = "Orders";
        toggleLoading(false);
      } else if (routerChoice == "HelpCenter") {
        this.menu_choice = "HelpCenter";
        this.selectedOption = "Help center";
        toggleLoading(false);
      } else if (routerChoice == "Settings") {
        this.menu_choice = "Settings";
        this.selectedOption = "Settings";
        toggleLoading(false);
      } else if (routerChoice === "OrdersOngoing") {
        this.menu_choice = "Ongoing";
        this.selectedOption = "Orders";
        toggleLoading(false);
      } else if (routerChoice === "OrderHistory") {
        this.menu_choice = "History";
        this.selectedOption = "Orders";
        toggleLoading(false);
      } else if (routerChoice === "OrderDrafts") {
        this.menu_choice = "Drafts";
        this.selectedOption = "Orders";
        toggleLoading(false);
      } else if (routerChoice === "Notifications") {
        this.menu_choice = "Notifications";
        this.selectedOption = "Notifications";
        toggleLoading(false);
      }
    },
    getSelectedOption() {
      toggleLoading(true);
      const route = useRoute();
      const routerChoice = route.name;

      if (routerChoice == "MyProfile") {
        this.selectedOption = "My profile";
        toggleLoading(false);
      } else if (routerChoice == "Orders") {
        this.selectedOption = "Orders";
        toggleLoading(false);
      } else if (routerChoice == "HelpCenter") {
        this.selectedOption = "Help center";
        toggleLoading(false);
      } else if (routerChoice == "Settings") {
        this.selectedOption = "Settings";
        toggleLoading(false);
      } else if (routerChoice === "OrdersOngoing") {
        this.selectedOption = "Orders";
        toggleLoading(false);
      } else if (routerChoice === "OrderHistory") {
        this.selectedOption = "Orders";
        toggleLoading(false);
      } else if (routerChoice === "OrderDrafts") {
        this.selectedOption = "Orders";
        toggleLoading(false);
      } else if (routerChoice === "Notifications") {
        this.selectedOption = "Notifications";
        toggleLoading(false);
      }
    },
    navigate(event: Event) {
      let selectedOption = (event.target as HTMLSelectElement).value;
      this.$router.push(`/${selectedOption}`);
    },
    checkUserSignedIn() {
      const token = localStorage.getItem("AuthToken");

      if (token) {
        this.Status = "Log out";
      } else {
        this.Status = "Sign in";
      }
    },
    async logUserOut() {
      await this.userStore.signOut();
      window.location.href = "/";
    },
  },
  watch: {
    $route(to: { name: string }, from: any) {
      if (to.name === "MyProfile") {
        this.menu_choice = "Profile";
        this.selectedOption = "My profile";
      } else if (to.name === "Orders") {
        this.menu_choice = "Orders";
        this.selectedOption = "Orders";
      } else if (to.name === "HelpCenter") {
        this.menu_choice = "Help Center";
        this.selectedOption = "Help center";
      } else if (to.name === "Settings") {
        this.menu_choice = "Settings";
        this.selectedOption = "Settings";
      } else if (to.name === "OrdersOngoing") {
        this.menu_choice = "Ongoing";
        this.selectedOption = "Orders";
      } else if (to.name === "OrderHistory") {
        this.menu_choice = "History";
        this.selectedOption = "Orders";
      } else if (to.name === "OrderDrafts") {
        this.menu_choice = "Drafts";
        this.selectedOption = "Orders";
      } else if (to.name === "Notifications") {
        this.menu_choice = "Notifications";
        this.selectedOption = "Notifications";
      }
    },
  },
  created() {
    this.getMenuChoice();
    document.title = "Account | FondStore";
    this.checkUserSignedIn();
  },
  mounted() {
    this.getSelectedOption();
  },
});
</script>

<template>
  <div id="menu">
    <ThirdHeader class="!mt-24 sm:!mt-0" mainf_header="Account" />
    <div id="menu-sidebar">
      <ul>
        <li>
          <router-link
            class="router-link profile"
            :class="{ selected: menu_choice === 'Profile' }"
            :style="
              menu_choice === 'Profile'
                ? 'font-weight: 500;'
                : 'color: var(--white, #FFF);'
            "
            to="/my-profile"
          >
            <span class="font-class">My profile</span>
          </router-link>
        </li>
        <li>
          <router-link
            class="router-link orders"
            :class="{
              selected:
                menu_choice === 'Orders' ||
                menu_choice === 'Ongoing' ||
                menu_choice === 'History' ||
                menu_choice === 'Drafts',
            }"
            to="/orders"
          >
            <span class="font-class span-selected" id="select">Orders</span>
          </router-link>
        </li>

        <li>
          <router-link class="router-link notifications" to="/notifications">
            <span class="font-class">Notifications</span>
          </router-link>
        </li>

        <li>
          <router-link class="router-link favourites" to="/favourites">
            <span class="font-class">Favourites</span>
          </router-link>
        </li>
        <li>
          <router-link class="router-link cart" to="/cart">
            <span class="font-class">Your cart</span>
          </router-link>
        </li>
        <li>
          <router-link
            class="router-link help-centre"
            :class="{ selected: menu_choice === 'HelpCenter' }"
            to="/help-center"
          >
            <span class="font-class">Help center</span>
          </router-link>
        </li>
        <li>
          <router-link
            class="router-link settings"
            :class="{ selected: menu_choice === 'Settings' }"
            to="/settings"
          >
            <span class="font-class">Settings</span>
          </router-link>
        </li>
        <li @click="logUserOut()" v-if="Status === 'Log out'">
          <span
            style="color: #fa0909; text-decoration: none"
            class="prodpage-link-text font-class logout"
            >Log out</span
          >
        </li>
        <li>
          <div id="signin" v-if="Status === 'Sign in'">
            <router-link class="signing" to="/signin">
              <p>{{ Status }}</p>
            </router-link>
          </div>
        </li>
      </ul>
      <select v-model="selectedOption" @change="navigate">
        <option value="my-profile">
          <router-link
            class="router-link profile"
            :class="{ selected: menu_choice === 'Profile' }"
            :style="
              menu_choice === 'Profile'
                ? 'font-weight: 500;'
                : 'color: var(--white, #FFF);'
            "
            to="/my-profile"
          >
            <span class="font-class">My profile</span>
          </router-link>
        </option>
        <option value="orders">
          <router-link
            class="router-link orders"
            :class="{
              selected:
                menu_choice === 'Orders' ||
                menu_choice === 'Ongoing' ||
                menu_choice === 'History' ||
                menu_choice === 'Drafts',
            }"
            to="/orders"
          >
            <span class="font-class span-selected" id="select">Orders</span>
          </router-link>
        </option>

        <option value="notifications">
          <router-link
            class="router-link notifications"
            to="/notifications"
            :class="{ selected: menu_choice === 'Notifications' }"
          >
            <span class="font-class">Notifications</span>
          </router-link>
        </option>

        <option value="favourites">
          <router-link class="router-link favourites" to="/favourites">
            <span class="font-class">Favourites</span>
          </router-link>
        </option>
        <option value="cart">
          <router-link class="router-link cart" to="/cart">
            <span class="font-class">Your cart</span>
          </router-link>
        </option>
        <option value="help-center">
          <router-link
            class="router-link help-centre"
            :class="{ selected: menu_choice === 'HelpCenter' }"
            to="/help-center"
          >
            <span class="font-class">Help center</span>
          </router-link>
        </option>
        <option value="settings">
          <router-link
            class="router-link settings"
            :class="{ selected: menu_choice === 'Settings' }"
            to="/settings"
          >
            <span class="font-class">Settings</span>
          </router-link>
        </option>

        <option
          value="logout"
          @click="logUserOut()"
          v-if="Status === 'Log out'"
          style="color: #fa0909; text-decoration: none"
          class="prodpage-link-text font-class logout"
        >
          Log out
        </option>
        <option value="signin">
          <div id="signin" v-if="Status === 'Sign in'">
            <router-link class="signing" to="/signin">
              <p>{{ Status }}</p>
            </router-link>
          </div>
        </option>
      </select>

      <div id="page-content">
        <FourthHeader
          v-if="menu_choice === 'Profile'"
          main_header="My profile"
        />
        <FourthHeader
          v-if="menu_choice === 'Settings'"
          main_header="Settings"
        />
        <FourthHeader
          v-if="
            menu_choice === 'Orders' ||
            menu_choice === 'Ongoing' ||
            menu_choice === 'History' ||
            menu_choice === 'Drafts'
          "
          main_header="Orders"
        />
        <FourthHeader
          v-if="menu_choice === 'HelpCenter'"
          main_header="Help Center"
        />
        <FourthHeader
          v-if="menu_choice === 'Notifications'"
          main_header="Notifications"
        />

        <ActualComponent />
      </div>
    </div>
  </div>
</template>

<style lang="scss" scoped>
#menu {
  margin-bottom: 140px;
}

#menu-sidebar {
  font-family: "DM Sans", sans-serif;
  font-size: 20px;
  font-style: normal;
  font-weight: 300;
  line-height: normal;
  margin-right: 55px;
  margin-left: 80px;
  //    width: fit-content;
  //    width: 100%;
  display: flex;
  gap: 20px;
  select {
    display: none;
  }

  #page-content {
    width: 100%;
  }

  ul {
    list-style: none;
    text-decoration: none;
    padding: 37px 43px;
    display: flex;
    flex-direction: column;
    height: fit-content;
    gap: 38px;
    border-radius: 24px;
    border: 1px solid var(--black-30, rgba(15, 23, 42, 0.3));

    li {
      white-space: nowrap;
      a.signing {
        text-decoration: none;
        p {
          border-radius: 30px;
          background: var(--button-default, #4385f5);
          color: var(--white, #fff);
          font-family: "DM Sans", sans-serif;
          font-size: 20px;
          font-weight: 500;
          line-height: normal;
          padding: 10px 20px;
          width: fit-content;
          white-space: nowrap;
          cursor: pointer;
        }
      }
      a.selected {
        display: flex;
        justify-content: center;
        align-items: center;
        border-radius: 30px;
        background: var(--button-default, #4385f5);
        color: var(--white, #fff) !important;
        font-family: "DM Sans", sans-serif;
        font-size: 20px;
        font-weight: 500;
        line-height: normal;
        padding: 10px;
        white-space: nowrap;
        width: 100%;
      }
      span {
        color: var(--black-70, rgba(15, 23, 42, 0.7));
        font-family: "DM Sans", sans-serif;
        font-size: 20px;
        font-style: normal;
        font-weight: 300;
        line-height: normal;
        cursor: pointer;
        white-space: nowrap;
        text-decoration: none;
      }

      span:not(.logout, .selected):hover {
        display: flex;
        justify-content: center;
        align-items: center;
        border-radius: 30px;
        background: var(--button-default, #4385f5);
        color: var(--white, #fff);
        font-family: "DM Sans", sans-serif;
        font-size: 20px;
        font-weight: 500;
        line-height: normal;
        padding: 10px;
        width: 100%;
      }
      span {
        color: var(--black-70, rgba(15, 23, 42, 0.7));
        font-family: "DM Sans", sans-serif;
        font-size: 20px;
        font-style: normal;
        font-weight: 300;
        line-height: normal;
        text-decoration: none;
      }

      span:not(.logout, .selected):hover {
        display: flex;
        justify-content: center;
        align-items: center;
        border-radius: 30px;
        background: var(--button-default, #4385f5);
        color: var(--white, #fff);
        font-family: "DM Sans", sans-serif;
        font-size: 20px;
        font-weight: 500;
        line-height: normal;
        padding: 10px;
        width: 100%;
      }
      a.router-link {
        text-decoration: none !important;

        span {
          color: var(--black-70, rgba(15, 23, 42, 0.7));
          font-family: "DM Sans", sans-serif;
          font-size: 20px;
          font-style: normal;
          font-weight: 300;
          line-height: normal;
          text-decoration: none;
        }

        span:not(.logout, .selected):hover {
          display: flex;
          justify-content: center;
          align-items: center;
          border-radius: 30px;
          background: var(--button-default, #4385f5);
          color: var(--white, #fff);
          font-family: "DM Sans", sans-serif;
          font-size: 20px;
          font-weight: 500;
          line-height: normal;
          padding: 10px;
          width: 100%;
        }
      }
    }
  }
}

@media only screen and (max-width: 940px) {
  #menu-sidebar {
    flex-direction: column;
    margin-left: 1.55rem;
    margin-right: 1.55rem;
    ul {
      display: none;
    }
    select {
      width: fit-content;
      display: flex;
      flex-direction: column;
      border: none;
      border-radius: 24px;
      padding: 1rem 1.5rem;
      background: var(--button-default, #4385f5);
      color: var(--white, #fff);
      font-family: DM Sans;
      font-size: 1rem;
      font-style: normal;
      font-weight: 500;
      line-height: normal;
      //appearance: none;
      //-webkit-appearance: none;
      //background-image: url('@/assets/images/icons/chevron-right.svg') no-repeat right center;
      //background-size: 19px;
      option {
        padding: 1rem 1.5rem;
        font-family: "DM Sans", sans-serif;
        font-size: 1rem;
        a.selected {
          display: flex;
          justify-content: center;
          align-items: center;
          border-radius: 30px;
          background: var(--button-default, #4385f5);
          color: var(--white, #fff);
          font-family: "DM Sans", sans-serif;
          font-weight: 500;
          line-height: normal;
          padding: 10px;
          width: 100%;
        }
        a.router-link {
          text-decoration: none !important;

          span {
            color: var(--black-70, rgba(15, 23, 42, 0.7));
            font-family: "DM Sans", sans-serif;
            font-style: normal;
            font-weight: 300;
            line-height: normal;
            text-decoration: none;
          }
          span:not(.logout, .selected):hover {
            display: flex;
            justify-content: center;
            align-items: center;
            border-radius: 30px;
            background: var(--button-default, #4385f5);
            color: var(--white, #fff);
            font-family: "DM Sans", sans-serif;
            font-weight: 500;
            line-height: normal;
            padding: 10px;
            width: 100%;
          }
        }
      }
    }
  }
}
</style>
