<script lang="ts">
import { defineComponent } from 'vue'
import axios from 'axios';

export default defineComponent({
    name: 'ItemComponent',
    data() {
      return {
        quantity: 1,
        cartbtn: 'Buy now',
        buttonDisabled: false,
        liked_to_favorite: false,
      }
    },
    props: {
        picture_url: String,
        item_name: String,
        price: Number,
        unique_tag: String,
        liked_to_favorite: Boolean,
        id: String,
        slug: String,
        //The unique tag is to tally with the options underneath the mens tab [shirts, pants, accessories, and all of that] they are the unique tag.
        //liked_to_favorite is to ensure if the user has clicked on the liked button therby making it a favorite item
    },
    methods: {
    addToCart() {
        const token = localStorage.getItem('AuthToken');
        this.cartbtn = 'Adding to cart...'
        this.buttonDisabled = true;

        axios.post('/api/carts/add_to_cart/', {product_id: this.id, quantity: this.quantity}
        )
        .then(response => {
          console.log('Product added to cart:', response.data);
          this.cartbtn = 'Added'
          this.buttonDisabled = false;
        })
        .catch(error => {
          console.error('Error adding product to cart:', error);
          this.cartbtn = 'Buy now'
          this.buttonDisabled = false;
        });
    },
    addToFavorites() {
        if (this.liked_to_favorite == false) {
            const token = localStorage.getItem('AuthToken');
            axios.post('/api/profiles/add_to_favourites/', {product_id: this.id},
            {
            headers: {
                        Authorization: `JWT ${token}`,
                    },
            }
            )
            .then(response => {
            alert('Item successfully added to cart!')
            console.log('Item successfully added to favourites:', response.data);
            this.liked_to_favorite = true
            })
            .catch(error => {
            alert('Error adding item to favorites')
            console.error('Error adding item to favourites:', error);
            });
        } else {
            const token = localStorage.getItem('AuthToken')

            axios.post('/api/profiles/remove_from_favourites/', {product_id: this.id},
            {
            headers: {
                        Authorization: `JWT ${token}`,
                    },
            })
            .then(response => {
                this.liked_to_favorite = false
                console.log('Item successfully removed from favourites:', response.data)
            })
            .catch(error => {
                alert('Error removing item from favorites')
                console.error('Error removing item from favourites:', error)
            })
        }
      },
    }
})
</script>

<template>
        <div class="main-item-component-container">
        <div class="item-picture">
            <router-link :to="'/products/' + slug + '/'">
                <img :src="picture_url" alt="Product Photo" loading="lazy">
            </router-link>
            <div class="liked-button">
                <svg @click="addToFavorites" viewBox="0 0 26 24" fill="none" xmlns="http://www.w3.org/2000/svg" v-if="liked_to_favorite == false">
                    <path d="M13.723 22.2789C13.3263 22.4189 12.673 22.4189 12.2763 22.2789C8.89301 21.1239 1.33301 16.3055 1.33301 8.13885C1.33301 4.53385 4.23801 1.61719 7.81967
                    1.61719C9.94301 1.61719 11.8213 2.64385 12.9997 4.23052C14.178 2.64385 16.068 1.61719 18.1797 1.61719C21.7613 1.61719 24.6663
                    4.53385 24.6663 8.13885C24.6663 16.3055 17.1063 21.1239 13.723 22.2789Z" stroke="#0F172A" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                </svg>
                <svg @click="addToFavorites" viewBox="0 0 26 24" fill="#4385F5" xmlns="http://www.w3.org/2000/svg" v-if="liked_to_favorite == true">
                    <path d="M13.723 22.2789C13.3263 22.4189 12.673 22.4189 12.2763 22.2789C8.89301 21.1239 1.33301 16.3055 1.33301 8.13885C1.33301 4.53385 4.23801 1.61719 7.81967
                    1.61719C9.94301 1.61719 11.8213 2.64385 12.9997 4.23052C14.178 2.64385 16.068 1.61719 18.1797 1.61719C21.7613 1.61719 24.6663
                    4.53385 24.6663 8.13885C24.6663 16.3055 17.1063 21.1239 13.723 22.2789Z" stroke="#0F172A" stroke-width="0" stroke-linecap="round" stroke-linejoin="round"/>
                </svg>
            </div>
        </div>
        <div class="item-details-container">
            <router-link :to="'/products/' + slug + '/'">
                <div class="name-price-container">
                    <div class="name-detail-container">
                        <router-link :to="'/products/' + slug + '/'" class="name-text font-class">{{ item_name }}</router-link>
                    </div>
                    <div class="price-detail-container">
                        <span class="price-text font-class">₦{{ price }}</span>
                    </div>
                </div>
            </router-link>
            <div class="buy-button-container">
                <button :disabled="buttonDisabled" @click="addToCart" class="buy-button">
                    <span class="buy-button-text font-class">{{cartbtn}}</span>
                </button>
            </div>
        </div>
        </div>
</template>

<style lang="scss" scoped>
    .main-item-component-container {
    display: grid;
    width: 320px;
    height: 450px;
    border-top-left-radius: 15px;
    border-top-right-radius: 15px;
    .item-picture {
        display: flex;
        position: relative;
        width: 320px;
        height: 385px;
        border-top-left-radius: 15px;
        border-top-right-radius: 15px;
        a {
            text-decoration: none;
            color: none;
            img {
            width: 320px;
            height: 383.5px;
            border-top-left-radius: 15px;
            border-top-right-radius: 15px;
        }
        }
        .liked-button {
            display: flex;
            position: absolute;
            border-radius: 50%;
            outline: 0;
            background: #FFFFFF;
            justify-content: center;
            align-items:center;
            height: 42px;
            width: 42px;
            top: 320px;
            right: 20px;
            svg {
                height: 16px;
                width: 16px;
            }
        }
    }
    .item-details-container {
        display: flex;
        width: 100%;
        height: 65px;
        align-items: center;
        justify-content: space-between;
        a {
            text-decoration: none;
            width: fit-content;
            .name-price-container {
            display: grid;
            width: fit-content;
            height: auto;
            padding: 5px 0px;
            .name-detail-container {
                width: fit-content;
                max-height: 35px;
                display: flex;
                .name-text {
                    text-decoration: none;
                    color: #0F172A;
                    font-weight: 300;
                    font-size: 18px;
                    line-height: 23.44px;
                }
            }
            .price-detail-container {
                width: fit-content;
                max-height: 30px;
                display: flex;
                .price-text {
                    color: #0F172A;
                    font-weight: 700;
                    font-size: 18px;
                    line-height: 31.25px;
                }
            }
        }
        }
        .buy-button-container {
            display: flex;
            width: 20%;
            margin-right: 0.5rem;
            justify-content: center;
            button {
                display: flex;
                height: 20px;
                background: transparent;
                align-items: center;
                justify-content: center;
                color: #4385F5;
                span {
                    font-size: 14px;
                    font-weight: 500;
                    line-height:20.83px;
                }
            }
        }
    }
    }
.font-class {
    font-family: 'DM Sans', sans-serif;
    font-style: normal;
}

@media only screen and (max-width: 940px) {
    .main-item-component-container {
            height: 280px;
            width: 100%; //190px
            border-top-left-radius: 5px;
            border-top-right-radius: 5px;
            .item-picture {
                width: 100%;
                height: 215px;
                border-top-left-radius: 5px;
                border-top-right-radius: 5px;
                img {
                    width: auto; //188px
                    height: 215px;
                    object-fit: cover;
                    max-width: 100%;
                    max-height: 215px;
                    border-top-left-radius: 5px;
                    border-top-right-radius: 5px;
                }
                .liked-button {
                    top: 170px;
                    right: 10px;
                    width: 32px;
                    height: 32px;
                    svg {
                        height: 14px;
                        width: 14px;
                    }
                }
            }
            .item-details-container {
                .name-price-container {
                    width: 67%;
                    align-items: start;
                    padding: 0px 0px;
                    .name-detail-container {
                        margin: 0;
                        width: 100%;
                        height: auto;
                        .name-text {
                            font-size: 16px !important;
                            font-weight: 300;
                        }
                    }
                    .price-detail-container {
                        .price-text {
                            font-size: 14px !important;
                            font-weight: 700;
                        }
                    }
                }
                .buy-button-container {
                    width: 33%;
                    height: 100%;
                    margin: 0;
                    button {
                        width: 100%;
                        height: 100%;
                        span {
                            font-size: 14px !important;
                            font-weight: 500;
                        }
                    }
                }
            }
        }
}
</style>