import axios from "axios";

export const fetchCategories = async () => {
  try {
    const res = await axios.get("api/categories/");

    return res.data;
  } catch (error) {
    console.error(error);
    throw new Error("Something went wrong while fetching categories");
  }
};

export const fetchSubCategories = async () => {
  try {
    const { data } = await axios.get(`/api/subcategories/`);

    return data;
  } catch (error) {
    throw new Error("Something went wrong while fetching sub categories");
  }
};

export const fetchSections = async () => {
  try {
    const { data } = await axios.get(`/api/sections/`);

    return data;
  } catch (error) {
    throw new Error("Something went wrong while fetching sub categories");
  }
};
