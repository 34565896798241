<template>
  <div class="max-w-6xl mx-auto py-10 mt-20">
    <template v-if="!loading && renderedPolicy">
      <div class="flex mb-5 justify-center items-center text-4xl sm:text-xl font-bold">
        <p class="capitalize">{{ policyParam }}</p>
      </div>
      <div class="px-6 md:px-0">
        <span
              class="description-subtext-text font-class"
              v-html="nl2Br(renderedPolicy)"
            ></span>
      </div>
    </template>
    <template v-else>
      <div class="py-40 flex justify-center bg-slate-50">
        <p class="text-gray-400 text-3xl">fetching...</p>
      </div>
    </template>
  </div>
</template>

<script setup lang="ts">
import { usePolicyStore } from "@/store/policy";
import { computed, ref, onMounted, watch } from "vue";
import { useRoute, useRouter } from "vue-router";

const route = useRoute();
const router = useRouter();
const policyStore = usePolicyStore();
const policyList = [
  { name: "Privacy Policy", endpoint: "privacy_policy" },
  { name: "Terms and Conditions", endpoint: "terms_and_condition" },
  { name: "Return and Exchange Policy", endpoint: "return_and_exchange_policy" },
  // { name: 'Cookie Policy', endpoint: undefined}
];
const policyParam = computed(
  () =>
    !!route.params?.policy && (route.params.policy as string).replace(/-/g, " ")
);
const policy = ref();
const renderedPolicy = computed(
  () =>
    Object.keys(policy.value?.[0] || {}).map((key) => policy.value?.[0][key])[0]
);

const loading = ref(false);
const initPage = async () => {
  loading.value = true;
  policy.value = undefined;
  const check =
    policyParam.value &&
    policyList.find(
      (p) => p.name.toLowerCase() === String(policyParam.value).toLowerCase()
    );
  if (!check) return router.push("/");

  policy.value = policyStore.getPolicy(check.endpoint);
  if (policy.value) return (loading.value = false);

  const endpoints = policyList.map((p) => p.endpoint);
  const res = await policyStore.fetchPolicies(endpoints);
  console.log(res);

  if (res) policy.value = policyStore.getPolicy(check.endpoint);
  loading.value = false;
};
 const nl2Br = (text: string) => text.replace(/\n/g, "<br />");
onMounted(initPage);
watch(() => route.params.policy, initPage);
</script>
