<template>
  <div>
    <div class="!h-[29rem] relative overflow-hidden rounded-t-xl">
      <img :src="(image as string)" class="h-full w-full object-cover object-top" loading="lazy" >
      <router-link class="absolute inset-0" :to="`/products/${slug}`"></router-link>
      <div class="absolute bottom-0 pb-5 pr-4 inset-x-0 flex justify-end">
        <button
          @click="handleFavourite" 
          className='w-9 h-9 p-1.5 rounded-full bg-white transition-all duration-300 flex items-center justify-center'>
          <svg
            v-if="!favoriting"
            xmlns="http://www.w3.org/2000/svg"
            class="w-8"
            viewBox="0 0 512 512">
            <path
              :fill="isFavourite ? 'currentColor' : 'none'" stroke="currentColor"
              stroke-linecap="round" stroke-linejoin="round" stroke-width="32"
              d="M352.92 80C288 80 256 144 256 144s-32-64-96.92-64c-52.76 0-94.54 44.14-95.08 96.81c-1.1 109.33 86.73 187.08 183 252.42a16 16 0 0 0 18 0c96.26-65.34 184.09-143.09 183-252.42c-.54-52.67-42.32-96.81-95.08-96.81Z"
            />
          </svg>
          <Spinner v-else />
        </button>
      </div>
    </div>

    <div class="flex justify-between items-center flex-1 mt-4">
      <div>
        <h2 class="text-lg">{{ name }}</h2>
        <div class="mp-price-container flex flex-col !items-start">
          <span
            :class="{'line-through text-red-500 !text-base !text-start' : !!renderedNewPrice }"
            class="price-text font-class">
            {{ renderedPrice }}
          </span>
          <span
            v-if="!!renderedNewPrice"
            class="price-text font-class !leading-none">
            {{ renderedNewPrice }}
          </span>
        </div>
      </div>
      <!-- <button class="text-primary">Buy Now</button> -->
      <!-- <button
        @click="addToCart"
        :disabled="addingToCart"
        class="text-primary font-medium disabled:cursor-not-allowed">
        Add to cart
      </button> -->
    </div>
  </div>
</template>

<script setup lang="ts">
import { defineProps, ref, computed, onMounted } from 'vue';
import Spinner from '@/components/Spinner.vue';
import axios from 'axios';
import { useUserStore } from "@/store/user"
import { useProductStore } from "@/store/product"
import { toast } from 'vue3-toastify';
import 'vue3-toastify/dist/index.css';
import { onBeforeRouteLeave, onBeforeRouteUpdate } from 'vue-router';

type Props = {
  id: null | string
  favorite_id: null | string
  name: string
  image: null | string
  slug: null | string
  price: string
  new_price: null | string
  is_favourite: boolean
}

const props = withDefaults(defineProps<Props>(), {
  id: null,
  name: '--',
  image: null,
  slug: null,
  price: '--',
  is_favourite: false
})

const renderedPrice = computed(()=>Number(props.price).toLocaleString('en-NG', { style: 'currency', currency: 'NGN' }))
const renderedNewPrice = computed(()=>!!props.new_price ? Number(props.new_price).toLocaleString('en-NG', { style: 'currency', currency: 'NGN' }) : null)

const userStore = useUserStore();
const productStore = useProductStore();

const favoriting = ref(false);
const justFavouritedId = ref<string>();
const isFavourite = ref(false)
onMounted(()=>isFavourite.value = props.is_favourite);
const handleFavourite = async () => {
  favoriting.value = true;
  try {
    const res = await productStore.favoriteItem({
      item_id: props.id!,
      favorite_id: justFavouritedId.value || props.favorite_id!,
      isFavorite: isFavourite.value
    })
    isFavourite.value = !isFavourite.value;
    justFavouritedId.value = res?.favorite_id;
  } catch(error) {
    console.log(error)
  } finally {
    favoriting.value = false;
  }
}
const saveUrl = () => {
  const url = window.location.href;
  localStorage.setItem('lastCatPath', url);
}

onBeforeRouteLeave(() => {
  saveUrl();
})

</script>