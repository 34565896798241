import { resolveComponent as _resolveComponent, createVNode as _createVNode, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createCommentVNode as _createCommentVNode, createBlock as _createBlock, vShow as _vShow, withDirectives as _withDirectives, normalizeStyle as _normalizeStyle, createTextVNode as _createTextVNode } from "vue"
import _imports_0 from '../assets/images/icons/share.svg'


const _hoisted_1 = { class: "main-product-page-container mt-20" }
const _hoisted_2 = { class: "main-product-picture-details-container" }
const _hoisted_3 = { class: "mp-main-picture-container" }
const _hoisted_4 = { class: "mp-main-picture bg-gray-50" }
const _hoisted_5 = ["src"]
const _hoisted_6 = { class: "mp-sub-pictures-container sm:ml-4" }
const _hoisted_7 = ["src"]
const _hoisted_8 = ["onClick"]
const _hoisted_9 = { class: "mp-main-details-container" }
const _hoisted_10 = { class: "mp-main-details-header" }
const _hoisted_11 = { class: "details-header-text font-class" }
const _hoisted_12 = { class: "mp-main-details-price-liked-container" }
const _hoisted_13 = { class: "mp-price-container flex flex-col gap-2 !items-start" }
const _hoisted_14 = {
  key: 0,
  class: "price-text font-class !leading-none"
}
const _hoisted_15 = ["disabled"]
const _hoisted_16 = ["fill"]
const _hoisted_17 = { class: "mp-main-rating-number-size-container" }
const _hoisted_18 = { class: "mp-rating-container flex items-center justify-start gap-4" }
const _hoisted_19 = {
  key: 0,
  class: "w-fit"
}
const _hoisted_20 = {
  key: 1,
  class: "w-fit"
}
const _hoisted_21 = { class: "mp-order-number-container" }
const _hoisted_22 = { class: "order-number-container" }
const _hoisted_23 = { class: "order-number-text font-class" }
const _hoisted_24 = { class: "mp-description-container" }
const _hoisted_25 = { class: "description-subtext" }
const _hoisted_26 = ["innerHTML"]
const _hoisted_27 = {
  key: 0,
  class: "font-semibold"
}
const _hoisted_28 = { class: "mt-3 flex gap-x-2" }
const _hoisted_29 = ["onClick"]
const _hoisted_30 = { class: "text-2xl" }
const _hoisted_31 = { class: "italic text-xs" }
const _hoisted_32 = { class: "button-container flex gap-10 w-auto sm:!justify-start" }
const _hoisted_33 = ["disabled"]
const _hoisted_34 = {
  key: 0,
  class: "absolute text-black"
}
const _hoisted_35 = ["src"]
const _hoisted_36 = { class: "flex ml-0 pl-2 md:ml-12 md:pl-6 flex-col gap-4 w-4/6" }
const _hoisted_37 = { class: "text-2xl lg:text-3xl tracking-tight font-extrabold text-gray-900" }
const _hoisted_38 = { key: 1 }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_SecondHeaderVue = _resolveComponent("SecondHeaderVue")!
  const _component_box_icon = _resolveComponent("box-icon")!
  const _component_Spinner = _resolveComponent("Spinner")!
  const _component_StarRating = _resolveComponent("StarRating")!
  const _component_ReviewComponent = _resolveComponent("ReviewComponent")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createElementVNode("div", _hoisted_1, [
      _createVNode(_component_SecondHeaderVue, {
        main_header: "Product Page",
        product_gender: "Men",
        bold_link: "productpage"
      }),
      _createElementVNode("div", _hoisted_2, [
        _createElementVNode("div", _hoisted_3, [
          _createElementVNode("div", _hoisted_4, [
            _createElementVNode("img", {
              loading: "lazy",
              onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.openPreview && _ctx.openPreview(...args))),
              class: _normalizeClass([{ '!w-full !h-full': !_ctx.isLoading }, "h-0 w-0 object-cover object-top cursor-pointer"]),
              src: _ctx.renderedBigImage
            }, null, 10, _hoisted_5),
            _createElementVNode("button", {
              onClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.seek('prev'))),
              class: "arrow-container left"
            }, [
              _createVNode(_component_box_icon, {
                name: "chevron-left",
                color: "#FFFFFF"
              })
            ]),
            _createElementVNode("button", {
              onClick: _cache[2] || (_cache[2] = ($event: any) => (_ctx.seek('next'))),
              class: "arrow-container right"
            }, [
              _createVNode(_component_box_icon, {
                name: "chevron-right",
                color: "#FFFFFF"
              })
            ])
          ]),
          _createElementVNode("div", _hoisted_6, [
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.subPictures, (image, i) => {
              return (_openBlock(), _createElementBlock("div", {
                class: "mp-sub-pictures relative",
                key: image
              }, [
                _createElementVNode("img", {
                  src: image,
                  loading: "lazy"
                }, null, 8, _hoisted_7),
                _createElementVNode("button", {
                  onClick: ($event: any) => (_ctx.setActiveBigImage(i)),
                  class: _normalizeClass([{ '!bg-black/40': i === _ctx.activePictureIndex }, "absolute inset-0 bg-transparent"])
                }, null, 10, _hoisted_8)
              ]))
            }), 128))
          ])
        ]),
        _createElementVNode("div", _hoisted_9, [
          _createElementVNode("div", _hoisted_10, [
            _createElementVNode("span", _hoisted_11, _toDisplayString(_ctx.product?.name), 1)
          ]),
          _createElementVNode("div", _hoisted_12, [
            _createElementVNode("div", _hoisted_13, [
              _createElementVNode("span", {
                class: _normalizeClass([{'line-through text-red-500 !text-base !text-start' : !!_ctx.product?.new_price }, "price-text font-class"])
              }, " ₦" + _toDisplayString(_ctx.product?.price), 3),
              (!!_ctx.product?.new_price)
                ? (_openBlock(), _createElementBlock("span", _hoisted_14, " ₦" + _toDisplayString(_ctx.product?.new_price), 1))
                : _createCommentVNode("", true)
            ]),
            (_ctx.logged_in === true)
              ? (_openBlock(), _createElementBlock("button", {
                  key: 0,
                  onClick: _cache[3] || (_cache[3] = 
//@ts-ignore
(...args) => (_ctx.handleFavourite && _ctx.handleFavourite(...args))),
                  disabled: _ctx.favoriting,
                  class: "flex items-center justify-center h-9 w-9 disabled:cursor-not-allowed"
                }, [
                  (!_ctx.favoriting)
                    ? (_openBlock(), _createElementBlock("svg", {
                        key: 0,
                        viewBox: "0 0 26 24",
                        fill: _ctx.is_favorite ? 'currentColor' : 'none',
                        xmlns: "http://www.w3.org/2000/svg"
                      }, _cache[10] || (_cache[10] = [
                        _createElementVNode("path", {
                          d: "M13.723 22.2789C13.3263 22.4189 12.673 22.4189 12.2763 22.2789C8.89301 21.1239 1.33301 16.3055 1.33301 8.13885C1.33301 4.53385 4.23801 1.61719 7.81967\n                          1.61719C9.94301 1.61719 11.8213 2.64385 12.9997 4.23052C14.178 2.64385 16.068 1.61719 18.1797 1.61719C21.7613 1.61719 24.6663\n                          4.53385 24.6663 8.13885C24.6663 16.3055 17.1063 21.1239 13.723 22.2789Z",
                          stroke: "currentColor",
                          "stroke-width": "1",
                          "stroke-linecap": "round",
                          "stroke-linejoin": "round"
                        }, null, -1)
                      ]), 8, _hoisted_16))
                    : (_openBlock(), _createBlock(_component_Spinner, { key: 1 }))
                ], 8, _hoisted_15))
              : _createCommentVNode("", true)
          ]),
          _createElementVNode("div", _hoisted_17, [
            _createElementVNode("div", _hoisted_18, [
              (_ctx.product?.average_rating)
                ? (_openBlock(), _createElementBlock("div", _hoisted_19, [
                    _createVNode(_component_StarRating, {
                      value: _ctx.ratings_value ? Math.ceil(_ctx.ratings_value) : 0,
                      disabled: !_ctx.product?.has_bought,
                      itemId: _ctx.product.id
                    }, null, 8, ["value", "disabled", "itemId"])
                  ]))
                : (_openBlock(), _createElementBlock("div", _hoisted_20, [
                    _createVNode(_component_StarRating, {
                      value: 0,
                      disabled: !_ctx.product?.has_bought
                    }, null, 8, ["disabled"])
                  ])),
              _createElementVNode("button", {
                onClick: _cache[4] || (_cache[4] = 
//@ts-ignore
(...args) => (_ctx.share && _ctx.share(...args)))
              }, _cache[11] || (_cache[11] = [
                _createElementVNode("img", {
                  loading: "lazy",
                  src: _imports_0,
                  alt: "",
                  class: "w-5 mt-0.5 lg:mt-0"
                }, null, -1)
              ]))
            ]),
            _createElementVNode("div", _hoisted_21, [
              _createElementVNode("button", {
                onClick: _cache[5] || (_cache[5] = 
//@ts-ignore
(...args) => (_ctx.decrementNumber && _ctx.decrementNumber(...args))),
                class: "number-container font-class"
              }, " - "),
              _createElementVNode("div", _hoisted_22, [
                _createElementVNode("span", _hoisted_23, _toDisplayString(_ctx.order_number), 1)
              ]),
              _createElementVNode("button", {
                onClick: _cache[6] || (_cache[6] = 
//@ts-ignore
(...args) => (_ctx.incrementNumber && _ctx.incrementNumber(...args))),
                class: "number-container font-class"
              }, " + ")
            ])
          ]),
          _createElementVNode("div", _hoisted_24, [
            _cache[12] || (_cache[12] = _createElementVNode("div", { class: "description-text-button-container" }, [
              _createElementVNode("span", { class: "description-text font-class" }, "Description")
            ], -1)),
            _createElementVNode("div", _hoisted_25, [
              _createElementVNode("span", {
                class: "description-subtext-text font-class",
                innerHTML: _ctx.nl2Br(_ctx.product?.description)
              }, null, 8, _hoisted_26)
            ])
          ]),
          _withDirectives(_createElementVNode("div", null, [
            (_ctx.product?.size.some((sz) => sz.name.split('-')[0] !== 'No Size'))
              ? (_openBlock(), _createElementBlock("p", _hoisted_27, "Sizes"))
              : _createCommentVNode("", true),
            _createElementVNode("div", _hoisted_28, [
              (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.product?.size, (sz, i) => {
                return (_openBlock(), _createElementBlock("span", {
                  onClick: ($event: any) => (sz?.quantity <= 0 ? console.log('first') : _ctx.changeSelectedSize(i+1)),
                  class: _normalizeClass({
            'border p-3 lg:p-2 cursor-pointer border-gray-400': true,
            'border-blue-500 hover:bg-blue-200 bg-blue-100': _ctx.sizeSelected === (i+1) && sz?.quantity,
            'border-gray-400 hover:bg-gray-100': _ctx.sizeSelected !== (i+1),
            'cursor-not-allowed': sz?.quantity,
          })
                }, [
                  _createElementVNode("p", _hoisted_30, _toDisplayString(sz.name.split('-')[0] === "No Size" ? "" : sz.name.split('-')[0]), 1),
                  _createElementVNode("p", _hoisted_31, _toDisplayString(sz?.quantity) + " units left", 1)
                ], 10, _hoisted_29))
              }), 256))
            ])
          ], 512), [
            [_vShow, _ctx.product?.size?.length > 0]
          ]),
          _withDirectives(_createElementVNode("div", null, [
            _cache[13] || (_cache[13] = _createElementVNode("p", { class: "mt-2 font-semibold" }, "Colors", -1)),
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.product?.colour, (colour) => {
              return (_openBlock(), _createElementBlock("span", {
                class: "w-5 h-5 cursor-pointer rounded-sm",
                style: _normalizeStyle(`background:${colour.toLowerCase()}`)
              }, _toDisplayString(colour), 5))
            }), 256))
          ], 512), [
            [_vShow, _ctx.product?.colour?.length]
          ]),
          _createElementVNode("div", _hoisted_32, [
            _createElementVNode("button", {
              class: _normalizeClass(["px-6 p-3 rounded-3xl text-gray-800 relative flex items-center bg-gray-300 border border-gray-400 justify-center", {
               'cursor-not-allowed bg-gray-300 border border-gray-400': _ctx.sizeSelected && _ctx.product.size[_ctx.sizeSelected - 1]?.quantity <= 0,
               'bg-primary text-white': (_ctx.sizeSelected && _ctx.product.size[_ctx.sizeSelected - 1]?.quantity > 0) || (_ctx.product.size[0].name.split('-')[0] === 'No Size' && _ctx.sizeSelected && _ctx.product.size[0]?.quantity > 0)
             }]),
              disabled: !_ctx.sizeSelected || _ctx.product.size[_ctx.sizeSelected - 1]?.quantity <= 0,
              onClick: _cache[7] || (_cache[7] = 
//@ts-ignore
(...args) => (_ctx.addToCart && _ctx.addToCart(...args)))
            }, [
              _cache[14] || (_cache[14] = _createTextVNode(" Add to Cart ")),
              (_ctx.addingToCart)
                ? (_openBlock(), _createElementBlock("span", _hoisted_34, [
                    _createVNode(_component_Spinner, { class: "w-7" })
                  ]))
                : _createCommentVNode("", true)
            ], 10, _hoisted_33)
          ])
        ])
      ]),
      _createElementVNode("div", {
        onClick: _cache[9] || (_cache[9] = 
//@ts-ignore
(...args) => (_ctx.closePreview && _ctx.closePreview(...args))),
        class: _normalizeClass([{ hidden: !_ctx.imagePreviewIsOpen }, "fixed inset-0 bg-black/10 backdrop-blur-sm z-10 overflow-y-auto flex items-center justify-center overscroll-x-contain"])
      }, [
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.subPictures, (image) => {
          return (_openBlock(), _createElementBlock("img", {
            loading: "lazy",
            onClick: _cache[8] || (_cache[8] = (e) => e.stopPropagation()),
            src: image,
            class: _normalizeClass([{ hidden: !(_ctx.renderedBigImage === image) }, "object-cover"])
          }, null, 10, _hoisted_35))
        }), 256))
      ], 2)
    ]),
    _createElementVNode("div", _hoisted_36, [
      _createElementVNode("div", null, [
        _createElementVNode("h3", _hoisted_37, "Reviews and Comments (" + _toDisplayString(_ctx.review_comments.length) + ")", 1)
      ]),
      (_ctx.review_comments.length > 0)
        ? (_openBlock(true), _createElementBlock(_Fragment, { key: 0 }, _renderList(_ctx.review_comments, (review) => {
            return (_openBlock(), _createBlock(_component_ReviewComponent, {
              key: review.description,
              created_at: review.created_at,
              description: review.description,
              username: review.username,
              rating: review.rating
            }, null, 8, ["created_at", "description", "username", "rating"]))
          }), 128))
        : (_openBlock(), _createElementBlock("div", _hoisted_38, _cache[15] || (_cache[15] = [
            _createElementVNode("div", { class: "h-96 px-4 flex items-center justify-center flex-col text-2xl font-medium text-gray-300" }, [
              _createElementVNode("span", null, " No commennts or reviews on this item yet... come back later. ")
            ], -1)
          ])))
    ])
  ], 64))
}