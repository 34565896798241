import { createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = { class: "main-filter-container" }
const _hoisted_2 = { class: "filter-text-button-container" }
const _hoisted_3 = { class: "filter-text-container" }
const _hoisted_4 = ["onclick"]
const _hoisted_5 = {
  key: 0,
  class: "filter-overlay"
}
const _hoisted_6 = { class: "header-container" }
const _hoisted_7 = ["onclick"]
const _hoisted_8 = { class: "filter-size filter-choice" }
const _hoisted_9 = { class: "boxicon" }
const _hoisted_10 = { class: "filter-style filter-choice" }
const _hoisted_11 = { class: "boxicon" }
const _hoisted_12 = { class: "filter-button-container" }
const _hoisted_13 = { class: "filter-size filter-button" }
const _hoisted_14 = { class: "filter-size filter-button" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_box_icon = _resolveComponent("box-icon")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, [
        _createElementVNode("button", {
          onclick: _ctx.expandFilter,
          class: "filter-text-button"
        }, [
          _cache[0] || (_cache[0] = _createElementVNode("span", { class: "filter-button-text font-class" }, "Filters", -1)),
          _createVNode(_component_box_icon, {
            color: "#494949",
            name: "filter"
          })
        ], 8, _hoisted_4),
        (_ctx.expand_filter == true)
          ? (_openBlock(), _createElementBlock("div", _hoisted_5, [
              _createElementVNode("div", _hoisted_6, [
                _cache[1] || (_cache[1] = _createElementVNode("div", { class: "filter-header-text" }, [
                  _createElementVNode("span", { class: "font-class" }, "Filters")
                ], -1)),
                _createElementVNode("button", {
                  onclick: _ctx.expandFilter,
                  class: "header-container-close"
                }, [
                  _createVNode(_component_box_icon, {
                    class: "box-icon",
                    type: "solid",
                    color: "#4385F5",
                    name: "x-circle"
                  })
                ], 8, _hoisted_7)
              ]),
              _createElementVNode("button", _hoisted_8, [
                _cache[2] || (_cache[2] = _createElementVNode("span", { class: "font-class" }, "Size", -1)),
                _createElementVNode("div", _hoisted_9, [
                  _createVNode(_component_box_icon, { name: "chevron-down" })
                ])
              ]),
              _createElementVNode("button", _hoisted_10, [
                _cache[3] || (_cache[3] = _createElementVNode("span", { class: "font-class" }, "Style", -1)),
                _createElementVNode("div", _hoisted_11, [
                  _createVNode(_component_box_icon, { name: "chevron-down" })
                ])
              ]),
              _cache[4] || (_cache[4] = _createElementVNode("button", { class: "clr-btn" }, [
                _createElementVNode("span", { class: "clr-fltr-btn-text font-class" }, "Clear all filters")
              ], -1))
            ]))
          : _createCommentVNode("", true)
      ]),
      _createElementVNode("div", _hoisted_12, [
        _createElementVNode("button", _hoisted_13, [
          _cache[5] || (_cache[5] = _createElementVNode("span", { class: "font-class" }, "Size", -1)),
          _createVNode(_component_box_icon, { name: "chevron-down" })
        ]),
        _createElementVNode("button", _hoisted_14, [
          _cache[6] || (_cache[6] = _createElementVNode("span", { class: "font-class" }, "Style", -1)),
          _createVNode(_component_box_icon, { name: "chevron-down" })
        ])
      ])
    ]),
    _cache[7] || (_cache[7] = _createElementVNode("div", { class: "filter-clr-btn-container" }, [
      _createElementVNode("button", { class: "clr-fltr-btn" }, [
        _createElementVNode("span", { class: "clr-fltr-btn-text font-class" }, "Clear all filters")
      ])
    ], -1))
  ]))
}